import './buttonLink.scss';

export const ButtonLink = ({ children, handleClick, Icon }) => {
  return (
    <div onClick={handleClick} className="button-link">
      <p className="label">{children}</p>
      <Icon />
    </div>
  );
};
