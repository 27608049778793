export const productionQueueColumns = [
  {
    label: '#',
    value: '',
    mobile: true,
  },
  {
    label: 'Material Type',
    value: 'materialType',
    mobile: true,
  },
  {
    label: 'Minutes before Stockout',
    value: 'minutesBeforeStockout',
  },
  {
    label: 'Batch size',
    value: 'batchSize',
    minWidth: '88px',
  },
  {
    label: 'Minute demand',
    value: 'minuteDemand',
    minWidth: '100px',
  },
  {
    label: 'Task delay',
    value: 'delay',
    endOfTable: true,
  },
  {
    label: 'Bread chicken',
    value: 'breadChicken',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Load chicken in basket',
    value: 'loadChickenInBasket',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Transfer basket to fryer',
    value: 'transferBasketToFryer',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Load into oil for cooking',
    value: 'loadIntoOilForCooking',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Transfer basket to CTS',
    value: 'transferBasketToCTS',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Transfer pan to hot holding',
    value: 'transferPanToHotHolding',
    width: '11%',
    minWidth: '80px',
  },
];

export const cateringQueueColumns = [
  {
    label: 'Prod. Priority',
    value: 'priority',
    mobile: true,
  },
  {
    label: '#',
    value: 'batchNumber',
    mobile: true,
  },
  {
    label: 'Material Type',
    value: 'material',
  },
  {
    label: 'Batch size',
    value: 'batchSize',
    minWidth: '88px',
  },
  {
    label: 'Task delay',
    value: 'delay',
    endOfTable: true,
  },
  {
    label: 'Bread chicken',
    value: 'breadChicken',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Load chicken in basket',
    value: 'loadChickenInBasket',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Transfer basket to fryer',
    value: 'transferBasketToFryer',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Load into oil for cooking',
    value: 'loadIntoOilForCooking',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Transfer basket to CTS',
    value: 'transferBasketToCTS',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Transfer pan to hot holding',
    value: 'transferPanToHotHolding',
    width: '11%',
    minWidth: '80px',
  },
  {
    label: 'Batch Complete',
    value: 'batchComplete',
    width: '11%',
    minWidth: '80px',
  },
];

export const futureQueueColumns = [
  {
    label: '#',
    value: '',
    mobile: true,
  },
  {
    label: 'Material Type',
    value: 'materialType',
    mobile: true,
  },
  {
    label: 'Scheduled Time',
    value: 'scheduledTime',
  },
  {
    label: 'Batch size',
    value: 'batchSize',
  },
  {
    label: 'Min. Batch Spacing',
    value: 'minBatchSpacing',
  },
  {
    label: 'Progress',
    value: 'progress',
    endOfTable: true,
  },
  {
    label: 'Waiting',
    value: 'waiting',
    width: '15%',
    minWidth: '72px',
  },
  {
    label: 'In Progress',
    value: 'inProgress',
    width: '15%',
    minWidth: '72px',
  },
  {
    label: 'Ready',
    value: 'ready',
    width: '15%',
    minWidth: '72px',
  },
];

export const statusValues = {
  BREADING_COMPLETE: 'BREADING_COMPLETE',
  GO: 'GO',
  CANCEL: 'CANCEL',
  WAIT: 'WAIT',
};

export const animationValues = {
  ACTIVATED: 'ACTIVATED',
  DELAYED: 'DELAYED',
  COMPLETED: 'COMPLETED',
  FLAT: 'FLAT',
};

export const statusColors = [
  {
    id: 3,
    label: 'Breading Complete',
    value: statusValues.BREADING_COMPLETE,
    color: '#009408',
  },
  {
    id: 2,
    label: 'Go',
    value: statusValues.GO,
    color: '#DBB91D',
  },
  {
    id: 1,
    label: 'Cancelled',
    value: statusValues.CANCEL,
    color: '#CB444A',
  },
  {
    id: 0,
    label: 'Waiting',
    value: statusValues.WAIT,
    color: '#C2C2C2',
  },
];

export const emptyStage = {
  name: null,
  stage: 0,
};

export const prqStages = [
  {
    name: 'BREADING',
    stage: 1,
  },
  {
    name: 'BASKET_LOADING',
    stage: 2,
  },
  {
    name: 'BASKET_TRANSFER',
    stage: 3,
  },
  {
    name: 'BASKET_FRYING',
    stage: 4,
  },
  {
    name: 'CTS_TRANSFER',
    stage: 5,
  },
  {
    name: 'HOT_HOLDING_TRANSFER',
    stage: 6,
  },
];

export const prqDisabledStatuses = [statusValues.WAIT];

export const cateringPCSStages = [
  {
    name: 'BREADING',
    stage: 1,
  },
  {
    name: 'BASKET_LOADING',
    stage: 2,
  },
  {
    name: 'BASKET_TRANSFER',
    stage: 3,
  },
  {
    name: 'BASKET_FRYING',
    stage: 4,
  },
  {
    name: 'CTS_TRANSFER',
    stage: 5,
  },
  {
    name: 'HOT_HOLDING_TRANSFER',
    stage: 6,
  },
  {
    name: 'BATCH_COMPLETE',
    stage: 7,
  },
];

export const stageProgressValues = {
  BEGIN: 'BEGIN',
  COMPLETED: 'COMPLETED',
};

export const notificationDestinations = {
  production: 'PRODUCTION_QUEUE',
  catering: 'CATERING_QUEUE',
};

export const queueTypes = [
  {
    label: 'Production',
    value: 'production',
  },
  {
    label: 'Catering',
    value: 'catering',
  },
];
