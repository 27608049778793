import request from '../utils/request';

export function getUser(id) {
  return request({
    url: `/users/${id}`,
    method: 'get',
  });
}

export function getUsers(params, signal) {
  return request({
    url: '/users',
    method: 'get',
    data: params,
    signal,
  });
}

export function createUser(params) {
  return request({
    url: '/users',
    method: 'post',
    data: params,
  });
}

export function updateUser(params) {
  return request({
    url: '/users/update',
    method: 'post',
    data: params,
  });
}

export function updateUserNotification(uuid, params) {
  return request({
    url: `/users/notification/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteUser(uuid) {
  return request({
    url: `/users/${uuid}`,
    method: 'delete',
  });
}

export function getUserRole(params) {
  return request({
    url: '/role',
    method: 'get',
    data: params,
  });
}

export function setPassword(params) {
  return request({
    url: '/users/password/reset',
    method: 'post',
    data: params,
  });
}

export function forgotPassword(params) {
  return request({
    url: '/users/password/forgot',
    method: 'post',
    data: params,
  });
}

export function getMe() {
  return request({
    url: `/users/user`,
    method: 'get',
  });
}

export function changePassword(params) {
  return request({
    url: '/users/password',
    method: 'put',
    data: params,
  });
}

export function checkSecretKey(params) {
  return request({
    url: '/users/password/reset/check',
    method: 'get',
    data: params,
  });
}

export function sendInvitation(userUuid) {
  return request({
    url: `/users/invitation/${userUuid}`,
    method: 'post',
  });
}

export function blockUser(userUuid, params) {
  return request({
    url: `/users/${userUuid}/block`,
    method: 'put',
    data: params,
  });
}

export function unblockUser(userUuid) {
  return request({
    url: `/users/${userUuid}/unblock`,
    method: 'put',
  });
}
