import cx from 'classnames';

import './FormRadioInput.scss';

export const FormRadioSlider = ({ title, checked, disabled, onChange }) => {
  return (
    <div className={cx('form-radio__slider__container', { disabled: disabled })}>
      <div className="form-radio__slider__span">
        <input
          className="form-radio__slider__input"
          type="checkbox"
          id="switch"
          disabled={disabled}
          checked={checked}
          onClick={(e) => {
            onChange(e);
          }}
        />
        <label className="form-radio__slider__label" htmlFor="switch"></label>
      </div>
      <span className="form-radio__slider__span">{title}</span>
    </div>
  );
};
