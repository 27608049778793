import InfiniteScroll from 'react-infinite-scroll-component';
import { TableContentContainer } from '../TableContentContainer';
import { MobileTHead } from './MobileTHead';
import { InfoRow } from '../InfoRow';
import { MobileTableContent } from './MobileTBody/MobileTableContent';
import { useOpenedRows } from './useOpenedRows';

export const MobileScrollableTable = ({
  rows,
  nextData,
  existsNextData,
  isLoading,
  isScrollLoading,
  scrollableTarget,
  headerOptions,
}) => {
  const [openedRows, checkOpenedRows] = useOpenedRows();

  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={nextData}
      hasMore={existsNextData && !isLoading && !isScrollLoading}
      scrollableTarget={scrollableTarget || 'mobile-table-container'}
      scrollThreshold={0.99}
      style={{ overflowY: 'hidden' }}
    >
      <div className="mobile-table__wrapper">
        <table className="mobile-table__table">
          <MobileTHead headerOptions={headerOptions} />
          <TableContentContainer rows={rows} isLoading={isLoading} isMobile>
            <MobileTableContent
              rows={rows}
              openedRows={openedRows}
              checkOpenedRows={checkOpenedRows}
              headerOptions={headerOptions}
            />
            {isScrollLoading && (
              <InfoRow>
                <i className="fa fa-spinner fa-spin fa-2x" />
              </InfoRow>
            )}
          </TableContentContainer>
        </table>
      </div>
    </InfiniteScroll>
  );
};
