import { isNil } from 'lodash';
import React from 'react';

export const BodyCell = ({
  cell: { label, cellComponent, align, padding, paddingLeft, paddingRight, color, width },
}) => {
  return (
    <td
      className="custom-table__body-cell"
      align={align || 'left'}
      style={{ padding, paddingLeft, paddingRight, color, width }}
    >
      {cellComponent ? cellComponent : !isNil(label) ? label : ''}
    </td>
  );
};
