import {
  getTablets,
  deleteTablet,
  getTabletsStreamNames,
  getTabletsAvailability,
  changeTabletRemoteAccess,
} from '../../api/tablets';

export const CHANGE_RESTAURANT_TABLETS_VALUE = 'CHANGE_RESTAURANT_TABLETS_VALUE';
export const CHANGE_RESTAURANT_TABLETS_SEVERAL_VALUES = 'CHANGE_RESTAURANT_TABLETS_SEVERAL_VALUES';
export const CHANGE_RESTAURANT_TABLETS_COMMAND_VALUE = 'CHANGE_RESTAURANT_TABLETS_COMMAND_VALUE';
export const RESET_RESTAURANT_TABLETS_STORE = 'RESET_RESTAURANT_TABLETS_STORE';

export const GET_RESTAURANT_TABLETS = 'GET_RESTAURANT_TABLETS';
export const GET_RESTAURANT_TABLETS_SUCCESS = 'GET_RESTAURANT_TABLETS_SUCCESS';
export const GET_RESTAURANT_TABLETS_ERROR = 'GET_RESTAURANT_TABLETS_ERROR';

export const GET_RESTAURANT_TABLETS_STREAM_NAMES = 'GET_RESTAURANT_TABLETS_STREAM_NAMES';
export const GET_RESTAURANT_TABLETS_STREAM_NAMES_SUCCESS = 'GET_RESTAURANT_TABLETS_STREAM_NAMES_SUCCESS';
export const GET_RESTAURANT_TABLETS_STREAM_NAMES_ERROR = 'GET_RESTAURANT_TABLETS_STREAM_NAMES_ERROR';

export const CHANGE_RESTAURANT_TABLET = 'CHANGE_RESTAURANT_TABLET';
export const CHANGE_RESTAURANT_TABLET_SUCCESS = 'CHANGE_RESTAURANT_TABLET_SUCCESS';
export const CHANGE_RESTAURANT_TABLET_ERROR = 'CHANGE_RESTAURANT_TABLET_ERROR';

export const CHANGE_RESTAURANT_TABLET_REMOTE_ACCESS = 'CHANGE_RESTAURANT_TABLET_REMOTE_ACCESS';
export const CHANGE_RESTAURANT_TABLET_REMOTE_ACCESS_SUCCESS =
  'CHANGE_RESTAURANT_TABLET_REMOTE_ACCESS_SUCCESS';
export const CHANGE_RESTAURANT_TABLET_REMOTE_ACCESS_ERROR = 'CHANGE_RESTAURANT_TABLET_REMOTE_ACCESS_ERROR';

export const GET_RESTAURANT_TABLETS_AVAILABILITY = 'GET_RESTAURANT_TABLETS_AVAILABILITY';
export const GET_RESTAURANT_TABLETS_AVAILABILITY_SUCCESS = 'GET_RESTAURANT_TABLETS_AVAILABILITY_SUCCESS';
export const GET_RESTAURANT_TABLETS_AVAILABILITY_ERROR = 'GET_RESTAURANT_TABLETS_AVAILABILITY_ERROR';

export const getRestaurantTabletsAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_RESTAURANT_TABLETS });
    try {
      const response = await getTablets(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_RESTAURANT_TABLETS_SUCCESS, payload: results, params: params });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_RESTAURANT_TABLETS_ERROR });
    }
  };
};

export const getRestaurantTabletStreamNamesAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_RESTAURANT_TABLETS_STREAM_NAMES });
    try {
      const response = await getTabletsStreamNames(params);
      const { success, results } = response;
      if (success) {
        setData(results);
        dispatch({ type: GET_RESTAURANT_TABLETS_STREAM_NAMES_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_RESTAURANT_TABLETS_STREAM_NAMES_ERROR });
    }
  };
};

export const getTabletsStreamNamesAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_RESTAURANT_TABLETS_STREAM_NAMES });
    try {
      const response = await getTabletsStreamNames(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_RESTAURANT_TABLETS_STREAM_NAMES_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_RESTAURANT_TABLETS_STREAM_NAMES_ERROR });
    }
  };
};

export const deleteTabletRestaurantAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_RESTAURANT_TABLET });
    try {
      const response = await deleteTablet(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: CHANGE_RESTAURANT_TABLET_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: CHANGE_RESTAURANT_TABLET_ERROR });
    }
  };
};

export const getTabletsAvailabilityAction = (params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_RESTAURANT_TABLETS_AVAILABILITY });
    try {
      const response = await getTabletsAvailability(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_RESTAURANT_TABLETS_AVAILABILITY_SUCCESS, payload: results, params });
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_RESTAURANT_TABLETS_AVAILABILITY_ERROR });
      actions.onError && actions.onError(error.message);
    }
  };
};

export const changeTabletRemoteAccessAction = (uuid, params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_RESTAURANT_TABLET_REMOTE_ACCESS });
    try {
      const response = await changeTabletRemoteAccess(uuid, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
        dispatch({
          type: CHANGE_RESTAURANT_TABLET_REMOTE_ACCESS_SUCCESS,
          payload: { uuid, remoteAccess: params.remoteAccess },
        });
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
      dispatch({ type: CHANGE_RESTAURANT_TABLET_REMOTE_ACCESS_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_RESTAURANT_TABLETS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_RESTAURANT_TABLETS_SEVERAL_VALUES,
  payload: value,
});

export const changeCommandValue = (key, value) => ({
  type: CHANGE_RESTAURANT_TABLETS_COMMAND_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_RESTAURANT_TABLETS_STORE,
});
