import request from '../utils/request';

export function getChickenEvents(params, signal) {
  return request({
    url: '/chickenEvent/chickenEvents',
    method: 'get',
    data: params,
    signal,
  });
}

export function getChickenEventNames() {
  return request({
    url: '/dictionaries/chickenEventName',
    method: 'get',
  });
}

export function getExelExport(params) {
  return request({
    url: '/chickenEvent/chickenEvents/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}
