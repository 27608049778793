import React from 'react';

import { BarChart, PieChart, TimeseriesChart } from './Charts';

import { useChartInterval } from '../../hooks/useChartInterval';

import './AnalyticsContent.scss';
import '../Analytics/Chart/Chart.scss';

export const AnalyticsContent = ({ activeTab, isLoading, content, mobile, dates, changeValue }) => {
  const intervalState = useChartInterval(dates.startDate, dates.endDate, dates.timeInterval, (value) =>
    changeValue('interval', value)
  );

  const getContent = () => {
    switch (activeTab) {
      case 'graphic':
        return (
          <TimeseriesChart
            content={content}
            mobile={mobile}
            dates={dates}
            intervalOptions={intervalState}
            isLoading={isLoading}
            changeValue={changeValue}
          />
        );

      case 'bar': {
        const hasSomeData = content?.totalValues?.some((total) => total.value > 0);

        return <BarChart content={hasSomeData ? content : null} mobile={mobile} isLoading={isLoading} />;
      }
      default:
        return <PieChart content={content} isLoading={isLoading} />;
    }
  };

  return <div className="analytics-content">{getContent()}</div>;
};
