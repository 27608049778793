import * as types from '../actions/monitoringAlertsAction';

const initialState = {
  restaurantUuid: null,
  severity: null,
  status: null,
  graphContent: [],
  restaurants: [{ label: 'Select Restaurant', value: 'select' }],
  hosts: [],
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: null,
  orderDirection: 'DESC',
  sortBy: 'startEventTime',
  limit: '20',
  page: 0,
  totalResults: 0,
  total: 0,
  critical: 0,
  warning: 0,
  loading: true,
  chartLoading: false,
  scrollLoading: false,
  waitingSearch: false,
  search: null,
  data: [],
};

const monitoringAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_MONITORING_ALERTS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_MONITORING_ALERTS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_MONITORING_ALERTS_RESTAURANTS_SUCCESS:
      return {
        ...state,
        restaurants:
          action.payload && action.payload.content.length !== 0
            ? [
                { label: 'Select Restaurant', value: 'select' },
                ...action.payload.content.map((rest) => ({
                  label: rest.number + ' - ' + rest.name,
                  value: rest.uuid,
                  hosts: rest.hosts,
                })),
              ]
            : [{ label: 'Select Restaurant', value: 'select' }],
      };

    case types.GET_MONITORING_ALERTS_ANALYTIC:
      return {
        ...state,
        chartLoading: true,
      };

    case types.GET_MONITORING_ALERTS_ANALYTIC_SUCCESS:
      return {
        ...state,
        chartLoading: false,
        graphContent:
          action.payload.length !== 0
            ? state.severity === 'all'
              ? action.payload[0].values.map((item, index) => [
                  new Date(item[0] * 1000),
                  action.payload.reduce(function (sum, elem) {
                    return sum + Number(elem.values[index][1].warning);
                  }, 0),
                  action.payload.reduce(function (sum, elem) {
                    return sum + Number(elem.values[index][1].critical);
                  }, 0),
                ])
              : state.severity === 'warning'
              ? action.payload[0].values.map((item, index) => [
                  new Date(item[0] * 1000),
                  action.payload.reduce(function (sum, elem) {
                    return sum + Number(elem.values[index][1].warning);
                  }, 0),
                ])
              : action.payload[0].values.map((item, index) => [
                  new Date(item[0] * 1000),
                  action.payload.reduce(function (sum, elem) {
                    return sum + Number(elem.values[index][1].critical);
                  }, 0),
                ])
            : [[0, 0]],
      };

    case types.GET_MONITORING_ALERTS_ANALYTIC_ERROR:
      return {
        ...state,
        chartLoading: false,
      };

    case types.GET_MONITORING_ALERTS:
      return {
        ...state,
        data: action.payload ? [] : state.data,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_MONITORING_ALERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        page: Math.floor(action.payload.offset / action.payload.limit),
        totalResults: action.payload.total,
        data:
          action.payload.offset !== 0 ? [...state.data, ...action.payload.content] : action.payload.content,
      };

    case types.GET_MONITORING_ALERTS_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
      };

    case types.RESET_MONITORING_ALERTS_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default monitoringAlertsReducer;
