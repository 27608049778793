import { getFormattedWaste } from '../../utils/dashboard';
import * as types from '../actions/chickenDashAction';
import { isNil, isObject } from 'lodash';
import {
  formatMsToMinutes,
  formatMsToSeconds,
  formatSecondsToMinutes,
} from '../../containers/Dashboard/ChickenDashboard/ChartCard/utils';
import { weightMeasurementUnits } from '../../constants/dashboard.constants';

const getChartData = (data, chickenTypes, customFormat) => {
  return data?.[0]?.values?.map((item, index) => {
    let sum = 0;
    return [
      new Date(item[0] * 1000),
      ...[...chickenTypes].map((type) => {
        if (type === 'total') {
          return sum;
        }

        let value = data.reduce(function (sum, elem) {
          return sum + Number(elem.values[index][1][type] > 0 ? elem.values[index][1][type] : 0);
        }, 0);

        const finalValue = value ? (customFormat ? customFormat(value) : getFormattedWaste(value)) : 0;
        sum += finalValue;
        return finalValue;
      }),
    ];
  });
};

const getDistributionData = (data, key) => {
  let total = 0;
  const distributionData = data?.map((item) => {
    total += item[key];
    return { ...item, value: item[key] };
  });
  return {
    distributionData,
    total: getFormattedWaste(total),
  };
};

const getTimersTableData = (data) => {
  const materialsWithPeriods = isObject(data) ? Object.entries(data) : [];

  let tableData = [];
  const headers = [
    {
      label: 'Product',
      mobile: true,
    },
  ];

  if (materialsWithPeriods.length > 0) {
    tableData = materialsWithPeriods.map(([material, periods]) => ({
      material,
      periods: Object.values(periods),
    }));
    Object.keys(materialsWithPeriods[0][1]).forEach((period) => {
      headers.push({
        label: `${period} Minutes`,
      });
    });
  }

  return { headers, data: tableData };
};

const initialState = {
  restaurantUuid: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  interval: null,
  intervalEnd: null,
  timezone: null,
  chickenTypes: [],
  period: 'DAY',
  settings: {
    units: weightMeasurementUnits[localStorage.getItem('settingsUnits')]
      ? localStorage.getItem('settingsUnits')
      : 'kg',
  },
  activeTab: 'summaryStatus',
  isFileLoading: false,
  charts: {
    summaryHTR: {
      groupTitle: 'Hold Time Distribution by Product',
      rows: null,
      options: {},
    },
    wasteSummary: {
      groupTitle: 'Waste Result',
      rows: null,
      options: {},
    },
    wasteByDayPart: {
      groupTitle: 'Waste by Products & Daypart',
      rows: null,
      options: {},
    },
    detailedHoldingTime: {
      groupTitle: 'Average Hold Time Trend',
      rows: null,
      options: {},
    },
    detailedWaste: {
      groupTitle: 'Waste Trend',
      rows: null,
      options: {},
    },
    holdTimeDistr: {
      groupTitle: 'Hold Time Distribution',
      rows: null,
      options: {},
    },
    averageHoldTime: {
      groupTitle: 'Average Hold Time by Product & Daypart',
      rows: null,
      options: {},
    },
    panCyclesAverage: {
      groupTitle: 'Average Pan Cycles per Hour with no filters - by Daypart & Product',
      rows: null,
      options: {},
    },
    panCycles: {
      groupTitle: 'Pan Cycles with no filters',
      rows: null,
      options: {},
    },
  },
  sortModel: {
    cooked: {
      sortBy: 'value',
      orderDirection: 'DESC',
    },
    waste: {
      sortBy: 'value',
      orderDirection: 'DESC',
    },
    recommendations: {
      sortBy: 'value',
      orderDirection: 'DESC',
    },
    containers: {
      sortBy: 'value',
      orderDirection: 'DESC',
    },
    conveyors: {
      sortBy: 'count',
      orderDirection: 'DESC',
    },
    stockOut: {
      sortBy: 'time',
      orderDirection: 'DESC',
    },
    taskDelay: {
      sortBy: 'averageTaskDelay',
      orderDirection: 'DESC',
    },
    fryerCycles: {
      sortBy: 'total',
      orderDirection: 'DESC',
    },
  },
  performanceRatio: [],
  performanceRatioType: 'COOKED/RECOMMENDATION',
  tabsModel: {
    cooked: 'production',
    recommended: 'production',
    containers: 'production',
    conveyors: 'inbound',
  },
  data: {
    timersChart: null,
    totalUsage: null,
    cooked: [],
    cookedDistribution: null,
    cookedCatering: [],
    cookedCateringDistribution: null,
    totalCooked: null,
    totalCookedCatering: null,
    totalWaste: null,
    totalRecommendations: null,
    totalRecommendationsCatering: null,
    totalContainers: null,
    totalContainersCatering: null,
    totalInboundConveyors: null,
    totalOutboundConveyors: null,
    totalFryerCycles: null,
    totalStockOut: null,
    totalTaskDelay: null,
    waste: null,
    wasteDistribution: null,
    recommendations: null,
    recommendationsDistribution: null,
    recommendationsCatering: null,
    recommendationsCateringDistribution: null,
    containersDistribution: null,
    containersCateringDistribution: null,
    inboundConveyorsDistribution: null,
    outboundConveyorsDistribution: null,
    fryerCycles: null,
    fryerCyclesDistribution: null,
    stockOut: null,
    stockOutDistribution: null,
    taskDelay: null,
    taskDelayDistribution: null,
  },
  loading: {
    timersChart: false,
    totalUsage: false,
    cooked: false,
    cookedDistribution: false,
    cookedCatering: false,
    cookedCateringDistribution: false,
    waste: false,
    wasteDistribution: false,
    recommendations: false,
    recommendationsDistribution: false,
    recommendationsCatering: false,
    recommendationsCateringDistribution: false,
    containersDistribution: false,
    containersCateringDistribution: false,
    inboundConveyorsDistribution: false,
    outboundConveyorsDistribution: false,
    fryerCycles: false,
    fryerCyclesDistribution: false,
    stockOut: false,
    stockOutDistribution: false,
    taskDelay: false,
    taskDelayDistribution: false,
    performanceRatio: false,
  },
};

const chickenDashReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CHICKEN_DASHBOARD_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_CHICKEN_DASHBOARD_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_TOTAL_USAGE: {
      return {
        ...state,
        loading: {
          ...state.loading,
          totalUsage: true,
        },
      };
    }

    case types.ADD_TOTAL_USAGE: {
      return {
        ...state,
        data: {
          ...state.data,
          totalUsage: action.payload
            ? Object.keys(action.payload).reduce((curr, key) => {
                const value = action.payload[key];

                if (isNil(value)) return curr;

                if (key === 'conveyor') {
                  return {
                    ...curr,
                    [key]: value,
                  };
                }

                if (isObject(value)) {
                  return {
                    ...curr,
                    [key]: Object.keys(value).reduce(
                      (curr, key) => ({
                        ...curr,
                        [key]: getFormattedWaste(value[key]),
                      }),
                      {}
                    ),
                  };
                }

                return {
                  ...curr,
                  [key]: getFormattedWaste(value),
                };
              }, {})
            : null,
        },
        loading: {
          ...state.loading,
          totalUsage: false,
        },
      };
    }

    case types.GET_COOKED_CHART_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          cooked: true,
        },
      };
    }

    case types.ADD_COOKED_CHART_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          cooked: getChartData(action.payload, state.chickenTypes),
        },
        loading: {
          ...state.loading,
          cooked: false,
        },
      };
    }

    case types.GET_COOKED_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          cookedDistribution: true,
        },
      };
    }

    case types.ADD_COOKED_DISTRIBUTION_DATA: {
      const { distributionData, total } = getDistributionData(action.payload, 'cookedWeight');
      return {
        ...state,
        data: {
          ...state.data,
          cookedDistribution: distributionData,
          totalCooked: total,
        },
        loading: {
          ...state.loading,
          cookedDistribution: false,
        },
      };
    }

    case types.GET_COOKED_CATERING_CHART_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          cookedCatering: true,
        },
      };
    }

    case types.ADD_COOKED_CATERING_CHART_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          cookedCatering: getChartData(action.payload, state.chickenTypes),
        },
        loading: {
          ...state.loading,
          cookedCatering: false,
        },
      };
    }

    case types.GET_COOKED_CATERING_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          cookedCateringDistribution: true,
        },
      };
    }

    case types.ADD_COOKED_CATERING_DISTRIBUTION_DATA: {
      const { distributionData, total } = getDistributionData(action.payload, 'cookedWeight');
      return {
        ...state,
        data: {
          ...state.data,
          cookedCateringDistribution: distributionData,
          totalCookedCatering: total,
        },
        loading: {
          ...state.loading,
          cookedCateringDistribution: false,
        },
      };
    }

    case types.GET_WASTE_CHART_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          waste: true,
        },
      };
    }

    case types.ADD_WASTE_CHART_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          waste: getChartData(action.payload, state.chickenTypes),
        },
        loading: {
          ...state.loading,
          waste: false,
        },
      };
    }

    case types.GET_WASTE_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          wasteDistribution: true,
        },
      };
    }

    case types.ADD_WASTE_DISTRIBUTION_DATA: {
      const { distributionData, total } = getDistributionData(action.payload, 'wasteWeight');

      return {
        ...state,
        data: {
          ...state.data,
          wasteDistribution: distributionData,
          totalWaste: total,
        },
        loading: {
          ...state.loading,
          wasteDistribution: false,
        },
      };
    }

    case types.GET_RECOMMENDATIONS_CHART_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          recommendations: true,
        },
      };
    }

    case types.ADD_RECOMMENDATIONS_CHART_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          recommendations: getChartData(action.payload, state.chickenTypes),
        },
        loading: {
          ...state.loading,
          recommendations: false,
        },
      };
    }

    case types.GET_RECOMMENDATIONS_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          recommendationsDistribution: true,
        },
      };
    }

    case types.ADD_RECOMMENDATIONS_DISTRIBUTION_DATA: {
      const { distributionData, total } = getDistributionData(action.payload, 'recommendationWeight');
      return {
        ...state,
        data: {
          ...state.data,
          recommendationsDistribution: distributionData,
          totalRecommendations: total,
        },
        loading: {
          ...state.loading,
          recommendationsDistribution: false,
        },
      };
    }

    case types.GET_RECOMMENDATIONS_CATERING_CHART_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          recommendationsCatering: true,
        },
      };
    }

    case types.ADD_RECOMMENDATIONS_CATERING_CHART_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          recommendationsCatering: getChartData(action.payload, state.chickenTypes),
        },
        loading: {
          ...state.loading,
          recommendationsCatering: false,
        },
      };
    }

    case types.GET_RECOMMENDATIONS_CATERING_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          recommendationsCateringDistribution: true,
        },
      };
    }

    case types.ADD_RECOMMENDATIONS_CATERING_DISTRIBUTION_DATA: {
      const { distributionData, total } = getDistributionData(action.payload, 'recommendationWeight');
      return {
        ...state,
        data: {
          ...state.data,
          recommendationsCateringDistribution: distributionData,
          totalRecommendationsCatering: total,
        },
        loading: {
          ...state.loading,
          recommendationsCateringDistribution: false,
        },
      };
    }

    case types.GET_CONTAINERS_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          containersDistribution: true,
        },
      };
    }

    case types.ADD_CONTAINERS_DISTRIBUTION_DATA: {
      let totalContainers = 0;
      return {
        ...state,
        data: {
          ...state.data,
          containersDistribution: action.payload?.map((item) => {
            totalContainers += item.totalCount;
            return {
              ...item,
              value: item.totalCount,
              share: item.totalShare,
              material: item.materialName,
            };
          }),
          totalContainers: getFormattedWaste(totalContainers),
        },
        loading: {
          ...state.loading,
          containersDistribution: false,
        },
      };
    }

    case types.GET_CONTAINERS_CATERING_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          containersCateringDistribution: true,
        },
      };
    }

    case types.ADD_CONTAINERS_CATERING_DISTRIBUTION_DATA: {
      let totalContainers = 0;
      return {
        ...state,
        data: {
          ...state.data,
          containersCateringDistribution: action.payload?.map((item) => {
            totalContainers += item.totalCount;
            return {
              ...item,
              value: item.totalCount,
              share: item.totalShare,
              material: item.materialName,
            };
          }),
          totalContainersCatering: getFormattedWaste(totalContainers),
        },
        loading: {
          ...state.loading,
          containersCateringDistribution: false,
        },
      };
    }

    case types.GET_INBOUND_CONVEYORS_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          inboundConveyorsDistribution: true,
        },
      };
    }

    case types.ADD_INBOUND_CONVEYORS_DISTRIBUTION_DATA: {
      let totalContainers = 0;
      return {
        ...state,
        data: {
          ...state.data,
          inboundConveyorsDistribution: action.payload?.map((item) => {
            totalContainers += item.count;
            return {
              ...item,
              value: item.count,
              share: item.utilized,
              material: item.materialName,
            };
          }),
          totalInboundConveyors: getFormattedWaste(totalContainers),
        },
        loading: {
          ...state.loading,
          inboundConveyorsDistribution: false,
        },
      };
    }

    case types.GET_OUTBOUND_CONVEYORS_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          outboundConveyorsDistribution: true,
        },
      };
    }

    case types.ADD_OUTBOUND_CONVEYORS_DISTRIBUTION_DATA: {
      let totalContainers = 0;
      return {
        ...state,
        data: {
          ...state.data,
          outboundConveyorsDistribution: action.payload?.map((item) => {
            totalContainers += item.count;
            return {
              ...item,
              value: item.count,
              share: item.utilized,
              material: item.materialName,
            };
          }),
          totalOutboundConveyors: getFormattedWaste(totalContainers),
        },
        loading: {
          ...state.loading,
          outboundConveyorsDistribution: false,
        },
      };
    }

    case types.GET_FRYER_CYCLES_CHART_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          fryerCycles: true,
        },
      };
    }

    case types.ADD_FRYER_CYCLES_CHART_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          fryerCycles: getChartData(action.payload, state.chickenTypes),
        },
        loading: {
          ...state.loading,
          fryerCycles: false,
        },
      };
    }

    case types.GET_FRYER_CYCLES_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          fryerCyclesDistribution: true,
        },
      };
    }

    case types.ADD_FRYER_CYCLES_DISTRIBUTION_DATA: {
      const { distributionData, total } = getDistributionData(action.payload, 'total');

      return {
        ...state,
        data: {
          ...state.data,
          fryerCyclesDistribution: distributionData,
          totalFryerCycles: total,
        },
        loading: {
          ...state.loading,
          fryerCyclesDistribution: false,
        },
      };
    }

    case types.GET_STOCK_OUT_CHART_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          stockOut: true,
        },
      };
    }

    case types.ADD_STOCK_OUT_CHART_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          stockOut: getChartData(action.payload, state.chickenTypes),
        },
        loading: {
          ...state.loading,
          stockOut: false,
        },
      };
    }

    case types.GET_STOCK_OUT_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          stockOutDistribution: true,
        },
      };
    }

    case types.ADD_STOCK_OUT_DISTRIBUTION_DATA: {
      const formattedData = action.payload.map((item) => ({
        ...item,
        time: formatSecondsToMinutes(item.time),
      }));
      const { distributionData, total } = getDistributionData(formattedData, 'time');

      return {
        ...state,
        data: {
          ...state.data,
          stockOutDistribution: distributionData,
          totalStockOut: total,
        },
        loading: {
          ...state.loading,
          stockOutDistribution: false,
        },
      };
    }

    case types.GET_TASK_DELAY_CHART_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          taskDelay: true,
        },
      };
    }

    case types.ADD_TASK_DELAY_CHART_DATA: {
      return {
        ...state,
        data: {
          ...state.data,
          taskDelay: getChartData(action.payload, state.chickenTypes, (value) => formatMsToSeconds(value, 0)),
        },
        loading: {
          ...state.loading,
          taskDelay: false,
        },
      };
    }

    case types.GET_TASK_DELAY_DISTRIBUTION_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          taskDelayDistribution: true,
        },
      };
    }

    case types.ADD_TASK_DELAY_DISTRIBUTION_DATA: {
      const formattedData = action.payload.map((item) => ({
        ...item,
        highTaskDelay: formatMsToMinutes(item.highTaskDelay),
        lowTaskDelay: formatMsToMinutes(item.lowTaskDelay),
        averageTaskDelay: formatMsToMinutes(item.averageTaskDelay),
        totalDelay: formatMsToMinutes(item.totalDelay),
      }));
      const { distributionData, total } = getDistributionData(formattedData, 'averageTaskDelay');

      return {
        ...state,
        data: {
          ...state.data,
          taskDelayDistribution: distributionData,
          totalTaskDelay: total,
        },
        loading: {
          ...state.loading,
          taskDelayDistribution: false,
        },
      };
    }

    case types.GET_TIMERS_CHART_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          timersChart: true,
        },
      };
    }

    case types.SET_TIMERS_CHART_DATA: {
      const { headers, data } = getTimersTableData(action.payload);

      return {
        ...state,
        data: {
          ...state.data,
          timersChart: action.payload,
          timersTable: { headers, data },
        },
        loading: {
          ...state.loading,
          timersChart: false,
        },
      };
    }

    case types.GET_PERFORMANCE_RATIO_DATA: {
      return {
        ...state,
        loading: {
          ...state.loading,
          performanceRatio: true,
        },
      };
    }

    case types.SET_PERFORMANCE_RATIO_DATA: {
      return {
        ...state,
        performanceRatio: action.payload || [],
        loading: {
          ...state.loading,
          performanceRatio: false,
        },
      };
    }

    case types.LOAD_CHICKEN_DASHBOARD_FILE:
      return {
        ...state,
        isFileLoading: true,
      };

    case types.LOAD_CHICKEN_DASHBOARD_FILE_SUCCESS:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.LOAD_CHICKEN_DASHBOARD_FILE_ERROR:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.SET_CHICKEN_DASHBOARD_SORT_BY:
      return {
        ...state,
        sortModel: {
          ...state.sortModel,
          [action.payload.section]: {
            ...state.sortModel[action.payload.section],
            sortBy: action.payload.sortBy,
          },
        },
      };

    case types.SET_CHICKEN_DASHBOARD_ORDER_DIRECTION:
      return {
        ...state,
        sortModel: {
          ...state.sortModel,
          [action.payload.section]: {
            ...state.sortModel[action.payload.section],
            orderDirection: action.payload.orderDirection,
          },
        },
      };

    case types.SET_CHICKEN_DASHBOARD_SECTION_TAB:
      return {
        ...state,
        tabsModel: {
          ...state.tabsModel,
          [action.payload.section]: action.payload.tab,
        },
      };

    case types.RESET_CHICKEN_DASHBOARD_STORE:
      return {
        ...state,
        ...initialState,
        settings: state.settings,
      };

    default:
      return state;
  }
};

export default chickenDashReducer;
