import * as types from '../actions/restaurantAction';

const initialState = {
  search: '',
  limit: '20',
  orderDirection: 'DESC',
  sortBy: 'status',
  page: 0,
  totalResults: 0,
  viewType: 'table',
  loading: false,
  data: [],
};

const restaurantReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_RESTAURANTS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_SEVERAL_RESTAURANTS_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.CHANGE_RESTAURANTS_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };

    case types.GET_RESTAURANTS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_RESTAURANTS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
        data: action.payload.content,
      };

    case types.GET_RESTAURANTS_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
      };
    case types.NEW_RESTAURANT:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_RESTAURANT:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_RESTAURANT:
      return {
        ...state,
        loading: false,
      };

    case types.NEW_RESTAURANT_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };
    case types.UPDATE_RESTAURANT_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };
    case types.DELETE_RESTAURANT_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults - 1,
      };
    case types.RESET_RESTAURANTS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default restaurantReducer;
