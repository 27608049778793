import {
  getAllGPU,
  getAllRAM,
  getAllStorage,
  getAvailableGPU,
  getAvailableRAM,
  getAvailableStorage,
  getCPUCores,
  getCPUScalar,
  getCPUTemperature,
  getCPUVector,
  getGPUScalar,
  getGPUTemperature,
  getGPUUtilVector,
  getGPUVector,
  getMachines,
  getRAMScalar,
  getRAMVector,
  getReceiveNetworkScalar,
  getReceiveNetworkVector,
  getRestaurants,
  getStorageScalar,
  getStorageVector,
  getTransmitNetworkScalar,
  getTransmitNetworkVector,
  getUpTime,
} from '../../api/graphics';
import { metricReadableNames, units } from '../../constants/graphics.constants';
import { convertBytes } from '../../utils';
import { getGraphicsData, getStaticValue, mergeSameMetrics } from '../../utils/chartHelper';

export const CHANGE_GRAPHICS_VALUE = 'CHANGE_GRAPHICS_VALUE';
export const CHANGE_GRAPHICS_SEVERAL_VALUES = 'CHANGE_GRAPHICS_SEVERAL_VALUES';

export const RESET_GRAPHICS_STORE = 'RESET_GRAPHICS_STORE';
export const RESET_GRAPHICS_METRICS = 'RESET_GRAPHICS_METRICS';

export const GET_MACHINES = 'GET_MACHINES';
export const GET_MACHINES_SUCCESS = 'GET_MACHINES_SUCCESS';
export const GET_MACHINES_ERROR = 'GET_MACHINES_ERROR';

export const GET_MONITORING_RESTAURANTS = 'GET_MONITORING_RESTAURANTS';
export const GET_MONITORING_RESTAURANTS_SUCCESS = 'GET_MONITORING_RESTAURANTS_SUCCESS';
export const GET_MONITORING_RESTAURANTS_ERROR = 'GET_MONITORING_RESTAURANTS_ERROR';

export const getRestaurantsAction = (actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_MONITORING_RESTAURANTS });
    try {
      const response = await getRestaurants();
      const { success, results } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: GET_MONITORING_RESTAURANTS_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_MONITORING_RESTAURANTS_ERROR });
    }
  };
};

export const getMachinesAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_MACHINES });
    try {
      const response = await getMachines();
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_MACHINES_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_MACHINES_ERROR });
    }
  };
};

export const getCPUScalarAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getCPUScalar(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('cpuScalar', results.data.result.length !== 0 ? results.data.result : null));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCPUCoresAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getCPUCores(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue(
            'cpuCores',
            results.data.result.length !== 0 ? Number(results.data.result[0].value[1]) : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCPUTemperatureAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getCPUTemperature(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue(
            'cpuTemperature',
            results.data.result.length !== 0
              ? results.data.result.map((cpu) => ({
                  machine: machine === 'select' ? cpu.metric.host : cpu.metric.device || cpu.metric.host,
                  value: Number(cpu.value[1]),
                }))
              : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCPUVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const response = await getCPUVector(machine, params);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('cpuVector', getGraphicsData(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllRAMAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getAllRAM(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('ramAll', getStaticValue(results, machine)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAvailableRAMAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getAvailableRAM(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('ramAvailable', getStaticValue(results, machine)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getRAMScalarAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getRAMScalar(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('ramScalar', results.data.result.length !== 0 ? results.data.result : null));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getRAMVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const response = await getRAMVector(machine, params);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('ramVector', getGraphicsData(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllGPUAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getAllGPU(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('gpuAll', getStaticValue(results, machine, false, 1048576)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getGPUTemperatureAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getGPUTemperature(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue(
            'gpuTemperature',
            results.data.result.length !== 0
              ? results.data.result.map((gpu) => ({
                  machine: machine === 'select' ? gpu.metric.host : gpu.metric.device || gpu.metric.host,
                  value: Number(gpu.value[1]),
                }))
              : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAvailableGPUAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getAvailableGPU(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('gpuAvailable', getStaticValue(results, machine, false, 1048576)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getGPUScalarAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getGPUScalar(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('gpuScalar', results.data.result.length !== 0 ? results.data.result : null));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getGPUVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const response = await getGPUVector(machine, params);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('gpuVector', getGraphicsData(results, machine, type, 1048576)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getGPUUtilVectorAction = (machine, params) => {
  return async (dispatch) => {
    try {
      const response = await getGPUUtilVector(machine, params);
      const { success, results } = response;
      if (success) {
        const mergedMetricsResults = mergeSameMetrics(results, '__name__');
        dispatch(
          changeValue(
            'gpuUtilsVector',
            getGraphicsData(mergedMetricsResults, machine, false, 1, {
              key: '__name__',
              readableDictionary: metricReadableNames,
            })
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllStorageAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getAllStorage(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('storageAll', getStaticValue(results, machine)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAvailableStorageAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getAvailableStorage(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('storageAvailable', getStaticValue(results, machine)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getStorageScalarAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getStorageScalar(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('storageScalar', results.data.result.length !== 0 ? results.data.result : null));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getStorageVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const response = await getStorageVector(machine, params);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('storageVector', getGraphicsData(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getReceiveNetworkScalarAction = (machine, actions = {}) => {
  return async () => {
    try {
      const response = await getReceiveNetworkScalar(machine);
      const { success, results } = response;
      if (success) {
        actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
    }
  };
};

export const getTransmitNetworkScalarAction = (machine, actions = {}) => {
  return async () => {
    try {
      const response = await getTransmitNetworkScalar(machine);
      const { success, results } = response;
      if (success) {
        actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
    }
  };
};

export const getReceiveNetworkVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const response = await getReceiveNetworkVector(machine, params);
      const { success, results } = response;
      if (success) {
        let values = results.data.result
          .filter((item) => !!item.metric && !!item.metric.host)
          .map((res) => res.values)
          .flat();
        dispatch(
          changeValue(
            'receiveMin',
            results.data.result.length !== 0
              ? Math.min(...values.map((value) => convertBytes(Number(value[1]), units, type, true)))
              : null
          )
        );
        dispatch(
          changeValue(
            'receiveMax',
            results.data.result.length !== 0
              ? Math.max(...values.map((value) => convertBytes(Number(value[1]), units, type, true)))
              : null
          )
        );
        dispatch(
          changeValue(
            'receiveAvg',
            results.data.result.length !== 0
              ? values
                  .map((value) => convertBytes(Number(value[1]), units, type, true))
                  .reduce((partialSum, a) => partialSum + a, 0) / values.length
              : null
          )
        );
        dispatch(changeValue('receiveNetworkVector', getGraphicsData(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getTransmitNetworkVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const response = await getTransmitNetworkVector(machine, params);
      const { success, results } = response;
      if (success) {
        let values = results.data.result
          .filter((item) => !!item.metric && !!item.metric.host)
          .map((res) => res.values)
          .flat();
        dispatch(
          changeValue(
            'transmitMin',
            results.data.result.length !== 0
              ? Math.min(...values.map((value) => convertBytes(Number(value[1]), units, type, true)))
              : null
          )
        );
        dispatch(
          changeValue(
            'transmitMax',
            results.data.result.length !== 0
              ? Math.max(...values.map((value) => convertBytes(Number(value[1]), units, type, true)))
              : null
          )
        );
        dispatch(
          changeValue(
            'transmitAvg',
            results.data.result.length !== 0
              ? values
                  .map((value) => convertBytes(Number(value[1]), units, type, true))
                  .reduce((partialSum, a) => partialSum + a, 0) / values.length
              : null
          )
        );
        dispatch(changeValue('transmitNetworkVector', getGraphicsData(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getUpTimeAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getUpTime(machine);
      const { success, results } = response;
      if (success) {
        if (results.data.result.length !== 0) {
          let w = (results.data.result[0].value[1] / 604800).toFixed(1);
          let d = (results.data.result[0].value[1] / 86400).toFixed(1);
          let h = (results.data.result[0].value[1] / 3600).toFixed(1);
          dispatch(
            changeValue(
              'upTime',
              w >= 1
                ? w + (w < 2 ? ' week' : ' weeks')
                : d >= 1
                ? d + (d < 2 ? ' day' : ' days')
                : h + (h < 2 ? ' hour' : ' hours')
            )
          );
        } else dispatch(changeValue('upTime', null));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_GRAPHICS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_GRAPHICS_SEVERAL_VALUES,
  payload: value,
});

export const resetAllMetrics = () => ({
  type: RESET_GRAPHICS_METRICS,
});

export const resetStore = () => ({
  type: RESET_GRAPHICS_STORE,
});
