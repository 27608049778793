import React from 'react';
import cx from 'classnames';

import './PieChartSwitch.scss';

export const PieChartSwitch = ({ active, options, width, onClick }) => {
  return (
    <div className="pie-chart-switch__container" style={{ width: width || '100%' }}>
      <ul className="pie-chart-switch__list">
        {options.map((option) => (
          <li
            key={option.value}
            className={cx('pie-chart-switch__item', { active: active === option.value })}
            onClick={() => onClick(option.value)}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};
