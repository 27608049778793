import * as types from '../actions/chickenEventsAction';

const initialState = {
  restaurantUuid: null,
  eventName: 'all',
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: null,
  orderDirection: 'DESC',
  sortBy: 'eventTime',
  limit: '20',
  loading: true,
  scrollLoading: false,
  isFileLoading: false,
  cursorDirection: 'FIRST',
  cursor: null,
  existNext: false,
  existPrevious: false,
  changePage: false,
  chartLoading: false,
  graphContent: [],
  data: [],
};

const chickenEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CHICKEN_EVENTS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_CHICKEN_EVENTS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_CHICKEN_EVENTS:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_CHICKEN_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
        data:
          action.params.cursorDirection === 'NEXT'
            ? [...state.data, ...action.payload.content]
            : action.payload.content,
      };

    case types.GET_CHICKEN_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
      };

    case types.GET_CHICKEN_EVENTS_ANALYTIC:
      return {
        ...state,
        chartLoading: true,
        graphContent: [],
      };

    case types.GET_CHICKEN_EVENTS_ANALYTIC_SUCCESS:
      return {
        ...state,
        chartLoading: false,
        graphContent:
          action.payload.length !== 0
            ? action.payload[0].values.map((item, index) => [
                new Date(item[0] * 1000),
                action.payload.reduce(function (sum, elem) {
                  return sum + Number(elem.values[index][1]);
                }, 0),
              ])
            : [[0, 0]],
      };

    case types.LOAD_CHICKEN_EVENTS_FILE:
      return {
        ...state,
        isFileLoading: true,
      };

    case types.LOAD_CHICKEN_EVENTS_FILE_SUCCESS:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.LOAD_CHICKEN_EVENTS_FILE_ERROR:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.RESET_CHICKEN_EVENTS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default chickenEventsReducer;
