import { confirmStatus, deleteDevice, getDevices, getDevicesAvailability } from '../../api/devices';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_DEVICES_VALUE = 'CHANGE_DEVICES_VALUE';
export const CHANGE_DEVICES_SEVERAL_VALUES = 'CHANGE_DEVICES_SEVERAL_VALUES';
export const RESET_DEVICES_STORE = 'RESET_DEVICES_STORE';

export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_ERROR = 'GET_DEVICES_ERROR';

export const DELETE_DEVICE = 'DELETE_DEVICE';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_ERROR = 'DELETE_DEVICE_ERROR';

export const CONFIRM_DEVICE = 'CONFIRM_DEVICE';

export const GET_DEVICES_AVAILABILITY = 'GET_DEVICES_AVAILABILITY';
export const GET_DEVICES_AVAILABILITY_SUCCESS = 'GET_DEVICES_AVAILABILITY_SUCCESS';
export const GET_DEVICES_AVAILABILITY_ERROR = 'GET_DEVICES_AVAILABILITY_ERROR';

export const getDevicesAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_DEVICES });
      try {
        const response = await getDevices(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_DEVICES_SUCCESS, payload: results, params: params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_DEVICES_ERROR });
        }
      }
    };
  };
})();

export const deleteDeviceAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_DEVICE });
    try {
      const response = await deleteDevice(uuid, params);
      const { success } = response;
      if (success) {
        dispatch({ type: DELETE_DEVICE_SUCCESS });
        actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_DEVICE_ERROR });
    }
  };
};

export const confirmStatusAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: CONFIRM_DEVICE });
    try {
      const response = await confirmStatus(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
    }
  };
};

export const getDevicesAvailabilityAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_DEVICES_AVAILABILITY });
      try {
        const response = await getDevicesAvailability(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_DEVICES_AVAILABILITY_SUCCESS, payload: results, params });
          actions.onSuccess && actions.onSuccess(results);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_DEVICES_AVAILABILITY_ERROR });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const changeValue = (key, value) => ({
  type: CHANGE_DEVICES_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_DEVICES_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_DEVICES_STORE,
});
