import * as types from '../actions/chickenMaterialsAction';

const initialState = {
  limit: '20',
  page: 0,
  totalResults: 0,
  loading: false,
  sortBy: 'materialCode',
  orderDirection: 'ASC',
};

const chickenMaterialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CHICKEN_MATERIALS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_CHICKEN_MATERIALS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case types.CHANGE_MODAL_MATERIALS_VALUE:
      return {
        ...state,
        modalInf: {
          ...state.modalInf,
          [action.payload.key]: action.payload.value,
        },
      };
    case types.GET_CHICKEN_MATERIALS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_CHICKEN_MATERIALS_SUCCESS:
      return {
        ...state,
        totalResults: action.payload.total,
        loading: false,
      };

    case types.GET_CHICKEN_MATERIALS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.NEW_CHICKEN_MATERIAL:
      return {
        ...state,
        loading: false,
      };

    case types.NEW_CHICKEN_MATERIAL_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };

    case types.UPDATE_CHICKEN_MATERIAL:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_CHICKEN_MATERIAL_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };

    case types.DELETE_CHICKEN_MATERIAL:
      return {
        ...state,
        loading: false,
      };

    case types.DELETE_CHICKEN_MATERIAL_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults - 1,
      };

    case types.RESET_CHICKEN_MATERIALS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default chickenMaterialsReducer;
