import request from '../utils/request';

export function getDevices(params, signal) {
  return request({
    url: '/devices',
    method: 'get',
    data: params,
    signal,
  });
}

export function confirmStatus(uuid) {
  return request({
    url: `/devices/${uuid}/confirm`,
    method: 'put',
    data: uuid,
  });
}

export function deleteDevice(uuid, params) {
  return request({
    url: `/devices/${uuid}`,
    method: 'delete',
    data: params,
  });
}

export function getDevicesAvailability(params, signal) {
  return request({
    url: '/availability/devices',
    method: 'get',
    data: params,
    signal,
  });
}
