import moment from 'moment';
import cx from 'classnames';

export const OpenedRow = ({ row, headerOptions }) => {
  return (
    <tr className="mobile-table__tbody-row">
      <td colSpan={row.cells.filter((it) => it.mobile).length + 1}>
        <div className={cx('mobile-table__extended-info')}>
          {row.cells
            .filter((it) => !it.mobile)
            .map((extCell, extIndex) => {
              return !!extCell.label ? (
                <div className="mobile-table__extended-info__item">
                  <div className="mobile-table__extended-info__header">
                    {headerOptions.filter((it) => !it.mobile)[extIndex].label}
                  </div>
                  <div className="mobile-table__extended-info__label">
                    {!extCell.date
                      ? extCell.label
                      : moment
                          .tz(extCell.label, extCell.date.timezone)
                          .format(`MMM D${!extCell.date.withoutYear ? ' YYYY' : ''}, LTS`)}
                  </div>
                </div>
              ) : (
                <></>
              );
            })}
        </div>
      </td>
    </tr>
  );
};
