import React from 'react';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as Info } from '../../../assets/img/icons/common/InfoOutlined.svg';
import { ReactComponent as Close } from '../../../assets/img/icons/common/Close.svg';
import { ReactComponent as ZoomIn } from '../../../assets/img/icons/player/ZoomIn.svg';
import { ReactComponent as Screenshot } from '../../../assets/img/icons/player/Screenshot.svg';
import { ReactComponent as Refresh } from '../../../assets/img/icons/player/Refresh.svg';
import { ReactComponent as Fullscreen } from '../../../assets/img/icons/player/Fullscreen.svg';

import { ButtonOnVideo, MainVideoButton } from '../../../components';

import { makeVideScreenshot } from '../../../utils';

import './ManagerPlayerControls.scss';

export const ManagerPlayerControls = ({
  mobile,
  camera,
  videoRef,
  isMetaLoaded,
  isFullscreen,
  changeCameraValue,
  toggleFullScreen,
  onCloseCamera,
  onResetScale,
  onRestartStream,
}) => {
  return (
    <div className="manager-player__controls">
      <div className="manager-player__controls__header">
        <ButtonOnVideo
          className="manager-player__controls__header-button"
          active={camera.showInfo}
          Icon={Info}
          onClick={(e) => {
            e.stopPropagation();
            changeCameraValue(camera.key, 'showInfo', !camera.showInfo);
          }}
          tooltip={{
            id: `camera-tooltip-${camera.key}`,
            title: 'Information',
            place: 'bottom',
          }}
        />

        <div className="manager-player__controls__title">{camera.name}</div>

        <ButtonOnVideo
          className="manager-player__controls__header-button"
          active={isFullscreen}
          Icon={Fullscreen}
          onClick={toggleFullScreen}
          disabled={!isFullscreen && (camera.hasStartStreamError || !isMetaLoaded)}
          tooltip={{
            id: `camera-tooltip-${camera.key}`,
            title: isFullscreen ? 'Exit full screen' : 'Full screen',
            place: 'bottom',
          }}
        />
      </div>

      <div className="manager-player__controls__footer">
        {!mobile && (
          <MainVideoButton
            Icon={ZoomIn}
            active={camera.enableScaling}
            disabled={camera.hasStartStreamError}
            tooltip={{
              id: `camera-tooltip-${camera.key}`,
              title: camera.enableScaling ? 'Disable zoom in & out' : 'Enable zoom in & out',
              place: 'top',
            }}
            onClick={(e) => {
              e.stopPropagation();
              camera.enableScaling && onResetScale();
              changeCameraValue(camera.key, 'enableScaling', !camera.enableScaling);
            }}
          />
        )}

        <MainVideoButton
          Icon={Screenshot}
          disabled={camera.hasStartStreamError}
          tooltip={{
            id: `camera-tooltip-${camera.key}`,
            title: 'Screenshot',
            place: 'top',
          }}
          onClick={(e) => {
            e.stopPropagation();
            makeVideScreenshot(videoRef);
          }}
        />

        <MainVideoButton
          Icon={Refresh}
          tooltip={{
            id: `camera-tooltip-${camera.key}`,
            title: 'Restart',
            place: 'top',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onRestartStream();
          }}
        />

        <MainVideoButton
          Icon={Close}
          active={true}
          tooltip={{
            id: `camera-tooltip-${camera.key}`,
            title: 'Close',
            place: 'top',
          }}
          onClick={onCloseCamera}
        />
      </div>

      {!mobile && (
        <Tooltip
          id={`camera-tooltip-${camera.key}`}
          style={{ fontSize: '12px', zIndex: 1100 }}
          hidden={mobile}
        />
      )}
    </div>
  );
};
