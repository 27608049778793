import { googleColors, pieChartThousands } from '../../../../constants/analytics.constants';
import { convertThousands } from '../../../../utils';
import { ColorIndicator } from '../../../TableCells';
import { pieChartHeader } from './constants';

export const createRows = (rows, totalAmount, colors = googleColors) => {
  return rows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: '',
        cellComponent: <ColorIndicator color={colors[index]} />,
      },
      {
        label: item.restaurant,
      },
      {
        label: `${item.value}`,
      },
      {
        label: `${Math.round((item.value / totalAmount || 0) * 1000) / 10}%`,
      },
    ],
    minHeight: '42px',
  }));
};

export const getChartData = (content, totalAmount) => {
  return [
    [...pieChartHeader],
    ...content.totalValues.map((item) => [
      convertThousands(item.value, pieChartThousands),
      item.value,
      `<div class="chart-tooltip"><span>${item.restaurant}</span>${`<div>Count: <b>${item.value} (${
        Math.round((item.value / totalAmount || 0) * 1000) / 10
      } %)</b></div>`}</div>`,
    ]),
  ];
};
