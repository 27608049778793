import request from '../utils/request';

export function getAnalytics(params, signal) {
  return request({
    url: '/eventAnalytic',
    method: 'get',
    data: params,
    signal,
  });
}

export function getMaterialsAnalytics(params, signal) {
  return request({
    url: '/eventAnalytic/materials',
    method: 'get',
    data: params,
    signal,
  });
}
