import _ from 'lodash';
import { deleteReport, downloadReport, getReports } from '../../api/reports';
import { downloadFile } from '../../utils';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_REPORTS_VALUE = 'CHANGE_REPORTS_VALUE';
export const CHANGE_REPORTS_SEVERAL_VALUES = 'CHANGE_REPORTS_SEVERAL_VALUES';
export const RESET_REPORTS_STORE = 'RESET_REPORTS_STORE';

export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_ERROR = 'GET_REPORTS_ERROR';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_PROGRESS = 'DOWNLOAD_REPORT_PROGRESS';
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD_REPORT_ERROR';

export const getReportsAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_REPORTS });
      try {
        const response = await getReports(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_REPORTS_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_REPORTS_ERROR });
          actions?.onError(error.message);
        }
      }
    };
  };
})();

export const deleteReportAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: GET_REPORTS });
    try {
      const response = await deleteReport(uuid);
      const { success, results } = response;
      if (success) {
        if (actions.onSuccess) {
          actions.onSuccess(results);
        }
      }
    } catch (error) {
      console.log(error);
      if (actions.onError) {
        actions.onError(error.message);
      }
    }
  };
};

export const downloadReportAction = (uuid, actions) => {
  return async (dispatch) => {
    const controller = new AbortController();
    dispatch({ type: DOWNLOAD_REPORT, payload: { uuid, controller } });
    try {
      const response = await downloadReport(
        uuid,
        controller.signal,
        _.throttle((progressEvent) => {
          if (progressEvent.loaded && progressEvent.total) {
            dispatch({
              type: DOWNLOAD_REPORT_PROGRESS,
              payload: { uuid, progress: Math.round((progressEvent.loaded / progressEvent.total) * 100) },
            });
          }
        }, 500)
      );
      const { success, results } = response;
      if (success) {
        downloadFile(results, 'application/zip');
        dispatch({ type: DOWNLOAD_REPORT_SUCCESS, payload: { uuid } });
        if (actions.onSuccess) {
          actions.onSuccess();
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: DOWNLOAD_REPORT_ERROR, payload: { uuid } });
      if (actions.onError) {
        actions.onError(error);
      }
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_REPORTS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_REPORTS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_REPORTS_STORE,
});
