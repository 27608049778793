import { getRestaurants } from '../../api/graphics';
import {
  deleteAlert,
  getMonitoringAlerts,
  getMonitoringAlertsCount,
  getMonitoringAnalytics,
} from '../../api/monitoringAlerts';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const GET_MONITORING_ALERTS_ANALYTIC = 'GET_MONITORING_ALERTS_ANALYTIC';
export const GET_MONITORING_ALERTS_ANALYTIC_SUCCESS = 'GET_MONITORING_ALERTS_ANALYTIC_SUCCESS';
export const GET_MONITORING_ALERTS_ANALYTIC_ERROR = 'GET_MONITORING_ALERTS_ANALYTIC_ERROR';

export const GET_MONITORING_ALERTS_RESTAURANTS = 'GET_MONITORING_ALERTS_RESTAURANTS';
export const GET_MONITORING_ALERTS_RESTAURANTS_SUCCESS = 'GET_MONITORING_ALERTS_RESTAURANTS_SUCCESS';
export const GET_MONITORING_ALERTS_RESTAURANTS_ERROR = 'GET_MONITORING_ALERTS_RESTAURANTS_ERROR';

export const CHANGE_MONITORING_ALERTS_VALUE = 'CHANGE_MONITORING_ALERTS_VALUE';
export const CHANGE_MONITORING_ALERTS_SEVERAL_VALUES = 'CHANGE_MONITORING_ALERTS_SEVERAL_VALUES';
export const RESET_MONITORING_ALERTS_STORE = 'RESET_MONITORING_ALERTS_STORE';

export const GET_MONITORING_ALERTS = 'GET_MONITORING_ALERTS';
export const GET_MONITORING_ALERTS_SUCCESS = 'GET_MONITORING_ALERTS_SUCCESS';
export const GET_MONITORING_ALERTS_ERROR = 'GET_MONITORING_ALERTS_ERROR';

export const DELETE_MONITORING_ALERT = 'DELETE_MONITORING_ALERT';
export const DELETE_MONITORING_ALERT_SUCCESS = 'DELETE_MONITORING_ALERT_SUCCESS';
export const DELETE_MONITORING_ALERT_ERROR = 'DELETE_MONITORING_ALERT_ERROR';

export const GET_MONITORING_ALERTS_COUNT = 'GET_MONITORING_ALERTS_COUNT';
export const GET_MONITORING_ALERTS_COUNT_SUCCESS = 'GET_MONITORING_ALERTS_COUNT_SUCCESS';
export const GET_MONITORING_ALERTS_COUNT_ERROR = 'GET_MONITORING_ALERTS_COUNT_ERROR';

export const getRestaurantsAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_MONITORING_ALERTS_RESTAURANTS });
    try {
      const response = await getRestaurants({
        limit: 100,
        offset: 0,
        sortBy: 'number',
        orderDirection: 'DESC',
      });
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_MONITORING_ALERTS_RESTAURANTS_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_MONITORING_ALERTS_RESTAURANTS_ERROR });
    }
  };
};

export const getMonitoringAnalyticAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_MONITORING_ALERTS_ANALYTIC });
      try {
        const response = await getMonitoringAnalytics(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_MONITORING_ALERTS_ANALYTIC_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_MONITORING_ALERTS_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const getMonitoringAlertsAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_MONITORING_ALERTS, payload: params.offset === 0 });
      try {
        const response = await getMonitoringAlerts(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_MONITORING_ALERTS_SUCCESS, payload: results, params });
          actions?.onSuccess(results);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_MONITORING_ALERTS_ERROR });
          actions?.onError(error.message);
        }
      }
    };
  };
})();

export const getMonitoringAlertsCountAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_MONITORING_ALERTS_COUNT });
    try {
      const response = await getMonitoringAlertsCount(params);
      const { success, results } = response;
      if (success) {
        setData(results);
        dispatch({ type: GET_MONITORING_ALERTS_COUNT_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_MONITORING_ALERTS_COUNT_ERROR });
    }
  };
};

export const deleteMonitoringAlertAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_MONITORING_ALERT });
    try {
      const response = await deleteAlert(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_MONITORING_ALERT_SUCCESS, payload: { uuid } });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_MONITORING_ALERT_ERROR });
    }
  };
};

export const deleteMonitoringAlertsAction = (uuids, actions) => {
  return async () => {
    const deletePromises = uuids.map((uuid) => {
      return new Promise(async (resolve) => {
        try {
          const response = await deleteAlert(uuid);
          const { success } = response;
          if (success) {
            actions.onSuccess();
            resolve();
          }
        } catch (error) {
          actions.onError(error.message);
          resolve();
        }
      });
    });

    Promise.all(deletePromises).finally(() => actions.onFinally());
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_MONITORING_ALERTS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_MONITORING_ALERTS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_MONITORING_ALERTS_STORE,
});
