import { getUnusualEvents } from '../../api/unusual';
import { getAnalytics } from '../../api/analytics';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_WRONG_MARKER_VALUE = 'CHANGE_WRONG_MARKER_VALUE';
export const CHANGE_WRONG_MARKER_SEVERAL_VALUES = 'CHANGE_WRONG_MARKER_SEVERAL_VALUES';
export const CHANGE_WRONG_MARKER_CURRENT_IMAGE = 'CHANGE_WRONG_MARKER_CURRENT_IMAGE';
export const SET_WRONG_MARKER_EVENT_IMAGE = 'SET_WRONG_MARKER_EVENT_IMAGE';
export const RESET_WRONG_MARKER_STORE = 'RESET_WRONG_MARKER_STORE';

export const GET_WRONG_MARKER = 'GET_WRONG_MARKER';
export const GET_WRONG_MARKER_SUCCESS = 'GET_WRONG_MARKER_SUCCESS';
export const GET_WRONG_MARKER_ERROR = 'GET_WRONG_MARKER_ERROR';

export const GET_WRONG_MARKER_ANALYTIC = 'GET_WRONG_MARKER_ANALYTIC';
export const GET_WRONG_MARKER_ANALYTIC_SUCCESS = 'GET_WRONG_MARKER_ANALYTIC_SUCCESS';
export const GET_WRONG_MARKER_ANALYTIC_ERROR = 'GET_WRONG_MARKER_ANALYTIC_ERROR';

export const getWrongMarkerAction = (function () {
  let prevController;

  return (params, actions) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_WRONG_MARKER });
      try {
        const response = await getUnusualEvents(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_WRONG_MARKER_SUCCESS, payload: results, params });
          actions.onSuccess && actions.onSuccess(results.content);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_WRONG_MARKER_ERROR });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const getWrongMarkerAnalyticAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_WRONG_MARKER_ANALYTIC });
      try {
        const response = await getAnalytics(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_WRONG_MARKER_ANALYTIC_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_WRONG_MARKER_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const changeValue = (key, value) => ({
  type: CHANGE_WRONG_MARKER_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_WRONG_MARKER_SEVERAL_VALUES,
  payload: value,
});

export const changeCurrentImage = (index, value) => ({
  type: CHANGE_WRONG_MARKER_CURRENT_IMAGE,
  payload: { index, value },
});

export const setEventImage = (eventId, index, image) => ({
  type: SET_WRONG_MARKER_EVENT_IMAGE,
  payload: { eventId, index, image },
});

export const resetStore = () => ({
  type: RESET_WRONG_MARKER_STORE,
});
