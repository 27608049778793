import request from '../utils/request';

export function signIn(params) {
  return request({
    url: '/auth/signIn',
    method: 'post',
    data: params,
    skipAuthRefresh: true,
  });
}

export function refresh() {
  return request({
    url: '/auth/refresh',
    method: 'post',
    skipAuthRefresh: true,
  });
}

export function signOut() {
  return request({
    url: '/auth/logout',
    method: 'post',
  });
}
