import {
  addRestaurantDevices,
  changeDeviceRestaurant,
  deleteDeviceRestaurant,
  getEmptyDevices,
  getRestaurantDevices,
} from '../../api/restaurants';
import { getDevicesAvailability } from '../../api/devices';

export const CHANGE_RESTAURANT_DEVICES_VALUE = 'CHANGE_RESTAURANT_DEVICES_VALUE';
export const CHANGE_RESTAURANT_DEVICES_SEVERAL_VALUES = 'CHANGE_RESTAURANT_DEVICES_SEVERAL_VALUES';
export const RESET_RESTAURANT_DEVICES_STORE = 'RESET_RESTAURANT_DEVICES_STORE';

export const GET_RESTAURANT_DEVICES = 'GET_RESTAURANT_DEVICES';
export const GET_RESTAURANT_DEVICES_SUCCESS = 'GET_RESTAURANT_DEVICES_SUCCESS';
export const GET_RESTAURANT_DEVICES_ERROR = 'GET_RESTAURANT_DEVICES_ERROR';

export const ADD_RESTAURANT_DEVICES = 'ADD_RESTAURANT_DEVICES';
export const ADD_RESTAURANT_DEVICES_SUCCESS = 'ADD_RESTAURANT_DEVICES_SUCCESS';
export const ADD_RESTAURANT_DEVICES_ERROR = 'ADD_RESTAURANT_DEVICES_ERROR';

export const GET_EMPTY_DEVICES = 'GET_EMPTY_DEVICES';
export const GET_EMPTY_DEVICES_RESULT = 'GET_EMPTY_DEVICES_RESULT';
export const GET_EMPTY_DEVICES_ERROR = 'GET_EMPTY_DEVICES_ERROR';

export const CHANGE_RESTAURANT_DEVICE = 'CHANGE_RESTAURANT_DEVICE';
export const CHANGE_RESTAURANT_DEVICE_SUCCESS = 'CHANGE_RESTAURANT_DEVICE_SUCCESS';
export const CHANGE_RESTAURANT_DEVICE_ERROR = 'CHANGE_RESTAURANT_DEVICE_ERROR';

export const GET_RESTAURANT_DEVICES_AVAILABILITY = 'GET_RESTAURANT_DEVICES_AVAILABILITY';
export const GET_RESTAURANT_DEVICES_AVAILABILITY_SUCCESS = 'GET_RESTAURANT_DEVICES_AVAILABILITY_SUCCESS';
export const GET_RESTAURANT_DEVICES_AVAILABILITY_ERROR = 'GET_RESTAURANT_DEVICES_AVAILABILITY_ERROR';

export const getRestaurantDevicesAction = (uuid) => {
  return async (dispatch) => {
    dispatch({ type: GET_RESTAURANT_DEVICES });
    try {
      const response = await getRestaurantDevices(uuid);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_RESTAURANT_DEVICES_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_RESTAURANT_DEVICES_ERROR });
    }
  };
};

export const changeDeviceRestaurantAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_RESTAURANT_DEVICE });
    try {
      const response = await changeDeviceRestaurant(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: CHANGE_RESTAURANT_DEVICE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: CHANGE_RESTAURANT_DEVICE_ERROR });
    }
  };
};

export const deleteDeviceRestaurantAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_RESTAURANT_DEVICE });
    try {
      const response = await deleteDeviceRestaurant(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: CHANGE_RESTAURANT_DEVICE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: CHANGE_RESTAURANT_DEVICE_ERROR });
    }
  };
};

export const addRestaurantDevicesAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: ADD_RESTAURANT_DEVICES });
    try {
      const response = await addRestaurantDevices(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: ADD_RESTAURANT_DEVICES_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: ADD_RESTAURANT_DEVICES_ERROR });
    }
  };
};

export const getEmptyDevicesAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_EMPTY_DEVICES });
    try {
      const response = await getEmptyDevices(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_EMPTY_DEVICES_RESULT, payload: results });
      }
    } catch (error) {
      dispatch({ type: GET_EMPTY_DEVICES_ERROR });
      console.log(error);
    }
  };
};

export const getDevicesAvailabilityAction = (params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_RESTAURANT_DEVICES_AVAILABILITY });
    try {
      const response = await getDevicesAvailability(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_RESTAURANT_DEVICES_AVAILABILITY_SUCCESS, payload: results, params });
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_RESTAURANT_DEVICES_AVAILABILITY_ERROR });
      actions.onError && actions.onError(error.message);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_RESTAURANT_DEVICES_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_RESTAURANT_DEVICES_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_RESTAURANT_DEVICES_STORE,
});
