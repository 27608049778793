import { getStreamRecording, getSupport, sendSupport } from '../../api/player';

export const SET_STREAMS_LIST = 'SET_STREAMS_LIST';
export const SET_ACTIVE_STREAM_ID = 'SET_ACTIVE_STREAM_ID';
export const CHANGE_PLAYER_VALUE = 'CHANGE_PLAYER_VALUE';
export const CHANGE_PLAYER_SEVERAL_VALUES = 'CHANGE_PLAYER_SEVERAL_VALUES';
export const SET_RECORDING_STREAM = 'SET_RECORDING_STREAM';

export const GET_STREAM_RECORDING = 'GET_STREAM_RECORDING';
export const GET_STREAM_RECORDING_RESULT = 'GET_STREAM_RECORDING_RESULT';
export const GET_STREAM_RECORDING_ERROR = 'GET_STREAM_RECORDING_ERROR';
export const RESET_STREAM_RECORDING = 'RESET_STREAM_RECORDING';

export const GET_SUPPORT = 'GET_SUPPORT';
export const GET_SUPPORT_RESULT = 'GET_SUPPORT_RESULT';
export const GET_SUPPORT_ERROR = 'GET_SUPPORT_ERROR';

export const NEW_SUPPORT = 'NEW_SUPPORT';
export const NEW_SUPPORT_RESULT = 'NEW_SUPPORT_RESULT';
export const NEW_SUPPORT_ERROR = 'NEW_SUPPORT_ERROR';

export const SEND_SUPPORT = 'SEND_SUPPORT';
export const SEND_SUPPORT_RESULT = 'SEND_SUPPORT_RESULT';
export const SEND_SUPPORT_ERROR = 'SEND_SUPPORT_ERROR';

export const ADD_SUPPORT_ATTACHMENT = 'ADD_SUPPORT_ATTACHMENT';
export const EDIT_SUPPORT_ATTACHMENT = 'EDIT_SUPPORT_ATTACHMENT';
export const DELETE_SUPPORT_ATTACHMENT = 'DELETE_SUPPORT_ATTACHMENT';

export const RESET_KITCHEN_PLAYER_STORE = 'RESET_KITCHEN_PLAYER_STORE';

export const setStreamsList = (list) => ({ type: SET_STREAMS_LIST, payload: list });
export const setActiveStreamId = (id) => ({ type: SET_ACTIVE_STREAM_ID, payload: id });

export const addSupportAttachment = (file) => ({
  type: ADD_SUPPORT_ATTACHMENT,
  payload: { file },
});

export const editSupportAttachment = (id, key, value) => ({
  type: EDIT_SUPPORT_ATTACHMENT,
  payload: { id, key, value },
});

export const deleteSupportAttachment = (id) => ({
  type: DELETE_SUPPORT_ATTACHMENT,
  payload: { id },
});

export const changeValue = (key, value) => ({
  type: CHANGE_PLAYER_VALUE,
  payload: { key, value },
});

export const resetStreamRecording = () => ({
  type: RESET_STREAM_RECORDING,
});

export const getStreamRecordingAction = (restaurantUuid, streamName, actions) => {
  return async (dispatch) => {
    dispatch({ type: GET_STREAM_RECORDING });
    try {
      const response = await getStreamRecording(restaurantUuid, streamName);
      const { success, results } = response;
      const recordUrl = `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/hls/${streamName}/index.m3u8`;
      if (success) {
        dispatch({ type: GET_STREAM_RECORDING_RESULT, payload: { url: recordUrl } });
        if (actions.onSuccess) {
          actions.onSuccess(results);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_STREAM_RECORDING_ERROR });
      if (actions.onError) {
        actions.onError(error.message);
      }
    }
  };
};

export const getSupportAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: GET_SUPPORT });
    try {
      const response = await getSupport(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_SUPPORT_RESULT, payload: results });
        if (actions.onSuccess) {
          actions.onSuccess(results);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_SUPPORT_ERROR });
    }
  };
};

export const sendSupportAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: SEND_SUPPORT });
    try {
      const response = await sendSupport(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: SEND_SUPPORT_RESULT, payload: results });
        if (actions.onSuccess) {
          actions.onSuccess(results);
        }
      }
    } catch (error) {
      console.log(error);
      actions.onError(error && error.message ? error.message : 'Something went wrong');
      dispatch({ type: SEND_SUPPORT_ERROR });
    }
  };
};

export const changeSeveralValues = (value) => ({
  type: CHANGE_PLAYER_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_KITCHEN_PLAYER_STORE,
});
