import { useEffect } from 'react';
import cx from 'classnames';
import { ScrollableTable } from '../Table/ScrollableTable';

export const TableWithScroll = ({
  className,
  isLoading,
  screen,
  nextData,
  existsNextData = false,
  scrollableTarget,
  isScrollLoading,
  rows,
  ...tableProps
}) => {
  const isMobile = screen === 'mobile';
  const containerClassName = isMobile ? 'mobile-table__container' : 'custom-table__container';

  useEffect(() => {
    if (rows.length > 0 && !isLoading && !isScrollLoading) {
      const scrollableContainer = document.getElementById(
        scrollableTarget ? scrollableTarget : 'scrollable-table'
      );
      let pageHeight = scrollableContainer.clientHeight;
      let scrollHeight = scrollableContainer.scrollHeight;
      const scrollTop = scrollableContainer.scrollTop + pageHeight;

      if (existsNextData && scrollHeight && scrollTop && scrollTop >= scrollHeight) {
        nextData();
      }
    }
  }, [rows]);

  return (
    <div
      id="scrollable-table"
      className={cx(containerClassName, className, { 'inner-scroll': !scrollableTarget })}
    >
      <ScrollableTable
        screen={screen}
        nextData={nextData}
        existsNextData={existsNextData}
        isLoading={isLoading}
        isScrollLoading={isScrollLoading}
        scrollableTarget={scrollableTarget}
        rows={rows}
        {...tableProps}
      />
    </div>
  );
};
