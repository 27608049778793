import React from 'react';

import moment from 'moment';
import { ReactComponent as Clock } from '../../assets/img/icons/common/Clock.svg';
import { ReactComponent as Calendar } from '../../assets/img/icons/datepicker/Calendar.svg';
import { Preloader } from '../Preloader/Preloader';
import { TablePagination } from '../TablePagination/TablePagination';

import { isEmpty } from 'lodash';
import { EmptyContainer } from '../EmptyContainer';
import './IconTableCustom.scss';

export const IconTableCustom = ({
  rows,
  totalRows,
  currentPage,
  perPage,
  isLoading,
  mobile = false,
  changeValue,
}) => {
  if (isLoading) {
    return (
      <div className="icon-view__loader">
        <Preloader big={true} />
      </div>
    );
  }

  return !isEmpty(rows) ? (
    <>
      <div className="icon-view__container">
        {rows.map((item, index) => (
          <div className="icon-view__item" key={item.id || index}>
            <div className="icon-view__component">{item.cells[0]}</div>
            <div className="icon-view__description">
              <span className="icon-view__name">{item.cells[1] || ''}</span>
              <div className="icon-view__time">
                <div className="icon-view__time-date">
                  <Calendar />
                  <span>{moment(item.cells[2]).format('MMM D YYYY') || ''}</span>
                </div>
                <div className="icon-view__time-clock">
                  <Clock />
                  <span>{moment(item.cells[2]).format('LTS')}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <TablePagination
        className="icon-view__pagination"
        totalRows={totalRows}
        page={currentPage}
        perPage={perPage}
        mobile={mobile}
        siblingCount={1}
        onChangePage={(value) => changeValue('page', value)}
        onChangePerPage={(value) => changeValue('limit', value)}
      />
    </>
  ) : (
    <EmptyContainer />
  );
};
