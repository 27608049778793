import {
  createChickenMaterial,
  deleteChickenMaterial,
  getChickenMaterials,
  updateChickenMaterial,
} from '../../api/materials';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_CHICKEN_MATERIALS_VALUE = 'CHANGE_CHICKEN_MATERIALS_VALUE';
export const CHANGE_MODAL_MATERIALS_VALUE = 'CHANGE_MODAL_MATERIALS_VALUE';
export const CHANGE_CHICKEN_MATERIALS_SEVERAL_VALUES = 'CHANGE_CHICKEN_MATERIALS_SEVERAL_VALUES';

export const RESET_CHICKEN_MATERIALS_STORE = 'RESET_CHICKEN_MATERIALS_STORE';

export const GET_CHICKEN_MATERIALS = 'GET_CHICKEN_MATERIALS';
export const GET_CHICKEN_MATERIALS_SUCCESS = 'GET_CHICKEN_MATERIALS_SUCCESS';
export const GET_CHICKEN_MATERIALS_ERROR = 'GET_CHICKEN_MATERIALS_ERROR';

export const NEW_CHICKEN_MATERIAL = 'NEW_CHICKEN_MATERIAL';
export const NEW_CHICKEN_MATERIAL_SUCCESS = 'NEW_CHICKEN_MATERIAL_SUCCESS';
export const NEW_CHICKEN_MATERIAL_ERROR = 'NEW_CHICKEN_MATERIAL_ERROR';

export const UPDATE_CHICKEN_MATERIAL = 'UPDATE_CHICKEN_MATERIAL';
export const UPDATE_CHICKEN_MATERIAL_SUCCESS = 'UPDATE_CHICKEN_MATERIAL_SUCCESS';
export const UPDATE_CHICKEN_MATERIAL_ERROR = 'UPDATE_CHICKEN_MATERIAL_ERROR';

export const DELETE_CHICKEN_MATERIAL = 'DELETE_CHICKEN_MATERIAL';
export const DELETE_CHICKEN_MATERIAL_SUCCESS = 'DELETE_CHICKEN_MATERIAL_SUCCESS';
export const DELETE_CHICKEN_MATERIAL_ERROR = 'DELETE_CHICKEN_MATERIAL_ERROR';

export const getChickenMaterialsAction = (function () {
  let prevController;

  return (params, setData) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_CHICKEN_MATERIALS });
      try {
        const response = await getChickenMaterials(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          setData([...results.content]);
          dispatch({ type: GET_CHICKEN_MATERIALS_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_CHICKEN_MATERIALS_ERROR });
        }
      }
    };
  };
})();

export const createChickenMaterialAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: NEW_CHICKEN_MATERIAL });
    try {
      const response = await createChickenMaterial(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: NEW_CHICKEN_MATERIAL_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: NEW_CHICKEN_MATERIAL_ERROR });
    }
  };
};

export const updateChickenMaterialAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CHICKEN_MATERIAL });
    try {
      const response = await updateChickenMaterial(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: UPDATE_CHICKEN_MATERIAL_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: UPDATE_CHICKEN_MATERIAL_ERROR });
    }
  };
};

export const deleteChickenMaterialAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CHICKEN_MATERIAL });
    try {
      const response = await deleteChickenMaterial(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_CHICKEN_MATERIAL_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_CHICKEN_MATERIAL_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CHICKEN_MATERIALS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_CHICKEN_MATERIALS_SEVERAL_VALUES,
  payload: value,
});

export const changeModalValue = (key, value) => ({
  type: CHANGE_MODAL_MATERIALS_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_CHICKEN_MATERIALS_STORE,
});
