export const LOGIN = '/login';
export const LOGIN_EXPIRED = '/login-expired';
export const CHANGE_EXPIRED_PASSWORD = '/change-expired-password';
export const SET_PASSWORD = '/reset-password';
export const PASSWORD_RESET = '/forgot-password';
export const BLOCKED_USER = '/blocked-account';
export const CHICKEN_DASHBOARD = '/dashboard/chicken';
export const CHICKEN_PERFORMANCE = '/performance/chicken';
export const FRAHA_DASHBOARD = '/dashboard/potato';
export const POTATO_EVENTS = '/events/potato';
export const CHICKEN_EVENTS = '/events/chicken';
export const ANALYTIC_EVENTS = '/events/analytics';
export const HOT_HOLDING = '/timers-cycle-history/hot-holding';
export const TIMERS_ANALYTICS = '/timers-cycle-history/analytics';
export const ENTRY_CHUTES = '/entry-chutes';
export const BASKET_EVENTS = '/basket-events';
export const FRYER_EVENTS = '/timers-cycle-history/fryer';
export const DUMP_PAN_EVENTS = '/timers-cycle-history/dump-pan';
export const FRY_CHUTES_EVENTS = '/timers-cycle-history/fry-chutes';
export const PRQ = '/timers-cycle-history/prq';
export const BREADING_EVENTS = '/timers-cycle-history/breading';
export const CONVEYOR_EVENTS = '/timers-cycle-history/conveyor';
export const CATERING_EVENTS = '/timers-cycle-history/catering';
export const UNUSUAL_EVENT = '/unusual/event';
export const UNUSUAL_DP_REMAINING = '/unusual/dp-remaining';
export const UNUSUAL_WRONG_MARKER = '/unusual/wrong-marker';
export const UNUSUAL_IDENTICAL = '/unusual/identical-markers';
export const UNUSUAL_RECORDINGS = '/unusual/recordings';
export const DEVICES_PC = '/devices/pc';
export const DEVICES_TABLETS = '/devices/tablets';
export const TABLET_SESSIONS = '/devices/tablets/:uuid/sessions';
export const RESTAURANTS = '/restaurants';
export const RESTAURANTS_DEVICES_PC = '/restaurants/:uuid/devices/pc';
export const RESTAURANTS_DEVICES_TABLETS = '/restaurants/:uuid/devices/tablets';
export const AUDIT_LOG = '/audit-log';
export const USERS = '/users';
export const USER_SESSIONS = '/users/:uuid/sessions';
export const KITCHEN_PLAYER = '/kitchen-player/player';
export const KITCHEN_PLAYER_RECORDINGS = '/kitchen-player/recordings';
export const SETTINGS_CHICKEN_MATERIALS = '/settings/chicken/materials';
export const SETTINGS_CHICKEN_CONTAINERS = '/settings/chicken/containers';
export const SETTINGS_POTATO_MATERIALS = '/settings/potato/materials';
export const SETTINGS_POTATO_PACKAGES = '/settings/potato/packages';
export const SETTINGS_PACKAGES = '/settings/packages';
export const SETTINGS_MATERIALS = '/settings/materials';
export const SETTINGS_CONTAINERS = '/settings/containers';
export const SUPPORT_HISTORY = '/support-history';
export const PLUG_TAB = '/plug-play/plug';
export const ARUCO_VALIDATION = '/plug-play/aruco';
export const AUTHENTIFICATION_LOG = '/user-settings/authentification-log';
export const ACTIVE_SESSIONS = '/user-settings/active-sessions';
export const USER_SECURITY = '/user-settings/security';
export const USERS_MANAGEMENT_EDIT = '/users/:uuid';
export const MONITORING_GRAPHICS = '/monitoring/graphics';
export const MONITORING_ALERTS = '/monitoring/alerts';
export const CAMERAS_MANAGEMENT_VIEW = '/cameras/view';
export const CAMERAS_MANAGEMENT_SETTINGS = '/cameras/settings';
export const CAMERAS_MANAGEMENT_RECORDINGS = '/cameras/recordings';
export const REPORTS = '/reports';
export const MULTI_FACTOR_AUTH = '/multi-step-auth';
export const SERVER_MANAGEMENT = '/monitoring/server-management';
export const STOCK_OUT_REPORTS = '/stock-out-reports/chicken';
export const PRODUCTION_QUEUE = '/pcs/monitor';
export const CATERING_INVENTORY = '/pcs/catering-inventory';
export const FRYER_MANAGER = '/pcs/fryer-manager';
export const ADJUSTMENT = '/pcs/adjustment';
export const RECOMMENDATIONS = '/pcs/recommendations';
