import { useEffect, useState } from 'react';

export const intervals = [
  { label: '1s', value: 1 },
  { label: '2s', value: 2 },
  { label: '5s', value: 5 },
  { label: '10s', value: 10 },
  { label: '20s', value: 20 },
  { label: '30s', value: 30 },
  { label: '1m', value: 60 },
  { label: '2m', value: 120 },
  { label: '5m', value: 300 },
  { label: '10m', value: 600 },
  { label: '20m', value: 1200 },
  { label: '30m', value: 1800 },
  { label: '1h', value: 3600 },
  { label: '2h', value: 7200 },
  { label: '4h', value: 14400 },
  { label: '8h', value: 28800 },
  { label: '12h', value: 43200 },
  { label: '1d', value: 86400 },
  { label: '2d', value: 172800 },
  { label: '7d', value: 604800 },
  { label: '15d', value: 1296000 },
  { label: '30d', value: 2592000 },
  { label: '90d', value: 7776000 },
  { label: '6mo', value: Math.floor((Date.now() - new Date().setMonth(new Date().getMonth() - 6)) / 1000) },
];

export const timeIntervals = [
  { name: '15m', start: 3, end: 9, value: 900, auto: 3 },
  { name: '1h', start: 4, end: 11, value: 3600, auto: 6 },
  { name: '4h', start: 5, end: 13, value: 14400, auto: 8 },
  { name: '1d', start: 8, end: 16, value: 86400, auto: 10 },
  { name: '2d', start: 8, end: 17, value: 172800, auto: 11 },
  { name: '3d', start: 9, end: 18, value: 259200, auto: 12 },
  { name: '7d', start: 11, end: 18, value: 604800, auto: 13 },
  { name: '15d', start: 12, end: 19, value: 1296000, auto: 14 },
  {
    name: '1mo',
    start: 13,
    end: 20,
    value: Math.floor(
      (Date.now() -
        (new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth() ===
        (new Date().getMonth() - 1 < 0 ? 12 + (new Date().getMonth() - 1) : new Date().getMonth() - 1)
          ? new Date().setMonth(new Date().getMonth() - 1)
          : new Date(new Date().getFullYear(), new Date().getMonth(), 0))) /
        1000
    ),
    auto: 16,
  },
  {
    name: '6mo',
    start: 16,
    end: 22,
    value: Math.floor(
      (Date.now() -
        (new Date(new Date().setMonth(new Date().getMonth() - 6)).getMonth() ===
        (new Date().getMonth() - 6 < 0 ? 12 + (new Date().getMonth() - 6) : new Date().getMonth() - 6)
          ? new Date().setMonth(new Date().getMonth() - 6)
          : new Date(new Date().getFullYear(), new Date().getMonth() - 5, 0))) /
        1000
    ),
    auto: 18,
  },
  {
    name: '1y',
    start: 17,
    end: 23,
    value: Math.floor((Date.now() - new Date().setFullYear(new Date().getFullYear() - 1)) / 1000),
    auto: 19,
  },
];

export const useChartInterval = (startDate, endDate, timeInterval, handleChange, isEnable = true) => {
  const [intervalState, setIntervalState] = useState(
    intervals.slice(timeIntervals[0].start, timeIntervals[0].end)
  );

  useEffect(() => {
    if (isEnable) {
      if ((startDate && startDate !== 'select' && endDate && endDate !== 'select') || timeInterval !== null) {
        let searchInterval = timeIntervals.find(
          (time) =>
            time.value === parseInt(timeInterval !== null ? timeInterval : (endDate - startDate) / 1000)
        );
        if (searchInterval) {
          setIntervalState(intervals.slice(searchInterval.start, searchInterval.end));
          handleChange(intervals[searchInterval.auto].value);
        } else {
          let searchCalendar = timeIntervals
            .slice(0, timeIntervals.length - 1)
            .find(
              (time, index) =>
                time.value >= parseInt(timeInterval !== null ? timeInterval : (endDate - startDate) / 1000) &&
                parseInt(timeInterval !== null ? timeInterval : (endDate - startDate) / 1000) <=
                  timeIntervals[index + 1].value
            );
          if (searchCalendar) {
            setIntervalState(intervals.slice(searchCalendar.start, searchCalendar.end));
            handleChange(intervals[searchCalendar.auto].value);
          } else {
            setIntervalState(
              intervals.slice(
                timeIntervals[timeIntervals.length - 1].start,
                timeIntervals[timeIntervals.length - 1].end
              )
            );
            handleChange(intervals[timeIntervals[timeIntervals.length - 1].auto].value);
          }
        }
      } else {
        setIntervalState(intervals.slice(timeIntervals[0].start, timeIntervals[0].end));
        handleChange(intervals[timeIntervals[0].auto].value);
      }
    }
  }, [startDate, endDate, timeInterval, isEnable]);

  return intervalState;
};
