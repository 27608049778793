import React, { memo } from 'react';

import cx from 'classnames';
import { ChartComponent, ChartNoInfo, Preloader } from '../../../components';

import { units } from '../../../constants/graphics.constants';

import { getChartData, getChartOptions } from './utils';

import '../MonitoringGraphic.scss';

export const MonitoringGraphic = memo(
  ({
    data,
    chartMetric = '%',
    chartArea,
    legend = false,
    title = false,
    allMetric = false,
    percentMaxValue = false,
    dates,
    colorIndex,
    network = null,
    showTooltipUnits = false,
  }) => {
    return (
      <div className="monitoring-graphic monitoring-graphic__without-border">
        <div className="monitoring-graphic__container">
          {title ? <div className="monitoring-graphic__title">{title}</div> : <></>}
          {data !== null && data.length !== 0 ? (
            data[0].values !== null ? (
              <div className="monitoring-graphic__item">
                <div className={cx('monitoring-graphic__item-chart', { network: network !== null })}>
                  <ChartComponent
                    chartType={data.length !== 1 ? 'LineChart' : 'AreaChart'}
                    content={getChartData(data, dates, legend, colorIndex, chartMetric, showTooltipUnits)}
                    chartOptions={getChartOptions(
                      data,
                      dates,
                      colorIndex,
                      legend,
                      chartArea,
                      allMetric,
                      chartMetric,
                      percentMaxValue
                    )}
                  />
                  {network !== null ? (
                    <div className="monitoring-graphic-network__values">
                      <div className="monitoring-graphic-network__values-item">
                        <span>Min</span>
                        <div>
                          {network.min !== null
                            ? network.min.toFixed(2) + ' ' + units[chartMetric] + '/s'
                            : 'N/A'}
                        </div>
                      </div>
                      <div className="monitoring-graphic-network__values-item">
                        <span>Max</span>
                        <div>
                          {network.max !== null
                            ? network.max.toFixed(2) + ' ' + units[chartMetric] + '/s'
                            : 'N/A'}
                        </div>
                      </div>
                      <div className="monitoring-graphic-network__values-item">
                        <span>Avg</span>
                        <div>
                          {network.avg !== null
                            ? network.avg.toFixed(2) + ' ' + units[chartMetric] + '/s'
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <div className="graphic-no-data--info graphic-no-data--info--without-border">
                <Preloader big={true} />
              </div>
            )
          ) : (
            <ChartNoInfo />
          )}
        </div>
      </div>
    );
  }
);
