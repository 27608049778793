import request from '../utils/request';

export function getAnalytic(url, params, signal) {
  return request({
    url: `/analytic${url}`,
    method: 'get',
    data: params,
    signal,
  });
}

export function getDashboardReport(params) {
  return request({
    url: '/analytic/report',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
    returnHeaders: true,
  });
}
