import cx from 'classnames';
import React from 'react';

import { QueueProgress } from './Progress/index';
import { InfoRow } from '../../Table/InfoRow';
import { NavBar } from '../../NavBar/NavBar';

import { useSyncAnimations } from './useSynchronizeAnimation';
import { animationValues } from '../../../constants/productionQueue.constants';

export const QueueTable = React.memo(
  ({
    className,
    headerOptions = null,
    rows,
    isLoading,
    title,
    mini = false,
    id,
    stages,
    disabledStatuses = [],
    switchModel,
  }) => {
    useSyncAnimations(rows);

    return (
      <div id={id} className={cx('custom-table__container', className)}>
        {title || switchModel ? (
          <div className="queue-table__header">
            {title && <div className="queue-table__title">{title}</div>}
            {switchModel && (
              <NavBar
                className="nav-bar__custom-width queue-table__switch"
                containerClassName="queue-table__switch-container"
                itemWidth={switchModel.width}
                navTabs={switchModel.options}
                activeTab={switchModel.activeTab}
                onChange={switchModel.onChange}
              />
            )}
          </div>
        ) : null}

        <div className="custom-table__wrapper">
          <table className="custom-table__table">
            {headerOptions !== null && (
              <thead className="custom-table__thead">
                <tr>
                  {headerOptions.map((cell, index) => (
                    <th
                      key={index}
                      className={cx('custom-table__header-cell queue-table__header-cell', {
                        bordered: cell.endOfTable,
                      })}
                      style={{
                        width: cell.width || 'auto',
                        minWidth: cell.minWidth || 'auto',
                        maxWidth: cell.maxWidth || 'auto',
                      }}
                    >
                      {cell.label}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody className="custom-table__tbody">
              {!isLoading &&
                rows?.map((row, index) => (
                  <tr
                    key={index}
                    className={cx(
                      'custom-table__tbody-row queue-table__tbody-row',
                      {
                        cancel: row.isCanceled,
                      },
                      [row.animation ? row.animation.toLowerCase() : '']
                    )}
                    style={{ height: row.minHeight || undefined }}
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        key={index}
                        className={cx('custom-table__body-cell queue-table__body-cell', {
                          bordered: cell.endOfTable,
                        })}
                      >
                        {cell.label || ''}
                      </td>
                    ))}
                    <td key="queue-1" className="queue-table__container">
                      <QueueProgress
                        row={row}
                        mini={mini}
                        stages={stages}
                        disabledStatuses={disabledStatuses}
                      />
                      {row.taskDelay ? (
                        <div className="queue-table__delay-indicatior">{row.taskDelay}</div>
                      ) : null}
                    </td>
                    {[
                      ...Array(
                        headerOptions.length - headerOptions.findIndex((option) => option.endOfTable) - 2
                      ).keys(),
                    ].map((it) => (
                      <td key={`queue-${it + 1}`} />
                    ))}
                  </tr>
                ))}
              {isLoading && (
                <InfoRow>
                  <i className="fa fa-spinner fa-spin fa-2x" />
                </InfoRow>
              )}
              {!isLoading && rows?.length === 0 && (
                <InfoRow>
                  <span className="no-info">No records</span>
                </InfoRow>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);
