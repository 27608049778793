import request from '../utils/request';

export function getSupport(params) {
  return request({
    url: '/support',
    method: 'get',
    data: params,
  });
}

export function deleteSupport(uuid) {
  return request({
    url: `/support/request/${uuid}`,
    method: 'delete',
  });
}

export function resolveSupport(uuid) {
  return request({
    url: `/support/request/resolve/${uuid}`,
    method: 'put',
  });
}

export function addSupport(params) {
  return request({
    url: `/support`,
    method: 'post',
    data: params,
  });
}

export function editSupport(uuid, params) {
  return request({
    url: `/support/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function getSupportHistory(params, signal) {
  return request({
    url: `/support/history`,
    method: 'get',
    data: params,
    signal,
  });
}
