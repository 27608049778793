import { memo, useEffect } from 'react';

import cx from 'classnames';

import './MonitoringGraphic.scss';

export const Gauge = memo(
  ({ label = false, idValue, value = null, type = '%', yellowFrom = 75, yellowTo = 90 }) => {
    useEffect(() => {
      let gaugeValue = document.getElementById(idValue).getContext('2d');
      gaugeValue.clearRect(0, 0, '120', '120');
      gaugeValue.beginPath();
      gaugeValue.lineWidth = 14;
      gaugeValue.strokeStyle = '#f2f2f2';
      gaugeValue.arc(60, 60, 53, 0.7 * Math.PI, 2.3 * Math.PI);
      gaugeValue.stroke();
      if (value !== null && value !== 0) {
        gaugeValue.beginPath();
        gaugeValue.lineWidth = 14;
        gaugeValue.strokeStyle =
          value < yellowFrom ? '#17B61F' : value >= yellowFrom && value < yellowTo ? '#DBB91D' : '#cb444a';
        gaugeValue.arc(60, 60, 53, 0.7 * Math.PI, (0.7 + 1.6 * Number(value / 100)) * Math.PI);
        gaugeValue.stroke();
      }
    }, [value]);

    return (
      <div className="custom-gauge">
        <canvas className="custom-gauge__canvas" id={idValue} width="120" height="120"></canvas>
        <span
          id="flotGaugeValue0"
          className={cx('custom-gauge__span', {
            success: value < yellowFrom,
            warning: value >= yellowFrom && value < yellowTo,
            danger: value >= yellowTo,
          })}
        >
          {value !== null ? (
            <>
              {value > 1 ? value : value.toFixed(1)}
              {type}
            </>
          ) : (
            'N/A'
          )}
        </span>
        {label ? <span className="custom-gauge__label">{label}</span> : <></>}
      </div>
    );
  }
);
