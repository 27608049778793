import { getPackages, updatePackage } from '../../api/packages';

export const CHANGE_POTATO_PACKAGES_VALUE = 'CHANGE_POTATO_PACKAGES_VALUE';
export const RESET_POTATO_PACKAGES_STORE = 'RESET_POTATO_PACKAGES_STORE';

export const GET_POTATO_PACKAGES = 'GET_POTATO_PACKAGES';
export const GET_POTATO_PACKAGES_SUCCESS = 'GET_POTATO_PACKAGES_SUCCESS';
export const GET_POTATO_PACKAGES_ERROR = 'GET_POTATO_PACKAGES_ERROR';

export const UPDATE_POTATO_PACKAGE = 'UPDATE_PACKAGE';

export const getPackagesAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_POTATO_PACKAGES });
    try {
      const response = await getPackages(params);
      const { success, results } = response;
      if (success) {
        setData([...results]);
        dispatch(changeValue('totalResults', results.total));
        dispatch({ type: GET_POTATO_PACKAGES_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_POTATO_PACKAGES_ERROR });
    }
  };
};

export const updatePackageAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_POTATO_PACKAGE });
    try {
      const response = await updatePackage(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_POTATO_PACKAGES_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_POTATO_PACKAGES_STORE,
});
