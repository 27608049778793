import React from 'react';
import cx from 'classnames';

import { CancelProgress } from './CancelProgress';
import { CompletedProgress } from './CompletedProgress';
import { GoProgress } from './GoProgress';
import { getInnerIndicatorRight } from '../utils';

const getProgressContent = (row, stages) => {
  if (row.isCanceled) {
    return <CancelProgress row={row} />;
  }

  if (row.isCompleted) {
    return <CompletedProgress stages={stages} row={row} />;
  }

  return <GoProgress row={row} stages={stages} />;
};

const getIndicatorStyle = (row, stages) => {
  const stageNumber = row.status.stage || 1;

  return {
    right: getInnerIndicatorRight(stages, stageNumber),
  };
};

export const QueueProgress = ({ row, stages, disabledStatuses, mini = false }) => {
  if (disabledStatuses.includes(row.statusName.value)) {
    return null;
  }

  return (
    <div className={cx('queue-table', { mini })}>
      {getProgressContent(row, stages)}
      {row.innerTaskDelay ? (
        <div className="queue-table__delay-indicatior" style={getIndicatorStyle(row, stages)}>
          {row.innerTaskDelay}
        </div>
      ) : null}
    </div>
  );
};
