import { getMaterialsAnalytics } from '../../api/analytics';
import {
  deleteCateringTimer,
  getExelExport,
  getCateringTimersTotal,
  getCateringTimers,
} from '../../api/catering';
import { downloadFile } from '../../utils';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_CATERING_TIMERS_VALUE = 'CHANGE_CATERING_TIMERS_VALUE';
export const CHANGE_CATERING_TIMERS_SEVERAL_VALUES = 'CHANGE_CATERING_TIMERS_SEVERAL_VALUES';
export const RESET_CATERING_TIMERS_STORE = 'RESET_CATERING_TIMERS_STORE';

export const GET_CATERING_TIMERS = 'GET_CATERING_TIMERS';
export const GET_CATERING_TIMERS_SUCCESS = 'GET_CATERING_TIMERS_SUCCESS';
export const GET_CATERING_TIMERS_ERROR = 'GET_CATERING_TIMERS_ERROR';

export const DELETE_CATERING_TIMER = 'DELETE_CATERING_TIMER';
export const DELETE_CATERING_TIMER_SUCCESS = 'DELETE_CATERING_TIMER_SUCCESS';
export const DELETE_CATERING_TIMER_ERROR = 'DELETE_CATERING_TIMER_ERROR';

export const LOAD_CATERING_TIMERS_FILE = 'LOAD_CATERING_TIMERS_FILE';
export const LOAD_CATERING_TIMERS_FILE_SUCCESS = 'LOAD_CATERING_TIMERS_FILE_SUCCESS';
export const LOAD_CATERING_TIMERS_FILE_ERROR = 'LOAD_CATERING_TIMERS_FILE_ERROR';

export const GET_CATERING_TIMERS_ANALYTIC = 'GET_CATERING_TIMERS_ANALYTIC';
export const GET_CATERING_TIMERS_ANALYTIC_SUCCESS = 'GET_CATERING_TIMERS_ANALYTIC_SUCCESS';
export const GET_CATERING_TIMERS_ANALYTIC_ERROR = 'GET_CATERING_TIMERS_ANALYTIC_ERROR';

export const downloadCateringTimersFile = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_CATERING_TIMERS_FILE });
    try {
      const response = await getExelExport(params);
      const { success, results } = response;
      if (success) {
        response.code !== 202 && downloadFile(results, 'application/zip');
        dispatch({ type: LOAD_CATERING_TIMERS_FILE_SUCCESS });
        if (actions.onSuccess) {
          actions.onSuccess(response);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_CATERING_TIMERS_FILE_ERROR });
      if (actions.onError) {
        actions.onError(error.message);
      }
    }
  };
};

export const getCateringTimersAnalyticAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_CATERING_TIMERS_ANALYTIC, payload: params.cursorDirection === 'FIRST' });
      try {
        const [response, totalResponse] = await Promise.all([
          getMaterialsAnalytics(params, abortController.signal),
          getCateringTimersTotal(
            {
              startTime: params.startEventTime,
              endTime: params.endEventTime,
              restaurantUuid: params.restaurantsUuid,
              materials: params.materials,
            },
            abortController.signal
          ),
        ]);

        const { success, results } = response;
        const { success: totalSuccess, results: total } = totalResponse;

        if (success && totalSuccess && !abortController.signal.aborted) {
          dispatch({ type: GET_CATERING_TIMERS_ANALYTIC_SUCCESS, payload: { results, total }, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_CATERING_TIMERS_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const getCateringTimersAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_CATERING_TIMERS, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await getCateringTimers(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_CATERING_TIMERS_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          actions?.onError(error.message);
          dispatch({ type: GET_CATERING_TIMERS_ERROR, params });
        }
      }
    };
  };
})();

export const deleteCateringTimerAction = (uuid, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CATERING_TIMER });
    try {
      const response = await deleteCateringTimer(uuid);
      const { success } = response;
      if (success) {
        actions?.onSuccess();
        dispatch({ type: DELETE_CATERING_TIMER_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions?.onError(error.message);
      dispatch({ type: DELETE_CATERING_TIMER_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CATERING_TIMERS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_CATERING_TIMERS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_CATERING_TIMERS_STORE,
});
