import React, { useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';

export const NumberInput = ({ value, onChange, label, minValue = 0, maxValue = 59 }) => {
  const numberInputRef = useRef(null);
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  const handleKeyDown = (event) => {
    if (numberInputRef.current && numberInputRef.current.focused) {
      if (event.which === 38 || event.keyCode === 38 || event.charCode === 38) {
        handleDecrease();
      } else if (event.which === 40 || event.keyCode === 40 || event.charCode === 40) {
        handleIncrease();
      }
    }
  };

  const handleInputChange = (inputValue) => {
    if (Number(inputValue) >= 0 && Number(inputValue) <= maxValue) {
      onChange(inputValue);
    } else {
      onChange(value);
    }
  };

  const handleIncrease = () => {
    if (Number(valueRef?.current) < maxValue) {
      const newValue = Number(valueRef.current) + 1;
      onChange(newValue.toString().padStart(2, '0'));
    } else {
      onChange(minValue.toString().padStart(2, '0'));
    }
  };

  const handleDecrease = () => {
    if (Number(valueRef?.current) > minValue) {
      const newValue = Number(valueRef.current) - 1;
      onChange(newValue.toString().padStart(2, '0'));
    } else {
      onChange(maxValue.toString().padStart(2, '0'));
    }
  };

  return (
    <div className="number-input__container">
      <span className="number-input__label">{label}</span>
      <div className="number-input__content">
        <button tabIndex="-1" className="number-input__previous-number" onClick={handleDecrease}>
          {(Number(value) - 1 < minValue ? maxValue : Number(value) - 1).toString().padStart(2, '0')}
        </button>
        <InputMask
          ref={numberInputRef}
          className="number-input__input"
          mask="99"
          maskChar="0"
          value={value}
          onChange={(e) => handleInputChange(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <button tabIndex="-1" className="number-input__next-number" onClick={handleIncrease}>
          {(Number(value) + 1 > maxValue ? minValue : Number(value) + 1).toString().padStart(2, '0')}
        </button>
      </div>
    </div>
  );
};
