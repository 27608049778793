import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUnlink } from '@fortawesome/free-solid-svg-icons';

import { MiniPlayerLive } from './MiniPlayerLive';

export class MiniPlayerStream extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsError: false,
      isLoading: true,
    };
    this.scaleRef = React.createRef();
  }

  onWebRTCError = () => {
    this.setState({ optionsError: '', isLoading: false });
    this.props.changeValue('hasWebRTCError', true);
  };

  componentDidMount() {
    if (this.props.miniPLayer.restaurantUuid === 'select') {
      this.setState({ optionsError: 'No selected restaurant', isLoading: false });
      this.props.changeValue('hasWebRTCError', false);
    }
  }

  componentDidUpdate(prevProps) {
    const { miniPLayer } = this.props;
    const { restaurantUuid, streamId, restart } = miniPLayer;

    if (
      prevProps.miniPLayer.restaurantUuid !== restaurantUuid ||
      prevProps.miniPLayer.streamId !== streamId ||
      prevProps.miniPLayer.restart !== restart
    ) {
      if (restaurantUuid === 'select') {
        this.setState({ optionsError: 'No selected restaurant', isLoading: false });
      } else {
        if (!streamId) {
          this.setState({ optionsError: '', isLoading: false });
        } else {
          this.setState({ optionsError: '', isLoading: true });
        }
      }
      this.props.changeValue('hasWebRTCError', false);
    }
  }

  handleRestartStream = () => {
    const { miniPLayer } = this.props;
    if (!miniPLayer.streamId) return;
    this.props.changeValue('restart', true);
  };

  render() {
    const { optionsError, isLoading } = this.state;
    const { miniPLayer } = this.props;
    const { streamId, hasWebRTCError } = miniPLayer;

    return (
      <div className="miniplayer_camera-view__player">
        {isLoading && !hasWebRTCError ? (
          <div className="miniplayer_camera-view__player-loader">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        ) : null}
        {hasWebRTCError || optionsError ? (
          <div className="miniplayer_camera-view__player-error">
            <FontAwesomeIcon icon={faUnlink} />
            <span>{hasWebRTCError && !optionsError ? "Can't connect to the restaurant" : optionsError}</span>
          </div>
        ) : null}
        {streamId && (
          <div className="miniplayer_camera-view__player-video">
            <MiniPlayerLive
              miniPlayer={this.props.miniPLayer}
              onWebRTCError={this.onWebRTCError}
              onRestartStream={this.handleRestartStream}
              changeValue={this.props.changeValue}
              closePlayer={this.props.closePlayer}
            />
          </div>
        )}
      </div>
    );
  }
}
