import InputMask from 'react-input-mask';

import cx from 'classnames';

import './FormInput.scss';

export const FormInputWithMask = ({
  name,
  value,
  type = 'text',
  placeholder,
  mask,
  maskChar,
  defaultValue,
  onChange,
}) => {
  const handleChange = (value) => {
    name ? onChange(name, value) : onChange(value);
  };

  return (
    <div className="form-input__container">
      {value && value.length !== 0 ? (
        <span className={cx('form-input__title', { filled: value && value.length !== 0 })}>
          {placeholder}
        </span>
      ) : null}
      <InputMask
        className={cx('form-input', {
          with__title: value && value.length !== 0,
        })}
        mask={mask}
        type={type}
        maskChar={maskChar}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      />
    </div>
  );
};
