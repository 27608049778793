import request from '../utils/request';

export function getFryChutes(params, signal) {
  return request({
    url: '/fryChutesInfo',
    method: 'get',
    data: params,
    signal,
  });
}

export function getFryChutesTotal(params, signal) {
  return request({
    url: '/fryChutesInfo/total',
    method: 'get',
    data: params,
    signal,
  });
}

export function getExelExport(params) {
  return request({
    url: '/fryChutesInfo/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}

export function deleteFryChute(uuid) {
  return request({
    url: `/fryChutesInfo/${uuid}`,
    method: 'delete',
  });
}
