import moment from 'moment';

export const prepareAvailabilityData = (startTime, endTime, devices, devicesAvailability) => {
  const availabilityData = devices.map((device) => {
    const deviceAvailability = applyAvailabilityToInterval(
      device.uuid,
      startTime,
      endTime,
      devicesAvailability
    );
    const chartData = [
      [
        { type: 'string', id: 'DeviceUuid' },
        { type: 'string', id: 'Status' },
        { type: 'string', role: 'tooltip' },
        { type: 'date', id: 'Start' },
        { type: 'date', id: 'End' },
      ],
      ...deviceAvailability.map((availabilityPeriod) => [
        device.uuid,
        availabilityPeriod.status,
        `<div class='custom-timeline-tooltip'>
            <span class=${availabilityPeriod.status.toLowerCase()}>
                ${moment(availabilityPeriod.start).format('lll')} - ${moment(availabilityPeriod.end).format(
          'lll'
        )}
                </span>
        </div>`,
        availabilityPeriod.start,
        availabilityPeriod.end,
      ]),
    ];
    const chartColors = deviceAvailability.map((availabilityPeriod) =>
      availabilityPeriod.status === 'Online' ? '#009408' : '#DD0031'
    );

    return {
      deviceUuid: device.uuid,
      deviceName: device.identifier,
      totalAvailable: computeTotalAvailability(startTime, endTime, deviceAvailability),
      chartData: chartData,
      chartColors: chartColors,
    };
  });

  return availabilityData;
};

const applyAvailabilityToInterval = (deviceUuid, startTime, endTime, devicesAvailability) => {
  const addSubInterval = (subIntervals, start, end, status) => {
    if (start < end) {
      subIntervals.push({ start, end, status });
    }
  };

  const processInterval = (onlinePeriods) => {
    let subIntervals = [];
    let intervalStart = new Date(startTime);
    let intervalEnd = new Date(endTime);
    let currentTime = new Date(intervalStart);

    onlinePeriods.forEach((period) => {
      let onlineStart = new Date(period.onlineStartTime);
      let onlineEnd = period.onlineEndTime ? new Date(period.onlineEndTime) : intervalEnd;

      if ((!onlineEnd || onlineEnd > intervalStart) && onlineStart < intervalEnd) {
        if (currentTime < onlineStart) {
          addSubInterval(subIntervals, currentTime, onlineStart, 'Offline');
        }
        addSubInterval(
          subIntervals,
          new Date(Math.max(onlineStart, intervalStart)),
          new Date(Math.min(onlineEnd, intervalEnd)),
          'Online'
        );
        currentTime = new Date(Math.min(onlineEnd, intervalEnd));
      }
    });

    if (currentTime < intervalEnd) {
      addSubInterval(subIntervals, currentTime, intervalEnd, 'Offline');
    }

    return subIntervals;
  };

  const deviceAvailability = devicesAvailability.find((item) => item.deviceUuid === deviceUuid);
  const deviceOnlinePeriods = deviceAvailability ? deviceAvailability.onlinePeriods : [];
  return processInterval(deviceOnlinePeriods);
};

const computeTotalAvailability = (startTime, endTime, deviceAvailability) => {
  const intervalStart = new Date(startTime);
  const intervalEnd = new Date(endTime);

  const intervalDuration = intervalEnd - intervalStart;
  const onlineDuration = deviceAvailability.reduce((duration, { start, end, status }) => {
    const periodDuration = status === 'Online' ? new Date(end) - new Date(start) : 0;
    return duration + periodDuration;
  }, 0);
  const percent = (onlineDuration / intervalDuration) * 100;

  return parseFloat(percent.toFixed(2)).toString() + '%';
};
