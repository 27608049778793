import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { useFloating, offset, shift, size, flip } from '@floating-ui/react-dom';

const useUpdateDropdownMenuPosition = (update) => {
  useEffect(() => {
    const debouncedRecalculatePosition = debounce(update, 100);
    window.addEventListener('resize', debouncedRecalculatePosition);
    return () => {
      debouncedRecalculatePosition.cancel();
      window.removeEventListener('resize', debouncedRecalculatePosition);
    };
  }, []);
};

export const usePlacement = (placement, selectOpened, settings) => {
  const maxHeight = useRef(0);
  const { refs, floatingStyles, update } = useFloating({
    middleware: [
      offset(5),
      shift({
        padding: 5,
      }),
      flip({
        padding: settings?.flipPadding || 0,
      }),
      size({
        apply({ availableHeight, availableWidth, elements }) {
          maxHeight.current = availableHeight;
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 10}px`,
          });
        },
      }),
    ],
    open: selectOpened,
    placement,
  });
  const styles = {
    ...floatingStyles,
    '--data-max-height': `${maxHeight.current}px`,
  };
  useUpdateDropdownMenuPosition(update);

  return { refs, floatingStyles: styles };
};
