import React from 'react';

import './ProgressBar.scss';

export const ProgressBar = ({ width = '100%', height = '3px', progress }) => {
  return (
    <div className="download-progress-bar" style={{ width, height }}>
      <div className="progress-bar__loader" style={{ width: `${progress}%` }}></div>
    </div>
  );
};
