import { getMaterialsAnalytics } from '../../api/analytics';

export const CHANGE_TIMERS_CYCLE_ANALYTIC_VALUE = 'CHANGE_TIMERS_CYCLE_ANALYTIC_VALUE';
export const CHANGE_TIMERS_CYCLE_ANALYTIC_SEVERAL_VALUES = 'CHANGE_TIMERS_CYCLE_ANALYTIC_SEVERAL_VALUES';
export const RESET_TIMERS_CYCLE_ANALYTIC_STORE = 'RESET_TIMERS_CYCLE_ANALYTIC_STORE';

export const GET_TIMERS_CYCLE_ANALYTIC = 'GET_TIMERS_CYCLE_ANALYTIC';
export const GET_TIMERS_CYCLE_ANALYTIC_SUCCESS = 'GET_TIMERS_CYCLE_ANALYTIC_SUCCESS';
export const GET_TIMERS_CYCLE_ANALYTIC_ERROR = 'GET_TIMERS_CYCLE_ANALYTIC_ERROR';

export const getTimersCycleAnalyticAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_TIMERS_CYCLE_ANALYTIC });
    try {
      const response = await getMaterialsAnalytics(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_TIMERS_CYCLE_ANALYTIC_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TIMERS_CYCLE_ANALYTIC_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_TIMERS_CYCLE_ANALYTIC_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_TIMERS_CYCLE_ANALYTIC_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_TIMERS_CYCLE_ANALYTIC_STORE,
});
