import { useRef } from 'react';

import cx from 'classnames';

import './MultiAuthField.scss';

export const MultiAuthField = ({ code, className, changeValue }) => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  const printCode = (value, index) => {
    let codes = code;
    changeValue(code.map((item, i) => (i === index ? value : item)));
    if (index < 5 && value.length !== 0) [ref1, ref2, ref3, ref4, ref5, ref6][index + 1].current.focus();
    else if (index > 0 && value.length === 0 && codes[index].length === 0)
      [ref1, ref2, ref3, ref4, ref5, ref6][index - 1].current.focus();
  };

  const checkKeyDown = (e, index, current, prev = false, next = false) => {
    if ((e.keyCode === 8 || e.which === 8 || e.charCode === 8) && !current.current.value) {
      printCode('', index);
    } else if (
      !!prev &&
      (e.keyCode === 37 || e.which === 37 || e.charCode === 37) &&
      e.target.selectionStart === 0
    ) {
      prev.current.focus();
    } else if (
      !!next &&
      (e.keyCode === 39 || e.which === 39 || e.charCode === 39) &&
      e.target.selectionStart === code[index].length
    ) {
      next.current.focus();
    }
  };

  const handlePasteCode = (event) => {
    const pastedCode = event.clipboardData.getData('text');
    const resultCode = new Array(6).fill('');

    for (let i = 0; i < Math.min(pastedCode.length, 6); i++) {
      resultCode[i] = pastedCode[i];
    }
    pastedCode.length >= 6
      ? ref6.current.focus()
      : [ref1, ref2, ref3, ref4, ref5, ref6][pastedCode.length].current.focus();
    changeValue(resultCode);
  };

  return (
    <div className={cx('multi-code__container', className)}>
      <input
        className="multi-code__input"
        inputMode="numeric"
        ref={ref1}
        maxLength={1}
        value={code[0]}
        onPaste={handlePasteCode}
        onChange={(e) => printCode(e.target.value, 0)}
        onKeyDown={(e) => {
          checkKeyDown(e, 0, ref1, false, ref2);
        }}
      />
      <input
        className="multi-code__input"
        inputMode="numeric"
        ref={ref2}
        maxLength={1}
        value={code[1]}
        onPaste={handlePasteCode}
        onChange={(e) => printCode(e.target.value, 1)}
        onKeyDown={(e) => {
          checkKeyDown(e, 1, ref2, ref1, ref3);
        }}
      />
      <input
        className="multi-code__input"
        inputMode="numeric"
        ref={ref3}
        maxLength={1}
        value={code[2]}
        onPaste={handlePasteCode}
        onChange={(e) => {
          e.stopPropagation();
          printCode(e.target.value, 2);
        }}
        onKeyDown={(e) => {
          checkKeyDown(e, 2, ref3, ref2, ref4);
        }}
      />
      <input
        className="multi-code__input"
        inputMode="numeric"
        ref={ref4}
        maxLength={1}
        value={code[3]}
        onPaste={handlePasteCode}
        onChange={(e) => printCode(e.target.value, 3)}
        onKeyDown={(e) => {
          checkKeyDown(e, 3, ref4, ref3, ref5);
        }}
      />
      <input
        className="multi-code__input"
        inputMode="numeric"
        ref={ref5}
        maxLength={1}
        value={code[4]}
        onPaste={handlePasteCode}
        onChange={(e) => printCode(e.target.value, 4)}
        onKeyDown={(e) => {
          checkKeyDown(e, 4, ref5, ref4, ref6);
        }}
      />
      <input
        className="multi-code__input"
        inputMode="numeric"
        ref={ref6}
        maxLength={1}
        value={code[5]}
        onPaste={handlePasteCode}
        onChange={(e) => printCode(e.target.value, 5)}
        onKeyDown={(e) => {
          checkKeyDown(e, 5, ref6, ref5, false);
        }}
      />
    </div>
  );
};
