import { getAnalytic } from '../../api/dashboardAnalytics';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_CHICKEN_PERFORMANCE_VALUE = 'CHANGE_CHICKEN_PERFORMANCE_VALUE';
export const CHANGE_CHICKEN_PERFORMANCE_SEVERAL_VALUES = 'CHANGE_CHICKEN_PERFORMANCE_SEVERAL_VALUES';

export const SET_CHICKEN_PERFORMANCE_LOADING = 'SET_CHICKEN_PERFORMANCE_LOADING';
export const SET_CHICKEN_PERFORMANCE_METRICS = 'SET_CHICKEN_PERFORMANCE_METRICS';
export const SET_CHICKEN_PERFORMANCE_ERROR = 'SET_CHICKEN_PERFORMANCE_ERROR';

export const RESET_CHICKEN_PERFORMANCE_STORE = 'RESET_CHICKEN_PERFORMANCE_STORE';
export const RESET_CHICKEN_PERFORMANCE_CHARTS = 'RESET_CHICKEN_PERFORMANCE_CHARTS';

export const getChickenMetrics = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: SET_CHICKEN_PERFORMANCE_LOADING });
      try {
        const [response, responseExt] = await Promise.all([
          getAnalytic('/chicken-performance', params, abortController.signal),
          getAnalytic('/extended-chicken-performance', params, abortController.signal),
        ]);

        const { success, results } = response;
        const { success: successExt, results: resultsExt } = responseExt;
        if (success && successExt && !abortController.signal.aborted) {
          const data = results;
          const dataExt = resultsExt;
          const metrics = {};

          Object.keys(dataExt).forEach((material) => {
            let table = {
              data: [],
              total: [],
            };

            const matStat = {};
            matStat.timePeriodStat = [];
            matStat.totalPercent = 0;
            matStat.totalWeight = 0;
            Object.keys(dataExt[material]).forEach((period) => {
              const periodStat = [];
              periodStat.push(period);
              periodStat.push(dataExt[material][period].percent);
              periodStat.push(dataExt[material][period].weight);
              matStat.totalWeight += dataExt[material][period].weight;
              matStat.totalPercent += dataExt[material][period].percent;
              matStat.timePeriodStat.push(periodStat);
            });

            table = {
              data: matStat.timePeriodStat,
              total: [
                { label: 'Total' },
                { label: Math.round(matStat.totalPercent * 100) / 100 },
                { label: Math.round(matStat.totalWeight * 100) / 100 },
              ],
            };

            metrics[material] = {};
            metrics[material].table = table;
          });

          Object.keys(data).forEach((material) => {
            const chartData = [];

            Object.keys(data[material]).forEach((period) => {
              chartData.push([period, data[material][period].percent, data[material][period].count]);
            });

            if (metrics[material]) {
              metrics[material].chart = chartData;
            } else {
              metrics[material] = {};
              metrics[material].chart = chartData;
            }
          });
          dispatch(setMetrics(metrics));
        }
      } catch {
        if (!abortController.signal.aborted) {
          dispatch({ type: SET_CHICKEN_PERFORMANCE_ERROR });
        }
      }
    };
  };
})();

const setMetrics = (metrics) => ({
  type: SET_CHICKEN_PERFORMANCE_METRICS,
  metrics,
});

export const changeValue = (key, value) => ({
  type: CHANGE_CHICKEN_PERFORMANCE_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_CHICKEN_PERFORMANCE_SEVERAL_VALUES,
  payload: value,
});

export const resetCharts = () => ({
  type: RESET_CHICKEN_PERFORMANCE_CHARTS,
});

export const resetStore = () => ({
  type: RESET_CHICKEN_PERFORMANCE_STORE,
});
