import './styles.scss';

export const TotalContainer = ({ label, value }) => {
  return (
    <div className="page-total-item">
      <h5 className="page-total-label ">{label}</h5>
      <p className="page-total-value">{value}</p>
    </div>
  );
};
