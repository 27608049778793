import React from 'react';

import './TagsCell.scss';

export const TagsCell = ({ tags = [] }) => {
  if (!tags || tags.length === 0) return null;

  return (
    <div className="custom-cell__tags-container">
      {tags.map((tag) => (
        <div key={tag} className="tag-item">
          {tag}
        </div>
      ))}
    </div>
  );
};
