import {
  addCameraProfile,
  deleteCamera,
  deleteCameraProfile,
  editCameraCredentials,
  editCameraName,
  editCameraProfile,
  getCameraImage,
  getCameraProfileLimits,
  getCameraProfileResolutions,
  getCameraProfiles,
  getCameras,
  resetFactorySettings,
  restartCamera,
  startCameraStream,
  stopCameraStream,
} from '../../api/wisenetCameras';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_CAMERAS_MANAGEMENT_VIEW_VALUE = 'CHANGE_CAMERAS_MANAGEMENT_VIEW_VALUE';
export const CHANGE_LAYOUT_CAMERA_VALUE = 'CHANGE_LAYOUT_CAMERA_VALUE';
export const CHANGE_CAMERAS_MANAGEMENT_SETTINGS_VALUE = 'CHANGE_CAMERAS_MANAGEMENT_SETTINGS_VALUE';
export const CHANGE_CAMERAS_MANAGEMENT_SETTINGS_SEVERAL_VALUES =
  'CHANGE_CAMERAS_MANAGEMENT_SETTINGS_SEVERAL_VALUES';
export const RESET_CAMERAS_MANAGEMENT_SETTINGS_INFORMATION = 'RESET_CAMERAS_MANAGEMENT_SETTINGS_INFORMATION';
export const SET_MANAGEMENT_ACTIVE_STREAM_ID = 'SET_MANAGEMENT_ACTIVE_STREAM_ID';
export const SET_MANAGEMENT_RESTART_STREAM = 'SET_MANAGEMENT_RESTART_STREAM';
export const SET_MANAGEMENT_STREAMS_LIST = 'SET_MANAGEMENT_STREAMS_LIST';

export const START_CAMERA_STREAM = 'START_CAMERA_STREAM';
export const START_CAMERA_STREAM_ERROR = 'START_CAMERA_STREAM_ERROR';
export const START_CAMERA_STREAM_RESULT = 'START_CAMERA_STREAM_RESULT';

export const ADD_CAMERA_TO_LAYOUT = 'ADD_CAMERA_TO_LAYOUT';
export const DELETE_CAMERA_FROM_LAYOUT = 'DELETE_CAMERA_FROM_LAYOUT';
export const SWAP_LAYOUT_CAMERAS = 'SWAP_LAYOUT_CAMERAS';
export const RESET_CAMERAS_MANAGEMENT_SETTINGS_STORE = 'RESET_CAMERAS_MANAGEMENT_SETTINGS_STORE';
export const RESET_CAMERAS_MANAGEMENT_VIEW_STORE = 'RESET_CAMERAS_MANAGEMENT_VIEW_STORE';
export const RESET_CAMERAS_MANAGEMENT_STORE = 'RESET_CAMERAS_MANAGEMENT_STORE';

export const GET_CAMERA_RESOLUTIONS_SUCCESS = 'GET_CAMERA_RESOLUTIONS_SUCCESS';
export const GET_CAMERA_RESOLUTIONS_ERROR = 'GET_CAMERA_RESOLUTIONS_ERROR';

export const GET_CAMERA_PROFILES = 'GET_CAMERA_PROFILES';
export const GET_CAMERA_PROFILES_SUCCESS = 'GET_CAMERA_PROFILES_SUCCESS';
export const GET_CAMERA_PROFILES_ERROR = 'GET_CAMERA_PROFILES_ERROR';

export const GET_CAMERA_IMAGE = 'GET_CAMERA_IMAGE';
export const GET_CAMERA_IMAGE_SUCCESS = 'GET_CAMERA_IMAGE_SUCCESS';
export const GET_CAMERA_IMAGE_ERROR = 'GET_CAMERA_IMAGE_ERROR';

export const CREATE_CAMERA_PROFILE = 'CREATE_CAMERA_PROFILE';
export const CREATE_CAMERA_PROFILE_SUCCESS = 'CREATE_CAMERA_PROFILE_SUCCESS';
export const CREATE_CAMERA_PROFILE_ERROR = 'CREATE_CAMERA_PROFILE_ERROR';

export const EDIT_CAMERA_PROFILE = 'EDIT_CAMERA_PROFILE';
export const EDIT_CAMERA_PROFILE_SUCCESS = 'EDIT_CAMERA_PROFILE_SUCCESS';
export const EDIT_CAMERA_PROFILE_ERROR = 'EDIT_CAMERA_PROFILE_ERROR';

export const EDIT_CAMERA_NAME = 'EDIT_CAMERA_NAME';
export const EDIT_CAMERA_NAME_SUCCESS = 'EDIT_CAMERA_NAME_SUCCESS';
export const EDIT_CAMERA_NAME_ERROR = 'EDIT_CAMERA_NAME_ERROR';

export const DELETE_CAMERA_PROFILE = 'DELETE_CAMERA_PROFILE';
export const DELETE_CAMERA_PROFILE_SUCCESS = 'DELETE_CAMERA_PROFILE_SUCCESS';
export const DELETE_CAMERA_PROFILE_ERROR = 'DELETE_CAMERA_PROFILE_ERROR';

export const GET_PROFILE_LIMITS_SUCCESS = 'GET_PROFILE_LIMITS_SUCCESS';
export const GET_PROFILE_LIMITS_ERROR = 'GET_PROFILE_LIMITS_ERROR';

export const getWisenetCamerasAction = (function () {
  let prevController;

  return (restaurantUuid, params, actions = {}) => {
    return async () => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;

      try {
        const response = await getCameras(restaurantUuid, params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          actions.onSuccess(results);
        }
      } catch (error) {
        if (!abortController.signal.aborted && actions.onError) {
          actions.onError(error);
        }
      }
    };
  };
})();

export const getWisenetCameraImage = (restaurantUuid, cameraId, params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_CAMERA_IMAGE });
    try {
      const response = await getCameraImage(restaurantUuid, cameraId, params);
      const { success, results } = response;
      if (success) {
        let image = URL.createObjectURL(results);
        dispatch({ type: GET_CAMERA_IMAGE_SUCCESS, payload: { uuid: cameraId, image: image } });
        setData(image, cameraId);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_CAMERA_IMAGE_ERROR, payload: { uuid: cameraId, image: null } });
      setData(null, cameraId);
    }
  };
};

export const getCameraProfilesAction = (restaurantUuid, uuid, params) => {
  return async (dispatch) => {
    dispatch({ type: GET_CAMERA_PROFILES });
    try {
      const response = await getCameraProfiles(restaurantUuid, uuid, params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_CAMERA_PROFILES_SUCCESS, payload: { profiles: results, uuid: uuid } });
      }
    } catch (error) {
      dispatch({ type: GET_CAMERA_PROFILES_ERROR });
      console.log(error);
    }
  };
};

export const getCameraProfilesWithActions = (restaurantUuid, uuid, params, actions = {}) => {
  return async () => {
    try {
      const response = await getCameraProfiles(restaurantUuid, uuid, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const getCameraProfileResolutionsAction = (restaurantUuid, uuid) => {
  return async (dispatch) => {
    try {
      const response = await getCameraProfileResolutions(restaurantUuid, uuid);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_CAMERA_RESOLUTIONS_SUCCESS, payload: { uuid: uuid, resolutions: results } });
      }
    } catch (error) {
      dispatch({ type: GET_CAMERA_RESOLUTIONS_ERROR, payload: { uuid: uuid } });
      console.log(error);
    }
  };
};

export const getCameraProfileLimitsAction = (restaurantUuid, uuid) => {
  return async (dispatch) => {
    try {
      const response = await getCameraProfileLimits(restaurantUuid, uuid);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_PROFILE_LIMITS_SUCCESS, payload: results });
      }
    } catch (error) {
      dispatch({ type: GET_PROFILE_LIMITS_ERROR });
      console.log(error);
    }
  };
};

export const createProfileAction = (restaurantUuid, cameraUuid, profile, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_CAMERA_PROFILE });
    try {
      const response = await addCameraProfile(restaurantUuid, cameraUuid, profile, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: CREATE_CAMERA_PROFILE_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: CREATE_CAMERA_PROFILE_ERROR });
    }
  };
};

export const editProfileAction = (restaurantUuid, cameraUuid, profileName, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_CAMERA_PROFILE });
    try {
      const response = await editCameraProfile(restaurantUuid, cameraUuid, profileName, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: EDIT_CAMERA_PROFILE_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: EDIT_CAMERA_PROFILE_ERROR });
    }
  };
};

export const editCameraNameAction = (restaurantUuid, cameraUuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: EDIT_CAMERA_NAME });
    try {
      const response = await editCameraName(restaurantUuid, cameraUuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: EDIT_CAMERA_NAME_SUCCESS, payload: { uuid: cameraUuid, name: params.name } });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: EDIT_CAMERA_NAME_ERROR });
    }
  };
};

export const deleteCameraAction = (restaurantUuid, cameraUuid, actions = {}) => {
  return async () => {
    try {
      const response = await deleteCamera(restaurantUuid, cameraUuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
      }
    } catch (e) {
      actions.onError(e.message);
    }
  };
};

export const deleteProfileAction = (restaurantUuid, cameraUuid, profile, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CAMERA_PROFILE });
    try {
      const response = await deleteCameraProfile(restaurantUuid, cameraUuid, profile);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_CAMERA_PROFILE_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: DELETE_CAMERA_PROFILE_ERROR });
    }
  };
};

export const restartCameraAction = (restaurantUuid, cameraId, params, actions = {}) => {
  return async () => {
    try {
      const response = await restartCamera(restaurantUuid, cameraId, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
    }
  };
};

export const resetFactorySettingsAction = (restaurantUuid, cameraId, params, actions = {}) => {
  return async () => {
    try {
      const response = await resetFactorySettings(restaurantUuid, cameraId, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
    }
  };
};

export const startWisenetCameraStream = (restaurantUuid, cameraId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: START_CAMERA_STREAM, payload: { cameraId } });
      const response = await startCameraStream(restaurantUuid, cameraId);
      const { success, results } = response;
      if (success) {
        results.id &&
          dispatch({
            type: START_CAMERA_STREAM_RESULT,
            payload: { cameraId, streamId: results.id, streamPin: results.pin },
          });
      }
    } catch (error) {
      dispatch({ type: START_CAMERA_STREAM_ERROR, payload: { cameraId } });
      console.log(error);
    }
  };
};

export const stopWisenetCameraStream = (restaurantUuid, cameraId, params, setData) => {
  return async () => {
    try {
      const response = await stopCameraStream(restaurantUuid, cameraId, params);
      const { success, results } = response;
      if (success) {
        setData(results);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const editCameraCredentialsAction = (restaurantUuid, cameraId, params, actions = {}) => {
  return async () => {
    try {
      const response = await editCameraCredentials(restaurantUuid, cameraId, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
    }
  };
};

export const setStreamsList = (list) => ({ type: SET_MANAGEMENT_STREAMS_LIST, payload: list });

export const changeViewValue = (key, value) => ({
  type: CHANGE_CAMERAS_MANAGEMENT_VIEW_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeCameraValue = (key, name, value) => ({
  type: CHANGE_LAYOUT_CAMERA_VALUE,
  payload: {
    key,
    name,
    value,
  },
});

export const changeSettingsValue = (key, value) => ({
  type: CHANGE_CAMERAS_MANAGEMENT_SETTINGS_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetCameraInformation = (value = false) => ({
  type: RESET_CAMERAS_MANAGEMENT_SETTINGS_INFORMATION,
  payload: value,
});

export const setActiveStreamId = (id) => ({ type: SET_MANAGEMENT_ACTIVE_STREAM_ID, payload: id });

export const setRestartStream = (needRestart) => ({
  type: SET_MANAGEMENT_RESTART_STREAM,
  payload: needRestart,
});

export const addCameraToLayout = (camera) => ({
  type: ADD_CAMERA_TO_LAYOUT,
  payload: { camera },
});

export const deleteCameraFromLayout = (key) => ({
  type: DELETE_CAMERA_FROM_LAYOUT,
  payload: { key },
});

export const swapLayoutCameras = (index1, index2) => ({
  type: SWAP_LAYOUT_CAMERAS,
  payload: { index1, index2 },
});

export const resetSettingsStore = () => ({
  type: RESET_CAMERAS_MANAGEMENT_SETTINGS_STORE,
});

export const resetViewStore = () => ({
  type: RESET_CAMERAS_MANAGEMENT_VIEW_STORE,
});

export const resetStore = () => ({
  type: RESET_CAMERAS_MANAGEMENT_STORE,
});

export const changeSeveralSettingsValues = (value) => ({
  type: CHANGE_CAMERAS_MANAGEMENT_SETTINGS_SEVERAL_VALUES,
  payload: value,
});
