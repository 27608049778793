import React from 'react';
import cx from 'classnames';

import './MainVideoButton.scss';

export const MainVideoButton = ({ className, Icon, content, active, disabled, tooltip, onClick }) => {
  return (
    <button
      className={cx('main-video-button', className, { active })}
      disabled={disabled}
      data-tooltip-id={tooltip?.id ? tooltip.id : null}
      data-tooltip-content={tooltip?.title ? tooltip.title : null}
      data-tooltip-place={tooltip?.place ? tooltip.place : 'top'}
      onClick={onClick}
    >
      {Icon && <Icon />}
      {content}
    </button>
  );
};
