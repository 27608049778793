import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { LargeIconButton } from '../Buttons';

import './Dropdown.scss';

export const PageDropdown = ({
  icon,
  loading,
  options,
  closeByClick = true,
  disabled,
  topAppear = false,
}) => {
  const [opened, setOpened] = useState(false);

  const toggleRef = useRef(null);

  useEffect(() => {
    if (opened) {
      document.addEventListener('click', checkClick);
    }
  }, [opened]);

  function checkClick(event) {
    if (!(event.path || (event.composedPath && event.composedPath())).includes(toggleRef.current)) {
      setOpened(false);
      return document.removeEventListener('click', checkClick);
    }
  }

  return (
    <div ref={toggleRef} className={cx('custom-dropdown__container', { opened: opened, disabled })}>
      <div className="custom-dropdown__toggle">
        <LargeIconButton
          Icon={icon}
          isLoading={loading}
          onClick={() => {
            !disabled && setOpened((prev) => !prev);
          }}
        />
      </div>
      {opened && (
        <ul className={cx('custom-dropdown__menu-options', { top: topAppear })}>
          {options.map((option, i) => (
            <li
              key={i}
              className={cx('custom-dropdown__item', { disabled: option.disabled })}
              style={{ color: option.color || '#1A1A1A' }}
              onClick={(e) => {
                option.function(e);
                closeByClick && setOpened(false);
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
