import request from '../utils/request';

export function getMonitoringAlerts(params, signal) {
  return request({
    url: '/alerts/list',
    method: 'get',
    data: params,
    signal,
  });
}

export function getMonitoringAnalytics(params, signal) {
  return request({
    url: '/alerts/analytic',
    method: 'get',
    data: params,
    signal,
  });
}

export function getMonitoringAlertsCount(params) {
  return request({
    url: '/alerts/count',
    method: 'get',
    data: params,
  });
}

export function deleteAlert(uuid) {
  return request({
    url: `/alerts/${uuid}`,
    method: 'delete',
    data: uuid,
  });
}
