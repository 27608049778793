import * as types from '../actions/reportsAction';

const initialState = {
  restaurantUuid: 'select',
  orderDirection: 'DESC',
  sortBy: 'requestTime',
  startDate: null,
  endDate: null,
  timeInterval: null,
  intervalEnd: null,
  limit: '20',
  page: 0,
  totalResults: 0,
  loading: false,
  loadingReports: [],
  data: [],
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_REPORTS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_REPORTS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_REPORTS:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case types.GET_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
        data: action.payload.content,
      };

    case types.GET_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.DOWNLOAD_REPORT:
      return {
        ...state,
        loadingReports: state.loadingReports.some((report) => report.uuid === action.payload.uuid)
          ? state.loadingReports
          : [
              ...state.loadingReports,
              { uuid: action.payload.uuid, progress: 0, controller: action.payload.controller },
            ],
      };

    case types.DOWNLOAD_REPORT_PROGRESS:
      return {
        ...state,
        loadingReports: state.loadingReports.map((report) =>
          report.uuid === action.payload.uuid ? { ...report, progress: action.payload.progress } : report
        ),
      };

    case types.DOWNLOAD_REPORT_SUCCESS:
    case types.DOWNLOAD_REPORT_ERROR:
      return {
        ...state,
        loadingReports: state.loadingReports.filter((report) => report.uuid !== action.payload.uuid),
      };

    case types.RESET_REPORTS_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reportsReducer;
