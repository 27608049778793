import {
  getTablets,
  deleteTablet,
  getTabletsModels,
  getTabletsBuilds,
  changeTabletName,
  getTabletsStreamNames,
  getTabletsAvailability,
  changeTabletRemoteAccess,
  changeTabletState,
} from '../../api/tablets';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_TABLETS_VALUE = 'CHANGE_TABLETS_VALUE';
export const CHANGE_TABLETS_SEVERAL_VALUES = 'CHANGE_TABLETS_SEVERAL_VALUES';
export const CHANGE_TABLETS_COMMAND_VALUE = 'CHANGE_TABLETS_COMMAND_VALUE';
export const RESET_TABLETS_STORE = 'RESET_TABLETS_STORE';

export const GET_TABLETS = 'GET_TABLETS';
export const GET_TABLETS_SUCCESS = 'GET_TABLETS_SUCCESS';
export const GET_TABLETS_ERROR = 'GET_TABLETS_ERROR';

export const CHANGE_TABLET_NAME = 'CHANGE_TABLET_NAME';
export const CHANGE_TABLET_NAME_SUCCESS = 'CHANGE_TABLET_NAME_SUCCESS';
export const CHANGE_TABLET_NAME_ERROR = 'CHANGE_TABLET_NAME_ERROR';

export const GET_TABLETS_MODELS = 'GET_TABLETS_MODELS';
export const GET_TABLETS_MODELS_SUCCESS = 'GET_TABLETS_MODELS_SUCCESS';
export const GET_TABLETS_MODELS_ERROR = 'GET_TABLETS_MODELS_ERROR';

export const GET_TABLETS_STREAM_NAMES = 'GET_TABLETS_STREAM_NAMES';
export const GET_TABLETS_STREAM_NAMES_SUCCESS = 'GET_TABLETS_STREAM_NAMES_SUCCESS';
export const GET_TABLETS_STREAM_NAMES_ERROR = 'GET_TABLETS_STREAM_NAMES_ERROR';

export const GET_TABLETS_BUILDS = 'GET_TABLETS_BUILDS';
export const GET_TABLETS_BUILDS_SUCCESS = 'GET_TABLETS_BUILDS_SUCCESS';
export const GET_TABLETS_BUILDS_ERROR = 'GET_TABLETS_BUILDS_ERROR';

export const CHANGE_TABLET = 'CHANGE_TABLET';
export const CHANGE_TABLET_SUCCESS = 'CHANGE_TABLET_SUCCESS';
export const CHANGE_TABLET_ERROR = 'CHANGE_TABLET_ERROR';

export const CHANGE_TABLET_REMOTE_ACCESS = 'CHANGE_TABLET_REMOTE_ACCESS';
export const CHANGE_TABLET_REMOTE_ACCESS_SUCCESS = 'CHANGE_TABLET_REMOTE_ACCESS_SUCCESS';
export const CHANGE_TABLET_REMOTE_ACCESS_ERROR = 'CHANGE_TABLET_REMOTE_ACCESS_ERROR';

export const GET_TABLETS_AVAILABILITY = 'GET_TABLETS_AVAILABILITY';
export const GET_TABLETS_AVAILABILITY_SUCCESS = 'GET_TABLETS_AVAILABILITY_SUCCESS';
export const GET_TABLETS_AVAILABILITY_ERROR = 'GET_TABLETS_AVAILABILITY_ERROR';

export const getTabletsAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_TABLETS });
      try {
        const response = await getTablets(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_TABLETS_SUCCESS, payload: results, params });
          actions.onSuccess && actions.onSuccess(results);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_TABLETS_ERROR, params });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const getTabletsModelsAction = (setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_TABLETS_MODELS });
    try {
      const response = await getTabletsModels();
      const { success, results } = response;
      if (success) {
        setData(results);
        dispatch({ type: GET_TABLETS_MODELS_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TABLETS_MODELS_ERROR });
    }
  };
};

export const getTabletsStreamNamesAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_TABLETS_STREAM_NAMES });
    try {
      const response = await getTabletsStreamNames(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_TABLETS_STREAM_NAMES_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TABLETS_STREAM_NAMES_ERROR });
    }
  };
};

export const sendCommandAction = (uuid, params, actions = null) => {
  return async () => {
    try {
      const response = await changeTabletState(uuid, params);
      const { success, results } = response;
      if (success) {
        actions?.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      actions?.onError(error.message);
    }
  };
};

export const changeTabletNameAction = (uuid, params, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_TABLET_NAME });
    try {
      const response = await changeTabletName(uuid, params);
      const { success, results } = response;
      if (success) {
        actions?.onSuccess(results);
        dispatch({ type: CHANGE_TABLET_NAME_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions?.onError(error.message);
      dispatch({ type: CHANGE_TABLET_NAME_ERROR });
    }
  };
};

export const getTabletsBuildsAction = (setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_TABLETS_BUILDS });
    try {
      const response = await getTabletsBuilds();
      const { success, results } = response;
      if (success) {
        setData(results);
        dispatch({ type: GET_TABLETS_BUILDS_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TABLETS_BUILDS_ERROR });
    }
  };
};

export const deleteTabletAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_TABLET });
    try {
      const response = await deleteTablet(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: CHANGE_TABLET_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: CHANGE_TABLET_ERROR });
    }
  };
};

export const getTabletsAvailabilityAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_TABLETS_AVAILABILITY });
      try {
        const response = await getTabletsAvailability(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_TABLETS_AVAILABILITY_SUCCESS, payload: results, params });
          actions.onSuccess && actions.onSuccess(results);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_TABLETS_AVAILABILITY_ERROR });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const changeTabletRemoteAccessAction = (uuid, params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_TABLET_REMOTE_ACCESS });
    try {
      const response = await changeTabletRemoteAccess(uuid, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
        dispatch({
          type: CHANGE_TABLET_REMOTE_ACCESS_SUCCESS,
          payload: { uuid, remoteAccess: params.remoteAccess },
        });
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
      dispatch({ type: CHANGE_TABLET_REMOTE_ACCESS_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_TABLETS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_TABLETS_SEVERAL_VALUES,
  payload: value,
});

export const changeCommandValue = (key, value) => ({
  type: CHANGE_TABLETS_COMMAND_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_TABLETS_STORE,
});
