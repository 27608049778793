import { getContainers, createContainer, deleteContainer, updateContainer } from '../../api/containers';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_CHICKEN_CONTAINERS_VALUE = 'CHANGE_CHICKEN_CONTAINERS_VALUE';
export const CHANGE_MODAL_CONTAINERS_VALUE = 'CHANGE_MODAL_CONTAINERS_VALUE';
export const CHANGE_CHICKEN_CONTAINERS_SEVERAL_VALUES = 'CHANGE_CHICKEN_CONTAINERS_SEVERAL_VALUES';
export const RESET_CHICKEN_CONTAINERS_STORE = 'RESET_CHICKEN_CONTAINERS_STORE';

export const GET_CHICKEN_CONTAINERS = 'GET_CHICKEN_CONTAINERS';
export const GET_CHICKEN_CONTAINERS_SUCCESS = 'GET_CHICKEN_CONTAINERS_SUCCESS';
export const GET_CHICKEN_CONTAINERS_ERROR = 'GET_CHICKEN_CONTAINERS_ERROR';

export const NEW_CHICKEN_CONTAINER = 'NEW_CHICKEN_CONTAINER';
export const NEW_CHICKEN_CONTAINER_SUCCESS = 'NEW_CHICKEN_CONTAINER_SUCCESS';
export const NEW_CHICKEN_CONTAINER_ERROR = 'NEW_CHICKEN_CONTAINER_ERROR';

export const UPDATE_CHICKEN_CONTAINER = 'UPDATE_CHICKEN_CONTAINER';
export const UPDATE_CHICKEN_CONTAINER_SUCCESS = 'UPDATE_CHICKEN_CONTAINER_SUCCESS';
export const UPDATE_CHICKEN_CONTAINER_ERROR = 'UPDATE_CHICKEN_CONTAINER_ERROR';

export const DELETE_CHICKEN_CONTAINER = 'DELETE_CHICKEN_CONTAINER';
export const DELETE_CHICKEN_CONTAINER_SUCCESS = 'DELETE_CHICKEN_CONTAINER_SUCCESS';
export const DELETE_CHICKEN_CONTAINER_ERROR = 'DELETE_CHICKEN_CONTAINER_ERROR';

export const getContainersAction = (function () {
  let prevController;

  return (params, actions) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_CHICKEN_CONTAINERS });
      try {
        const response = await getContainers(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          actions.onSuccess([...results.content]);
          dispatch({ type: GET_CHICKEN_CONTAINERS_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_CHICKEN_CONTAINERS_ERROR });
        }
      }
    };
  };
})();

export const createContainerAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: NEW_CHICKEN_CONTAINER });
    try {
      const response = await createContainer(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: NEW_CHICKEN_CONTAINER_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: NEW_CHICKEN_CONTAINER_ERROR });
    }
  };
};

export const updateContainerAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CHICKEN_CONTAINER });
    try {
      const response = await updateContainer(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: UPDATE_CHICKEN_CONTAINER_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: UPDATE_CHICKEN_CONTAINER_ERROR });
    }
  };
};

export const deleteContainerAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CHICKEN_CONTAINER });
    try {
      const response = await deleteContainer(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_CHICKEN_CONTAINER_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_CHICKEN_CONTAINER_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CHICKEN_CONTAINERS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_CHICKEN_CONTAINERS_SEVERAL_VALUES,
  payload: value,
});

export const changeModalValue = (key, value) => ({
  type: CHANGE_MODAL_CONTAINERS_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_CHICKEN_CONTAINERS_STORE,
});
