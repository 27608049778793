import React, { useRef } from 'react';
import cx from 'classnames';

export const MainSidebarItem = ({
  Icon,
  label,
  tooltip,
  active,
  disabled,
  subitemsList,
  timerRef,
  setSubitemsList,
  onClick,
}) => {
  const liRef = useRef(null);

  const handleMouseOver = () => {
    clearTimeout(timerRef.current);
    if (!disabled && tooltip && tooltip.length > 1 && (!subitemsList || subitemsList.span !== label)) {
      const pageHeaderHeight = document.getElementById('app-header').clientHeight;
      const liBoundingRect = liRef.current?.getBoundingClientRect();
      liBoundingRect &&
        setSubitemsList({
          span: label,
          top: liBoundingRect.top - pageHeaderHeight,
          tooltip: tooltip,
        });
    } else if (disabled || !tooltip || tooltip.length <= 1) {
      setSubitemsList(null);
    }
  };

  const handleMouseLeave = () => {
    if (subitemsList) {
      timerRef.current = setTimeout(() => {
        setSubitemsList(null);
      }, 500);
    }
  };

  return (
    <li
      ref={liRef}
      className={cx('main-sidebar__item', { active, disabled })}
      onClick={() => !disabled && onClick()}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <Icon />
      <span className="main-sidebar__item-label">{label}</span>
    </li>
  );
};
