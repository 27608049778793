import request from '../utils/request';

export function getChickenMaterials(params, signal) {
  return request({
    url: '/dictionaries/materials',
    method: 'get',
    data: params,
    signal,
  });
}

export function getChickenMaterial(id) {
  return request({
    url: `/dictionaries/materials/${id}`,
    method: 'get',
  });
}

export function createChickenMaterial(params) {
  return request({
    url: '/dictionaries/materials',
    method: 'post',
    data: params,
  });
}

export function updateChickenMaterial(uuid, params) {
  return request({
    url: `/dictionaries/materials/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteChickenMaterial(uuid) {
  return request({
    url: `/dictionaries/materials/${uuid}`,
    method: 'delete',
    data: uuid,
  });
}

export function getPotatoMaterials(params, signal) {
  return request({
    url: '/dictionaries/potato-materials',
    method: 'get',
    data: params,
    signal,
  });
}

export function getPotatoMaterial(id) {
  return request({
    url: `/dictionaries/potato-materials/${id}`,
    method: 'get',
  });
}

export function createPotatoMaterial(params) {
  return request({
    url: '/dictionaries/potato-materials',
    method: 'post',
    data: params,
  });
}

export function updatePotatoMaterial(uuid, params) {
  return request({
    url: `/dictionaries/potato-materials/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deletePotatoMaterial(uuid) {
  return request({
    url: `/dictionaries/potato-materials/${uuid}`,
    method: 'delete',
    data: uuid,
  });
}

export function getHoldingZones(params) {
  return request({
    url: 'dictionaries/holdingZones',
    method: 'get',
    data: params,
  });
}

export function getMeasurementUnit(params) {
  return request({
    url: 'dictionaries/measurementUnits',
    method: 'get',
    data: params,
  });
}

export function getChickenFilterMaterials() {
  return request({
    url: '/dictionaries/materials/all',
    method: 'get',
  });
}

export function getPotatoFilterPackages() {
  return request({
    url: '/dictionaries/packages',
    method: 'get',
  });
}

export function getPotatoFilterMaterials() {
  return request({
    url: '/dictionaries/potato-materials/all',
    method: 'get',
  });
}

export function getStockOutFilterMaterials() {
  return request({
    url: '/materials/filters/stock-out-materials',
    method: 'get',
  });
}
