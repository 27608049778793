import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './PageLoader.scss';

export const PageLoader = () => (
  <div className="page-loader">
    <FontAwesomeIcon icon={faSpinner} spin />
  </div>
);
