import { getTimezoneOffsetInSeconds } from '../../utils';
import * as types from '../actions/tabletLogsAction';

const initialState = {
  data: [],
  timezone: null,
  limit: '20',
  loading: true,
  scrollLoading: false,
  isFileLoading: false,
  cursorDirection: 'FIRST',
  cursor: null,
  existNext: false,
  existPrevious: false,
  changePage: false,
  sortBy: 'createdDate',
  orderDirection: 'ASC',
  startDate: null,
  endDate: null,
  timeInterval: null,
  intervalEnd: null,
};

const tabletLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_TABLET_LOGS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_TABLET_LOGS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_TABLET_LOGS:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_TABLET_LOGS_SUCCESS:
      return {
        ...state,
        ...((((state.startDate === 'select' && !action.params.startTime) ||
          new Date(state.startDate - getTimezoneOffsetInSeconds(state.timezone)).toISOString() ===
            action.params.startTime) &&
          ((state.endDate === 'select' && !action.params.endTime) ||
            new Date(state.endDate - getTimezoneOffsetInSeconds(state.timezone)).toISOString() ===
              action.params.endTime)) ||
        (state.timeInterval !== null &&
          Number(state.timeInterval) ===
            Math.floor(
              (new Date(action.params.endTime).getTime() - new Date(action.params.startTime).getTime()) / 1000
            ))
          ? {
              loading: false,
              scrollLoading: false,
              cursor: action.payload.cursor,
              cursorDirection: action.payload.cursorDirection,
              existNext: action.payload.existsNextPage,
              existPrevious: action.payload.existsPreviousPage,
              data:
                action.params.cursorDirection === 'NEXT'
                  ? [...state.data, ...action.payload.content]
                  : action.payload.content,
            }
          : {}),
      };

    case types.GET_TABLET_LOGS_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
      };
    case types.RESET_TABLET_LOGS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default tabletLogsReducer;
