import request from '../utils/request';

export function getBreadingEvents(params, signal) {
  return request({
    url: '/breadingTimerCycleHistory',
    method: 'get',
    data: params,
    signal,
  });
}

export function getBreadingTotal(params, signal) {
  return request({
    url: '/breadingTimerCycleHistory/total',
    method: 'get',
    data: params,
    signal,
  });
}

export function getExelExport(params) {
  return request({
    url: '/breadingTimerCycleHistory/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}

export function deleteBreading(uuid) {
  return request({
    url: `/breadingTimerCycleHistory/${uuid}`,
    method: 'delete',
  });
}
