import React from 'react';

import { ReactComponent as ChevronRight } from '../../assets/img/icons/common/ChevroneRight.svg';

import './BreadCrumbs.scss';

export const BreadCrumbs = ({ previousPage, onLinkClick, currentPage }) => {
  return (
    <div className="breadcrumbs__container">
      <div className="previous" onClick={() => onLinkClick()}>
        {previousPage}
      </div>
      <ChevronRight />
      {currentPage}
    </div>
  );
};
