import * as ActionTypes from '../actions/kitchenPlayerAction';

const initialState = {
  restaurantUuid: 'select',
  recordsRestaurantUuid: 'select',
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  streamsList: [{ label: 'Select Stream', value: 'select' }],
  activeStreamId: 'select',
  problems: [],
  problem: '',
  problemMessage: '',
  another: '',
  attachmentFiles: [],
  attachments: [],
  playingRecording: {
    url: null,
    loading: false,
    error: false,
  },
  live: true,
  mute: localStorage.getItem('playerMuted') !== null ? !!localStorage.getItem('playerMuted') : false,
  fullscreen: false,
  recording: false,
  restart: false,
  isLoading: false,
  showInfo: false,
};

const kitchenPlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_STREAMS_LIST:
      return {
        ...state,
        streamsList: [
          { label: 'Select Stream', value: 'select' },
          ...action.payload
            .map((item) => ({ label: item.description, value: item.id }))
            .sort((a, b) => a.label.localeCompare(b.label)),
        ],
      };

    case ActionTypes.CHANGE_PLAYER_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case ActionTypes.SET_ACTIVE_STREAM_ID:
      return {
        ...state,
        activeStreamId: action.payload,
      };

    case ActionTypes.SEND_SUPPORT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.SEND_SUPPORT_RESULT:
    case ActionTypes.SEND_SUPPORT_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case ActionTypes.CHANGE_PLAYER_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case ActionTypes.SET_RECORDING_STREAM:
      return {
        ...state,
        recording: action.payload,
      };

    case ActionTypes.GET_STREAM_RECORDING:
      return {
        ...state,
        playingRecording: {
          url: null,
          loading: true,
          error: false,
        },
      };

    case ActionTypes.GET_STREAM_RECORDING_RESULT:
      return {
        ...state,
        playingRecording: { ...action.payload, loading: false, error: false },
      };

    case ActionTypes.GET_STREAM_RECORDING_ERROR:
      return {
        ...state,
        playingRecording: { url: null, loading: false, error: true },
      };

    case ActionTypes.RESET_STREAM_RECORDING:
      return {
        ...state,
        playingRecording: initialState.playingRecording,
        live: true,
      };

    case ActionTypes.GET_SUPPORT:
      return {
        ...state,
        isLoading: true,
      };

    case ActionTypes.GET_SUPPORT_RESULT:
      return {
        ...state,
        problems: action.payload,
        isLoading: false,
      };

    case ActionTypes.GET_SUPPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: action.payload,
      };

    case ActionTypes.ADD_SUPPORT_ATTACHMENT:
      return {
        ...state,
        attachments: [action.payload.file, ...state.attachments],
      };

    case ActionTypes.EDIT_SUPPORT_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.map((attachment) => {
          if (attachment.id === action.payload.id) {
            return {
              ...attachment,
              [action.payload.key]: action.payload.value,
            };
          } else {
            return attachment;
          }
        }),
      };

    case ActionTypes.DELETE_SUPPORT_ATTACHMENT:
      return {
        ...state,
        attachments: state.attachments.filter((attachment) => attachment.id !== action.payload.id),
      };

    case ActionTypes.RESET_KITCHEN_PLAYER_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default kitchenPlayerReducer;
