import * as types from '../actions/potatoPackagesAction';

const initialState = {
  limit: '20',
  page: 0,
  totalResults: 0,
  loading: false,
};

const potatoPackagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_POTATO_PACKAGES:
      return {
        ...state,
        loading: true,
      };

    case types.GET_POTATO_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.GET_POTATO_PACKAGES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_POTATO_PACKAGE:
      return {
        ...state,
        loading: false,
      };

    case types.RESET_POTATO_PACKAGES_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default potatoPackagesReducer;
