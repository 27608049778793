import cx from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as Warning } from '../../assets/img/icons/common/WarningAmber.svg';
import { ReactComponent as ContentCut } from '../../assets/img/icons/form/ContentCut.svg';

import { TimeControl } from '../TimeControls/TimeControl';

import './InputTime.scss';
const DROPDOWN_PANEL_WIDTH = 210;

export const InputTime = ({
  title,
  className,
  value,
  onChange,
  views = [{ label: 'Hours', maxValue: 23 }, { label: 'Minutes' }, { label: 'Seconds' }],
  error,
  errorMessage,
  Icon = ContentCut,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [openMode, setOpenMode] = useState('left');

  const fieldRef = useRef(null);
  const dropdownPanelRef = useRef(null);

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('resize', checkOpenMode);
    checkOpenMode();

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', checkOpenMode);
    };
  }, []);

  useEffect(() => {
    isOpen && checkOpenMode();
  }, [isOpen]);

  const handleMouseDown = (event) => {
    if (fieldRef.current && !fieldRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (dropdownPanelRef.current && (event.which === 13 || event.keyCode === 13 || event.charCode === 13)) {
      setOpen(false);
    }
  };

  const checkOpenMode = () => {
    if (!fieldRef.current) setOpenMode('left');
    const fieldWidth = fieldRef.current.clientWidth;

    fieldWidth < DROPDOWN_PANEL_WIDTH ? setOpenMode('right') : setOpenMode('left');
  };

  const handleChangeTime = (value) => {
    onChange(value);
  };

  const handleInputTime = (event) => {
    const inputTime = event.target.value;
    const timeParts = inputTime.split(':');
    if (views.every((part, index) => Number(timeParts[index]) <= (part.maxValue ? part.maxValue : 59))) {
      onChange(inputTime);
    } else {
      onChange(value);
    }
  };

  const timeValue = useMemo(() => {
    if (!value) return views.map(() => '00').join(':');
    return value;
  }, [value]);

  return (
    <div
      ref={fieldRef}
      className={cx('time-input__container', className, {
        active: isOpen,
        error: error || errorMessage,
        'not-empty': value,
      })}
      onClick={() => setOpen(true)}
    >
      <div className="time-input__input">
        <span className="time-input__field-label">{title}</span>
        <InputMask
          name="startTime"
          mask={views.map(() => '99').join(':')}
          maskChar="0"
          placeholder={title}
          value={value}
          onChange={(e) => handleInputTime(e)}
        />

        {(error || errorMessage) && (
          <div
            className="time-input__error"
            data-tooltip-id="time-input-tooltip"
            data-tooltip-content={errorMessage}
          >
            <Warning />
          </div>
        )}

        <div
          className="time-input__icon"
          onClick={(e) => {
            e.stopPropagation();
            setOpen((prev) => !prev);
          }}
        >
          <Icon />
        </div>
      </div>

      {isOpen && (
        <div ref={dropdownPanelRef} className={`time-input__dropdown-panel ${openMode}`}>
          <TimeControl
            className={'time-control'}
            views={views}
            time={timeValue}
            onChange={handleChangeTime}
          />
        </div>
      )}
      {errorMessage && <Tooltip id="time-input-tooltip" variant={'error'} />}
    </div>
  );
};
