import React, { useState, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { TableWithPagination } from '../../TableWithPagination';

import './style.scss';

export const InnerScrollableTable = ({ outerRow, getData, headerOptions, createRows, emptyText }) => {
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [cursor, setCursor] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    loadMoreData();
  }, []);

  const loadMoreData = () => {
    if (hasMore) {
      setLoading(true);

      getData(outerRow, cursor, {
        onSuccess: (results) => {
          setData((prev) => (results.existsPreviousPage ? [...prev, ...results.content] : results.content));
          setHasMore(results.existsNextPage);
          setCursor(results.cursor);
          setLoading(false);
        },
        onError: () => {
          setHasMore(false);
          setCursor(null);
          setLoading(false);
        },
      });
    }
  };

  const rows = useMemo(() => createRows(data), [data]);

  const firstLoading = data.length === 0 && isLoading;

  return (
    <div
      id={`inner-scrollable-table-${outerRow.id}`}
      className="inner-scrollable-table"
      style={{ maxHeight: '250px', overflowY: 'auto' }}
    >
      <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        hasMore={hasMore}
        loader={
          !firstLoading ? (
            <div className="inner-scrollable-table__loader">
              <i className="fa fa-spinner fa-spin fa-2x" />
            </div>
          ) : null
        }
        scrollableTarget={`inner-scrollable-table-${outerRow.id}`}
        style={{ overflow: 'hidden' }}
      >
        <TableWithPagination
          rows={rows}
          headerOptions={headerOptions}
          isLoading={firstLoading}
          emptyText={emptyText}
        />
      </InfiniteScroll>
    </div>
  );
};
