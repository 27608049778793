import { FooterCell } from '../Cells';

export const DesktopTFoot = ({ footerOptions }) => {
  if (!footerOptions) {
    return null;
  }

  return (
    <tfoot className="custom-table__tfoot">
      <tr>
        {footerOptions.map((cell, index) => (
          <FooterCell key={index} cell={cell} />
        ))}
      </tr>
    </tfoot>
  );
};
