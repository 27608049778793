import request from '../utils/request';

export function getPRQ(params, signal) {
  return request({
    url: '/prqTimerCycleHistory',
    method: 'get',
    data: params,
    signal,
  });
}

export function getPRQTotal(params, signal) {
  return request({
    url: '/prqTimerCycleHistory/total',
    method: 'get',
    data: params,
    signal,
  });
}

export function getExelExport(params) {
  return request({
    url: '/prqTimerCycleHistory/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}

export function deletePrq(uuid) {
  return request({
    url: `/prqTimerCycleHistory/${uuid}`,
    method: 'delete',
  });
}
