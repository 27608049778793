import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import cx from 'classnames';
import { ReactComponent as ArrowDropDown } from '../../../assets/img/icons/common/ArrowDropDown.svg';

import './TransparentSelect.scss';

export const TransparentSelect = ({ name, mobile, options, value, onChange, disabled, isOpenUp }) => {
  const [selectOpened, setSelectOpened] = useState(false);

  const toggleRef = useRef(null);

  useEffect(() => {
    return () => document.removeEventListener('click', checkSelectClick);
  }, []);

  useEffect(() => {
    if (selectOpened) {
      document.addEventListener('click', checkSelectClick);
    }
  }, [selectOpened]);

  function checkSelectClick(event) {
    if (!(event.path || (event.composedPath && event.composedPath())).includes(toggleRef.current)) {
      setSelectOpened(false);
      return document.removeEventListener('click', checkSelectClick);
    }
  }

  const handleSelectOption = (newValue) => {
    name ? onChange(name, newValue) : onChange(newValue);
    setSelectOpened(false);
    document.removeEventListener('click', checkSelectClick);
  };

  const dropdownList = (
    <div className={cx('filter__dropdown-menu', { 'open-up': isOpenUp })}>
      <ul className="filter__dropdown-menu__options">
        {options.map((option) => {
          const optionValue = option.value;
          return (
            <li
              key={optionValue}
              className={cx('option', { active: value === optionValue })}
              onClick={() => handleSelectOption(optionValue)}
            >
              <span className="option__label">{option.label}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <div ref={toggleRef} className={cx('transparent-select', { opened: selectOpened, disabled })}>
      <div
        className="filter__toggle"
        onClick={() => {
          !disabled && setSelectOpened((prev) => !prev);
        }}
      >
        <span className="filter__label">
          {(value && value !== 'select' && options.find((option) => option.value === value)?.label) || ''}
        </span>
        <ArrowDropDown />
      </div>

      {selectOpened &&
        (mobile ? createPortal(dropdownList, document.getElementById('tablePagination')) : dropdownList)}
    </div>
  );
};
