import { useState } from 'react';

import cx from 'classnames';

import { ReactComponent as ChevronDown } from '../../../assets/img/icons/common/ChevroneDown.svg';
import { ReactComponent as ChevronUp } from '../../../assets/img/icons/common/ChevroneUp.svg';
import { ReactComponent as ClosedEye } from '../../../assets/img/icons/form/ClosedEye.svg';
import { ReactComponent as OpenedEye } from '../../../assets/img/icons/form/OpenedEye.svg';

import './FormInput.scss';

export const FormInputWithTitle = ({
  className,
  name,
  value = null,
  type = 'text',
  disabled = false,
  validation = false,
  placeholder,
  defaultValue = null,
  hideArrows = false,
  max = null,
  min,
  step,
  labelRows = 1,
  errorMessage,
  onChange,
}) => {
  const [showed, toggleShowed] = useState(false);

  const handleChange = (value) => {
    name ? onChange(name, value) : onChange(value);
  };

  const checkDefaultValue = () => {
    if (!value && defaultValue !== null) {
      handleChange(defaultValue);
    }
  };

  return (
    <div className={cx('form-input__container', { disabled: disabled })}>
      {value !== null && (value === 0 || value.length !== 0) ? (
        <span
          className={cx('form-input__title', { invalid: validation, filled: value && value.length !== 0 })}
        >
          {placeholder}
        </span>
      ) : null}
      <input
        className={cx('form-input', className, {
          invalid: validation,
          password: type === 'password',
          with__title: value !== null && (value === 0 || value.length !== 0),
          extra_label_padding: labelRows === 2,
        })}
        name={name}
        disabled={disabled}
        maxLength={max}
        min={min}
        step={step ? step : null}
        placeholder={placeholder}
        type={!showed ? type : 'text'}
        value={value}
        onBlur={() => {
          defaultValue !== null && checkDefaultValue();
        }}
        onChange={(e) => handleChange(e.target.value)}
      />
      {type === 'password' ? (
        <div className="form-input__eye-icon" onClick={() => toggleShowed((prev) => !prev)}>
          {showed ? <ClosedEye /> : <OpenedEye />}
        </div>
      ) : type === 'number' && !hideArrows ? (
        <div className="form-input__number-icons">
          <ChevronUp onClick={() => handleChange(value ? Number(value) + (step || 1) : step || 1)} />
          <ChevronDown onClick={() => handleChange(value ? Number(value) - (step || 1) : min || 0)} />
        </div>
      ) : null}
      {errorMessage ? <span className="form-input__error-message">{errorMessage}</span> : null}
    </div>
  );
};
