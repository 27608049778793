import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { Modal, ModalBody } from 'reactstrap';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './ImageZoom.scss';

export const ImageZoomSlider = ({ className, navIcons, images, startWith, setModal, onChangeCurrent }) => {
  const [pos, setPos] = useState({ x: 0, y: 0, scale: 1, index: -1 });
  const sliderRef = useRef(null);

  useEffect(() => {
    window.addEventListener('keydown', keyHandler);

    return () => window.removeEventListener('keydown', keyHandler);
  }, []);

  const setNewScale = (e, index) => {
    const delta = e.deltaY * -0.0065;
    const newScale = pos.scale + delta >= 1 ? (pos.scale + delta < 5 ? pos.scale + delta : 5) : 1;

    setPos({
      index: index,
      scale: newScale,
      x: newScale !== 1 ? e.nativeEvent.offsetX * (1 - newScale) : 0,
      y: newScale !== 1 ? e.nativeEvent.offsetY * (1 - newScale) : 0,
    });
  };

  function keyHandler(e) {
    if (sliderRef.current) {
      if (e.which === 37 || e.keyCode === 37 || e.charCode === 37) {
        sliderRef.current.slickPrev();
      }
      if (e.which === 39 || e.keyCode === 39 || e.charCode === 39) {
        sliderRef.current.slickNext();
      }
    }
  }

  return (
    <>
      <div className="zoomed-image--nav-icons">{navIcons}</div>
      <Modal
        scrollable={false}
        className={`modal--image--zoomed ${className}`}
        isOpen={!!startWith}
        id="image_zoom_slider"
        toggle={() => setModal(false)}
        fade={true}
        centered
        backdrop={true}
        onClick={(e) => {
          if (e.target && e.target.className === 'images-slider--container') {
            setModal(false);
          }
        }}
      >
        <ModalBody className="modal_body_history">
          {images.length > 1 ? (
            <Slider
              ref={sliderRef}
              infinite={true}
              speed={200}
              slidesToShow={1}
              slidesToScroll={1}
              initialSlide={Number(startWith)}
              beforeChange={() => setPos({ x: 0, y: 0, scale: 1, index: -1 })}
            >
              {images.map((item, index) => (
                <div
                  key={index}
                  className="images-slider--container"
                  onWheelCapture={(e) => setNewScale(e, index)}
                >
                  <div className="image-zoomer--switchable-image">
                    {item.images.length > 1 && (
                      <div className="image-zoomer--switcher">
                        {item.images.map((_, imageIndex) => (
                          <span
                            key={imageIndex}
                            className={cx('image-zoomer--switcher--item', {
                              active: imageIndex === item.currentImage,
                            })}
                            onClick={() => onChangeCurrent(index, imageIndex)}
                          >
                            {imageIndex + 1}
                          </span>
                        ))}
                      </div>
                    )}
                    <img className="zoomed--image--modal--opacity" src={item.images[item.currentImage]} />
                    <img
                      className="zoomed--image--modal"
                      src={item.images[item.currentImage]}
                      style={{
                        left: (pos.index === index ? pos.x : 0) + 'px',
                        top: (pos.index === index ? pos.y : 0) + 'px',
                        transformOrigin: '0 0',
                        transform: ` scale(${pos.index === index ? pos.scale : 1})`,
                      }}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="images-slider--container" onWheelCapture={(e) => setNewScale(e, 0)}>
              <div className="image-zoomer--switchable-image">
                {images[0].images.length > 1 && (
                  <div className="image-zoomer--switcher">
                    {images[0].images.map((_, imageIndex) => (
                      <span
                        key={imageIndex}
                        className={cx('image-zoomer--switcher--item', {
                          active: imageIndex === images[0].currentImage,
                        })}
                        onClick={() => onChangeCurrent(0, imageIndex)}
                      >
                        {imageIndex + 1}
                      </span>
                    ))}
                  </div>
                )}
                <img
                  className="zoomed--image--modal--opacity"
                  src={`${images[0].images[images[0].currentImage]}`}
                />
                <img
                  className="zoomed--image--modal"
                  src={`${images[0].images[images[0].currentImage]}`}
                  style={{
                    left: (pos.index === 0 ? pos.x : 0) + 'px',
                    top: (pos.index === 0 ? pos.y : 0) + 'px',
                    transformOrigin: '0 0',
                    transform: ` scale(${pos.index === 0 ? pos.scale : 1})`,
                  }}
                />
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
