import { createFryer, deleteFryer, getFryers, updateFryer } from '../../api/fryerManager';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const GET_FRYER_MANAGER = 'GET_FRYER_MANAGER';
export const GET_FRYER_MANAGER_SUCCESS = 'GET_FRYER_MANAGER_SUCCESS';
export const GET_FRYER_MANAGER_ERROR = 'GET_FRYER_MANAGER_ERROR';

export const CREATE_FRYER_MANAGER = 'CREATE_FRYER_MANAGER';
export const CREATE_FRYER_MANAGER_SUCCESS = 'CREATE_FRYER_MANAGER_SUCCESS';
export const CREATE_FRYER_MANAGER_ERROR = 'CREATE_FRYER_MANAGER_ERROR';

export const UPDATE_FRYER_MANAGER = 'UPDATE_FRYER_MANAGER';
export const UPDATE_FRYER_MANAGER_SUCCESS = 'UPDATE_FRYER_MANAGER_SUCCESS';
export const UPDATE_FRYER_MANAGER_ERROR = 'UPDATE_FRYER_MANAGER_ERROR';

export const DELETE_FRYER_MANAGER = 'DELETE_FRYER_MANAGER';
export const DELETE_FRYER_MANAGER_SUCCESS = 'DELETE_FRYER_MANAGER_SUCCESS';
export const DELETE_FRYER_MANAGER_ERROR = 'DELETE_FRYER_MANAGER_ERROR';

export const CHANGE_FRYER_MANAGER_VALUE = 'CHANGE_FRYER_MANAGER_VALUE';
export const CHANGE_FRYER_MANAGER_SEVERAL_VALUES = 'CHANGE_FRYER_MANAGER_SEVERAL_VALUES';

export const RESET_FRYER_MANAGER_STORE = 'RESET_FRYER_MANAGER_STORE';

export const getFryerManagersAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_FRYER_MANAGER });
      try {
        const response = await getFryers(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          actions?.onSuccess([...results.content]);
          dispatch({ type: GET_FRYER_MANAGER_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error(error);
          actions?.onError(error.message);
          dispatch({ type: GET_FRYER_MANAGER_ERROR, params });
        }
      }
    };
  };
})();

export const createFryerManagerAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_FRYER_MANAGER });
    try {
      const response = await createFryer(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: CREATE_FRYER_MANAGER_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: CREATE_FRYER_MANAGER_ERROR });
    }
  };
};

export const updateFryerManagerAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_FRYER_MANAGER });
    try {
      const response = await updateFryer(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: UPDATE_FRYER_MANAGER_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: UPDATE_FRYER_MANAGER_ERROR });
    }
  };
};

export const deleteFryerManagerAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_FRYER_MANAGER });
    try {
      const response = await deleteFryer(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_FRYER_MANAGER_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: DELETE_FRYER_MANAGER_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_FRYER_MANAGER_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_FRYER_MANAGER_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_FRYER_MANAGER_STORE,
});
