import React from 'react';

import cx from 'classnames';

import './SwitchableImage.scss';

export const SwitchableImage = ({
  imageClassName,
  images = [],
  currentImage = 0,
  onChangeCurrent,
  onImageClick,
}) => {
  return (
    <div className="table-cell__switchable-image">
      {images.length > 1 && (
        <div className="images-switcher">
          {images.map((_, imageIndex) => (
            <span
              key={imageIndex}
              className={cx('images-switcher__item', { active: imageIndex === currentImage })}
              onClick={() => onChangeCurrent(imageIndex)}
            >
              {imageIndex + 1}
            </span>
          ))}
        </div>
      )}
      <img className={imageClassName} src={images[currentImage]} onClick={onImageClick} />
    </div>
  );
};
