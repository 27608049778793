import { getCities, getRestaurant, getStates } from '../../api/restaurants';

export const CHANGE_RESTAURANTS_VALUE = 'CHANGE_RESTAURANTS_VALUE';
export const RESET_RESTAURANTS_MODAL_STORE = 'RESET_RESTAURANTS_MODAL_STORE';
export const GET_RESTAURANT_MODAL_SUCCESS = 'GET_RESTAURANT_MODAL_SUCCESS';
export const GET_RESTAURANT_MODAL_ERROR = 'GET_RESTAURANT_MODAL_ERROR';

export const getStatesAction = (setData) => {
  return async () => {
    try {
      const response = await getStates();
      const { success, results } = response;
      if (success) {
        setData([
          ...results.content.map((state) => ({
            label: state.name,
            value: state.uuid,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCitiesAction = (params, setData) => {
  return async () => {
    try {
      const response = await getCities(params);
      const { success, results } = response;
      if (success) {
        setData([
          ...results.content.map((state) => ({
            label: state.name,
            value: state.name,
            uuid: state.uuid,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getRestaurantInfAction = (id, setData) => {
  return async (dispatch) => {
    try {
      const response = await getRestaurant(id);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_RESTAURANT_MODAL_SUCCESS, payload: results });
        setData(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_RESTAURANT_MODAL_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_RESTAURANTS_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_RESTAURANTS_MODAL_STORE,
});
