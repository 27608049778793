import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import './PlayButton.scss';

export const PlayButton = ({ onClick = () => {} }) => {
  return (
    <button className="play-button" onClick={onClick}>
      <div className="play-button__inner">
        <FontAwesomeIcon icon={faPlay} />
      </div>
    </button>
  );
};
