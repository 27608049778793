import cx from 'classnames';
import { TablePagination } from '../TablePagination/TablePagination';
import { Table } from '../Table';

export const TableWithPagination = ({
  className,
  hasInnerScroll = false,
  totalRows = 0,
  currentPage,
  perPage,
  isLoading,
  screen,
  changeValue,
  ...tableProps
}) => {
  const isMobile = screen === 'mobile';
  const containerClassName = isMobile ? 'mobile-table__container' : 'custom-table__container';
  const wrapperClassName = isMobile ? 'mobile-table__wrapper' : 'custom-table__wrapper';

  return (
    <div className={cx(containerClassName, className, { 'inner-scroll': hasInnerScroll })}>
      <div className={wrapperClassName}>
        <Table isLoading={isLoading} screen={screen} {...tableProps} />
        <TablePagination
          isLoading={isLoading}
          mobile={isMobile}
          totalRows={totalRows}
          page={currentPage}
          perPage={perPage}
          siblingCount={1}
          onChangePage={(value) => changeValue('page', value)}
          onChangePerPage={(value) => changeValue('limit', value)}
        />
      </div>
    </div>
  );
};
