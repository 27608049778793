export const periodOptions = [
  { label: 'Hour', value: 'HOUR' },
  { label: 'Day Part', value: 'DAY_PART' },
  { label: 'Days', value: 'DAY' },
  { label: 'Week', value: 'WEEK' },
  { label: 'Month', value: 'MONTH' },
];

export const potatoStationOptions = [
  { label: 'Primary potato station', value: '1' },
  { label: 'Secondary potato station', value: '2' },
];

export const unitsOptions = [
  { label: 'Kilograms', value: 'kg' },
  { label: 'Pounds', value: 'lbs' },
];

export const weightMeasurementUnits = {
  kg: 'KG',
  lbs: 'LB',
};
