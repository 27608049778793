import {
  createPotatoMaterial,
  deletePotatoMaterial,
  getPotatoMaterials,
  updatePotatoMaterial,
} from '../../api/materials';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_POTATO_MATERIALS_VALUE = 'CHANGE_POTATO_MATERIALS_VALUE';
export const CHANGE_POTATO_MATERIALS_SEVERAL_VALUES = 'CHANGE_POTATO_MATERIALS_SEVERAL_VALUES';

export const RESET_POTATO_MATERIALS_STORE = 'RESET_POTATO_MATERIALS_STORE';

export const GET_POTATO_MATERIALS = 'GET_POTATO_MATERIALS';
export const GET_POTATO_MATERIALS_SUCCESS = 'GET_POTATO_MATERIALS_SUCCESS';
export const GET_POTATO_MATERIALS_ERROR = 'GET_POTATO_MATERIALS_ERROR';

export const NEW_POTATO_MATERIAL = 'NEW_POTATO_MATERIAL';
export const NEW_POTATO_MATERIAL_SUCCESS = 'NEW_POTATO_MATERIAL_SUCCESS';
export const NEW_POTATO_MATERIAL_ERROR = 'NEW_POTATO_MATERIAL_ERROR';

export const UPDATE_POTATO_MATERIAL = 'UPDATE_POTATO_MATERIAL';
export const UPDATE_POTATO_MATERIAL_SUCCESS = 'UPDATE_POTATO_MATERIAL_SUCCESS';
export const UPDATE_POTATO_MATERIAL_ERROR = 'UPDATE_POTATO_MATERIAL_ERROR';

export const DELETE_POTATO_MATERIAL = 'DELETE_POTATO_MATERIAL';
export const DELETE_POTATO_MATERIAL_SUCCESS = 'DELETE_POTATO_MATERIAL_SUCCESS';
export const DELETE_POTATO_MATERIAL_ERROR = 'DELETE_POTATO_MATERIAL_ERROR';

export const getPotatoMaterialsAction = (function () {
  let prevController;

  return (params, setData) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_POTATO_MATERIALS });
      try {
        const response = await getPotatoMaterials(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          setData([...results.content]);
          dispatch({ type: GET_POTATO_MATERIALS_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_POTATO_MATERIALS_ERROR });
        }
      }
    };
  };
})();

export const createPotatoMaterialAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: NEW_POTATO_MATERIAL });
    try {
      const response = await createPotatoMaterial(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: NEW_POTATO_MATERIAL_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: NEW_POTATO_MATERIAL_ERROR });
    }
  };
};

export const updatePotatoMaterialAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_POTATO_MATERIAL });
    try {
      const response = await updatePotatoMaterial(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: UPDATE_POTATO_MATERIAL_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: UPDATE_POTATO_MATERIAL_ERROR });
    }
  };
};

export const deletePotatoMaterialAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_POTATO_MATERIAL });
    try {
      const response = await deletePotatoMaterial(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_POTATO_MATERIAL_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_POTATO_MATERIAL_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_POTATO_MATERIALS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_POTATO_MATERIALS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_POTATO_MATERIALS_STORE,
});
