import { ReactComponent as Info } from '../../assets/img/icons/common/InfoOutlined.svg';

export const ChartNoInfo = () => {
  return (
    <div className="empty__container">
      <Info />
      <span>No information</span>
    </div>
  );
};
