import React, { memo, useEffect } from 'react';

import cx from 'classnames';
import moment from 'moment';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ChartComponent } from '../../../components';

import { intervals, timeIntervals } from '../../../constants/analytics.constants';

import { getTimezoneOffsetInSeconds } from '../../../utils';
import {
  checkSearchParameter,
  deleteSearchParameters,
  getSearchParameter,
  setSearchParameters,
} from '../../../utils/urlFunctions';
import { checkGraph, getChartData, getOptions } from './utils';

import './SmallBarChart.scss';

export const SmallBarChart = memo(
  ({
    className,
    mobile,
    isLoading,
    history,
    graphContent,
    customColors = false,
    customOptions = {},
    customFormat,
    chartHeader,
    dates,
    clickBar,
    changeInterval,
    timeLimit,
    coloredTooltip = false,
    chartRef,
    isShowTotal = false,
  }) => {
    useEffect(() => {
      if (changeInterval) {
        getInterval();
      }
    }, [dates[0], dates[2], dates[3]]);

    const getInterval = () => {
      if ([dates[0], dates[1]].every((item) => item !== null && item !== 'select') || dates[2] !== null) {
        let searchInterval = checkSearchParameter('interval')
          ? timeIntervals.find((time) => time.name === getSearchParameter('interval'))
          : timeIntervals.find(
              (time) => time.value === parseInt(dates[2] !== null ? dates[2] : (dates[1] - dates[0]) / 1000)
            );
        let datesDifference = (dates[1] - dates[0]) / 1000;
        if (searchInterval) {
          changeInterval(intervals[searchInterval.auto].value);
        } else {
          let searchCalendar = timeIntervals
            .slice(0, timeIntervals.length - 1)
            .find(
              (time, index) =>
                time.value >= parseInt(dates[2] !== null ? dates[2] : datesDifference) &&
                parseInt(datesDifference) <= timeIntervals[index + 1].value
            );
          if (searchCalendar) {
            changeInterval(intervals[searchCalendar.auto].value);
          } else {
            changeInterval(intervals[timeIntervals[timeIntervals.length - 1].auto].value);
          }
        }
      }
    };

    const checkChartClick = (chartWrapper, google) => {
      const chart = chartWrapper.getChart();
      google.visualization.events.addListener(chart, 'select', () => {
        let selection = chart.getSelection();
        if (
          selection &&
          selection.length !== 0 &&
          selection[0] &&
          selection[0].length !== 0 &&
          selection[0].row !== null
        ) {
          let select = selection[0].row;
          if (
            graphContent[select] &&
            Math.abs(
              (graphContent[select + 1]
                ? graphContent[select + 1][0].getTime()
                : dates[2] !== null
                ? moment().tz(dates[4]).format('x')
                : new Date(dates[1]).getTime()) - graphContent[select][0].getTime()
            ) > timeLimit
          ) {
            let oldStartDate = dates[0];
            let start = graphContent[select][0].getTime() + getTimezoneOffsetInSeconds(dates[4]);
            let end = graphContent[select + 1]
              ? graphContent[select + 1][0].getTime() + getTimezoneOffsetInSeconds(dates[4])
              : dates[2] !== null
              ? moment().tz(dates[4]).format('x')
              : moment(dates[1]).format('x');
            let historyParameters =
              start && start !== 'select'
                ? setSearchParameters(['startDate', 'endDate'], [start, end], ['timeInterval', 'interval'])
                : deleteSearchParameters(['startDate', 'endDate', 'timeInterval', 'interval']);
            clickBar(start, end, 0);
            if (
              history !== null &&
              history.location.pathname + history.location.search !== historyParameters
            ) {
              history.push(historyParameters);
            }
            if (oldStartDate === graphContent[select][0].getTime() && changeInterval) {
              getInterval();
            }
          }
        }
      });
    };

    return checkGraph(graphContent) || isLoading ? (
      <div className={cx('small-bar__chart', className)}>
        {!isLoading ? (
          graphContent !== 'No information' ? (
            <ChartComponent
              chartRef={chartRef}
              chartType="ColumnChart"
              content={getChartData(
                graphContent,
                chartHeader,
                dates[4],
                coloredTooltip,
                customColors,
                customFormat,
                isShowTotal
              )}
              checkChartClick={checkChartClick}
              chartOptions={getOptions(graphContent, dates, mobile, customOptions, customColors)}
            />
          ) : (
            <div className="small-bar__chart-error">No information</div>
          )
        ) : (
          <div className="small-bar__loader">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        )}
      </div>
    ) : (
      <></>
    );
  }
);
