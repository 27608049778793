import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';

import { ReactComponent as ArrowDropDown } from '../../../assets/img/icons/common/ArrowDropDown.svg';
import { ReactComponent as Cancel } from '../../../assets/img/icons/common/Cancel.svg';
import { ReactComponent as Search } from '../../../assets/img/icons/common/Search.svg';

import './FormMultiSelect.scss';
import { usePlacement } from '../../../hooks/usePlacement';
import { useHandleOutsideClick } from '../../../hooks/useHandleOutsideClick';

export const FormMultiSelect = ({
  title,
  name,
  options,
  values,
  disabled,
  searchable,
  onChange,
  placementSettings,
}) => {
  const [selectOpened, setSelectOpened] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const { refs, floatingStyles } = usePlacement('bottom-start', selectOpened, placementSettings);

  useHandleOutsideClick(
    selectOpened,
    () => {
      setSelectOpened(false);
    },
    refs.floating,
    refs.reference
  );

  const handleSelectOption = (value) => {
    const newValues = values.includes(value) ? values.filter((item) => item !== value) : [...values, value];
    name ? onChange(name, newValues) : onChange(newValues);
  };

  const resetFilter = () => {
    name ? onChange(name, []) : onChange([]);
    setSelectOpened(false);
  };

  const isNotEmpty = useMemo(() => values?.length > 0, [values]);

  const dropdownMenu = (
    <div ref={refs.setFloating} style={floatingStyles} className="filter__dropdown-menu">
      {searchable && (
        <div className="filter__dropdown-menu__search">
          <Search />
          <input
            type="text"
            placeholder="Search"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
        </div>
      )}
      <ul className="filter__dropdown-menu__options">
        {options
          .filter((option) => option.label.toLowerCase().includes(searchInput.toLowerCase()))
          .map((option) => (
            <li
              key={option.value}
              className={cx('option', { active: values.includes(option.value) })}
              onClick={() => handleSelectOption(option.value)}
            >
              <span className="option__label">{option.label}</span>
            </li>
          ))}
      </ul>
    </div>
  );

  return (
    <div
      ref={refs.setReference}
      className={cx('form-multi-select-filter', { opened: selectOpened, disabled })}
    >
      <div
        className="filter__toggle"
        onClick={() => {
          !disabled && setSelectOpened((prev) => !prev);
        }}
      >
        <div className="filter__label-container">
          <span
            className={cx('filter__title', {
              selected: isNotEmpty,
            })}
          >
            {title}
          </span>
          {isNotEmpty && (
            <span className="filter__values">
              {options
                .filter((option) => values.includes(option.value))
                .map((option) => (
                  <div key={option.value} className="filter__values__item">
                    {option.label}
                    <button
                      className="filter__cancel-btn"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleSelectOption(option.value);
                      }}
                    >
                      <Cancel />
                    </button>
                  </div>
                ))}
            </span>
          )}
        </div>
        <div className="filter__controls">
          {isNotEmpty && (
            <button
              className="filter__cancel-btn"
              onClick={(event) => {
                event.stopPropagation();
                resetFilter();
              }}
            >
              <Cancel />
            </button>
          )}
          <ArrowDropDown />
        </div>
      </div>

      {selectOpened && createPortal(dropdownMenu, document.getElementById('root'))}
    </div>
  );
};
