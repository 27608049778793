import * as types from '../actions/devicesAction';

import { prepareAvailabilityData } from '../../utils/devices';
import { getEndDate, getStartDate } from '../../utils/urlFunctions';

const initialState = {
  status: null,
  restaurantUuid: null,
  activeTab: 'devices',
  startDate: null,
  endDate: null,
  timeInterval: null,
  intervalEnd: null,
  orderDirection: 'DESC',
  sortBy: 'status',
  limit: '20',
  page: 0,
  totalResults: 0,
  loading: false,
  availabilityLoading: false,
  data: [],
  availabilityData: [],
};

const devicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_DEVICES_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_DEVICES_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_DEVICES:
      return {
        ...state,
        loading: true,
        availabilityData: [],
      };

    case types.GET_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
        data: action.payload.content,
      };

    case types.GET_DEVICES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.CONFIRM_DEVICE:
      return {
        ...state,
        loading: false,
      };

    case types.GET_DEVICES_AVAILABILITY:
      return {
        ...state,
        availabilityLoading: true,
      };

    case types.GET_DEVICES_AVAILABILITY_SUCCESS: {
      const startTime = getStartDate(state.startDate, state.timeInterval, state.timezone);
      const endTime = getEndDate(state.endDate, state.timeInterval, state.timezone);
      return {
        ...state,
        availabilityLoading: false,
        availabilityData: prepareAvailabilityData(startTime, endTime, state.data, action.payload),
      };
    }

    case types.GET_DEVICES_AVAILABILITY_ERROR:
      return {
        ...state,
        availabilityLoading: false,
      };

    case types.RESET_DEVICES_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default devicesReducer;
