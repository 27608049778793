import { useEffect } from 'react';

export const useHandleOutsideClick = (isOpen, handler, ...refs) => {
  useEffect(() => {
    const handleClick = (e) => {
      if (!refs.some((ref) => ref.current?.contains(e.target))) {
        handler();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [isOpen]);
};
