import { hAxisOptions } from '../../../constants/analytics.constants';
import { miniGraphicColors, units } from '../../../constants/graphics.constants';
import { convertBytes, getTimezoneOffsetInSeconds } from '../../../utils';
import { getChartTooltip, initialValues } from '../../../utils/chartHelper';

export const getChartOptions = (
  data,
  dates,
  colorIndex,
  legend,
  chartArea,
  allMetric,
  chartMetric,
  percentMaxValue = false
) => {
  let maxValue =
    data !== null && data.length !== 0 && data[0].values !== null && (chartMetric !== '%' || percentMaxValue)
      ? Math.ceil(
          convertBytes(
            !!allMetric
              ? Number(allMetric)
              : Number(Math.max(...data.map((it) => it.values.map((val) => val[1])).flat())),
            units,
            chartMetric,
            true
          ) * (!!allMetric ? 1 : 1.2)
        ) || 1
      : 100;
  return {
    animation: { startup: true, duration: 700, easing: 'out' },
    focusTarget: 'category',
    tooltip: { isHtml: true },
    colors: miniGraphicColors.slice(colorIndex).concat(miniGraphicColors.slice(0, colorIndex)),
    legend: { position: data.length > 1 || legend ? 'bottom' : 'none' },
    width: '100%',
    hAxis: {
      minValue: new Date(
        Math.floor(
          dates[2] !== null ? Date.now() + getTimezoneOffsetInSeconds(dates[3]) - dates[2] * 1000 : dates[0]
        )
      ),
      ...hAxisOptions,
    },
    vAxis: {
      gridlines: {
        count: 4,
      },
      format: chartMetric === '%' ? `#'${chartMetric}'` : `#.#' ${units[chartMetric]}'`,
      viewWindow: {
        max: maxValue,
        min: 0,
      },
      minValue: 0,
      maxValue: maxValue,
    },
    chartArea: chartArea ? chartArea : { width: '93%', height: data.length > 1 ? '70%' : '80%' },
  };
};

export const getChartData = (data, dates, legend, colorIndex, chartMetric, showUnits = false) => {
  return [
    [
      'Timestamp',
      { type: 'string', role: 'tooltip', p: { html: true } },
      ...(data.length === 1 && !legend
        ? [chartMetric === '%' ? '%' : units[chartMetric]]
        : data.map((cpu) => cpu.metricLabel || cpu.machine)),
    ],
    ...(data[0].values.length !== 0
      ? data[0].values.map((value, index) => [
          new Date(new Date(value[0]).getTime() + getTimezoneOffsetInSeconds(dates[3])),
          getChartTooltip(data, value, index, dates, chartMetric, colorIndex, showUnits),
          ...(data.length > 1
            ? data.map((value) => (value.values[index] ? value.values[index][1] : null))
            : [value[1]]),
        ])
      : initialValues(dates)),
  ];
};
