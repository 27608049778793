import { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';

import cx from 'classnames';

import { presetSelectColors } from '../../../constants/settings.constants';

import './FormInputColor.scss';

export const FormInputColor = ({ id = 'input__color', name, value, onChange }) => {
  const [opened, toggleOpened] = useState(false);

  useEffect(() => {
    return () => {
      toggleOpened(false);
      document.removeEventListener('click', checkClick);
    };
  }, []);

  useEffect(() => {
    if (opened) {
      document.addEventListener('click', checkClick);
    }
  }, [opened]);

  function checkClick(e) {
    if (!(e.path || (e.composedPath && e.composedPath())).includes(document.getElementById(id))) {
      toggleOpened(false);
      return document.removeEventListener('click', checkClick);
    }
  }

  const handleChange = (color) => {
    name ? onChange(name, color) : onChange(color);
  };

  return (
    <div className="form-color__container" id={id}>
      <div className={cx('form-color__input', { empty: !value })} onClick={() => toggleOpened((val) => !val)}>
        {!!value ? value : 'Color'}{' '}
        <div
          className="form-color__input-image"
          style={{ backgroundColor: !!value ? value : '#dedede' }}
        ></div>
      </div>
      {opened && (
        <div className="form-color__modal">
          <SketchPicker
            className="form-color__modal-item"
            color={value}
            presetColors={presetSelectColors}
            disableAlpha={true}
            onChangeComplete={(color) => {
              handleChange(color.hex.toUpperCase());
            }}
          />
        </div>
      )}
    </div>
  );
};
