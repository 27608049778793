import React, { useMemo } from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ChartComponent, ChartNoInfo, TableWithPagination } from '../../../../components';

import { googleColors, pieChartThousands } from '../../../../constants/analytics.constants';
import { pieChartColumns, pieChartOptions } from './constants';

import { convertThousands } from '../../../../utils';
import { createRows, getChartData } from './utils';

export const PieChart = ({
  content,
  customColors = googleColors,
  customTableHeader = false,
  prefix = '',
  isLoading,
}) => {
  const totalAmount = useMemo(
    () => (content ? content.totalValues.reduce((sum, element) => sum + Number(element.value), 0) : 0),
    [content]
  );

  return (
    <div className="pie__container">
      {content !== null && !isLoading ? (
        <>
          {totalAmount && totalAmount !== 0 ? (
            <div className="chart-part">
              <div className="pie-chart__wrapper">
                <ChartComponent
                  chartType="PieChart"
                  height="280px"
                  width="280px"
                  content={getChartData(content, totalAmount)}
                  chartOptions={{ ...pieChartOptions, colors: customColors }}
                />
                <span className="pie-chart__total-amount">
                  {convertThousands(totalAmount || 0, pieChartThousands)}
                  {prefix}
                </span>
              </div>
            </div>
          ) : null}
          <div className="table-part">
            <TableWithPagination
              className="analytics__pie-chart-table"
              rows={createRows(content.totalValues, totalAmount, customColors)}
              headerOptions={customTableHeader || pieChartColumns}
            />
          </div>
        </>
      ) : (
        <div className="analytics__info-block">
          {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : <ChartNoInfo />}
        </div>
      )}
    </div>
  );
};
