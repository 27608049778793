import React from 'react';

import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Condition.scss';

export const Condition = ({ condition, text }) => {
  return (
    <div className="conditions-item">
      <FontAwesomeIcon
        className={`conditions-item--icon conditions-item--icon${condition ? '-green' : '-red'}`}
        icon={condition ? faCheck : faXmark}
      />
      <span>{text}</span>
    </div>
  );
};
