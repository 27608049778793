import * as types from '../actions/restaurantModalAction';

export const initialState = {
  number: '',
  name: '',
  state: 'select',
  city: 'select',
  postalCode: '',
  securityCode: '',
  addressLine1: '',
  addressLine2: '',
  uuid: '',
  timezoneName: '',
};

export const restaurantModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_RESTAURANTS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.GET_RESTAURANT_MODAL_SUCCESS:
      return {
        ...state,
        number: action.payload.number,
        name: action.payload.name,
        state: action.payload.state.uuid,
        city: action.payload.city,
        postalCode: action.payload.postalCode,
        securityCode: action.payload.securityCode,
        addressLine1: action.payload.addressLine1,
        addressLine2: action.payload.addressLine2 ? action.payload.addressLine2 : '',
        uuid: action.payload.uuid,
        timezoneName: action.payload.timezoneName,
      };
    case types.GET_RESTAURANT_MODAL_ERROR:
      return {
        ...initialState,
      };
    case types.RESET_RESTAURANTS_MODAL_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default restaurantModalReducer;
