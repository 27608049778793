import request from '../utils/request';

export function getUnusualEvents(params, signal) {
  return request({
    url: '/events/unusual',
    method: 'get',
    data: params,
    signal,
  });
}
