import * as types from '../actions/miniPlayerAction';

const initialState = {
  restaurantUuid: 'select',
  open: false,
  returnLink: null,
  name: '',
  type: 'stream',
  janusHost: null,
  streamId: '',
  tries: 0,
  restart: false,
  hasWebRTCError: false,
  savedPosition: null,
  savedSize: null,
};

const miniPlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_MINI_PLAYER_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.START_MINI_PLAYER_STREAM:
      return {
        ...state,
        ...action.payload,
        open: true,
        type: 'stream',
      };

    case types.START_MINI_PLAYER_VIDEO:
      return {
        ...state,
        ...action.payload,
        open: true,
        type: 'video',
      };

    case types.SET_MINI_PLAYER_POSITION:
      return {
        ...state,
        savedPosition: action.payload,
      };

    case types.SET_MINI_PLAYER_SIZE:
      return {
        ...state,
        savedSize: action.payload,
      };

    case types.RESET_MINI_PLAYER_STORE:
      return {
        ...initialState,
        savedPosition: state.savedPosition,
        savedSize: state.savedSize,
      };

    default:
      return state;
  }
};

export default miniPlayerReducer;
