import { getMaterialsAnalytics } from '../../api/analytics';
import { getConveyorEvents, getExelExport, deleteConveyor, getConveyorTotal } from '../../api/conveyor';
import { downloadFile } from '../../utils';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_CONVEYOR_EVENTS_VALUE = 'CHANGE_CONVEYOR_EVENTS_VALUE';
export const CHANGE_CONVEYOR_EVENTS_SEVERAL_VALUES = 'CHANGE_CONVEYOR_EVENTS_SEVERAL_VALUES';
export const RESET_CONVEYOR_EVENTS_STORE = 'RESET_CONVEYOR_EVENTS_STORE';

export const GET_CONVEYOR_EVENTS = 'GET_CONVEYOR_EVENTS';
export const GET_CONVEYOR_EVENTS_SUCCESS = 'GET_CONVEYOR_EVENTS_SUCCESS';
export const GET_CONVEYOR_EVENTS_ERROR = 'GET_CONVEYOR_EVENTS_ERROR';

export const DELETE_CONVEYOR_EVENT = 'DELETE_CONVEYOR_EVENT';
export const DELETE_CONVEYOR_EVENT_SUCCESS = 'DELETE_CONVEYOR_EVENT_SUCCESS';
export const DELETE_CONVEYOR_EVENT_ERROR = 'DELETE_CONVEYOR_EVENT_ERROR';

export const LOAD_CONVEYOR_EVENTS_FILE = 'LOAD_CONVEYOR_EVENTS_FILE';
export const LOAD_CONVEYOR_EVENTS_FILE_SUCCESS = 'LOAD_CONVEYOR_EVENTS_FILE_SUCCESS';
export const LOAD_CONVEYOR_EVENTS_FILE_ERROR = 'LOAD_CONVEYOR_EVENTS_FILE_ERROR';

export const GET_CONVEYOR_EVENTS_ANALYTIC = 'GET_CONVEYOR_EVENTS_ANALYTIC';
export const GET_CONVEYOR_EVENTS_ANALYTIC_SUCCESS = 'GET_CONVEYOR_EVENTS_ANALYTIC_SUCCESS';
export const GET_CONVEYOR_EVENTS_ANALYTIC_ERROR = 'GET_CONVEYOR_EVENTS_ANALYTIC_ERROR';

export const downloadConveyorFile = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_CONVEYOR_EVENTS_FILE });
    try {
      const response = await getExelExport(params);
      const { success, results } = response;
      if (success) {
        response.code !== 202 && downloadFile(results, 'application/zip');
        dispatch({ type: LOAD_CONVEYOR_EVENTS_FILE_SUCCESS });
        if (actions.onSuccess) {
          actions.onSuccess(response);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_CONVEYOR_EVENTS_FILE_ERROR });
      if (actions.onError) {
        actions.onError(error.message);
      }
    }
  };
};

export const getConveyorAnalyticAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_CONVEYOR_EVENTS_ANALYTIC, payload: params.cursorDirection === 'FIRST' });
      try {
        const [response, totalResponse] = await Promise.all([
          getMaterialsAnalytics(params, abortController.signal),
          getConveyorTotal(
            {
              startTime: params.startEventTime,
              endTime: params.endEventTime,
              restaurantUuid: params.restaurantsUuid,
              materials: params.materials,
            },
            abortController.signal
          ),
        ]);
        const { success, results } = response;
        const { success: totalSuccess, results: total } = totalResponse;

        if (success && totalSuccess && !abortController.signal.aborted) {
          dispatch({
            type: GET_CONVEYOR_EVENTS_ANALYTIC_SUCCESS,
            payload: { results, total },
            params,
          });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_CONVEYOR_EVENTS_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const getConveyorEventsAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_CONVEYOR_EVENTS, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await getConveyorEvents(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_CONVEYOR_EVENTS_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          actions?.onError(error.message);
          dispatch({ type: GET_CONVEYOR_EVENTS_ERROR, params });
        }
      }
    };
  };
})();

export const deleteConveyorEventAction = (uuid, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CONVEYOR_EVENT });
    try {
      const response = await deleteConveyor(uuid);
      const { success } = response;
      if (success) {
        actions?.onSuccess();
        dispatch({ type: DELETE_CONVEYOR_EVENT_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions?.onError(error.message);
      dispatch({ type: DELETE_CONVEYOR_EVENT_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CONVEYOR_EVENTS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_CONVEYOR_EVENTS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_CONVEYOR_EVENTS_STORE,
});
