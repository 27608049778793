import * as types from '../actions/userSecurityAction';

const initialState = {
  code: ['', '', '', '', '', ''],
  isLoading: false,
  verificationString: '',
  qrString: null,
  recoveryCodes: [],
};

const userSecurityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_USER_SECURITY_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.ENABLE_VERIFICATION:
    case types.GET_USER_SECURITY_QR_CODE:
      return {
        ...state,
        isLoading: true,
      };
    case types.ENABLE_VERIFICATION_ERROR:
    case types.GET_USER_SECURITY_QR_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case types.ENABLE_VERIFICATION_SUCCESS:
      return {
        ...state,
        verificationString: action.payload,
      };
    case types.GET_USER_SECURITY_QR_CODE_SUCCESS:
      return {
        ...state,
        qrString: action.payload,
        isLoading: false,
      };
    case types.RESET_USER_RECOVERY_CODES_SUCCESS:
      return {
        ...state,
        recoveryCodes: action.payload,
      };
    case types.RESET_USER_SECURITY_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default userSecurityReducer;
