import * as types from '../actions/fryerManagerAction';

const initialState = {
  restaurantUuid: null,
  material: [],
  limit: '20',
  loading: false,
  page: 0,
  totalResults: 0,
  data: [],
};

const fryerManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_FRYER_MANAGER_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_FRYER_MANAGER_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_FRYER_MANAGER:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case types.GET_FRYER_MANAGER_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
        data: action.payload.content,
      };

    case types.GET_FRYER_MANAGER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.RESET_FRYER_MANAGER_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default fryerManagerReducer;
