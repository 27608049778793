import { SET_CURRENT_PAGE, SET_SCREEN, SET_SIDEBAR, SET_VIEW } from '../actions/appAction';

const initialState = {
  sidebar: false,
  currentPage: 'Dashboard',
  view: 'icon',
  screen: 'desktop',
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR:
      return { ...state, sidebar: action.isOpen };

    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.page };

    case SET_VIEW:
      return { ...state, view: action.view };

    case SET_SCREEN:
      return {
        ...state,
        screen: action.value,
      };
    default:
      return state;
  }
};

export default appReducer;
