import * as types from '../actions/arucoAction';

const initialState = {
  restaurantUuid: 'select',
  isLoading: false,
  monitors: 0,
  layoutType: 'horizontal',
  activeStreamId: '',
  restart: false,
  streamsList: [],
  hasWebRTCError: false,
  tries: 0,
};

const arucoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ARUCO_ZONE_STREAMS_SUCCESS:
      return {
        ...state,
        streamsList: action.payload.map((stream, index) => ({
          key: Date.now() + index,
          streamName: stream,
          modalOpened: false,
          metaLoaded: false,
        })),
        monitors: action.payload.length,
      };
    case types.CHANGE_ARUCO_VALIDATION_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_ARUCO_STREAM_VALUE:
      return {
        ...state,
        streamsList: state.streamsList.map((stream) =>
          stream.key === action.payload.key
            ? {
                ...stream,
                [action.payload.name]: action.payload.value,
              }
            : stream
        ),
      };
    case types.SWAP_ARUCO_STREAMS: {
      const firstStream = state.streamsList[action.payload.index1];
      const secondStream = state.streamsList[action.payload.index2];
      const updatedStreamsList = [...state.streamsList];
      updatedStreamsList[action.payload.index1] = secondStream;
      updatedStreamsList[action.payload.index2] = firstStream;
      return {
        ...state,
        streamsList: updatedStreamsList,
      };
    }
    case types.RESET_ARUCO_VALIDATION_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default arucoReducer;
