import * as types from '../actions/notificationsAction';

export const initialState = {
  content: null,
  loading: false,
  totalResults: 0,
  page: 0,
  unreadTotalResults: 0,
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_NOTIFICATIONS_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          content: action.payload.content,
          page: action.payload.pageable.pageNumber,
          last: action.payload.last,
          totalResults: action.payload.totalElements,
          loading: false,
        };
      } else
        return {
          ...state,
          loading: false,
        };
    case types.GET_NEXT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        content: [...state.content, ...action.payload.content],
        page: action.payload.pageable.pageNumber,
        last: action.payload.last,
        loading: false,
      };
    case types.SET_MULTIPLE_NOTIFICATIONS_READ_SUCCESS:
      state.content.forEach(
        (notification) =>
          action.payload.notificationsUuid.includes(notification.uuid) && (notification.status = 'READ')
      );
      return {
        ...state,
        content: state.content,
        unreadTotalResults: state.unreadTotalResults - action.payload.notificationsUuid.length,
      };
    case types.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        content: state.content.filter((notification) => notification.uuid !== action.payload),
        totalResults: state.totalResults - 1,
      };
    case types.CHANGE_NOTIFICATIONS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.RESET_NOTIFICATIONS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default notificationsReducer;
