import * as types from '../actions/serverManagementAction';

const initialState = {
  restaurantUuid: 'select',
  restaurants: [],
  machines: [],
  machine: 'select',
  status: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: null,
  loading: false,
  loadOpened: true,
  temperatureOpened: true,
  cpuGpuOpened: true,
  memoryOpened: true,
  swapOpened: true,
  diskOpened: true,
  networkOpened: true,
  cpuCores: 0,
  cpeTemp: [],
  cpuScalar: [],
  cpuVector: [{ machine: '', values: [] }],
  gpuTemp: [],
  gpuMax: null,
  gpuDevices: [],
  gpuScalar: [],
  gpuVector: [{ machine: '', values: [] }],
  gpuType: 3,
  gpuUtilsVector: [{ machine: '', values: [] }],
  ramScalar: [],
  ramVector: [{ machine: '', values: [] }],
  ramAll: null,
  ramFree: null,
  ramType: 3,
  storageScalar: [],
  storageVector: [{ machine: '', values: [] }],
  storageIOVector: [{ machine: '', values: [] }],
  storageFree: null,
  storageAll: null,
  storageType: 3,
  storageIOType: 2,
  swapFree: null,
  swapAll: null,
  swapScalar: [],
  swapType: 3,
  swapVector: [{ machine: '', values: [] }],
  networkType: 0,
  networkVector: [{ machine: '', values: [] }],
  upTime: '',
  computerName: null,
  osName: null,
  osVersion: null,
  processor: null,
  swapTotal: '',
  timeZone: null,
  activeAlerts: null,
};

const serverManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SERVER_MANAGEMENT_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_SERVER_MANAGEMENT_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_SERVER_RESTAURANTS_SUCCESS:
      return {
        ...state,
        restaurants: action.payload
          ? action.payload.content.map((rest) => ({
              label: rest.number + ' - ' + rest.name,
              name: rest.number + ' ' + rest.name,
              number: rest.number,
              hosts: rest.hosts,
              value: rest.uuid,
            }))
          : [],
      };

    case types.GET_SERVER_MACHINES_SUCCESS:
      return {
        ...state,
        machines: action.payload.data
          .filter((machine) => state.restaurants.find((rest) => rest.hosts.includes(machine)))
          .map((machine) => ({
            label: machine,
            code: state.restaurants.find((rest) => rest.hosts.includes(machine)).number,
            value: machine,
          }))
          .sort((a, b) => a.code - b.code),
      };

    case types.RESET_GRAPHICS_METRICS:
      return {
        ...state,
        cpuCores: 0,
        cpuTemp: [],
        cpuScalar: [],
        cpuVector: [{ machine: '', values: null }],
        ramScalar: [],
        ramVector: [{ machine: '', values: null }],
        ramAll: null,
        ramFree: null,
        gpuMax: null,
        gpuTemp: [],
        gpuDevices: [],
        gpuScalar: [],
        gpuVector: [{ machine: '', values: null }],
        gpuUtilsVector: [{ machine: '', values: null }],
        storageFree: null,
        storageAll: null,
        storageScalar: [],
        swapFree: null,
        swapAll: null,
        swapScalar: [],
        swapVector: [{ machine: '', values: null }],
        storageVector: [{ machine: '', values: null }],
        storageIOVector: [{ machine: '', values: null }],
        networkVector: [{ machine: '', values: null }],
        upTime: '',
        computerName: null,
        osName: null,
        osVersion: null,
        processor: '',
        swapTotal: '',
        activeAlerts: null,
      };

    case types.RESET_SERVER_MANAGEMENT_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default serverManagementReducer;
