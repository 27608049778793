import {
  getRecommendations,
  getRecommendationsTotal,
  getRecommendationsAnalytic,
  deleteRecommendation,
} from '../../api/recommendations';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_RECOMMENDATIONS_VALUE = 'CHANGE_RECOMMENDATIONS_VALUE';
export const CHANGE_RECOMMENDATIONS_SEVERAL_VALUES = 'CHANGE_RECOMMENDATIONS_SEVERAL_VALUES';
export const RESET_RECOMMENDATIONS_STORE = 'RESET_RECOMMENDATIONS_STORE';

export const GET_RECOMMENDATIONS = 'GET_RECOMMENDATIONS';
export const GET_RECOMMENDATIONS_SUCCESS = 'GET_RECOMMENDATIONS_SUCCESS';
export const GET_RECOMMENDATIONS_ERROR = 'GET_RECOMMENDATIONS_ERROR';

export const DELETE_RECOMMENDATION = 'DELETE_RECOMMENDATION';
export const DELETE_RECOMMENDATION_SUCCESS = 'DELETE_RECOMMENDATION_SUCCESS';
export const DELETE_RECOMMENDATION_ERROR = 'DELETE_RECOMMENDATION_ERROR';

export const GET_RECOMMENDATIONS_ANALYTIC = 'GET_RECOMMENDATIONS_ANALYTIC';
export const GET_RECOMMENDATIONS_ANALYTIC_SUCCESS = 'GET_RECOMMENDATIONS_ANALYTIC_SUCCESS';
export const GET_RECOMMENDATIONS_ANALYTIC_ERROR = 'GET_RECOMMENDATIONS_ANALYTIC_ERROR';

export const getRecommendationsAnalyticAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_RECOMMENDATIONS_ANALYTIC, payload: params.cursorDirection === 'FIRST' });
      try {
        const [response, totalResponse] = await Promise.all([
          getRecommendationsAnalytic(params, abortController.signal),
          getRecommendationsTotal(
            {
              restaurantUuid: params.restaurantUuid,
              materials: params.materials,
              startTime: params.startTime,
              endTime: params.endTime,
              canceled: params.canceled,
              catering: params.catering,
            },
            abortController.signal
          ),
        ]);
        const { success, results } = response;
        const { success: totalSuccess, results: total } = totalResponse;

        if (success && totalSuccess && !abortController.signal.aborted) {
          dispatch({
            type: GET_RECOMMENDATIONS_ANALYTIC_SUCCESS,
            payload: { results, total },
            params,
          });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_RECOMMENDATIONS_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const getRecommendationsAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_RECOMMENDATIONS, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await getRecommendations(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_RECOMMENDATIONS_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          actions?.onError(error.message);
          dispatch({ type: GET_RECOMMENDATIONS_ERROR, params });
        }
      }
    };
  };
})();

export const deleteRecommendationAction = (uuid, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_RECOMMENDATION });
    try {
      const response = await deleteRecommendation(uuid);
      const { success } = response;
      if (success) {
        actions?.onSuccess();
        dispatch({ type: DELETE_RECOMMENDATION_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions?.onError(error.message);
      dispatch({ type: DELETE_RECOMMENDATION_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_RECOMMENDATIONS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_RECOMMENDATIONS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_RECOMMENDATIONS_STORE,
});
