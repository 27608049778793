import { deleteAllSessions, deleteSession, getSessions } from '../../api/sessions';
import { getUser } from '../../api/users';

export const CHANGE_USER_SESSIONS_VALUE = 'CHANGE_USER_SESSIONS_VALUE';
export const RESET_USER_SESSIONS_STORE = 'RESET_USER_SESSIONS_STORE';

export const GET_USER_SESSIONS = 'GET_USER_SESSIONS';
export const GET_USER_SESSIONS_SUCCESS = 'GET_USER_SESSIONS_SUCCESS';
export const GET_USER_SESSIONS_ERROR = 'GET_USER_SESSIONS_ERROR';

export const DELETE_USER_SESSION = 'DELETE_USER_SESSION';
export const DELETE_USER_SESSION_SUCCESS = 'DELETE_USER_SESSION_SUCCESS';
export const DELETE_USER_SESSION_ERROR = 'DELETE_USER_SESSION_ERROR';

export const DELETE_ALL_USER_SESSIONS = 'DELETE_ALL_USER_SESSIONS';
export const DELETE_ALL_USER_SESSIONS_SUCCESS = 'DELETE_ALL_USER_SESSIONS_SUCCESS';
export const DELETE_ALL_USER_SESSIONS_ERROR = 'DELETE_ALL_USER_SESSIONS_ERROR';

export const getSessionsAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_SESSIONS });
    try {
      const response = await getSessions(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_USER_SESSIONS_SUCCESS, payload: results });
        typeof setData === 'function' && setData(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_USER_SESSIONS_ERROR });
    }
  };
};

export const deleteSessionAction = (uuid, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_USER_SESSION });
    try {
      const response = await deleteSession(uuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess(results);
        dispatch({ type: DELETE_USER_SESSION_SUCCESS, payload: uuid });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_USER_SESSION_ERROR });
    }
  };
};

export const deleteAllSessionsAction = (uuid, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ALL_USER_SESSIONS });
    try {
      const response = await deleteAllSessions(uuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess(results);
        dispatch({ type: DELETE_ALL_USER_SESSIONS_SUCCESS, payload: uuid });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_ALL_USER_SESSIONS_ERROR });
    }
  };
};

export const getUserAction = (userUuid, actions = {}) => {
  return async () => {
    try {
      const response = await getUser(userUuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_USER_SESSIONS_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_USER_SESSIONS_STORE,
});
