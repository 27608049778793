import React, { useState } from 'react';

import { ReactComponent as ChevronDown } from '../../../../assets/img/icons/common/ChevroneDown.svg';
import { ReactComponent as ChevronRight } from '../../../../assets/img/icons/common/ChevroneRight.svg';
import { TablePagination } from '../../../TablePagination/TablePagination';

import moment from 'moment';
import { isEmpty } from 'lodash';
import { EmptyContainer } from '../../../EmptyContainer';

const TableWrapper = ({
  headerOptions,
  rows,
  openedRows,
  checkOpenedRows,
  totalRows,
  currentPage,
  perPage,
  changeValue,
  isLoading,
}) => {
  return (
    <div className="recordings-table__wrapper ">
      {headerOptions[0]?.Component}
      <div className="recordings-table">
        {rows.map((row) => (
          <div key={row.id} className="recordings-table__item">
            <div className="recordings-table__row">
              <div className="recordings-table__cells">
                {row.cells.map((item, i) => (
                  <div key={i}>{item}</div>
                ))}
              </div>
              <button className="recordings-table__chevron" onClick={() => checkOpenedRows(row.id)}>
                {openedRows.includes(row.id) ? <ChevronDown /> : <ChevronRight />}
              </button>
            </div>
            {openedRows.includes(row.id) && !isEmpty(row.openedCells) ? (
              <div className="recordings-table__extended-info">
                {row.openedCells.map((extCell, i) => {
                  return !!extCell.label ? (
                    <div key={i} className="recordings-table__extended-info__item">
                      <div className="recordings-table__extended-info__header">{extCell.title}</div>
                      <div className="recordings-table__extended-info__label">
                        {!extCell.date
                          ? extCell.label
                          : moment
                              .tz(extCell.label, extCell.timezone)
                              .format(`MMM D${!extCell.withoutYear ? ' YYYY' : ''}, LTS`)}
                      </div>
                    </div>
                  ) : null;
                })}
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <TablePagination
        mobile
        isLoading={isLoading}
        totalRows={totalRows}
        page={currentPage}
        perPage={perPage}
        siblingCount={1}
        onChangePage={(value) => changeValue('page', value)}
        onChangePerPage={(value) => changeValue('limit', value)}
      />
    </div>
  );
};

export const RecordingsTable = ({
  headerOptions = null,
  rows,
  isLoading,
  totalRows = 0,
  currentPage,
  perPage,
  changeValue,
}) => {
  const [openedRows, setOpenedRows] = useState([]);

  const checkOpenedRows = (id) => {
    if (openedRows.includes(id)) {
      setOpenedRows(openedRows.filter((it) => it !== id));
    } else {
      setOpenedRows([...openedRows, id]);
    }
  };
  const getContent = () => {
    return isEmpty(rows) ? (
      <EmptyContainer />
    ) : (
      <TableWrapper
        headerOptions={headerOptions}
        rows={rows}
        openedRows={openedRows}
        checkOpenedRows={checkOpenedRows}
        totalRows={totalRows}
        currentPage={currentPage}
        perPage={perPage}
        changeValue={changeValue}
        isLoading={isLoading}
      />
    );
  };

  return (
    <div className={'recordings-table__container inner-scroll'}>
      {isLoading && <i className="fa fa-spinner fa-spin fa-2x" />}
      {!isLoading && getContent()}
    </div>
  );
};
