import * as types from '../actions/stockOutReportsAction';

const initialState = {
  restaurantUuid: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: null,
  material: null,
  limit: '20',
  sortBy: 'startTime',
  orderDirection: 'DESC',
  totalSortBy: 'total',
  totalOrderDirection: 'DESC',
  loading: true,
  isFileLoading: false,
  scrollLoading: false,
  cursorDirection: 'FIRST',
  cursor: null,
  existNext: false,
  existPrevious: false,
  changePage: false,
  data: [],
  chartLoading: false,
  totalLoading: false,
  pieContent: null,
  graphContent: [],
  chartHeader: [],
  stockTab: 'list',
};

const stockOutReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_STOCK_OUT_REPORTS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_STOCK_OUT_REPORTS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_STOCK_OUT_REPORTS:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_STOCK_OUT_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
        data:
          action.params.cursorDirection === 'NEXT'
            ? [...state.data, ...action.payload.content]
            : action.payload.content,
      };

    case types.GET_STOCK_OUT_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
      };

    case types.GET_STOCK_OUT_REPORTS_ANALYTIC:
      return {
        ...state,
        chartLoading: true,
        graphContent: [],
      };

    case types.GET_STOCK_OUT_REPORTS_ANALYTIC_SUCCESS:
      return {
        ...state,
        chartLoading: false,
        chartHeader: action.payload.length !== 0 ? Object.keys(action.payload[0].values[0][1]) : [],
        graphContent:
          action.payload.length !== 0
            ? action.payload[0].values.map((item, index) => [
                new Date(item[0] * 1000),
                ...Object.keys(item[1]).map((material) => {
                  return action.payload.reduce(function (sum, elem) {
                    return (
                      sum + Number(elem.values[index][1][material] > 0 ? elem.values[index][1][material] : 0)
                    );
                  }, 0);
                }),
              ])
            : [[0, 0]],
      };

    case types.GET_STOCK_OUT_REPORTS_ANALYTIC_ERROR:
      return {
        ...state,
        chartLoading: false,
      };

    case types.GET_STOCK_OUT_REPORTS_TOTAL:
      return {
        ...state,
        totalLoading: true,
        pieContent: null,
      };

    case types.GET_STOCK_OUT_REPORTS_TOTAL_SUCCESS:
      return {
        ...state,
        totalLoading: false,
        pieContent:
          action.payload !== null
            ? {
                totalValues: action.payload.map((item) => ({
                  restaurant: item.material,
                  value: parseFloat((item.time / 60).toFixed(2)),
                })),
              }
            : null,
      };

    case types.GET_STOCK_OUT_REPORTS_TOTAL_ERROR:
      return {
        ...state,
        totalLoading: false,
      };
    case types.LOAD_STOCK_OUT_FILE:
      return {
        ...state,
        isFileLoading: true,
      };

    case types.LOAD_STOCK_OUT_FILE_SUCCESS:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.LOAD_STOCK_OUT_FILE_ERROR:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.RESET_STOCK_OUT_REPORTS_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default stockOutReportsReducer;
