import { deleteTabletLog, getTabletLogs } from '../../api/tablets';

export const CHANGE_TABLET_LOGS_VALUE = 'CHANGE_TABLET_LOGS_VALUE';
export const CHANGE_TABLET_LOGS_SEVERAL_VALUES = 'CHANGE_TABLET_LOGS_SEVERAL_VALUES';

export const RESET_TABLET_LOGS_STORE = 'RESET_TABLET_LOGS_STORE';

export const GET_TABLET_LOGS = 'GET_TABLET_LOGS';
export const GET_TABLET_LOGS_SUCCESS = 'GET_TABLET_LOGS_SUCCESS';
export const GET_TABLET_LOGS_ERROR = 'GET_TABLET_LOGS_ERROR';

export const DELETE_TABLET_LOG = 'DELETE_TABLET_LOG';
export const DELETE_TABLET_LOG_SUCCESS = 'DELETE_TABLET_LOG_SUCCESS';
export const DELETE_TABLET_LOG_ERROR = 'DELETE_TABLET_LOG_ERROR';

export const getTabletLogsAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_TABLET_LOGS, payload: params.cursorDirection === 'FIRST' });
    try {
      const response = await getTabletLogs(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_TABLET_LOGS_SUCCESS, payload: results, params });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TABLET_LOGS_ERROR });
    }
  };
};

export const deleteTabletLogAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_TABLET_LOG });
    try {
      const response = await deleteTabletLog(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_TABLET_LOG_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_TABLET_LOG_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_TABLET_LOGS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_TABLET_LOGS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_TABLET_LOGS_STORE,
});
