import request from '../utils/request';

export function getFryerEvents(params, signal) {
  return request({
    url: '/fryerTimerCycleHistory',
    method: 'get',
    data: params,
    signal,
  });
}

export function getFryerTotal(params, signal) {
  return request({
    url: '/fryerTimerCycleHistory/total',
    method: 'get',
    data: params,
    signal,
  });
}

export function getExelExport(params) {
  return request({
    url: '/fryerTimerCycleHistory/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}

export function deleteFryer(uuid) {
  return request({
    url: `/fryerTimerCycleHistory/${uuid}`,
    method: 'delete',
  });
}
