export const barChartOptions = {
  animation: { startup: true, duration: 1000, easing: 'out' },
  tooltip: { isHtml: true },
  colors: ['#DD0031'],
  hAxis: {
    ticks: [0],
  },
  vAxis: {
    allowContainerBoundaryTextCutoff: true,
    maxTextLines: 2,
  },
  legend: { position: 'none' },
  annotations: {
    textStyle: {
      maxWidth: '100%',
    },
    annotationsWidth: 50,
  },
};

export const barChartHeader = [
  'Restaurant',
  'Count',
  { type: 'string', role: 'tooltip', p: { html: true } },
  {
    sourceColumn: 0,
    role: 'annotation',
    type: 'string',
    calc: 'stringify',
  },
];
