export const packagesColumns = [
  { label: '', width: '50px', mobile: true },
  {
    label: 'Type',
    value: 'type',
    mobile: true,
  },
  {
    label: 'Weight (lb)',
    value: 'weight',
  },
  {
    label: '',
    value: '',
    mobile: true,
  },
];

export const settingChickenTabs = [
  {
    label: 'Materials',
    value: 'materials',
  },
  {
    label: 'Containers',
    value: 'containers',
  },
];

export const settingPotatoTabs = [
  {
    label: 'Materials',
    value: 'materials',
  },
  {
    label: 'Packages',
    value: 'packages',
  },
];

export const presetSelectColors = [
  '#D0021B',
  '#F5A623',
  '#F8E71C',
  '#8B572A',
  '#7ED321',
  '#417505',
  '#BD10E0',
  '#FA33C5',
  '#9013FE',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#000000',
  '#4A4A4A',
  '#9B9B9B',
  '#FFFFFF',
];

export const chickenTags = [
  {
    label: 'Catering',
    value: 'CATERING',
  },
  {
    label: 'Production',
    value: 'PRODUCTION',
  },
  {
    label: 'Cookable',
    value: 'COOKABLE',
  },
];
