import * as types from '../actions/adjustmentAction';

const initialState = {
  restaurantUuid: null,
  material: [],
  data: [],
  rulesData: [],
  loading: false,
  rulesLoading: false,
  adjustmentTab: 'rules',
};

const adjustmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_ADJUSTMENT_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_ADJUSTMENT_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_ADJUSTMENT:
      return {
        ...state,
        loading: true,
      };

    case types.GET_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.scales,
      };

    case types.GET_ADJUSTMENT_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
      };

    case types.GET_ADJUSTMENT_RULES:
      return {
        ...state,
        rulesLoading: true,
      };

    case types.GET_ADJUSTMENT_RULES_SUCCESS:
      return {
        ...state,
        rulesLoading: false,
        rulesData: action.payload,
      };

    case types.GET_ADJUSTMENT_RULES_ERROR:
      return {
        ...state,
        rulesLoading: false,
        rulesData: [],
      };

    case types.RESET_ADJUSTMENT_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default adjustmentReducer;
