import { useEffect, useLayoutEffect } from 'react';
import {
  activatedAnimationName,
  animationNames,
  cancelAnimationName,
  completedAnimationName,
  delayedAnimationName,
} from './constants';

const borderWidthAnimationName = 'borderWidthAnimation';
const stashedTime = {};

const syncAnimation = (animationName, animations) => {
  const firstAnimation = animations[0];
  const time = stashedTime[animationName];
  if (firstAnimation) {
    if (time) {
      firstAnimation.currentTime = time;
    }

    for (let i = 1; i < animations.length; i++) {
      animations[i].currentTime = firstAnimation.currentTime;
    }
  }
};
const getAnimations = () => {
  const animations = document.getAnimations();
  const widthBorderAnimations = animations.filter((animation) =>
    animationNames.some((name) => name === animation.animationName)
  );

  const cancelAnimations = animations.filter((animation) => animation.animationName === cancelAnimationName);
  const activatedAnimations = animations.filter(
    (animation) => animation.animationName === activatedAnimationName
  );
  const delayedAnimations = animations.filter(
    (animation) => animation.animationName === delayedAnimationName
  );
  const completedAnimations = animations.filter(
    (animation) => animation.animationName === completedAnimationName
  );

  return {
    widthBorderAnimations,
    cancelAnimations,
    activatedAnimations,
    delayedAnimations,
    completedAnimations,
  };
};

export const useSyncAnimations = (rows) => {
  useLayoutEffect(() => {
    const {
      widthBorderAnimations,
      cancelAnimations,
      activatedAnimations,
      delayedAnimations,
      completedAnimations,
    } = getAnimations();
    syncAnimation(borderWidthAnimationName, widthBorderAnimations);
    syncAnimation(cancelAnimationName, cancelAnimations);
    syncAnimation(activatedAnimationName, activatedAnimations);
    syncAnimation(delayedAnimationName, delayedAnimations);
    syncAnimation(completedAnimationName, completedAnimations);

    return () => {
      stashedTime[borderWidthAnimationName] = widthBorderAnimations[0]?.currentTime;
      stashedTime[cancelAnimationName] = cancelAnimations[0]?.currentTime;
      stashedTime[activatedAnimationName] = activatedAnimations[0]?.currentTime;
      stashedTime[delayedAnimationName] = delayedAnimations[0]?.currentTime;
      stashedTime[completedAnimationName] = completedAnimations[0]?.currentTime;
    };
  }, [rows]);

  useEffect(() => {
    const synchronizedAnimation = () => {
      //use setTimeout because in firefox document.hidden is true in first time when we reload page and stay on tab
      setTimeout(() => {
        const {
          widthBorderAnimations,
          cancelAnimations,
          activatedAnimations,
          delayedAnimations,
          completedAnimations,
        } = getAnimations();

        if (!document.hidden) {
          syncAnimation(borderWidthAnimationName, widthBorderAnimations);
          syncAnimation(cancelAnimationName, cancelAnimations);
          syncAnimation(activatedAnimationName, activatedAnimations);
          syncAnimation(delayedAnimationName, delayedAnimations);
          syncAnimation(completedAnimationName, completedAnimations);
        }
      });
    };

    document.addEventListener('visibilitychange', synchronizedAnimation);

    return () => {
      document.removeEventListener('visibilitychange', synchronizedAnimation);
    };
  }, []);
};
