export const checkAndUpdateDimensions = (position, size, paddingX = 0, paddingY = 0, minWidth, minHeight) => {
  let left = position.left;
  let top = position.top;
  let width = size.width;
  let height = size.height;

  if (width > window.innerWidth - 2 * paddingX) {
    width = window.innerWidth - 2 * paddingX;
    left = paddingX;
  } else if (left < paddingX) {
    left = paddingX;
  } else if (left + width > window.innerWidth - paddingX) {
    left = left - (left + width - (window.innerWidth - paddingX));
  }

  if (height > window.innerHeight - 2 * paddingY) {
    height = window.innerHeight - 2 * paddingY;
    top = paddingY;
  } else if (top < paddingY) {
    top = paddingY;
  } else if (top + height > window.innerHeight - paddingY) {
    top = top - (top + height - window.innerHeight - paddingY);
  }

  if (width < minWidth) {
    width = minWidth;
  }
  if (height < minHeight) {
    height = minHeight;
  }

  return { left, top, width, height };
};

export const checkScreenWidth = () => {
  if (window.innerWidth < 575) {
    return 'mobile';
  } else return 'desktop';
};
