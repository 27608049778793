import React, { useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import { debounce } from 'lodash';

import { ReactComponent as Search } from '../../../assets/img/icons/common/Search.svg';
import { ReactComponent as Close } from '../../../assets/img/icons/common/Close.svg';

import './PageSearch.scss';

export const PageSearch = ({ name, placeholder, searchInput, maxLength, onChange }) => {
  const [isInputOpened, setInputOpened] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    return () => debouncedHandleChange.cancel();
  }, []);

  const handleChange = (event) => {
    name ? onChange(name, event.target.value) : onChange(event.target.value);
  };

  const debouncedHandleChange = useMemo(() => {
    return debounce(handleChange, 300);
  }, []);

  const handleSearchClose = (event) => {
    debouncedHandleChange.cancel();
    event.stopPropagation();
    name ? onChange(name, '') : onChange('');
    setInputOpened(false);
  };

  return (
    <div
      className={cx('page-search__container', { closed: !isInputOpened, focused: isInputFocused })}
      onClick={() => setInputOpened(true)}
    >
      <Search />
      {isInputOpened && (
        <>
          <input
            className="page-search__input"
            type="text"
            placeholder={placeholder || 'Search'}
            maxLength={maxLength || 255}
            onChange={debouncedHandleChange}
            autoFocus={true}
            onFocus={() => setInputFocused(true)}
            onBlur={() => {
              setInputFocused(false);
              (!searchInput || searchInput.length === 0) && setInputOpened(false);
            }}
          />
          <button className="page-search__close-btn" onClick={handleSearchClose}>
            <Close />
          </button>
        </>
      )}
    </div>
  );
};
