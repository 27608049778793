import cx from 'classnames';
import { ReactComponent as ChevronDown } from '../../../../assets/img/icons/common/ChevroneDown.svg';
import { ReactComponent as ChevronRight } from '../../../../assets/img/icons/common/ChevroneRight.svg';
import { BodyCell } from '../../Cells';
import { OpenedRow } from '../OpenedRow';

export const MobileTableContent = ({ rows, openedRows, checkOpenedRows, headerOptions, hideMobileCell }) => {
  return rows.map((row) => {
    const isOpen = openedRows.includes(row.id);
    return (
      <>
        <tr
          key={row.id}
          className={cx('mobile-table__tbody-row', {
            selectable: row.onSelectClick,
            opened: isOpen,
          })}
          style={{ height: row.minHeight || 'auto' }}
          onClick={(event) => (row.onSelectClick ? row.onSelectClick(event) : {})}
        >
          {row.cells
            .filter((it) => it.mobile && it.beforeChevron)
            .map((cell, index) => (
              <BodyCell key={`${row.id}-${index}`} cell={cell} />
            ))}
          {!hideMobileCell && (
            <td className="mobile-table__body-cell">
              <button
                className="mobile-table__menu-cell__button chevron"
                onClick={(e) => checkOpenedRows(e, row.id)}
              >
                {isOpen ? <ChevronDown /> : <ChevronRight />}
              </button>
            </td>
          )}
          {row.cells
            .filter((it) => it.mobile && !it.beforeChevron)
            .map((cell, index) => (
              <BodyCell key={`${row.id}-${index}`} cell={cell} />
            ))}
        </tr>
        {isOpen && row.cells.filter((it) => !it.mobile).length !== 0 ? (
          <OpenedRow row={row} headerOptions={headerOptions} />
        ) : (
          <></>
        )}
      </>
    );
  });
};
