import React from 'react';

import cx from 'classnames';

import './FormCheckbox.scss';

export const CheckboxWithoutLabel = ({ checked, rounded = false, disabled, onChange }) => {
  return (
    <div className="form-checkbox without-name" onClick={(event) => onChange(event)}>
      <input
        type="checkbox"
        disabled={disabled}
        readOnly
        className={cx('form-checkbox__input', { disabled: disabled })}
        checked={checked}
      />
      <div className="form-checkbox__background"></div>
      <span className={rounded ? 'rounded' : ''}></span>
    </div>
  );
};
