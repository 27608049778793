import { useMemo } from 'react';

const range = (start, end) => {
  let length = end - start + 1;

  return Array.from({ length }, (_, i) => start + i);
};

export const usePagination = ({ totalCount, perPage, siblingCount = 1, currentPage, pages = 3 }) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / perPage);
    const totalPageNumbers = siblingCount + (pages + 2 * siblingCount);

    if (totalPageNumbers >= totalPageCount) {
      return range(0, totalPageCount - 1);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 0);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount - 1);

    const shouldShowLeftDots = leftSiblingIndex > 1;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 3;

    const firstPageIndex = 0;
    const lastPageIndex = totalPageCount - 1;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = pages + 2 * siblingCount;
      let leftRange = range(0, leftItemCount - 1);

      return [...leftRange, 'DOTS', totalPageCount - 1];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = pages + 2 * siblingCount;
      let rightRange = range(totalPageCount - rightItemCount, totalPageCount - 1);
      return [firstPageIndex, 'DOTS', ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, 'DOTS', ...middleRange, 'DOTS', lastPageIndex];
    }
  }, [totalCount, perPage, siblingCount, currentPage]);

  return paginationRange;
};
