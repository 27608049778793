import React from 'react';

import { isEmpty } from 'lodash';
import moment from 'moment';
import { ReactComponent as Clock } from '../../../../assets/img/icons/common/Clock.svg';
import { ReactComponent as Calendar } from '../../../../assets/img/icons/datepicker/Calendar.svg';
import { EmptyContainer } from '../../../EmptyContainer';
import { Preloader } from '../../../Preloader/Preloader';
import { TablePagination } from '../../../TablePagination/TablePagination';

export const UnusualTable = ({ rows, totalRows, currentPage, perPage, isLoading, changeValue }) => {
  if (isLoading) {
    return (
      <div className="icon-view__loader">
        <Preloader big={true} />
      </div>
    );
  }

  return !isEmpty(rows) ? (
    <>
      <div className="unusual-table__container">
        {rows.map((item, i) => (
          <div className="unusual-table__item" key={item.id || i}>
            <div className="unusual-table__component">{item.cells[0]}</div>
            <div className="unusual-table__description">
              {item.cells[1] || ''}
              <div>
                <Calendar />
                <span>{moment(item.cells[2]).format('MMM D YYYY') || ''}</span>
              </div>
              <div>
                <Clock />
                <span>{moment(item.cells[2]).format('LTS')}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <TablePagination
        isLoading={isLoading}
        className="icon-view__pagination"
        mobile
        totalRows={totalRows}
        page={currentPage}
        perPage={perPage}
        siblingCount={1}
        onChangePage={(value) => changeValue('page', value)}
        onChangePerPage={(value) => changeValue('limit', value)}
      />
    </>
  ) : (
    <EmptyContainer />
  );
};
