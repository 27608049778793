import { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';

import cx from 'classnames';
import moment from 'moment';
import 'moment-timezone';

import { ReactComponent as Clear } from '../../../assets/img/icons/datepicker/Clear.svg';
import { intervalsDate } from '../../../constants/analytics.constants';
import { getRelativeDate } from '../../../utils';
import { getLocalTimestamp } from '../../../utils/commonMethods';
import { TimeControlWithOptions } from '../../TimeControls/TimeControlWithOptions';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../DateTimepicker.scss';

export const CustomCalendar = ({
  selectMax = intervalsDate.length,
  selectMin = 1,
  calendarMax,
  addNotification,
  start,
  end,
  allowFuture,
  timeInterval = null,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  withTime = false,
  onChange,
  onCancel,
}) => {
  const [time, setTime] = useState(['00:00', '23:59']);
  const [activeCalendarItem, setActiveCalendarItem] = useState('');
  const [calendarState, setCalendarState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (timeInterval !== null) {
      setCalendarState([
        {
          startDate: new Date(getLocalTimestamp(Date.now(), timezone) - timeInterval.value * 1000),
          endDate: new Date(getLocalTimestamp(Date.now(), timezone)),
          key: 'selection',
        },
      ]);
      if (withTime) {
        setTime([
          moment(getLocalTimestamp(Date.now(), timezone) - timeInterval.value * 1000).format('HH:mm'),
          moment(getLocalTimestamp(Date.now(), timezone)).format('HH:mm'),
        ]);
      }
      setActiveCalendarItem(timeInterval.icon);
    } else if (start !== null && start !== 'select' && end !== null) {
      setCalendarState([
        {
          startDate: new Date(start),
          endDate: new Date(end),
          key: 'selection',
        },
      ]);
      if (withTime) {
        setTime([
          moment(start).format('HH:mm'),
          moment(
            moment(end).isSame(moment().tz(timezone), 'day') && timeInterval !== null
              ? new Date(getLocalTimestamp(Date.now(), timezone))
              : new Date(end)
          ).format('HH:mm'),
        ]);
      }
      setActiveCalendarItem('custom');
    } else if (withTime) {
      setTime([time[0], moment(getLocalTimestamp(Date.now(), timezone)).format('HH:mm')]);
    }

    setTimeout(() => document.addEventListener('click', checkCalendarClick), 100);
  }, []);

  const onChangeAction = (startDate, endDate = null) => {
    onChange(startDate, endDate);
  };

  function checkCalendarClick(e) {
    if (
      !(e.path || (e.composedPath && e.composedPath())).includes(
        document.getElementById('datepicker_custom_range__container')
      )
    ) {
      onCancel();
      return document.removeEventListener('click', checkCalendarClick);
    }
  }

  const applyDates = () => {
    if (withTime) {
      onChangeAction(
        new Date(moment(calendarState[0].startDate).format('YYYY-MM-DD') + 'T' + time[0]).getTime(),
        new Date(moment(calendarState[0].endDate).format('YYYY-MM-DD') + 'T' + time[1]).getTime()
      );
    } else {
      onChangeAction(calendarState[0].startDate, calendarState[0].endDate);
    }
  };

  const checkMaxDate = () => {
    if (
      calendarMax !== null &&
      calendarState[0].endDate.getTime() - calendarState[0].startDate.getTime() > calendarMax
    ) {
      addNotification(
        'danger',
        `Date range must be 
          less than ${getRelativeDate(Date.now() - calendarMax)}`.replace('ago', ''),
        'Error',
        3000
      );
      return ' disabled';
    } else return '';
  };

  const changeCalendarState = (item) => {
    setCalendarState([
      {
        startDate: item.startDate,
        endDate: item.endDate,
        key: item.key,
      },
    ]);
    if (
      item.endDate.toDateString() === new Date().toDateString() &&
      typeof intervalsDate.find(
        (interval) => interval.value === moment(item.endDate).diff(moment(item.startDate), 'seconds')
      ) !== 'undefined'
    ) {
      setActiveCalendarItem(
        intervalsDate.find(
          (interval) => interval.value === moment(item.endDate).diff(moment(item.startDate), 'seconds')
        ).icon
      );
    } else {
      setActiveCalendarItem('custom');
    }
    setTime([
      moment(item.startDate).format('HH:mm'),
      item.endDate.toDateString() === new Date().toDateString()
        ? moment().tz(timezone).format('HH:mm')
        : '23:59',
    ]);
  };

  const setInitialCalendarState = () => {
    setActiveCalendarItem('');
    setCalendarState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    onCancel();
  };

  const checkYear = (dates, withoutTime = false) => {
    let withoutYear = withoutTime ? 'MMM D' : 'MMM D, h:mm a';
    let withYear = withoutTime ? 'YYYY MMM D' : 'YYYY MMM D, h:mm a';
    return dates.every((date) => new Date(date).getFullYear() === new Date().getFullYear())
      ? withoutYear
      : withYear;
  };

  return (
    <div className="datepicker-custom-range" id="datepicker_custom_range__container">
      <div className="datepicker-custom-range__body">
        <ul className="datepicker-ranges datepicker-ranges__scrolled">
          {intervalsDate.slice(selectMin, selectMax).map((item, i) => (
            <li
              key={i}
              className={`datepicker-ranges-item ${
                activeCalendarItem === item.icon ? 'datepicker-ranges-item__active' : ''
              }`}
              onClick={() => {
                onChangeAction(item.icon);
              }}
            >
              {item.label}
            </li>
          ))}
          <li
            className={`datepicker-ranges-item datepicker-ranges-item__disabled ${
              activeCalendarItem === 'custom' ? 'datepicker-ranges-item__active' : ''
            }`}
          >
            Custom
          </li>
          <li
            className="datepicker-ranges-item datepicker-ranges-item__clear"
            onClick={() => {
              onChangeAction('select', 'select');
            }}
          >
            <Clear />
            Clear
          </li>
        </ul>
        <DateRange
          className="datepicker-custom-range__calendar"
          maxDate={allowFuture ? undefined : new Date()}
          minDate={new Date(2019, 0, 0, 0)}
          ranges={calendarState}
          fixedHeight={true}
          onChange={(item) => {
            changeCalendarState(item.selection);
          }}
          showMonthArrow={true}
          showMonthAndYearPickers={false}
          showDateDisplay={false}
          direction="horizontal"
          calendarFocus="backwards"
          preventSnapRefocus={true}
          months={2}
          rangeColors={['#dd00311a', '#3ecf8e', '#fed14c']}
        />
      </div>
      <div className={cx('datepicker-custom-range__footer', { right: !withTime })}>
        {withTime && (
          <div className="datepicker-custom-range__footer__time-container">
            <div className="time-range__group">
              <div>
                {moment(calendarState[0].startDate).format(checkYear([calendarState[0].startDate], true))}
              </div>
              <TimeControlWithOptions
                className={'time-control-with-options__datepicker'}
                interval={15}
                maxTime={
                  calendarState[0].startDate && moment(calendarState[0].startDate).isSame(moment(), 'day')
                    ? moment().tz(timezone).format('HH:mm')
                    : false
                }
                time={time[0]}
                onChange={(e) => setTime([e, time[1]])}
              />
            </div>
            to
            <div className="time-range__group">
              <div>
                {moment(calendarState[0].endDate !== null ? calendarState[0].endDate : new Date()).format(
                  checkYear([calendarState[0].endDate], true)
                )}
              </div>
              <TimeControlWithOptions
                className={'time-control-with-options__datepicker'}
                interval={15}
                maxTime={
                  calendarState[0].endDate && moment(calendarState[0].endDate).isSame(moment(), 'day')
                    ? moment().tz(timezone).format('HH:mm')
                    : false
                }
                time={time[1]}
                startTime={
                  calendarState[0].startDate &&
                  calendarState[0].endDate &&
                  moment(calendarState[0].startDate).isSame(moment(calendarState[0].endDate), 'day')
                    ? time[0]
                    : null
                }
                onChange={(e) => setTime([time[0], e])}
              />
            </div>
          </div>
        )}
        <div className="datepicker-custom-range__footer__buttons">
          <button
            className="datepicker-custom-range__footer__buttons-item datepicker-custom-range__footer__buttons-cancel"
            onClick={() => {
              setInitialCalendarState();
            }}
          >
            Cancel
          </button>
          <button
            className={`datepicker-custom-range__footer__buttons-item datepicker-custom-range__footer__buttons-apply${checkMaxDate()}`}
            onClick={() => applyDates()}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};
