import * as types from '../actions/kitchenPlayerRecordingsAction';

const initialState = {
  restaurantUuid: null,
  displayType: 'ready',
  startDate: null,
  endDate: null,
  timeInterval: null,
  intervalEnd: null,
  timezone: null,
  limit: '20',
  page: 0,
  totalResults: 0,
  sortBy: 'createdDate',
  orderDirection: 'DESC',
  search: '',
  loading: false,
  scrollLoading: false,
  readyLoading: false,
  cursorDirection: 'FIRST',
  cursor: null,
  existNext: false,
  existPrevious: false,
  recordings: [],
  readyRecordings: [],
};

const kitchenPlayerRecordingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_STREAM_RECORDINGS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_STREAM_RECORDINGS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_STREAM_RECORDINGS: {
      return {
        ...state,
        readyLoading: true,
      };
    }

    case types.GET_STREAM_RECORDINGS_SUCCESS: {
      const preparedRecordings = action.payload.content.map((item) => ({
        ...item,
        metadata: JSON.parse(item.metadata),
        preview: { image: null, loading: true, error: false },
        isDownloading: false,
        progress: 0,
        controller: null,
      }));
      return {
        ...state,
        readyLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
        readyRecordings:
          action.params.cursorDirection === 'NEXT'
            ? [...state.readyRecordings, ...preparedRecordings]
            : preparedRecordings,
      };
    }

    case types.GET_STREAM_RECORDINGS_ERROR: {
      return {
        ...state,
        readyLoading: false,
      };
    }

    case types.SET_STREAM_RECORDING_PREVIEW: {
      return {
        ...state,
        readyRecordings: state.readyRecordings.map((recording) =>
          recording.uuid === action.payload.uuid
            ? {
                ...recording,
                preview: action.payload.preview,
              }
            : recording
        ),
      };
    }

    case types.DOWNLOAD_STREAM_RECORDING: {
      return {
        ...state,
        readyRecordings: state.readyRecordings.map((recording) =>
          recording.uuid === action.payload.uuid
            ? {
                ...recording,
                isDownloading: true,
                progress: 0,
                controller: action.payload.controller,
              }
            : recording
        ),
      };
    }

    case types.DOWNLOAD_STREAM_RECORDING_SUCCESS:
    case types.DOWNLOAD_STREAM_RECORDING_ERROR: {
      return {
        ...state,
        readyRecordings: state.readyRecordings.map((recording) =>
          recording.uuid === action.payload.uuid
            ? {
                ...recording,
                isDownloading: false,
                progress: 0,
                controller: null,
              }
            : recording
        ),
      };
    }

    case types.SET_STREAM_RECORDING_PROGRESS: {
      return {
        ...state,
        readyRecordings: state.readyRecordings.map((recording) =>
          recording.uuid === action.payload.uuid
            ? {
                ...recording,
                progress: action.payload.progress,
              }
            : recording
        ),
      };
    }

    case types.GET_SCHEDULED_STREAM_RECORDINGS: {
      return {
        ...state,
        loading: true,
        recordings: [],
      };
    }

    case types.GET_SCHEDULED_STREAM_RECORDINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        recordings: action.payload.content,
        totalResults: action.payload.total,
      };
    }

    case types.GET_SCHEDULED_STREAM_RECORDINGS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case types.UPDATE_SCHEDULED_STREAM_RECORDING: {
      return {
        ...state,
        recordings: state.recordings.map((recording) =>
          recording.id === action.payload.recording.id ? action.payload.recording : recording
        ),
      };
    }

    case types.RESET_STREAM_RECORDINGS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default kitchenPlayerRecordingsReducer;
