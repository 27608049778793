import './LabeledValue.scss';

export const LabeledValue = ({ label, value }) => {
  return (
    <div className="labeled-value">
      <p className="label">{label}</p>
      <p className="value">{value}</p>
    </div>
  );
};
