import * as types from '../actions/eventsAnalyticsAction';

const initialState = {
  content: null,
  restaurantUuid: [],
  timezone: null,
  eventType: null,
  eventName: null,
  activeTab: 'graphic',
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  loading: false,
};

const eventsAnalyticReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_EVENTS_ANALYTIC_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_EVENTS_ANALYTIC_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_EVENTS_ANALYTIC:
      return {
        ...state,
        loading: true,
      };

    case types.GET_EVENTS_ANALYTIC_SUCCESS:
      return {
        ...state,
        loading: false,
        content:
          action.payload.length !== 0
            ? {
                restaurants:
                  action.payload.length !== 0 ? action.payload.map((item) => item.restaurantName) : [],
                graphicValues: action.payload[0].values.map((item, index) => [
                  new Date(item[0] * 1000),
                  ...action.payload.map((it) => Number(it.values[index][1])),
                ]),
                totalValues: action.payload
                  .map((item) => ({
                    restaurant: item.restaurantName,
                    value: item.values.reduce(function (sum, elem) {
                      return sum + Number(elem[1]);
                    }, 0),
                  }))
                  .sort((a, b) => b.value - a.value),
              }
            : null,
      };

    case types.GET_EVENTS_ANALYTIC_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.RESET_EVENTS_ANALYTIC_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default eventsAnalyticReducer;
