import cx from 'classnames';

import { ReactComponent as Close } from '../../assets/img/icons/common/Close.svg';

import './Modal.scss';

export const SideModal = ({
  title,
  customTitle = false,
  size = null,
  modalBody,
  confirmText = null,
  cancelText = null,
  onCancel,
  onConfirm,
  confirmDisabled = false,
  customButtons = null,
  withoutPadding = false,
}) => {
  return (
    <div
      className="modal modal__side-container"
      id="side_modal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        role="document"
        className={`modal-dialog modal__side modal__pattern-sm ${size !== null ? `modal__side-${size}` : ''}`}
      >
        <div className="modal-content">
          <div className="modal-header">
            {!customTitle ? <h4 className="modal-title">{title}</h4> : customTitle}
            <div className="modal-close__button" onClick={() => onCancel()}>
              <Close />
            </div>
          </div>
          <div className={cx('modal-body', { 'without-padding': withoutPadding })}>{modalBody}</div>
          <div className="modal-footer">
            <div className="modal-button__container">
              {customButtons !== null ? (
                customButtons
              ) : (
                <>
                  <button
                    type="button"
                    className="modal-button modal-button__cancel"
                    onClick={() => onCancel()}
                  >
                    {cancelText !== null ? cancelText : 'Cancel'}
                  </button>
                  <button
                    className={cx('modal-button modal-button__confirm', { disabled: confirmDisabled })}
                    type="submit"
                    disabled={confirmDisabled}
                    onClick={() => onConfirm()}
                  >
                    {confirmText !== null ? confirmText : 'Save'}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
