import * as types from '../actions/potatoDashAction';

const initialState = {
  restaurantUuid: null,
  startDate: 'select',
  endDate: 'select',
  timeInterval: null,
  intervalEnd: null,
  timezone: null,
  period: 'DAY',
  potatoChutes: ['1', '2'],
  packageTypes: [],
  charts: [],
};

const potatoDashReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_POTATO_DASHBOARD_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_POTATO_DASHBOARD_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case types.RESET_POTATO_CHARTS_DATA:
      return {
        ...state,
        charts: action.payload || [],
      };

    case types.GET_POTATO_CHART: {
      return {
        ...state,
        charts: state.charts.map((chart) =>
          chart.order === action.order ? { ...chart, isLoading: true } : chart
        ),
      };
    }

    case types.ADD_POTATO_CHART:
      return {
        ...state,
        charts: state.charts
          .map((chart) => {
            return { isLoading: false, ...(action.chart.order === chart.order ? action.chart : chart) };
          })
          .sort((a, b) => a.order - b.order),
      };

    case types.RESET_POTATO_DASHBOARD_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default potatoDashReducer;
