import React from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import './preloader.scss';

export const Preloader = ({ big = false, page = false }) => (
  <div className={cx('d-flex justify-content-center w-100', { 'h-100 align-items-center': page })}>
    <FontAwesomeIcon icon={faSpinner} className={big ? 'big--spinner' : ''} spin />
  </div>
);
