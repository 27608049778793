import request from '../utils/request';

export function getScales(uuid, params, signal) {
  return request({
    url: `/restaurants/${uuid}/scales`,
    method: 'get',
    signal,
    data: params,
  });
}

export function updateScales(uuid, params) {
  return request({
    url: `/restaurants/${uuid}/scales`,
    method: 'put',
    data: params,
  });
}

export function getRules(params, signal) {
  return request({
    url: `/adjustmentRules`,
    method: 'get',
    signal,
    data: params,
  });
}

export function addRule(data) {
  return request({
    url: `/adjustmentRules`,
    method: 'post',
    data,
  });
}

export function updateRule(uuid, data) {
  return request({
    url: `/adjustmentRules/${uuid}`,
    method: 'put',
    data,
  });
}

export function deleteRule(uuid) {
  return request({
    url: `/adjustmentRules/${uuid}`,
    method: 'delete',
  });
}
