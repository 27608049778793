import request from '../utils/request';

export function getRecording(restaurantUuid, recordingId) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/streams/${recordingId}`,
    method: 'get',
  });
}

export function getRecordings(restaurantUuid, params, signal) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/streams`,
    method: 'get',
    data: params,
    signal,
  });
}

export function scheduleRecordings(restaurantUuid, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/streams`,
    method: 'post',
    data: params,
  });
}

export function rescheduleRecording(restaurantUuid, recordingId, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/streams/${recordingId}`,
    method: 'put',
    data: params,
  });
}

export function stopRecording(restaurantUuid, recordingId) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/streams/${recordingId}/stop`,
    method: 'post',
  });
}

export function deleteRecording(restaurantUuid, recordingId) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/streams/${recordingId}`,
    method: 'delete',
  });
}

export function getStreams(restaurantUuid) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/streams/live`,
    method: 'get',
  });
}

export function getStream(restaurantUuid, streamName) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/streams/${streamName}/live`,
    method: 'get',
  });
}

export function getStreamThumbnail(restaurantUuid, streamName, params) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/streams/${streamName}/thumbnail?width=320&height=240&image_type=png`,
    method: 'get',
    responseType: 'blob',
    data: params,
  });
}
