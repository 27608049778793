import { getAuditLog } from '../../api/auditLog';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_AUDIT_LOG_VALUE = 'CHANGE_AUDIT_LOG_VALUE';
export const CHANGE_AUDIT_LOG_SEVERAL_VALUES = 'CHANGE_AUDIT_LOG_SEVERAL_VALUES';

export const RESET_AUDIT_LOG_STORE = 'RESET_AUDIT_LOG_STORE';

export const GET_AUDIT_LOG = 'GET_AUDIT_LOG';
export const GET_AUDIT_LOG_SUCCESS = 'GET_AUDIT_LOG_SUCCESS';
export const GET_AUDIT_LOG_ERROR = 'GET_AUDIT_LOG_ERROR';

export const getAuditLogAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_AUDIT_LOG, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await getAuditLog(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_AUDIT_LOG_SUCCESS, payload: results, params: params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_AUDIT_LOG_ERROR });
        }
      }
    };
  };
})();

export const changeValue = (key, value) => ({
  type: CHANGE_AUDIT_LOG_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_AUDIT_LOG_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_AUDIT_LOG_STORE,
});
