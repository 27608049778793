export const pieChartColumns = [
  {
    label: '',
    value: 'color',
    width: '50px',
  },
  {
    label: 'Restaurant',
    value: 'restaurant',
  },
  {
    label: 'Count',
    value: 'count',
  },
  {
    label: 'Share',
    value: 'share',
  },
];

export const pieChartHeader = ['Restaurant', 'Qty', { type: 'string', role: 'tooltip', p: { html: true } }];
export const pieChartOptions = {
  tooltip: { isHtml: true },
  pieHole: 0.8,
  pieSliceText: 'none',
  legend: { position: 'none' },
  backgroundColor: {},
  chartArea: { width: '90%', height: '90%' },
};
