import request from '../utils/request';

export function getModes(params) {
  return request({
    url: `/pnp/modes`,
    method: 'get',
    data: params,
  });
}

export function getZones(uuid) {
  return request({
    url: `/pnp/zones`,
    method: 'get',
    data: uuid,
  });
}

export function getZoneStreams(zone) {
  return request({
    url: `/pnp/zones/${zone}/streams`,
    method: 'get',
  });
}

export function startStream(zone, params) {
  return request({
    url: `/pnp/zones/${zone}/start`,
    method: 'post',
    data: params,
  });
}

export function stopStream(zone, params) {
  return request({
    url: `/pnp/zones/${zone}/stop`,
    method: 'post',
    data: params,
  });
}

export function getArucoRestaurants(params) {
  return request({
    url: `/pnp/aruco-validation/restaurants`,
    method: 'get',
    data: params,
  });
}

export function getArucoStreams(params) {
  return request({
    url: `/pnp/aruco-validation/streams`,
    method: 'get',
    data: params,
  });
}
