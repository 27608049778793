import request from '../utils/request';

export function getStockOutReports(params, signal) {
  return request({
    url: '/stockOut/list',
    method: 'get',
    data: params,
    signal,
  });
}

export function getStockOutTotal(params, signal) {
  return request({
    url: '/stockOut/total',
    method: 'get',
    data: params,
    signal,
  });
}

export function getExcelExport(params) {
  return request({
    url: '/stockOut/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}

export function getAnalytics(params, signal) {
  return request({
    url: '/stockOut/analytic',
    method: 'get',
    data: params,
    signal,
  });
}
