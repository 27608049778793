import cx from 'classnames';

import { ReactComponent as Close } from '../../assets/img/icons/common/Close.svg';

import './Modal.scss';

export const ModalPattern = ({
  title,
  TitleComponent,
  scrolled = false,
  size = null,
  modalBody,
  confirmText = null,
  cancelText = null,
  onClose,
  onCancel,
  onConfirm,
  confirmDisabled = false,
  customButtons = null,
  smallButtons = false,
}) => {
  return (
    <div
      className="modal fade show"
      id="ModalPassword"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="ModalPasswordLabel"
      aria-hidden="true"
    >
      <div
        role="document"
        className={`modal-dialog modal-dialog-centered modal__pattern ${
          size !== null ? `modal__pattern-${size}` : ''
        }`}
      >
        <div className="modal-content">
          <div className="modal-header">
            {TitleComponent ? TitleComponent : <h4 className="modal-title">{title}</h4>}
            <div className="modal-close__button" onClick={onClose ? onClose : onCancel}>
              <Close />
            </div>
          </div>
          <div className={cx('modal-body', { scrolled: scrolled })}>{modalBody}</div>
          <div className="modal-footer">
            <div className="modal-button__container">
              {customButtons !== null ? (
                customButtons
              ) : (
                <>
                  <button
                    type="button"
                    className={cx('modal-button modal-button__cancel', { cut: smallButtons })}
                    onClick={() => onCancel()}
                  >
                    {cancelText !== null ? cancelText : 'Cancel'}
                  </button>
                  <button
                    className={cx('modal-button modal-button__confirm', {
                      disabled: confirmDisabled,
                      cut: smallButtons,
                    })}
                    type="submit"
                    disabled={confirmDisabled}
                    onClick={() => onConfirm()}
                  >
                    {confirmText !== null ? confirmText : 'Save'}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
