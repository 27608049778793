import { useState } from 'react';
import { connect } from 'react-redux';

import { Condition, FormInput } from '../index';
import { ModalPattern } from './ModalPattern';

import { changePasswordAction, changeValue } from '../../redux/actions/loginAction';

import './Modal.scss';

//eslint-disable-next-line no-useless-escape
const passwordRegExp = /[\*\[.'-,/_!:?;|~`><@#$%^&+=*)(\\\]]/g;

function ChangePasswordModal({ addNotification, setOpened, changePasswordAction }) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const changePassword = () => {
    setOldPassword(oldPassword.trim());
    setNewPassword(newPassword.trim());
    changePasswordAction(
      {
        oldPassword: oldPassword,
        newPassword: newPassword,
      },
      {
        onSuccess: () => {
          addNotification('success', 'Password successfully changed', 'Success', 3000);
          changeValue('changePasswordClicked', false);
          setOpened(false);
        },
        onError: (message) => addNotification('danger', message, 'Error', 3000),
      }
    );
  };

  return (
    <ModalPattern
      size={'sm'}
      title="Change Password"
      onCancel={() => {
        changeValue('changePasswordClicked', false);
        setOpened(false);
      }}
      onConfirm={() => {
        changePassword();
      }}
      modalBody={
        <>
          <FormInput
            className="mb-12"
            placeholder="Current Password"
            type="password"
            value={oldPassword}
            onChange={(value) => setOldPassword(value)}
          />
          <FormInput
            className="mb-12"
            placeholder="New Password"
            type="password"
            value={newPassword}
            onChange={(value) => setNewPassword(value)}
          />
          <Condition
            condition={/(?=.*[a-z])(?=.*[A-Z])/g.test(newPassword)}
            text="at least 1 uppercase letter and 1 lowercase letter"
          />
          <Condition condition={/[0-9]/g.test(newPassword)} text="at least one digit" />
          <Condition condition={newPassword.match(passwordRegExp)} text="at least one special character" />
          <Condition condition={newPassword.length > 9} text="at least 10 characters" />
          <div className="modal__pattern-description mt-10">
            It must not be similar to previous passwords, not a dictionary word, not similar to e-mail and
            display name.
          </div>
        </>
      }
      confirmText="Save"
      confirmDisabled={
        !/(?=.*[a-z])(?=.*[A-Z])/g.test(newPassword) ||
        !/[0-9]/g.test(newPassword) ||
        !newPassword.match(passwordRegExp) ||
        newPassword.length < 10 ||
        oldPassword.length === 0
      }
    />
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    changePasswordAction: (data, actions) => dispatch(changePasswordAction(data, actions)),
    changeLoginValue: (key, value) => dispatch(changeValue(key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
