import request from '../utils/request';

export function getReports(params, signal) {
  return request({
    url: '/report',
    method: 'get',
    data: params,
    signal,
  });
}

export function deleteReport(uuid) {
  return request({
    url: `/report/${uuid}`,
    method: 'delete',
  });
}

export function downloadReport(uuid, signal, onProgress) {
  return request({
    url: `/report/${uuid}/download`,
    method: 'get',
    responseType: 'arraybuffer',
    signal,
    onProgress,
  });
}
