import * as types from '../actions/identicalMarkersAction';

const initialState = {
  restaurantUuid: null,
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: null,
  limit: '20',
  page: 0,
  totalResults: 0,
  loading: true,
  chartLoading: false,
  graphContent: [],
  data: [],
};

const identicalMarkersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_IDENTICAL_MARKERS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_IDENTICAL_MARKERS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.CHANGE_IDENTICAL_MARKERS_CURRENT_IMAGE:
      return {
        ...state,
        data: state.data.map((item, index) =>
          index === action.payload.index ? { ...item, currentImage: action.payload.value } : item
        ),
      };

    case types.SET_IDENTICAL_MARKERS_EVENT_IMAGE:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.eventId === action.payload.eventId) {
            const updatedImages = [...item.images];
            updatedImages.splice(action.payload.index, 1, action.payload.image);
            return { ...item, images: updatedImages };
          } else {
            return item;
          }
        }),
      };

    case types.GET_IDENTICAL_MARKERS:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case types.GET_IDENTICAL_MARKERS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
        data: action.payload.content.map((item) => ({
          ...item,
          currentImage: 0,
          images: item.frames.map(() => null),
        })),
      };

    case types.GET_IDENTICAL_MARKERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.GET_IDENTICAL_MARKERS_ANALYTIC:
      return {
        ...state,
        chartLoading: true,
        graphContent: [],
      };

    case types.GET_IDENTICAL_MARKERS_ANALYTIC_SUCCESS:
      return {
        ...state,
        chartLoading: false,
        graphContent:
          action.payload.length !== 0
            ? action.payload[0].values.map((item, index) => [
                new Date(item[0] * 1000),
                action.payload.reduce(function (sum, elem) {
                  return sum + Number(elem.values[index][1]);
                }, 0),
              ])
            : [[0, 0]],
      };

    case types.GET_IDENTICAL_MARKERS_ANALYTIC_ERROR:
      return {
        ...state,
        chartLoading: false,
      };

    case types.RESET_IDENTICAL_MARKERS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default identicalMarkersReducer;
