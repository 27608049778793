import './FormRadioInput.scss';

export const FormRadioInput = ({ items, value, onChange }) => {
  return (
    <div className="form-radio-group">
      {items.map((item) => {
        const { value: itemValue, description } = item;

        return (
          <div className="form__radio-container" key={itemValue}>
            <div className="form__radio">
              <input
                type="radio"
                id={itemValue}
                value={itemValue}
                checked={value === itemValue}
                onChange={(e) => onChange(Number(e.target.value))}
              />
              <label htmlFor={itemValue}>{item.label}</label>
            </div>
            {description ? <div className="form__radio-description">{description}</div> : null}
          </div>
        );
      })}
    </div>
  );
};
