import request from '../utils/request';

export function getTimersCycleHistory(params, signal) {
  return request({
    url: '/timerCycleHistory',
    method: 'get',
    data: params,
    signal,
  });
}

export function getTimersCycleHistoryTotal(params, signal) {
  return request({
    url: '/timerCycleHistory/total',
    method: 'get',
    data: params,
    signal,
  });
}

export function getExelExport(params) {
  return request({
    url: '/timerCycleHistory/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}

export function deleteTimer(uuid) {
  return request({
    url: `/timerCycleHistory/${uuid}`,
    method: 'delete',
  });
}
