import request from '../utils/request';

export function getTablets(params, signal) {
  return request({
    url: '/tablets/list',
    method: 'get',
    data: params,
    signal,
  });
}

export function getTabletLogs(params) {
  return request({
    url: '/tablets/logs',
    method: 'get',
    data: params,
  });
}

export function deleteTablet(uuid, params) {
  return request({
    url: `/tablets/${uuid}`,
    method: 'delete',
    data: params,
  });
}

export function deleteTabletLog(uuid, params) {
  return request({
    url: `/tablets/logs/${uuid}`,
    method: 'delete',
    data: params,
  });
}

export function getTabletsModels() {
  return request({
    url: '/tablets/models',
    method: 'get',
  });
}

export function getTabletsBuilds() {
  return request({
    url: '/tablets/builds',
    method: 'get',
  });
}

export function changeTabletName(uuid, params) {
  return request({
    url: `/tablets/name/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function changeTabletState(uuid, params) {
  return request({
    url: `/tablets/command/${uuid}`,
    method: 'post',
    data: params,
  });
}

export function getTabletsStreamNames() {
  return request({
    url: '/tablets/command/zones/stream',
    method: 'get',
  });
}

export function getTabletsAvailability(params, signal) {
  return request({
    url: '/availability/tablets',
    method: 'get',
    data: params,
    signal,
  });
}

export function getTabletSessions(tabletUuid) {
  return request({
    url: '/tablet-sessions',
    method: 'get',
    data: { tabletUuid },
  });
}

export function deleteTabletSessions(tabletUuid) {
  return request({
    url: '/tablet-sessions',
    method: 'delete',
    data: { tabletUuid },
  });
}

export function changeTabletRemoteAccess(uuid, params) {
  return request({
    url: `/tablets/${uuid}/remote-access`,
    method: 'put',
    data: params,
  });
}
