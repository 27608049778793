import React, { Component } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import ManagerPlayerLive from './ManagerPlayerLive';

import './ManagerPlayer.scss';

class ManagerPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsError: false,
      isLoading: true,
    };
    this.timer = null;
    this.containerRef = React.createRef();
    this.scaleRef = React.createRef();
  }

  onWebRTCError = () => {
    this.setState({ optionsError: '', isLoading: false });
    this.props.changeCameraValue(this.props.camera.key, 'hasWebRTCError', true);
  };

  componentDidMount() {
    if (this.props.camerasView.restaurantUuid === 'select') {
      this.setState({ optionsError: 'No selected restaurant', isLoading: false });
      this.props.changeCameraValue(this.props.camera.key, 'hasWebRTCError', false);
    }
  }

  componentDidUpdate(prevProps) {
    const { camerasView, camera } = this.props;
    const { restaurantUuid } = camerasView;
    const { selectedCameraId, hasStartStreamError, restart } = camera;

    if (
      prevProps.camerasView.restaurantUuid !== restaurantUuid ||
      prevProps.camera.selectedCameraId !== selectedCameraId ||
      prevProps.camera.hasStartStreamError !== hasStartStreamError ||
      prevProps.camera.restart !== restart
    ) {
      if (restaurantUuid === 'select') {
        this.setState({ optionsError: 'No selected restaurant', isLoading: false });
      } else {
        if (!selectedCameraId) {
          this.setState({ optionsError: '', isLoading: false });
        } else if (selectedCameraId && hasStartStreamError) {
          this.setState({ optionsError: 'Unable to open stream', isLoading: false });
        } else {
          this.setState({ optionsError: '', isLoading: true });
        }
      }
      this.props.changeCameraValue(camera.key, 'hasWebRTCError', false);
    }
  }

  handleRestartStream = () => {
    const { camerasView, camera, startCameraStream } = this.props;
    if (!camera.selectedCameraId) return;
    if (camera.hasStartStreamError) {
      startCameraStream(camerasView.restaurantUuid, camera.id);
    }
    this.props.changeCameraValue(camera.key, 'restart', true);
  };

  handleResetScale = () => {
    this.scaleRef.current?.resetTransform();
  };

  render() {
    const { optionsError, isLoading } = this.state;
    const { camera } = this.props;

    return (
      <TransformWrapper
        ref={this.scaleRef}
        wheel={{ step: 0.1 }}
        disabled={!camera.enableScaling || this.props.mobile}
      >
        <TransformComponent disabled={!camera.enableScaling || this.props.mobile}>
          <div className={`manager_camera-view__player-container`}>
            <div className="manager_camera-view__player">
              {camera.selectedCameraId && (
                <div ref={this.containerRef} className="manager_camera-view__player-video">
                  <ManagerPlayerLive
                    mobile={this.props.mobile}
                    camerasView={this.props.camerasView}
                    camera={this.props.camera}
                    isLoading={isLoading}
                    optionsError={optionsError}
                    onWebRTCError={this.onWebRTCError}
                    onRestartStream={this.handleRestartStream}
                    onResetScale={this.handleResetScale}
                    changeCameraValue={this.props.changeCameraValue}
                    onDragStart={this.props.onDragStart}
                    onDeleteCameraStream={this.props.onDeleteCameraStream}
                    containerRef={this.containerRef}
                  />
                </div>
              )}
            </div>
          </div>
        </TransformComponent>
      </TransformWrapper>
    );
  }
}

export default ManagerPlayer;
