import * as types from '../actions/chickenContainersAction';

const initialState = {
  limit: '20',
  page: 0,
  totalResults: 0,
  sortBy: 'code',
  orderDirection: 'ASC',
  loading: false,
};

const chickenContainersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CHICKEN_CONTAINERS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_CHICKEN_CONTAINERS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case types.CHANGE_MODAL_CONTAINERS_VALUE:
      return {
        ...state,
        modalInf: {
          ...state.modalInf,
          [action.payload.key]: action.payload.value,
        },
      };

    case types.GET_CHICKEN_CONTAINERS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_CHICKEN_CONTAINERS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
      };

    case types.GET_CHICKEN_CONTAINERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.NEW_CHICKEN_CONTAINER:
      return {
        ...state,
        loading: false,
      };

    case types.NEW_CHICKEN_CONTAINER_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };

    case types.UPDATE_CHICKEN_CONTAINER:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_CHICKEN_CONTAINER_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };

    case types.DELETE_CHICKEN_CONTAINER:
      return {
        ...state,
        loading: false,
      };

    case types.DELETE_CHICKEN_CONTAINER_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults - 1,
      };

    case types.RESET_CHICKEN_CONTAINERS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default chickenContainersReducer;
