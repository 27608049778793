const leftRightMargin = 20;
const marginInCompletedStage = 8;

export const getStageProgressWidth = (stages, stageNumber, isCompleted = true) => {
  return `calc((100% / ${stages.length} * ${stageNumber}) - ${
    isCompleted ? leftRightMargin : marginInCompletedStage
  }px)`;
};

export const getInnerIndicatorRight = (stages, stageNumber) => {
  return `calc((100% / ${stages.length}) * (${stages.length} - ${stageNumber}) + 12px)`;
};
