import * as types from '../actions/authLogAction';

const initialState = {
  startDate: 'select',
  endDate: 'select',
  timeInterval: null,
  limit: '20',
  loading: false,
  scrollLoading: false,
  cursorDirection: 'FIRST',
  cursor: null,
  existNext: false,
  existPrevious: false,
  changePage: false,
};

const authLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_AUTH_LOG_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_AUTH_LOG_TIME_VALUE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeInterval: action.payload.timeInterval,
      };
    case types.GET_AUTH_LOG:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_AUTH_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
      };

    case types.GET_AUTH_LOG_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
      };
    case types.RESET_AUTH_LOG_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default authLogReducer;
