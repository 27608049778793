import * as types from '../actions/timersCycleAnalyticAction';

const initialState = {
  content: null,
  restaurantUuid: [],
  className: 'TimerCycleHistory',
  activeTab: 'graphic',
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: null,
  loading: false,
};

const timersCycleAnalyticReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_TIMERS_CYCLE_ANALYTIC_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_TIMERS_CYCLE_ANALYTIC_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case types.GET_TIMERS_CYCLE_ANALYTIC:
      return {
        ...state,
        loading: true,
      };

    case types.GET_TIMERS_CYCLE_ANALYTIC_SUCCESS: {
      const preparedAnalytic =
        action.payload.length !== 0
          ? action.payload.map((item) => ({
              ...item,
              values: item.values.map((tsValue) => [
                tsValue[0],
                Object.values(tsValue[1]).reduce((sum, value) => sum + value, 0),
              ]),
            }))
          : [];
      return {
        ...state,
        loading: false,
        content:
          preparedAnalytic.length !== 0
            ? {
                restaurants: preparedAnalytic.map((item) => item.restaurantName),
                graphicValues: preparedAnalytic[0].values.map((item, index) => [
                  new Date(item[0] * 1000),
                  ...preparedAnalytic.map((it) => Number(it.values[index][1])),
                ]),
                totalValues: preparedAnalytic
                  .map((item) => ({
                    restaurant: item.restaurantName,
                    value: item.values.reduce(function (sum, elem) {
                      return sum + Number(elem[1]);
                    }, 0),
                  }))
                  .sort((a, b) => b.value - a.value),
              }
            : null,
      };
    }

    case types.GET_TIMERS_CYCLE_ANALYTIC_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_TIMERS_CYCLE_ANALYTIC_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default timersCycleAnalyticReducer;
