import React from 'react';

import cx from 'classnames';

import './FormCheckbox.scss';

export const FormCheckbox = ({ label, rounded = false, checked, disabled, onChange }) => {
  return (
    <div className="form-checkbox">
      <label>
        <input
          type="checkbox"
          disabled={disabled}
          className={cx('form-checkbox__input', { disabled: disabled })}
          onChange={(event) => onChange(event)}
          checked={checked}
        />
        <div className="form-checkbox__background">
          <span className={rounded ? 'rounded' : ''}></span>
        </div>
        {label}
      </label>
    </div>
  );
};
