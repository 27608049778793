import request from '../utils/request';

export function getRestaurants(params, signal) {
  return request({
    url: '/restaurants',
    method: 'get',
    data: params,
    signal,
  });
}

export function getCities(params) {
  return request({
    url: '/cities',
    method: 'get',
    data: params,
  });
}

export function getRestaurant(id) {
  return request({
    url: `/restaurants/${id}`,
    method: 'get',
  });
}

export function getRestaurantDevices(uuid) {
  return request({
    url: `/restaurants/${uuid}`,
    method: 'get',
  });
}

export function getEmptyDevices(params) {
  return request({
    url: '/devices/restaurant/empty',
    method: 'get',
    data: params,
  });
}

export function createRestaurant(params) {
  return request({
    url: '/restaurants',
    method: 'post',
    data: params,
  });
}

export function updateRestaurant(uuid, params) {
  return request({
    url: `/restaurants/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteRestaurant(uuid) {
  return request({
    url: `/restaurants/${uuid}`,
    method: 'delete',
    data: uuid,
  });
}

export function getStates() {
  return request({
    url: `/states`,
    method: 'get',
  });
}

export function getTunnelIp(params) {
  return request({
    url: `/networkInformation/tun/`,
    method: 'get',
    data: params,
  });
}

export function addRestaurantDevices(uuid, params) {
  return request({
    url: `/restaurants/${uuid}/devices`,
    method: 'post',
    data: params,
  });
}

export function deleteDeviceRestaurant(uuid, params) {
  return request({
    url: `/restaurants/${uuid}/changeRestaurant`,
    method: 'put',
    data: params,
  });
}

export function changeDeviceRestaurant(uuid, restaurantUuid) {
  return request({
    url: `/restaurants/${uuid}/changeRestaurant/${restaurantUuid}`,
    method: 'put',
  });
}
