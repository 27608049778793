import { useState } from 'react';
import { MobileDatepicker, SideModal, SingleSelectFilter } from '../../';
import { intervalsDate } from '../../../constants/analytics.constants';
import { TimeRangeFilter } from '../../Filters';

export const RestDateFiltersModal = ({
  login,
  info,
  defaultDatepicker = '1mo',
  onConfirm,
  onCancel,
  isTimeRangeEnabled,
}) => {
  const [restaurantUuid, setRestaurantUuid] = useState(info.restaurantUuid);
  const [timeRange, setTimeRange] = useState({ start: info.startTimeInterval, end: info.endTimeInterval });
  const [dates, setDates] = useState({
    startDate: info.startDate,
    endDate: info.endDate,
    timeInterval: info.timeInterval,
  });

  return (
    <SideModal
      withoutPadding={true}
      size={'sm'}
      title="Filters"
      onCancel={() => {
        onCancel();
      }}
      onConfirm={() => {
        onConfirm({
          restaurantUuid,
          startDate: dates.startDate,
          endDate: dates.endDate,
          timeInterval: dates.timeInterval,
          timeIntervalIcon:
            intervalsDate.find((item) => item.icon === defaultDatepicker).value !== dates.timeInterval
              ? intervalsDate.find((item) => item.value === dates.timeInterval)?.icon
              : null,
          ...(isTimeRangeEnabled && {
            startTimeInterval: timeRange.start,
            endTimeInterval: timeRange.end,
          }),
        });
      }}
      modalBody={
        <div className="mobile-filters__modal">
          {login.role !== 'USER' && (
            <>
              {login.restaurantUuid === 'select' && (
                <>
                  <SingleSelectFilter
                    title="Restaurant"
                    name="restaurantUuid"
                    options={login.restaurantOptions}
                    value={restaurantUuid}
                    onChange={(_name, value) => {
                      setRestaurantUuid(value);
                    }}
                    clearable={true}
                    searchable={true}
                  />
                </>
              )}
              {isTimeRangeEnabled && (
                <TimeRangeFilter
                  range={{ start: timeRange.start, end: timeRange.end }}
                  handleChange={(value) => {
                    setTimeRange(value);
                  }}
                  clearable
                />
              )}
            </>
          )}
          {}
          <MobileDatepicker
            start={dates.startDate}
            end={dates.endDate}
            timeInterval={dates.timeInterval}
            timezone={login.restaurantOptions.find((option) => option.value === restaurantUuid)?.timezone}
            defaultValue={intervalsDate.find((item) => item.icon === defaultDatepicker).value}
            onChange={(data) => setDates(data)}
          />
        </div>
      }
      confirmText="Save"
    />
  );
};
