import * as types from '../actions/auditLogAction';

const initialState = {
  status: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  intervalEnd: null,
  search: '',
  role: 'all',
  limit: '20',
  loading: false,
  scrollLoading: false,
  cursorDirection: 'FIRST',
  cursor: null,
  existNext: false,
  existPrevious: false,
  changePage: false,
  data: [],
};

const auditLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_AUDIT_LOG_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_AUDIT_LOG_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case types.GET_AUDIT_LOG:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
        data:
          action.params.cursorDirection === 'NEXT'
            ? [...state.data, ...action.payload.content]
            : action.payload.content,
      };
    case types.GET_AUDIT_LOG_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
      };
    case types.RESET_AUDIT_LOG_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default auditLogReducer;
