import { pieChartThousands } from '../../../../constants/analytics.constants';
import { convertThousands } from '../../../../utils';
import { barChartHeader, barChartOptions } from './constants';

export const getChartData = (content) => {
  return [
    [...barChartHeader],
    ...content.totalValues.map((item) => [
      item.restaurant,
      item.value,
      `<div class="chart-tooltip"><span>${
        item.restaurant
      }</span>${`<div>Count: <b>${item.value}</b></div>`}</div>`,
      convertThousands(item.value, pieChartThousands) || 0,
    ]),
  ];
};

export const getChartOptions = (mobile) => {
  return {
    chartArea: { width: mobile ? '85%' : '90%', height: '90%', top: 0 },
    ...barChartOptions,
  };
};
