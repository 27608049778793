import cx from 'classnames';
import { useState } from 'react';
import { DateRange } from 'react-date-range';

import { ReactComponent as Close } from '../../../assets/img/icons/common/Close.svg';
import { getRelativeDate } from '../../../utils';

import '../../DateTimepicker/DateTimepicker.scss';
import '../Modal.scss';

export const DateModal = ({
  dates = [new Date(), new Date()],
  maxInterval,
  addNotification,
  allowFuture = false,
  onCancel,
  onConfirm,
}) => {
  const [calendarState, setCalendarState] = useState([
    {
      startDate: dates[0],
      endDate: dates[1],
      key: 'selection',
    },
  ]);

  const checkDates = () => {
    if (
      maxInterval !== null &&
      Math.abs(calendarState[0].endDate.getTime() - calendarState[0].startDate.getTime()) > maxInterval
    ) {
      addNotification(
        'danger',
        `Date range must be 
      less than ${getRelativeDate(Date.now() - maxInterval)}`.replace('ago', ''),
        'Error',
        3000
      );
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className="modal fade show"
      id="ModalPassword"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="ModalPasswordLabel"
      aria-hidden="true"
    >
      <div role="document" className="modal-dialog modal__pattern-down modal__pattern modal__pattern-sm">
        <div className="modal-content modal-content__date">
          <div className="modal-header">
            <h4 className="modal-title">Dates</h4>
            <div className="modal-close__button" onClick={onCancel}>
              <Close />
            </div>
          </div>
          <div className="modal-body">
            <DateRange
              className="datepicker-custom-range__calendar datepicker-custom-range__calendar-one-month"
              maxDate={allowFuture ? undefined : new Date()}
              minDate={new Date(2019, 0, 0, 0)}
              ranges={calendarState}
              fixedHeight={true}
              onChange={(item) => {
                setCalendarState([item.selection]);
              }}
              showMonthArrow={true}
              showMonthAndYearPickers={false}
              showDateDisplay={false}
              direction="horizontal"
              calendarFocus="backwards"
              preventSnapRefocus={true}
              months={1}
              rangeColors={['#dd00311a', '#3ecf8e', '#fed14c']}
            />
          </div>
          <div className="modal-footer">
            <div className="modal-button__container">
              <button type="button" className="modal-button modal-button__cancel" onClick={() => onCancel()}>
                Cancel
              </button>
              <button
                className={cx('modal-button modal-button__confirm', {
                  disabled: checkDates(),
                })}
                type="submit"
                onClick={() =>
                  onConfirm(
                    Math.floor(calendarState[0].startDate.getTime() / 1000),
                    Math.floor(calendarState[0].endDate.getTime() / 1000)
                  )
                }
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
