import { getAnalytics } from '../../api/analytics';
import { getEventNames, getEvents, getExelExport } from '../../api/events';
import { downloadFile } from '../../utils';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_POTATO_EVENTS_VALUE = 'CHANGE_POTATO_EVENTS_VALUE';
export const CHANGE_POTATO_EVENTS_SEVERAL_VALUES = 'CHANGE_POTATO_EVENTS_SEVERAL_VALUES';
export const RESET_POTATO_EVENTS_STORE = 'RESET_POTATO_EVENTS_STORE';

export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_ERROR = 'GET_EVENTS_ERROR';

export const LOAD_EVENTS_FILE = 'LOAD_EVENTS_FILE';
export const LOAD_EVENTS_FILE_SUCCESS = 'LOAD_EVENTS_FILE_SUCCESS';
export const LOAD_EVENTS_FILE_ERROR = 'LOAD_EVENTS_FILE_ERROR';

export const GET_POTATO_EVENTS_ANALYTIC = 'GET_POTATO_EVENTS_ANALYTIC';
export const GET_POTATO_EVENTS_ANALYTIC_SUCCESS = 'GET_POTATO_EVENTS_ANALYTIC_SUCCESS';
export const GET_POTATO_EVENTS_ANALYTIC_ERROR = 'GET_POTATO_EVENTS_ANALYTIC_ERROR';

export const downloadEventsFile = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_EVENTS_FILE });
    try {
      const response = await getExelExport(params);
      const { success, results } = response;
      if (success) {
        response.code !== 202 && downloadFile(results, 'application/zip');
        dispatch({ type: LOAD_EVENTS_FILE_SUCCESS });
        if (actions.onSuccess) {
          actions.onSuccess(response);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_EVENTS_FILE_ERROR });
      if (actions.onError) {
        actions.onError(error.message);
      }
    }
  };
};

export const getEventsAnalyticAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_POTATO_EVENTS_ANALYTIC, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await getAnalytics(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_POTATO_EVENTS_ANALYTIC_SUCCESS, payload: results, params: params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_POTATO_EVENTS_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const getEventsAction = (function () {
  let prevController;
  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_EVENTS, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await getEvents(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_EVENTS_SUCCESS, payload: results, params: params });
        } else {
          dispatch({ type: GET_EVENTS_ERROR });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_EVENTS_ERROR });
        }
      }
    };
  };
})();

export const getEventNamesOptions = (setData) => {
  return async () => {
    try {
      const response = await getEventNames();
      const { success, results } = response;
      if (success) {
        setData([
          { label: 'All', value: 'all' },
          ...results.map((eventName) => ({
            label: eventName,
            value: eventName,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_POTATO_EVENTS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_POTATO_EVENTS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_POTATO_EVENTS_STORE,
});
