import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { ReactComponent as NoImage } from '../../assets/img/icons/common/NoImage.svg';

import './Preview.scss';

export const Preview = ({ preview, width, height }) => {
  return (
    <div className="preview__container" style={{ width: `${width}px`, height: `${height}px` }}>
      {!preview || preview.error ? (
        <div className="image-content">
          <NoImage />
        </div>
      ) : preview?.loading ? (
        <div className="image-content md">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        <img className="camera-preview" src={preview?.image} alt="Preview" />
      )}
    </div>
  );
};
