export const SET_SIDEBAR = 'SET_SIDEBAR';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_VIEW = 'SET_VIEW';
export const SET_SCREEN = 'SET_SCREEN';

export const setSidebar = (isOpen) => ({
  type: SET_SIDEBAR,
  isOpen,
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  page,
});

export const setView = (view) => ({
  type: SET_VIEW,
  view,
});

export const changeScreen = (value) => ({
  type: SET_SCREEN,
  value,
});
