import {
  createRestaurant,
  deleteRestaurant,
  getRestaurant,
  getRestaurants,
  getTunnelIp,
  updateRestaurant,
} from '../../api/restaurants';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_RESTAURANTS_VALUE = 'CHANGE_RESTAURANTS_VALUE';
export const CHANGE_SEVERAL_RESTAURANTS_VALUES = 'CHANGE_SEVERAL_RESTAURANTS_VALUES';
export const CHANGE_RESTAURANTS_VIEW_TYPE = 'CHANGE_RESTAURANTS_VIEW_TYPE';
export const RESET_RESTAURANTS_STORE = 'RESET_RESTAURANTS_STORE';

export const GET_RESTAURANTS = 'GET_RESTAURANTS';
export const GET_RESTAURANTS_SUCCESS = 'GET_RESTAURANTS_SUCCESS';
export const GET_RESTAURANTS_ERROR = 'GET_RESTAURANTS_ERROR';

export const GET_RESTAURANT = 'GET_RESTAURANT';
export const GET_RESTAURANT_SUCCESS = 'GET_RESTAURANT_SUCCESS';
export const GET_RESTAURANT_ERROR = 'GET_RESTAURANT_ERROR';

export const NEW_RESTAURANT = 'NEW_RESTAURANT';
export const NEW_RESTAURANT_SUCCESS = 'NEW_RESTAURANT_SUCCESS';
export const NEW_RESTAURANT_ERROR = 'NEW_RESTAURANT_ERROR';

export const UPDATE_RESTAURANT = 'UPDATE_RESTAURANT';
export const UPDATE_RESTAURANT_SUCCESS = 'UPDATE_RESTAURANT_SUCCESS';
export const UPDATE_RESTAURANT_ERROR = 'UPDATE_RESTAURANT_ERROR';

export const DELETE_RESTAURANT = 'DELETE_RESTAURANT';
export const DELETE_RESTAURANT_SUCCESS = 'DELETE_RESTAURANT_SUCCESS';
export const DELETE_RESTAURANT_ERROR = 'DELETE_RESTAURANT_ERROR';

export const changeRestaurantsViewType = (viewType) => ({
  type: CHANGE_RESTAURANTS_VIEW_TYPE,
  payload: viewType,
});

export const getRestaurantOptions = (setData) => {
  return async () => {
    try {
      const response = await getRestaurants({
        limit: 100,
        offset: 0,
        sortBy: 'number',
        orderDirection: 'DESC',
      });
      const { success, results } = response;
      if (success) {
        setData([
          { label: 'Select Restaurant', value: 'select' },
          ...results.content.map((restaurant) => ({
            label: `${restaurant.number} - ${restaurant.name}`,
            value: restaurant.uuid,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getRestaurantsAction = (function () {
  let prevController;

  return (params, actions) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_RESTAURANTS });
      try {
        const response = await getRestaurants(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          actions?.onSuccess([...results.content]);
          dispatch({ type: GET_RESTAURANTS_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          actions?.onError(error.message);
          dispatch({ type: GET_RESTAURANTS_ERROR, params });
        }
      }
    };
  };
})();

export const getRestaurantAction = (uuid, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_RESTAURANT });
    try {
      const response = await getRestaurant(uuid);
      const { success, results } = response;
      if (success) {
        setData(results);
        dispatch({ type: GET_RESTAURANT_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_RESTAURANT_ERROR });
    }
  };
};

export const createRestaurantAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: NEW_RESTAURANT });
    try {
      const response = await createRestaurant(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: NEW_RESTAURANT_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: NEW_RESTAURANT_ERROR });
    }
  };
};

export const updateRestaurantAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_RESTAURANT });
    try {
      const response = await updateRestaurant(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: UPDATE_RESTAURANT_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: UPDATE_RESTAURANT_ERROR });
    }
  };
};

export const deleteRestaurantAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_RESTAURANT });
    try {
      const response = await deleteRestaurant(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_RESTAURANT_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_RESTAURANT_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_RESTAURANTS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_SEVERAL_RESTAURANTS_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_RESTAURANTS_STORE,
});

export const getTunnelIpAction = (params, actions) => {
  return async () => {
    try {
      const response = await getTunnelIp(params);
      const { success, results } = response;
      if (success) {
        if (actions.onSuccess) {
          actions.onSuccess(results);
        }
      }
    } catch (error) {
      console.log(error);
      if (actions.onError) {
        actions.onError(error);
      }
    }
  };
};
