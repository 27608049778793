import { deleteTabletSessions, getTabletSessions } from '../../api/tablets';

export const CHANGE_TABLET_SESSIONS_VALUE = 'CHANGE_TABLET_SESSIONS_VALUE';
export const RESET_TABLET_SESSIONS_STORE = 'RESET_TABLET_SESSIONS_STORE';

export const GET_TABLET_SESSIONS = 'GET_TABLET_SESSIONS';
export const GET_TABLET_SESSIONS_SUCCESS = 'GET_TABLET_SESSIONS_SUCCESS';
export const GET_TABLET_SESSIONS_ERROR = 'GET_TABLET_SESSIONS_ERROR';

export const DELETE_TABLET_SESSION = 'DELETE_TABLET_SESSION';
export const DELETE_TABLET_SESSION_SUCCESS = 'DELETE_TABLET_SESSION_SUCCESS';
export const DELETE_TABLET_SESSION_ERROR = 'DELETE_TABLET_SESSION_ERROR';

export const DELETE_ALL_TABLET_SESSIONS = 'DELETE_ALL_TABLET_SESSIONS';
export const DELETE_ALL_TABLET_SESSIONS_SUCCESS = 'DELETE_ALL_TABLET_SESSIONS_SUCCESS';
export const DELETE_ALL_TABLET_SESSIONS_ERROR = 'DELETE_ALL_TABLET_SESSIONS_ERROR';

export const getTabletSessionsAction = (tabletUuid, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_TABLET_SESSIONS });
    try {
      const response = await getTabletSessions(tabletUuid);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_TABLET_SESSIONS_SUCCESS, payload: results });
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TABLET_SESSIONS_ERROR });
      actions.onError && actions.onError(error.message);
    }
  };
};

export const deleteTabletSessionsAction = (tabletUuid, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ALL_TABLET_SESSIONS });
    try {
      const response = await deleteTabletSessions(tabletUuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess(results);
        dispatch({ type: DELETE_ALL_TABLET_SESSIONS_SUCCESS, payload: tabletUuid });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_ALL_TABLET_SESSIONS_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_TABLET_SESSIONS_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_TABLET_SESSIONS_STORE,
});
