import moment from 'moment';
import 'moment-timezone';

import { ReactComponent as ArrowLeft } from '../../../assets/img/icons/datepicker/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from '../../../assets/img/icons/datepicker/ArrowRight.svg';
import { intervalsDate } from '../../../constants/analytics.constants';
import { getLocalTimestamp } from '../../../utils/commonMethods';
import { checkSearchParameter, getSearchParameter } from '../../../utils/urlFunctions';
import '../DateTimepicker.scss';

export const CustomDateArrow = ({
  start,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  onChange,
  end,
  withoutTime = false,
  timeInterval = null,
  disabled = false,
  reverse = false,
  allowFuture = false,
}) => {
  const countInterval = (startDate, interval, revert = false) => {
    let difference = startDate;
    if (revert) {
      return moment(difference).diff(interval, 'milliseconds');
    } else {
      return moment(difference).add(interval, 'milliseconds');
    }
  };

  const onChangeAction = (startDate, endDate = null, arrowInterval = null) => {
    onChange(startDate, endDate, arrowInterval);
  };

  const changeLeftRange = () => {
    if (timeInterval !== null) {
      onChangeAction(
        countInterval(
          getLocalTimestamp(Date.now(), timezone) - timeInterval.value * 1000,
          timeInterval.value * 1000,
          true
        ),
        Number(getLocalTimestamp(Date.now(), timezone) - timeInterval.value * 1000),
        timeInterval.icon
      );
    } else if (start !== null && start !== 'select' && end !== null) {
      let datesDifference = (moment(end).unix() - moment(start).unix()) * 1000;
      if (
        moment('2019-01-01').isBefore(start, 'day') &&
        moment('2019-01-01').isBefore(moment(start).diff(datesDifference), 'day')
      ) {
        if (checkSearchParameter('interval')) {
          let interval = countInterval(
            start,
            intervalsDate.find((it) => it.icon === getSearchParameter('interval')).value * 1000,
            true
          );
          onChangeAction(new Date(interval), start, getSearchParameter('interval'));
        } else {
          if (
            !withoutTime &&
            moment(start).format('HH:mm') === '00:00' &&
            moment(end).format('HH:mm') === '23:59'
          ) {
            onChangeAction(
              moment(countInterval(start, datesDifference, true)).set({ h: 0, m: 0 }),
              moment(countInterval(start, 120000, true)).set({ h: 23, m: 59 })
            );
          } else {
            let startDate =
              withoutTime && moment(start).isSame(moment(end), 'date')
                ? moment(start).subtract(1, 'day')
                : start;
            onChangeAction(new Date(countInterval(startDate, datesDifference, true)), startDate);
          }
        }
      } else {
        onChangeAction(new Date(2019, 0, 1), start, getSearchParameter('interval'));
      }
    }
  };

  const changeRightRange = () => {
    if (
      start !== 'select' &&
      ((start !== null && end !== null && timeInterval === null) ||
        (allowFuture && (start !== null || timeInterval !== null)))
    ) {
      if (checkSearchParameter('interval')) {
        let nextDate = countInterval(
          end,
          intervalsDate.find((it) => it.icon === getSearchParameter('interval')).value * 1000
        );
        if (
          moment(getLocalTimestamp(Date.now(), timezone)).isSameOrBefore(
            nextDate,
            withoutTime ? 'day' : 'minute'
          ) &&
          !allowFuture
        ) {
          onChangeAction(getSearchParameter('interval'));
        } else {
          onChangeAction(end, nextDate, getSearchParameter('interval'));
        }
      } else if (end !== null && start !== null) {
        let datesDifference = (moment(end).unix() - moment(start).unix()) * 1000;
        let ifNextIsTomorrow =
          !allowFuture &&
          moment(getLocalTimestamp(Date.now(), timezone)).isSameOrBefore(
            countInterval(end, datesDifference),
            withoutTime ? 'day' : 'minute'
          );
        if (
          !withoutTime &&
          moment(end).format('HH:mm') === '23:59' &&
          moment(start).format('HH:mm') === '00:00'
        ) {
          onChangeAction(
            moment(countInterval(end, 120000)).set({ h: 0, m: 0 }),
            ifNextIsTomorrow
              ? getLocalTimestamp(Date.now(), timezone)
              : moment(countInterval(end, datesDifference)).set({ h: 23, m: 59 })
          );
        } else {
          let endDate =
            withoutTime && !ifNextIsTomorrow && moment(start).isSame(moment(end), 'date')
              ? moment(end).add(1, 'day')
              : end;
          onChangeAction(
            endDate,
            ifNextIsTomorrow
              ? getLocalTimestamp(Date.now(), timezone)
              : countInterval(endDate, datesDifference)
          );
        }
      } else if (allowFuture && timeInterval !== null) {
        onChangeAction(
          getLocalTimestamp(Date.now(), timezone),
          countInterval(getLocalTimestamp(Date.now(), timezone), timeInterval.value * 1000),
          timeInterval.icon
        );
      }
    }
  };

  const changeRange = () => {
    if (reverse) {
      changeLeftRange();
    } else changeRightRange();
  };

  return (
    <div
      className={`datepicker__arrow${disabled ? ' datepicker__arrow__disabled' : ''}`}
      onClick={() => {
        changeRange();
      }}
    >
      {reverse ? <ArrowLeft /> : <ArrowRight />}
    </div>
  );
};
