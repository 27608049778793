import * as types from '../actions/graphicsAction';

const initialState = {
  restaurants: [],
  machines: [],
  restaurantUuid: null,
  machine: null,
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: null,
  loading: false,
  cpuOpened: true,
  cpuCores: 0,
  cpuTemperature: [],
  cpuScalar: null,
  cpuVector: [{ machine: '', values: [] }],
  ramOpened: true,
  ramType: 3,
  ramAll: null,
  ramAvailable: null,
  ramScalar: null,
  ramVector: [{ machine: '', values: [] }],
  gpuOpened: true,
  gpuType: 2,
  gpuAll: null,
  gpuTemperature: [],
  gpuAvailable: null,
  gpuScalar: null,
  gpuVector: [{ machine: '', values: [] }],
  gpuVectorType: 3,
  gpuUtilsVector: [{ machine: '', values: [] }],
  storageOpened: true,
  storageType: 3,
  storageAll: null,
  storageAvailable: null,
  storageScalar: null,
  storageVector: [{ machine: '', values: [] }],
  networkOpened: true,
  receiveType: 0,
  receiveNetworkScalar: null,
  receiveMin: 0,
  receiveMax: 0,
  receiveAvg: 0,
  receiveNetworkVector: [{ machine: '', values: [] }],
  transmitType: 0,
  transmitMin: 0,
  transmitMax: 0,
  transmitAvg: 0,
  transmitNetworkVector: [{ machine: '', values: [] }],
  upTime: 0,
};

const getRestaurantHost = (restaurants, machine) => {
  return restaurants.find((rest) => rest.hosts.includes(machine));
};

const graphicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_GRAPHICS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_GRAPHICS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_MONITORING_RESTAURANTS_SUCCESS:
      return {
        ...state,
        restaurants: action.payload
          ? action.payload.content.map((rest) => ({
              name: rest.number + ' ' + rest.name,
              number: rest.number,
              hosts: rest.hosts,
              value: rest.uuid,
            }))
          : [],
      };

    case types.GET_MACHINES_SUCCESS:
      return {
        ...state,
        machines: action.payload.data
          .filter((machine) => getRestaurantHost(state.restaurants, machine))
          .map((machine) => {
            const host = getRestaurantHost(state.restaurants, machine);
            return {
              label: host.name + ' - ' + machine,
              code: host.number,
              value: machine,
              restUuid: host.value,
            };
          })
          .sort((a, b) => a.code - b.code),
      };

    case types.RESET_GRAPHICS_METRICS:
      return {
        ...state,
        cpuCores: [],
        cpuTemperature: [],
        cpuScalar: [],
        cpuVector: [{ machine: '', values: null }],
        ramAll: [],
        ramAvailable: [],
        ramScalar: [],
        ramVector: [{ machine: '', values: null }],
        gpuAll: [],
        gpuTemperature: [],
        gpuAvailable: [],
        gpuScalar: [],
        gpuVector: [{ machine: '', values: null }],
        gpuUtilsVector: [{ machine: '', values: null }],
        storageAll: [],
        storageAvailable: [],
        storageScalar: [],
        storageVector: [{ machine: '', values: null }],
        receiveNetworkScalar: [],
        receiveMin: 0,
        receiveMax: 0,
        receiveAvg: 0,
        receiveNetworkVector: [{ machine: '', values: null }],
        transmitMin: 0,
        transmitMax: 0,
        transmitAvg: 0,
        transmitNetworkVector: [{ machine: '', values: null }],
        upTime: [],
      };

    case types.RESET_GRAPHICS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default graphicsReducer;
