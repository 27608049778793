import React from 'react';

import * as R from './constants/routes.constants';

const LoginPage = React.lazy(() => import('./containers/SignIn/Login'));
const LoginExpiredPage = React.lazy(() => import('./containers/SignIn/ExpiredToken'));
const PasswordExpiredPage = React.lazy(() => import('./containers/SignIn/ExpiredChangePassword'));
const ResetPage = React.lazy(() => import('./containers/SignIn/Reset'));
const BlockedUserPage = React.lazy(() => import('./containers/SignIn/BlockedAccount'));
const EventsPage = React.lazy(() => import('./containers/EventsNav/Events'));
const AnalyticEventsPage = React.lazy(() => import('./containers/EventsNav/Analytics'));
const ChickenEventsPage = React.lazy(() => import('./containers/EventsNav/ChickenEvents'));
const RestaurantsPage = React.lazy(() => import('./containers/Restaurants/Restaurants'));
const AuditLogPage = React.lazy(() => import('./containers/AuditLog/AuditLog'));
const DevicesTabletsPage = React.lazy(() => import('./containers/Devices/Tablets'));
const DevicesPCPage = React.lazy(() => import('./containers/Devices/Devices'));
const TabletSessionsPage = React.lazy(() => import('./containers/Devices/TabletSessions'));
const SettingsChickenMaterialsPage = React.lazy(() =>
  import('./containers/Settings/Materials/ChickenMaterials')
);
const SettingsChickenContainersPage = React.lazy(() =>
  import('./containers/Settings/Containers/ChickenContainers')
);
const SettingsPotatoMaterialsPage = React.lazy(() =>
  import('./containers/Settings/Materials/PotatoMaterials')
);
const SettingsPotatoPackagesPage = React.lazy(() => import('./containers/Settings/Packages/PotatoPackages'));

const RestaurantPCPage = React.lazy(() => import('./containers/Devices/RestaurantPC'));
const RestaurantTabletsPage = React.lazy(() => import('./containers/Devices/RestaurantTablets'));
const SupportHistoryPage = React.lazy(() => import('./containers/SupportHistory/SupportHistory'));
const KitchenPlayerPage = React.lazy(() => import('./containers/KitchenPlayer/PlayerTab'));
const KitchenPlayerRecordingsPage = React.lazy(() => import('./containers/KitchenPlayer/RecordingsTab'));
const PlugPlayPage = React.lazy(() => import('./containers/PlugPlay/PlugPlay'));
const ArucoValidationPage = React.lazy(() => import('./containers/PlugPlay/ArucoValidation'));
const ChickenDashboardPage = React.lazy(() => import('./containers/Dashboard/ChickenDashboard'));
// const PotatoDashboardPage = React.lazy(() => import('./containers/Dashboard/PotatoDashboard'));
const DPRemainingPage = React.lazy(() => import('./containers/Unusual/DPRemainingPotato'));
const IdenticalMarkerPage = React.lazy(() => import('./containers/Unusual/IdenticalMarkers'));
const UnusualPage = React.lazy(() => import('./containers/Unusual/UnusualEventInformation'));
const WrongMarkerPage = React.lazy(() => import('./containers/Unusual/WrongMarketDetected'));
const UnusualRecordingsPage = React.lazy(() => import('./containers/Unusual/UnusualEventRecordings'));

const TimersCyclePage = React.lazy(() => import('./containers/TimersCycle/TimersCycleHistory'));
const FryChutesPage = React.lazy(() => import('./containers/TimersCycle/FryChutesInformation'));
const DumpPanPage = React.lazy(() => import('./containers/TimersCycle/DumpPanInformation'));
const PRQPage = React.lazy(() => import('./containers/TimersCycle/PRQ'));
const BreadingPage = React.lazy(() => import('./containers/TimersCycle/BreadingInformation'));
const ConveyorPage = React.lazy(() => import('./containers/TimersCycle/ConveyorInformation'));
const FryerPage = React.lazy(() => import('./containers/TimersCycle/FryerInformation'));
const CateringTimersPage = React.lazy(() => import('./containers/TimersCycle/CateringTimers'));
const AnalyticTimerCyclePage = React.lazy(() => import('./containers/TimersCycle/Analytics'));
const ChickenPerformancePage = React.lazy(() => import('./containers/Performance/ChickenPerformance'));
const SetPassword = React.lazy(() => import('./containers/SignIn/SetPassword'));
const ActiveSessionsPage = React.lazy(() => import('./containers/UserProfile/ActiveSessions/ActiveSessions'));
const AuthentificationLogPage = React.lazy(() =>
  import('./containers/UserProfile/AuthentificationLog/AuthentificationLog')
);
const UserSecurityPage = React.lazy(() => import('./containers/UserProfile/Security/Security'));
const MultiFactorAuthentication = React.lazy(() => import('./containers/SignIn/MultiFactorAuth'));
const MonitoringGraphicsPage = React.lazy(() => import('./containers/Monitoring/Graphics'));
const MonitoringAlertsPage = React.lazy(() => import('./containers/Monitoring/Alerts'));
const UsersManagementPage = React.lazy(() => import('./containers/UsersManagement/UsersManagement'));
const UserSessionsPage = React.lazy(() => import('./containers/UsersManagement/UserSessions'));
const CamerasViewPage = React.lazy(() => import('./containers/Cameras/CamerasView'));
const CamerasSettingsPage = React.lazy(() => import('./containers/Cameras/CamerasSettings'));
const CamerasRecordingsPage = React.lazy(() => import('./containers/Cameras/CamerasRecordings'));
const ReportsPage = React.lazy(() => import('./containers/Reports/Reports'));
const ServerManagementPage = React.lazy(() => import('./containers/Monitoring/ServerManagement'));
const StockOutReportsPage = React.lazy(() => import('./containers/StockOutReports/StockOutReports'));
const ProductionQueuePage = React.lazy(() => import('./containers/PCS/ProductionQueue'));
const CateringInventoryPage = React.lazy(() => import('./containers/PCS/Catering'));
const FryerManagerPage = React.lazy(() => import('./containers/PCS/FryerManager'));
const AdjustmentPage = React.lazy(() => import('./containers/PCS/Adjustment'));
const RecommendationsPage = React.lazy(() => import('./containers/PCS/Recommendations'));

const routes = [
  { id: 1, path: R.LOGIN, component: LoginPage },
  { id: 2, path: R.PASSWORD_RESET, component: ResetPage },
  { id: 3, path: R.POTATO_EVENTS, component: EventsPage },
  { id: 4, path: R.CHICKEN_DASHBOARD, component: ChickenDashboardPage },
  { id: 5, path: R.CHICKEN_PERFORMANCE, component: ChickenPerformancePage },
  { id: 6, path: R.HOT_HOLDING, component: TimersCyclePage },
  { id: 7, path: R.ENTRY_CHUTES, component: ChickenDashboardPage },
  { id: 8, path: R.BASKET_EVENTS, component: ChickenDashboardPage },
  { id: 9, path: R.FRYER_EVENTS, component: FryerPage },
  { id: 10, path: R.UNUSUAL_EVENT, component: UnusualPage },
  { id: 11, path: R.DEVICES_PC, component: DevicesPCPage },
  { id: 12, path: R.RESTAURANTS, component: RestaurantsPage },
  { id: 13, path: R.RESTAURANTS_DEVICES_PC, component: RestaurantPCPage },
  { id: 14, path: R.AUDIT_LOG, component: AuditLogPage },
  { id: 15, path: R.SETTINGS_CHICKEN_MATERIALS, component: SettingsChickenMaterialsPage },
  { id: 16, path: R.MONITORING_GRAPHICS, component: MonitoringGraphicsPage },
  { id: 17, path: R.USERS, component: UsersManagementPage },
  { id: 18, path: R.KITCHEN_PLAYER, component: KitchenPlayerPage },
  { id: 19, path: R.MULTI_FACTOR_AUTH, component: MultiFactorAuthentication },
  { id: 20, path: R.MONITORING_ALERTS, component: MonitoringAlertsPage },
  { id: 21, path: R.UNUSUAL_RECORDINGS, component: UnusualRecordingsPage },
  { id: 22, path: R.SUPPORT_HISTORY, component: SupportHistoryPage },
  { id: 23, path: R.SET_PASSWORD, component: SetPassword },
  { id: 24, path: R.PLUG_TAB, component: PlugPlayPage },
  { id: 25, path: R.CAMERAS_MANAGEMENT_RECORDINGS, component: CamerasRecordingsPage },
  { id: 26, path: R.CAMERAS_MANAGEMENT_VIEW, component: CamerasViewPage },
  { id: 27, path: R.SERVER_MANAGEMENT, component: ServerManagementPage },
  //TODO add in the future
  // { id: 28, path: R.FRAHA_DASHBOARD, component: PotatoDashboardPage },
  { id: 29, path: R.RESTAURANTS_DEVICES_TABLETS, component: RestaurantTabletsPage },
  { id: 30, path: R.DEVICES_TABLETS, component: DevicesTabletsPage },
  { id: 31, path: R.CHICKEN_EVENTS, component: ChickenEventsPage },
  { id: 32, path: R.ANALYTIC_EVENTS, component: AnalyticEventsPage },
  // TODO: temporary hidden pages (search for 'hidden pages')
  // { id: 33, path: R.PRQ, component: PRQPage },
  // { id: 34, path: R.FRY_CHUTES_EVENTS, component: FryChutesPage },
  // { id: 35, path: R.DUMP_PAN_EVENTS, component: DumpPanPage },
  { id: 36, path: R.TIMERS_ANALYTICS, component: AnalyticTimerCyclePage },
  { id: 37, path: R.SETTINGS_POTATO_MATERIALS, component: SettingsPotatoMaterialsPage },
  { id: 38, path: R.SETTINGS_CHICKEN_CONTAINERS, component: SettingsChickenContainersPage },
  { id: 39, path: R.UNUSUAL_DP_REMAINING, component: DPRemainingPage },
  { id: 40, path: R.UNUSUAL_WRONG_MARKER, component: WrongMarkerPage },
  { id: 41, path: R.UNUSUAL_IDENTICAL, component: IdenticalMarkerPage },
  { id: 42, path: R.KITCHEN_PLAYER_RECORDINGS, component: KitchenPlayerRecordingsPage },
  { id: 43, path: R.ARUCO_VALIDATION, component: ArucoValidationPage },
  { id: 44, path: R.AUTHENTIFICATION_LOG, component: AuthentificationLogPage },
  { id: 45, path: R.ACTIVE_SESSIONS, component: ActiveSessionsPage },
  { id: 46, path: R.STOCK_OUT_REPORTS, component: StockOutReportsPage },
  { id: 47, path: R.CAMERAS_MANAGEMENT_SETTINGS, component: CamerasSettingsPage },
  { id: 48, path: R.LOGIN_EXPIRED, component: LoginExpiredPage },
  { id: 49, path: R.CHANGE_EXPIRED_PASSWORD, component: PasswordExpiredPage },
  { id: 50, path: R.REPORTS, component: ReportsPage },
  { id: 51, path: R.USER_SECURITY, component: UserSecurityPage },
  { id: 52, path: R.SETTINGS_POTATO_PACKAGES, component: SettingsPotatoPackagesPage },
  { id: 53, path: R.BREADING_EVENTS, component: BreadingPage },
  { id: 54, path: R.BLOCKED_USER, component: BlockedUserPage },
  { id: 55, path: R.USER_SESSIONS, component: UserSessionsPage },
  { id: 56, path: R.CONVEYOR_EVENTS, component: ConveyorPage },
  { id: 57, path: R.TABLET_SESSIONS, component: TabletSessionsPage },
  { id: 58, path: R.PRODUCTION_QUEUE, component: ProductionQueuePage },
  { id: 59, path: R.CATERING_INVENTORY, component: CateringInventoryPage },
  { id: 60, path: R.FRYER_MANAGER, component: FryerManagerPage },
  { id: 61, path: R.ADJUSTMENT, component: AdjustmentPage },
  { id: 62, path: R.RECOMMENDATIONS, component: RecommendationsPage },
  { id: 62, path: R.CATERING_EVENTS, component: CateringTimersPage },
];

export default routes;
