import { getAuthLog } from '../../api/authLog';

export const CHANGE_AUTH_LOG_VALUE = 'CHANGE_AUTH_LOG_VALUE';
export const CHANGE_AUTH_LOG_TIME_VALUE = 'CHANGE_AUTH_LOG_TIME_VALUE';
export const RESET_AUTH_LOG_STORE = 'RESET_AUTH_LOG_STORE';

export const GET_AUTH_LOG = 'GET_AUTH_LOG';
export const GET_AUTH_LOG_SUCCESS = 'GET_AUTH_LOG_SUCCESS';
export const GET_AUTH_LOG_ERROR = 'GET_AUTH_LOG_ERROR';

export const getAuthLogAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_AUTH_LOG, payload: params.cursorDirection === 'FIRST' });
    try {
      const response = await getAuthLog(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_AUTH_LOG_SUCCESS, payload: results });
        setData([...results.content]);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_AUTH_LOG_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_AUTH_LOG_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeTimeValue = (value) => ({
  type: CHANGE_AUTH_LOG_TIME_VALUE,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_AUTH_LOG_STORE,
});
