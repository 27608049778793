import React from 'react';
import moment from 'moment';
import 'moment-timezone';

import './DatetimeCell.scss';

export const DateTimeCell = ({ date, timezone, withYear = true, withSeconds = true }) => {
  return (
    <div className="custom-cell__datetime">
      {date ? (
        <>
          <span>{moment.tz(date, timezone).format(`MMM D${withYear ? ', YYYY' : ''}`)}</span>
          <span className="secondary">{moment.tz(date, timezone).format(withSeconds ? 'LTS' : 'LT')}</span>
        </>
      ) : (
        ''
      )}
    </div>
  );
};
