import {
  getAnalytics,
  getExcelExport,
  getStockOutReports,
  getStockOutTotal,
} from '../../api/stockOutReports';
import { downloadFile } from '../../utils';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_STOCK_OUT_REPORTS_VALUE = 'CHANGE_STOCK_OUT_REPORTS_VALUE';
export const CHANGE_STOCK_OUT_REPORTS_SEVERAL_VALUES = 'CHANGE_STOCK_OUT_REPORTS_SEVERAL_VALUES';
export const RESET_STOCK_OUT_REPORTS_STORE = 'RESET_STOCK_OUT_REPORTS_STORE';

export const GET_STOCK_OUT_REPORTS = 'GET_STOCK_OUT_REPORTS';
export const GET_STOCK_OUT_REPORTS_SUCCESS = 'GET_STOCK_OUT_REPORTS_SUCCESS';
export const GET_STOCK_OUT_REPORTS_ERROR = 'GET_STOCK_OUT_REPORTS_ERROR';

export const GET_STOCK_OUT_REPORTS_TOTAL = 'GET_STOCK_OUT_REPORTS_TOTAL';
export const GET_STOCK_OUT_REPORTS_TOTAL_SUCCESS = 'GET_STOCK_OUT_REPORTS_TOTAL_SUCCESS';
export const GET_STOCK_OUT_REPORTS_TOTAL_ERROR = 'GET_STOCK_OUT_REPORTS_TOTAL_ERROR';

export const LOAD_STOCK_OUT_FILE = 'LOAD_STOCK_OUT_FILE';
export const LOAD_STOCK_OUT_FILE_SUCCESS = 'LOAD_STOCK_OUT_FILE_SUCCESS';
export const LOAD_STOCK_OUT_FILE_ERROR = 'LOAD_STOCK_OUT_FILE_ERROR';

export const GET_STOCK_OUT_REPORTS_ANALYTIC = 'GET_STOCK_OUT_REPORTS_ANALYTIC';
export const GET_STOCK_OUT_REPORTS_ANALYTIC_SUCCESS = 'GET_STOCK_OUT_REPORTS_ANALYTIC_SUCCESS';
export const GET_STOCK_OUT_REPORTS_ANALYTIC_ERROR = 'GET_STOCK_OUT_REPORTS_ANALYTIC_ERROR';

export const downloadStockOutFile = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_STOCK_OUT_FILE });
    try {
      const response = await getExcelExport(params);
      const { success, results } = response;
      if (success) {
        response.code !== 202 && downloadFile(results, 'application/zip');
        dispatch({ type: LOAD_STOCK_OUT_FILE_SUCCESS });
        if (actions.onSuccess) {
          actions.onSuccess(response);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_STOCK_OUT_FILE_ERROR });
      if (actions.onError) {
        actions.onError(error.message);
      }
    }
  };
};

export const getStockOutReportsAnalyticAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_STOCK_OUT_REPORTS_ANALYTIC });
      try {
        const response = await getAnalytics(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_STOCK_OUT_REPORTS_ANALYTIC_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_STOCK_OUT_REPORTS_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const getStockOutReportsTotalAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_STOCK_OUT_REPORTS_TOTAL });
      try {
        const response = await getStockOutTotal(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_STOCK_OUT_REPORTS_TOTAL_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_STOCK_OUT_REPORTS_TOTAL_ERROR });
        }
      }
    };
  };
})();

export const getStockOutReportsAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_STOCK_OUT_REPORTS, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await getStockOutReports(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_STOCK_OUT_REPORTS_SUCCESS, payload: results, params: params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          actions?.onError(error.message);
          dispatch({ type: GET_STOCK_OUT_REPORTS_ERROR });
        }
      }
    };
  };
})();

export const changeValue = (key, value) => ({
  type: CHANGE_STOCK_OUT_REPORTS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_STOCK_OUT_REPORTS_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_STOCK_OUT_REPORTS_STORE,
});
