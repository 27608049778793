export const CHANGE_MINI_PLAYER_VALUE = 'CHANGE_MINI_PLAYER_VALUE';
export const SET_MINI_PLAYER_POSITION = 'SET_MINI_PLAYER_POSITION';
export const SET_MINI_PLAYER_SIZE = 'SET_MINI_PLAYER_SIZE';
export const RESET_MINI_PLAYER_STORE = 'RESET_MINI_PLAYER_STORE';

export const START_MINI_PLAYER_STREAM = 'START_MINI_PLAYER_STREAM';
export const START_MINI_PLAYER_VIDEO = 'START_MINI_PLAYER_VIDEO';

export const changeValue = (key, value) => ({
  type: CHANGE_MINI_PLAYER_VALUE,
  payload: { key, value },
});

export const startMiniPlayerStream = (params) => ({
  type: START_MINI_PLAYER_STREAM,
  payload: params,
});

export const startMiniPlayerVideo = (params) => ({
  type: START_MINI_PLAYER_VIDEO,
  payload: params,
});

export const setMiniPLayerPosition = (position) => ({
  type: SET_MINI_PLAYER_POSITION,
  payload: position,
});

export const setMiniPLayerSize = (size) => ({
  type: SET_MINI_PLAYER_SIZE,
  payload: size,
});

export const resetStore = () => ({
  type: RESET_MINI_PLAYER_STORE,
});
