import React from 'react';
import Chart from 'react-google-charts';

import './Chart.scss';

export const ChartComponent = ({
  chartType,
  className,
  content,
  width = null,
  height = null,
  chartOptions,
  checkChartClick = false,
  chartRef,
}) => {
  return (
    <Chart
      className={className}
      chartType={chartType}
      data={content}
      width={width}
      height={height}
      chartEvents={
        checkChartClick
          ? [
              {
                eventName: 'ready',
                callback: ({ chartWrapper, google }) => {
                  if (chartRef) {
                    chartRef.current = chartWrapper.getChart();
                  }
                  checkChartClick(chartWrapper, google);
                },
              },
            ]
          : []
      }
      options={chartOptions}
    />
  );
};
