import request from '../utils/request';

export function getNotifications(params) {
  return request({
    url: '/notification/list',
    method: 'get',
    data: params,
  });
}

export function getUnreadNotificationsCount() {
  return request({
    url: '/notification/unread/count',
    method: 'get',
  });
}

export function readMultipleNotifications(params) {
  return request({
    url: '/notification/read/multiple',
    method: 'put',
    data: params,
  });
}

export function deleteNotification(uuid) {
  return request({
    url: `/notification/${uuid}`,
    method: 'delete',
    data: uuid,
  });
}
