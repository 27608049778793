export const performanceOptions = {
  tooltip: { isHtml: true },
  slices: {
    0: { color: '#009308' },
    1: { color: '#17b61f' },
    2: { color: '#1fcc28' },
    3: { color: '#dbb91d' },
    4: { color: '#ff7d00' },
    5: { color: '#dd0031' },
  },
  sliceVisibilityThreshold: 0.0,
  pieHole: 0.8,
  pieSliceText: 'none',
  legend: { position: 'none' },
  backgroundColor: {},
  chartArea: { width: '90%', height: '90%' },
};
