import {
  createCateringOrder,
  deleteCateringOrder,
  getCateringOrders,
  updateCateringOrder,
} from '../../api/catering';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const GET_CATERING_INVENTORY = 'GET_CATERING_INVENTORY';
export const GET_CATERING_INVENTORY_SUCCESS = 'GET_CATERING_INVENTORY_SUCCESS';
export const GET_CATERING_INVENTORY_ERROR = 'GET_CATERING_INVENTORY_ERROR';

export const CREATE_CATERING_ORDER = 'CREATE_CATERING_ORDER';
export const CREATE_CATERING_ORDER_SUCCESS = 'CREATE_CATERING_ORDER_SUCCESS';
export const CREATE_CATERING_ORDER_ERROR = 'CREATE_CATERING_ORDER_ERROR';

export const UPDATE_CATERING_ORDER = 'UPDATE_CATERING_ORDER';
export const UPDATE_CATERING_ORDER_SUCCESS = 'UPDATE_CATERING_ORDER_SUCCESS';
export const UPDATE_CATERING_ORDER_ERROR = 'UPDATE_CATERING_ORDER_ERROR';

export const DELETE_CATERING_ORDER = 'DELETE_CATERING_ORDER';
export const DELETE_CATERING_ORDER_SUCCESS = 'DELETE_CATERING_ORDER_SUCCESS';
export const DELETE_CATERING_ORDER_ERROR = 'DELETE_CATERING_ORDER_ERROR';

export const CHANGE_CATERING_INVENTORY_VALUE = 'CHANGE_CATERING_INVENTORY_VALUE';
export const CHANGE_CATERING_INVENTORY_SEVERAL_VALUES = 'CHANGE_CATERING_INVENTORY_SEVERAL_VALUES';

export const UPDATE_CATERING_INVENTORY_BATCHES = 'UPDATE_CATERING_INVENTORY_BATCHES';

export const RESET_CATERING_INVENTORY_STORE = 'RESET_CATERING_INVENTORY_STORE';

export const getCateringOrdersAction = (function () {
  let prevController;

  return (params, actions = null) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_CATERING_INVENTORY, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await getCateringOrders(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          actions?.onSuccess([...results.content]);
          dispatch({ type: GET_CATERING_INVENTORY_SUCCESS, payload: results, params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.error(error);
          actions?.onError(error.message);
          dispatch({ type: GET_CATERING_INVENTORY_ERROR, params });
        }
      }
    };
  };
})();

export const createCateringOrderAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_CATERING_ORDER });
    try {
      const response = await createCateringOrder(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: CREATE_CATERING_ORDER_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: CREATE_CATERING_ORDER_ERROR });
    }
  };
};

export const updateCateringOrderAction = (uuid, params, actions) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_CATERING_ORDER });
    try {
      const response = await updateCateringOrder(uuid, params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: UPDATE_CATERING_ORDER_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: UPDATE_CATERING_ORDER_ERROR });
    }
  };
};

export const deleteCateringOrderAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_CATERING_ORDER });
    try {
      const response = await deleteCateringOrder(params);
      const { success } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: DELETE_CATERING_ORDER_SUCCESS });
      }
    } catch (e) {
      actions.onError(e.message);
      dispatch({ type: DELETE_CATERING_ORDER_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CATERING_INVENTORY_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_CATERING_INVENTORY_SEVERAL_VALUES,
  payload: value,
});

export const updateCateringOrdersBatches = (data) => ({
  type: UPDATE_CATERING_INVENTORY_BATCHES,
  payload: data,
});

export const resetStore = () => ({
  type: RESET_CATERING_INVENTORY_STORE,
});
