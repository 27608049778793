import cx from 'classnames';

const NavItem = ({ id, width, Icon, label, active, onClick, lastTab, nextTabActive }) => {
  return (
    <li
      data-id={id}
      className={cx('nav-item', { active: active, 'tab-separator': !active && !lastTab && !nextTabActive })}
      style={{ width: width || 'auto' }}
      aria-selected={active}
      role="tab"
      onClick={onClick}
    >
      {Icon ? Icon : ''}
      <span>{label}</span>
    </li>
  );
};

export default NavItem;
