import { TableContentContainer } from '../../TableContentContainer';
import { MobileTableContent } from './MobileTableContent';

export const MobileTBody = ({
  rows,
  isLoading,
  emptyText,
  openedRows,
  checkOpenedRows,
  headerOptions,
  hideMobileCell,
}) => {
  return (
    <TableContentContainer rows={rows} isLoading={isLoading} emptyText={emptyText} isMobile>
      <MobileTableContent
        rows={rows}
        openedRows={openedRows}
        checkOpenedRows={checkOpenedRows}
        headerOptions={headerOptions}
        hideMobileCell={hideMobileCell}
      />
    </TableContentContainer>
  );
};
