import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import cx from 'classnames';

import { ResizableWrapper } from '../ResizableWrapper';
import { MiniPlayerStream } from './MiniPlayerStream';
import { MiniPlayerVideo } from './MiniPlayerVideo';

import {
  changeValue,
  resetStore,
  setMiniPLayerPosition,
  setMiniPLayerSize,
} from '../../redux/actions/miniPlayerAction';

import { checkAndUpdateDimensions } from '../../utils/resizing';

import './MiniPlayer.scss';

export const MiniPlayer = ({ login, app, miniPlayer, changeValue, savePosition, saveSize, resetStore }) => {
  const [playerSize, setPlayerSize] = useState({ height: 290, width: 445 });
  const [position, setPosition] = useState({ top: -500, left: -500 });
  const [isMoving, setMoving] = useState(false);

  const miniPlayerRef = useRef(null);
  const videoContainerRef = useRef(null);
  const isCapturedRef = useRef(false);
  const offsetX = useRef(null);
  const offsetY = useRef(null);
  const positionRef = useRef({ top: -500, left: -500 });
  const playerSizeRef = useRef({ height: 290, width: 445 });

  useEffect(() => {
    const limits = getLimits();
    if ((miniPlayer.savedPosition && miniPlayer.savedSize) || miniPlayer.savedSize) {
      const { left, top, width, height } = checkAndUpdateDimensions(
        miniPlayer.savedPosition,
        miniPlayer.savedSize,
        15,
        0,
        300,
        200
      );
      setPosition({ left, top });
      setPlayerSize({ width, height });
    } else if (miniPlayer.savedPosition) {
      setPosition({
        top: miniPlayer.savedPosition.top > limits.maxTop ? limits.maxTop : miniPlayer.savedPosition.top,
        left: miniPlayer.savedPosition.left > limits.maxLeft ? limits.maxLeft : miniPlayer.savedPosition.left,
      });
    } else {
      setPosition({
        top: limits.maxTop,
        left: limits.maxLeft,
      });
    }

    const handleMouseMove = (e) => {
      if (isCapturedRef.current) {
        const limits = getLimits();
        const top = e.clientY - (offsetY.current ? offsetY.current : 25);
        const left = e.clientX - (offsetX.current ? offsetX.current : 15);
        setPosition({
          top: top + 20 > limits.maxTop ? limits.maxTop : top - 20 < limits.minTop ? limits.minTop : top,
          left: left > limits.maxLeft ? limits.maxLeft : left < limits.minLeft ? limits.minLeft : left,
        });
      }
    };

    const handleMouseUp = () => {
      if (isCapturedRef.current) {
        isCapturedRef.current = false;
        setMoving(false);
        positionRef.current && savePosition(positionRef.current);
      }
    };

    const handleResizeWindow = () => {
      const { left, top, width, height } = checkAndUpdateDimensions(
        positionRef.current,
        playerSizeRef.current,
        15,
        0,
        300,
        200
      );
      setPosition({ left, top });
      setPlayerSize({ width, height });
      updateSavedPositionAndSize({ left, top }, { width, height });
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('resize', handleResizeWindow);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (app.screen === 'mobile') {
      resetStore();
    }
  }, [app.screen]);

  useEffect(() => {
    !login.isLoggedIn && resetStore();
  }, [login.isLoggedIn]);

  useEffect(() => {
    positionRef.current = position;
  }, [position]);

  useEffect(() => {
    playerSizeRef.current = playerSize;
  }, [playerSize]);

  const getLimits = () => {
    return {
      maxLeft: window.innerWidth - miniPlayerRef.current?.offsetWidth - 15,
      maxTop: window.innerHeight - miniPlayerRef.current?.offsetHeight,
      minLeft: 15,
      minTop: 0,
    };
  };

  const handleDragStart = (e) => {
    e.preventDefault();
    setMoving(true);
    isCapturedRef.current = true;
    offsetX.current = e.nativeEvent.offsetX;
    offsetY.current = e.nativeEvent.offsetY;
  };

  const updateSavedPositionAndSize = (position, size) => {
    savePosition(position);
    saveSize(size);
  };

  const generatePlayerContent = (type) => {
    switch (type) {
      case 'stream':
        return (
          <MiniPlayerStream miniPLayer={miniPlayer} changeValue={changeValue} closePlayer={resetStore} />
        );

      case 'video':
        return <MiniPlayerVideo miniPLayer={miniPlayer} changeValue={changeValue} closePlayer={resetStore} />;

      default:
        return (
          <MiniPlayerStream miniPLayer={miniPlayer} changeValue={changeValue} closePlayer={resetStore} />
        );
    }
  };

  const getBorderRadius = () => {
    const limits = getLimits();
    if (position.top + 20 > limits.maxTop && position.top - 20 > limits.minTop) {
      return {
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
      };
    } else if (position.top - 20 < limits.minTop) {
      return {
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
      };
    } else {
      return {
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
      };
    }
  };

  return (
    <div
      ref={miniPlayerRef}
      className={cx('mini-player', { moving: isMoving })}
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: playerSize.width && `${playerSize.width}px`,
        height: playerSize.height && `${playerSize.height}px`,
        ...getBorderRadius(),
      }}
      onDragStart={handleDragStart}
      draggable
    >
      <ResizableWrapper
        wrapperSize={playerSize}
        sizeRef={playerSizeRef}
        position={position}
        positionRef={positionRef}
        setPosition={setPosition}
        setSize={setPlayerSize}
        minHeight={200}
        minWidth={300}
        updateSavedPositionAndSize={updateSavedPositionAndSize}
      >
        <div ref={videoContainerRef} className="mini-player__container">
          {generatePlayerContent(miniPlayer.type)}
        </div>
        <div className="mini-player__metadata">
          <h3 className="mini-player__title">{miniPlayer.name}</h3>
        </div>
      </ResizableWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  miniPlayer: state.miniPlayer,
  login: state.login,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (key, value) => dispatch(changeValue(key, value)),
    savePosition: (position) => dispatch(setMiniPLayerPosition(position)),
    saveSize: (size) => dispatch(setMiniPLayerSize(size)),
    resetStore: () => dispatch(resetStore()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniPlayer);
