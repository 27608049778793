import React from 'react';

import { Preloader } from '../Preloader/Preloader';

import './ImagePreview.scss';

export const ImagePreview = ({ image: { src, loading, error } }) => {
  const imageContent = () => {
    if (loading) {
      return <Preloader big={true} />;
    }

    if (error || !src) {
      return <span className="image-preview__no-image">No Image</span>;
    }

    return <img className="image-preview__image" src={src} alt="Preview" />;
  };

  return <div className="image-preview__container">{imageContent()}</div>;
};
