import React from 'react';
import cx from 'classnames';

import './ButtonOnVideo.scss';

export const ButtonOnVideo = ({ className, Icon, active, disabled, tooltip, onClick }) => {
  return (
    <button
      className={cx('button-on-video', className, { active })}
      disabled={disabled}
      data-tooltip-id={tooltip?.id ? tooltip.id : null}
      data-tooltip-content={tooltip?.title ? tooltip.title : null}
      data-tooltip-place={tooltip?.place ? tooltip.place : 'top'}
      onClick={onClick}
    >
      <Icon />
    </button>
  );
};
