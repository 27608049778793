import { EmptyContainer, Preloader } from '../../../components';
import './styles.scss';

export const MobileUserSessions = ({ rows, isLoading }) => {
  return (
    <div className="mobile-block__container">
      {isLoading ? (
        <Preloader big={true} />
      ) : rows.length !== 0 ? (
        rows.map((row, index) => (
          <div key={row.id || index} className="mobile-block">
            {row.cells.map((item, i) => (
              <div className="mobile-block__item" key={i}>
                {item.label ? (
                  <>
                    <div className="mobile-block__item-title">{item.title}</div>
                    <div className="mobile-block__item-label">{item.label}</div>
                  </>
                ) : (
                  item.cellComponent
                )}
              </div>
            ))}
          </div>
        ))
      ) : (
        <EmptyContainer />
      )}
    </div>
  );
};
