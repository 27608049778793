import request from '../utils/request';

export function getRestaurants(params) {
  return request({
    url: `/restaurants`,
    method: 'get',
    data: {
      expand: 'hosts',
      ...params,
    },
  });
}

export function getMachines() {
  return request({
    url: `/monitoring/label/host/values`,
    method: 'get',
  });
}

export function getCPUScalar(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `100-(avg(rate(node_cpu_seconds_total${
        machine === 'select' ? '' : `{host="${machine}",mode="idle"}`
      }[2m]))*100)`,
    },
  });
}

export function getCPUCores(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: { query: `machine_cpu_cores${machine === 'select' ? '' : `{host="${machine}"}`}` },
  });
}

export function getOSInfo(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_os_info${machine === 'select' ? '' : `{host="${machine}"}`}`,
    },
  });
}

export function getTimezone(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_time_zone_offset_seconds${machine === 'select' ? '' : `{host="${machine}"}`}`,
    },
  });
}

export function getSWAPTotal(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_memory_SwapTotal_bytes${machine === 'select' ? '' : `{host="${machine}"}`}`,
    },
  });
}

export function getSWAPFree(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_memory_SwapFree_bytes${machine === 'select' ? '' : `{host="${machine}"}`}`,
    },
  });
}

export function getSWAPScalar(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `((node_memory_SwapTotal_bytes${
        machine === 'select' ? '' : `{host="${machine}"}`
      } - node_memory_SwapFree_bytes${
        machine === 'select' ? '' : `{host="${machine}"}`
      }) / (node_memory_SwapTotal_bytes${machine === 'select' ? '' : `{host="${machine}"}`} )) * 100`,
    },
  });
}

export function getSWAPVector(machine, params) {
  return request({
    url: `/monitoring/query_range`,
    method: 'get',
    data: {
      query: `((node_memory_SwapTotal_bytes${
        machine === 'select' ? '' : `{host="${machine}"}`
      } - node_memory_SwapFree_bytes${machine === 'select' ? '' : `{host="${machine}"}`}))`,
      ...params,
    },
  });
}

export function getCPUInfo(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_cpu_info{cpu="0"${machine === 'select' ? '' : `,host="${machine}"`}}`,
    },
  });
}

export function getCPUTemperature(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_hwmon_temp_celsius${
        machine === 'select' ? '' : `{host="${machine}"}`
      } * on(chip, sensor) group_left(label) node_hwmon_sensor_label{label="Package id 0"${
        machine === 'select' ? '' : `,host="${machine}"`
      }}`,
    },
  });
}

export function getCPUVector(machine, params) {
  return request({
    url: `/monitoring/query_range`,
    method: 'get',
    data: {
      query: `100 - avg by (host) (rate(node_cpu_seconds_total${
        machine === 'select'
          ? `{mode="idle"}`
          : `{host=${machine.includes('|') ? '~' : ''}"${machine}",mode="idle"}`
      }[2m]))*100`,
      ...params,
    },
  });
}

export function getAllRAM(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_memory_MemTotal_bytes${machine === 'select' ? '' : `{host="${machine}"}`}`,
    },
  });
}

export function getAvailableRAM(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_memory_MemAvailable_bytes${machine === 'select' ? '' : `{host="${machine}"}`}`,
    },
  });
}

export function getRAMScalar(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `((node_memory_MemTotal_bytes${
        machine === 'select' ? '' : `{host="${machine}"}`
      } - node_memory_MemAvailable_bytes${
        machine === 'select' ? '' : `{host="${machine}"}`
      })/node_memory_MemTotal_bytes${machine === 'select' ? '' : `{host="${machine}"}`})*100`,
    },
  });
}

export function getRAMVector(machine, params) {
  return request({
    url: `/monitoring/query_range`,
    method: 'get',
    data: {
      query: `((node_memory_MemTotal_bytes${
        machine === 'select' ? '' : `{host=${machine.includes('|') ? '~' : ''}"${machine}"}`
      } - node_memory_MemAvailable_bytes${
        machine === 'select' ? '' : `{host=${machine.includes('|') ? '~' : ''}"${machine}"}`
      })${
        machine === 'select' || machine.includes('|')
          ? `/node_memory_MemTotal_bytes${machine.includes('|') ? `{host=~"${machine}"}` : ''})*100`
          : ')'
      }`,
      ...params,
    },
  });
}

export function getAllGPU(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `DCGM_FI_DEV_FB_FREE${machine === 'select' ? '' : `{host="${machine}"}`}+DCGM_FI_DEV_FB_USED${
        machine === 'select' ? '' : `{host="${machine}"}`
      }`,
    },
  });
}

export function getGPUTemperature(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `DCGM_FI_DEV_GPU_TEMP${machine === 'select' ? '' : `{host="${machine}"}`}`,
    },
  });
}

export function getAvailableGPU(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `DCGM_FI_DEV_FB_FREE${machine === 'select' ? '' : `{host="${machine}"}`}`,
    },
  });
}

export function getGPUScalar(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `DCGM_FI_DEV_FB_USED${machine === 'select' ? '' : `{host="${machine}"}`}/(DCGM_FI_DEV_FB_FREE${
        machine === 'select' ? '' : `{host="${machine}"}`
      }+DCGM_FI_DEV_FB_USED${machine === 'select' ? '' : `{host="${machine}"}`})*100`,
    },
  });
}

export function getGPUVector(machine, params) {
  return request({
    url: `/monitoring/query_range`,
    method: 'get',
    data: {
      query: `(DCGM_FI_DEV_FB_USED${
        machine === 'select' ? '' : `{host=${machine.includes('|') ? '~' : ''}"${machine}"}`
      }${
        machine === 'select' || machine.includes('|')
          ? `/(DCGM_FI_DEV_FB_FREE+DCGM_FI_DEV_FB_USED${
              machine.includes('|') ? `{host=~"${machine}"}` : ''
            }))*100`
          : ')'
      }`,
      ...params,
    },
  });
}

export function getGPUUtilVector(machine, params) {
  const metrics = [
    'DCGM_FI_DEV_GPU_UTIL',
    'DCGM_FI_DEV_MEM_COPY_UTIL',
    'DCGM_FI_DEV_ENC_UTIL',
    'DCGM_FI_DEV_DEC_UTIL',
  ];

  const metricQuery = metrics.join('|');
  const hostFilter = machine === 'select' ? '' : `, host=${machine.includes('|') ? '~' : ''}"${machine}"`;

  return request({
    url: `/monitoring/query_range`,
    method: 'get',
    data: {
      query: `{__name__=~"${metricQuery}"${hostFilter}}`,
      ...params,
    },
  });
}

export function getAllStorage(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_filesystem_size_bytes{mountpoint="/",fstype!="rootfs"${
        machine === 'select' ? '' : `,host="${machine}"`
      }}`,
    },
  });
}

export function getAvailableStorage(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_filesystem_free_bytes{mountpoint="/",fstype!="rootfs"${
        machine === 'select' ? '' : `, host="${machine}"`
      }}`,
    },
  });
}

export function getStorageScalar(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `((node_filesystem_size_bytes{mountpoint="/",fstype!="rootfs"${
        machine === 'select' ? '' : `,host="${machine}"`
      }}-node_filesystem_free_bytes{mountpoint="/",fstype!="rootfs"${
        machine === 'select' ? '' : `,host="${machine}"`
      }})/node_filesystem_size_bytes{mountpoint="/",fstype!="rootfs"${
        machine === 'select' ? '' : `,host="${machine}"`
      }})*100`,
    },
  });
}

export function getStorageVector(machine, params) {
  return request({
    url: `/monitoring/query_range`,
    method: 'get',
    data: {
      query: `((node_filesystem_size_bytes{mountpoint="/",fstype!="rootfs"${
        machine === 'select' ? '' : `,host=${machine.includes('|') ? '~' : ''}"${machine}"`
      }}-node_filesystem_free_bytes{mountpoint="/",fstype!="rootfs"${
        machine === 'select' ? '' : `,host=${machine.includes('|') ? '~' : ''}"${machine}"`
      }})${
        machine === 'select' || machine.includes('|')
          ? `/node_filesystem_size_bytes{mountpoint="/",fstype!="rootfs"${
              machine.includes('|') ? `,host=~"${machine}"` : ''
            }})*100`
          : ')'
      }`,
      ...params,
    },
  });
}

export function getStorageReadsVector(machine, params) {
  return request({
    url: `/monitoring/query_range`,
    method: 'get',
    data: {
      query: `irate(node_disk_read_bytes_total{device!="lo"${
        machine === 'select' ? '' : `,host=${machine.includes('|') ? '~' : ''}"${machine}"`
      }}[2m])`,
      ...params,
    },
  });
}

export function getStorageWritesVector(machine, params) {
  return request({
    url: `/monitoring/query_range`,
    method: 'get',
    data: {
      query: `irate(node_disk_written_bytes_total{device!="lo"${
        machine === 'select' ? '' : `,host=${machine.includes('|') ? '~' : ''}"${machine}"`
      }}[2m])`,
      ...params,
    },
  });
}

export function getReceiveNetworkScalar(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `irate(node_network_receive_bytes_total{device!="lo"${
        machine === 'select' ? '' : `,host="${machine}"`
      }}[2m])`,
    },
  });
}

export function getTransmitNetworkScalar(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `irate(node_network_transmit_bytes_total{device!="lo"${
        machine === 'select' ? '' : `,host="${machine}"`
      }}[2m])`,
    },
  });
}

export function getReceiveNetworkVector(machine, params) {
  return request({
    url: `/monitoring/query_range`,
    method: 'get',
    data: {
      query: `irate(node_network_receive_bytes_total{device!="lo"${
        machine === 'select' ? '' : `,host=${machine.includes('|') ? '~' : ''}"${machine}"`
      }}[2m])`,
      ...params,
    },
  });
}

export function getTransmitNetworkVector(machine, params) {
  return request({
    url: `/monitoring/query_range`,
    method: 'get',
    data: {
      query: `irate(node_network_transmit_bytes_total{device!="lo"${
        machine === 'select' ? '' : `,host=${machine.includes('|') ? '~' : ''}"${machine}"`
      }}[2m])`,
      ...params,
    },
  });
}

export function getUpTime(machine) {
  return request({
    url: `/monitoring/query`,
    method: 'get',
    data: {
      query: `node_time_seconds-node_boot_time_seconds${machine === 'select' ? '' : `{host="${machine}"}`}`,
    },
  });
}
