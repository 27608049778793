import moment from 'moment';
import { hAxisOptions } from '../../../constants/analytics.constants';
import { miniGraphicColors, units } from '../../../constants/graphics.constants';
import { convertBytes, getTimezoneOffsetInSeconds } from '../../../utils';
import { initialValues } from '../../../utils/chartHelper';

export const getNetworkOptions = (data, dates, colorIndex, chartMetric) => {
  let minValue =
    convertBytes(Number(Math.max(...data[1].values.map((val) => val[1]).flat())), units, chartMetric, true) *
    1.2;
  let maxValue =
    convertBytes(Number(Math.max(...data[0].values.map((val) => val[1]).flat())), units, chartMetric, true) *
    1.2;
  let minimum =
    data !== null && data.length !== 0 && data[1].values !== null && chartMetric !== '%'
      ? -Math.ceil(minValue) || -1
      : -100;
  let maximum =
    data !== null && data.length !== 0 && data[0].values !== null && chartMetric !== '%'
      ? Math.ceil(maxValue > 100 ? maxValue : 100) || 1
      : 100;
  return serverOptions(
    data,
    colorIndex,
    dates,
    chartMetric,
    { width: '94%', height: '60%' },
    maximum,
    minimum
  );
};

export const serverOptions = (data, colorIndex, dates, chartMetric, chartArea, max, min = 0) => ({
  animation: { startup: true, duration: 700, easing: 'out' },
  focusTarget: 'category',
  tooltip: { isHtml: true },
  colors: miniGraphicColors.slice(colorIndex).concat(miniGraphicColors.slice(0, colorIndex)),
  legend: { position: data.length > 1 ? 'bottom' : 'none' },
  width: '100%',
  hAxis: {
    minValue: new Date(
      Math.floor(
        dates[2] !== null ? Date.now() + getTimezoneOffsetInSeconds(dates[3]) - dates[2] * 1000 : dates[0]
      )
    ),
    ...hAxisOptions,
  },
  vAxis: {
    gridlines: {
      count: 4,
    },
    format: chartMetric === '%' ? `#'${chartMetric}'` : `#.#' ${chartMetric}'`,
    viewWindow: {
      min: min,
      max: max,
    },
    minValue: min,
    maxValue: max,
  },
  chartArea: chartArea,
});

export const getChartOptions = (
  data,
  dates,
  colorIndex,
  allMetric,
  chartMetric,
  chartArea,
  percentMaxValue = false
) => {
  let maxValue =
    data !== null && data.length !== 0 && data[0].values !== null && (chartMetric !== '%' || percentMaxValue)
      ? Math.ceil(
          convertBytes(
            !!allMetric
              ? Number(Math.max(...allMetric.map((item) => Math.round(item.value))))
              : Number(Math.max(...data.map((it) => it.values.map((val) => val[1])).flat())),
            units,
            chartMetric,
            true
          ) * (!!allMetric ? 1 : 1.2)
        ) || 1
      : 100;
  return serverOptions(
    data,
    colorIndex,
    dates,
    chartMetric,
    chartArea ? chartArea : { width: '93%', height: '80%', right: 5 },
    maxValue
  );
};

export const getChartData = (data, dates, chartMetric, doubleChart = false, showUnits = false) => {
  return [
    [
      'Timestamp',
      { type: 'string', role: 'tooltip', p: { html: true } },
      ...(data.length === 1 ? [chartMetric] : data.map((it) => it.metricLabel || it.machine)),
    ],
    ...(data[0].values.length !== 0
      ? data[0].values.map((value, index) => [
          new Date(new Date(value[0]).getTime() + getTimezoneOffsetInSeconds(dates[3])),
          `<div class="chart-tooltip">${moment(value[0]).tz(dates[3]).format('MMM D, YYYY, HH:mm:ss')}${`${
            data.length > 1
              ? data
                  .map((content) => [
                    content.metricLabel || content.machine,
                    content.values[index] && content.values[index][1] !== null
                      ? Number(content.values[index][1].toFixed(2))
                      : 'No data',
                  ])
                  .sort((x, y) => {
                    return y[1] - x[1];
                  })
                  .map(
                    (content) =>
                      `<div>${content[0]}: <b>${content[1]}${
                        showUnits ? (chartMetric === '%' ? '%' : units[chartMetric]) : ''
                      }</b></div>`
                  )
              : value
                  .slice(1)
                  .map(
                    (content) =>
                      `<div>${chartMetric === '%' ? 'Percent' : chartMetric}: <b>${content.toFixed(
                        2
                      )}</b></div>`
                  )
          }`.replaceAll(',', '')}</div>`,
          ...(data.length > 1
            ? data.map((value, valueIndex) =>
                value.values[index]
                  ? doubleChart
                    ? valueIndex === 0
                      ? value.values[index][1]
                      : -value.values[index][1]
                    : value.values[index][1]
                  : null
              )
            : [value[1]]),
        ])
      : initialValues(dates)),
  ];
};
