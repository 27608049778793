import * as types from '../actions/chickenPerfAction';

const initialState = {
  restaurantUuid: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: null,
  availableTabs: [],
  materialTab: null,
  metrics: null,
  isLoading: false,
  startTimeInterval: null,
  endTimeInterval: null,
};

const chickenPerfReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CHICKEN_PERFORMANCE_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_CHICKEN_PERFORMANCE_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.SET_CHICKEN_PERFORMANCE_LOADING:
      return {
        ...state,
        metrics: {},
        availableTabs: [],
        isLoading: true,
      };

    case types.SET_CHICKEN_PERFORMANCE_METRICS:
      return {
        ...state,
        metrics: action.metrics,
        availableTabs: Object.keys(action.metrics),
        materialTab: Object.keys(action.metrics)[0] || null,
        isLoading: false,
      };

    case types.SET_CHICKEN_PERFORMANCE_ERROR:
      return {
        ...state,
        metrics: {},
        isLoading: false,
      };

    case types.RESET_CHICKEN_PERFORMANCE_CHARTS:
      return {
        ...state,
        availableTabs: [],
        metrics: null,
      };

    case types.RESET_CHICKEN_PERFORMANCE_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default chickenPerfReducer;
