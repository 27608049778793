import { getAnalytic } from '../../api/dashboardAnalytics';
import {
  getAverageHoldTimeColor,
  getAverageHoldTimeTitle,
  getColorCompliant,
  getPackageTypeColor,
  getPotatoTypeColor,
} from '../../utils/chartHelper';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_POTATO_DASHBOARD_VALUE = 'CHANGE_POTATO_DASHBOARD_VALUE';
export const CHANGE_POTATO_DASHBOARD_SEVERAL_VALUES = 'CHANGE_POTATO_DASHBOARD_SEVERAL_VALUES';
export const RESET_POTATO_DASHBOARD_STORE = 'RESET_POTATO_DASHBOARD_STORE';
export const RESET_POTATO_CHARTS_DATA = 'RESET_POTATO_CHARTS_DATA';
export const ADD_POTATO_CHART = 'ADD_POTATO_CHART';
export const GET_POTATO_CHART = 'GET_POTATO_CHART';

export const getPotatoCharts = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      try {
        dispatch(
          resetCharts([
            {
              groupTitle: 'Hold Time Compliance by Item (Package type)',
              rows: null,
              order: 1,
              options: {},
            },
            {
              groupTitle: 'Hold Time Daypart Reports',
              chartTitle: 'Hold Time Compliance by Daypart',
              rows: null,
              order: 2,
              options: {},
            },
            {
              chartTitle: 'Hold Time Average by Daypart',
              rows: null,
              order: 3,
              options: {},
            },
            {
              groupTitle: 'Production Reports',
              chartTitle: 'Number of Items Packaged per Day',
              rows: null,
              order: 4,
              options: {},
            },
            {
              chartTitle: 'Number of Batches per Day',
              rows: null,
              order: 5,
              options: {},
            },
          ])
        );
        const abortController = handleActionAbortController(prevController);
        prevController = abortController;
        if (!abortController.signal.aborted) {
          dispatch(getHoldTimeComplianceByItem(params, 1, abortController.signal));
          dispatch(getHoldTimeComplianceByDayPart(params, 2, abortController.signal));
          dispatch(getHoldTimeAverageByDayPart(params, 3, abortController.signal));
          dispatch(getNumberOfItemPackagedPerDay(params, 4, abortController.signal));
          dispatch(getNumberOfBatchesPerDay(params, 5, abortController.signal));
        }
      } catch {
        //empty block
        () => undefined;
      }
    };
  };
})();

export const getHoldTimeComplianceByItem = (params, order, signal) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_POTATO_CHART, order });
      const response = await getAnalytic('/potato-hold-time', params, signal);
      const { success, results } = response;
      if (success) {
        const data = results;

        const chartRows = [];
        const chartColumns = [];
        const sKeys = [];

        chartColumns.push('Package type');
        Object.keys(data).forEach((value) => {
          const tempArr = [];
          tempArr.push(value);
          Object.keys(data[value]).forEach((key) => {
            if (key !== 'Unknown') {
              if (!chartColumns.includes(key)) {
                chartColumns.push(key);
                sKeys.push(key);
              }
              tempArr.push(data[value][key]);
            }
          });

          chartRows.push(tempArr);
        });

        const chartOptions = {
          titleTextStyle: {
            color: '#68605a',
            fontName: 'Roboto',
            fontSize: '20pt',
          },
          isStacked: true,
          // height: 300,
          legend: { position: 'top', maxLines: 3 },
          vAxis: {
            title: 'Percent',
            minValue: 0,
          },
          hAxis: {
            title: 'Material',
          },
          series: {
            ...sKeys.map((key) => {
              return { color: getColorCompliant(key) };
            }),
          },
        };

        const chart = {
          groupTitle: 'Hold Time Compliance by Item (Package type)',
          order: order,
          chartType: 'ColumnChart',
          columns: chartColumns,
          rows: chartRows,
          options: chartOptions,
          rootProps: { 'data-testid': `${order}` },
        };
        dispatch(addChart(chart, params));
      }
    } catch {
      if (!signal.aborted) {
        const chart = {
          groupTitle: 'Hold Time Compliance by Item (Package type)',
          rows: [],
          order: order,
          options: {},
        };
        dispatch(addChart(chart, params));
      }
    }
  };
};

export const getHoldTimeComplianceByDayPart = (params, order, signal) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_POTATO_CHART, order });
      const response = await getAnalytic('/potato-hold-time-by-day-part', params, signal);
      const { success, results } = response;
      if (success) {
        const data = results;

        const chartRows = [];
        const chartColumns = [];
        const sKeys = [];

        chartColumns.push('Daypart');
        Object.keys(data).forEach((value) => {
          const tempArr = [];
          tempArr.push(value);
          Object.keys(data[value]).forEach((key) => {
            if (key !== 'Unknown') {
              if (!chartColumns.includes(key)) {
                chartColumns.push(key);
                sKeys.push(key);
              }
              tempArr.push(data[value][key]);
            }
          });

          chartRows.push(tempArr);
        });

        const chartOptions = {
          titleTextStyle: {
            color: '#68605a',
            fontName: 'Roboto',
            fontSize: '20pt',
          },
          isStacked: true,
          legend: { position: 'top', maxLines: 3 },
          vAxis: {
            title: 'Percent',
            minValue: 0,
          },
          hAxis: {
            title: 'Daypart',
          },
          series: {
            ...sKeys.map((key) => {
              return { color: getColorCompliant(key) };
            }),
          },
        };

        const chart = {
          groupTitle: 'Hold Time Daypart Reports',
          chartTitle: 'Hold Time Compliance by Daypart',
          order: order,
          chartType: 'ColumnChart',
          columns: chartColumns,
          rows: chartRows,
          options: chartOptions,
          rootProps: { 'data-testid': `${order}` },
        };
        dispatch(addChart(chart, params));
      }
    } catch {
      if (!signal.aborted) {
        const chart = {
          groupTitle: 'Hold Time Daypart Reports',
          chartTitle: 'Hold Time Compliance by Daypart',
          rows: [],
          order: order,
          options: {},
        };
        dispatch(addChart(chart, params));
      }
    }
  };
};

export const getHoldTimeAverageByDayPart = (params, order, signal) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_POTATO_CHART, order });
      const response = await getAnalytic('/potato-average-hold-time', params, signal);
      const { success, results } = response;
      if (success) {
        const data = results;

        const chartRows = [];
        const chartColumns = [];
        const sKeys = [];

        chartColumns.push('Daypart');
        Object.keys(data).forEach((value) => {
          const tempArr = [];
          tempArr.push(value);
          Object.keys(data[value]).forEach((key) => {
            if (key !== 'Unknown') {
              if (!chartColumns.includes(key)) {
                sKeys.push(key);
              }
              if (!chartColumns.includes(getAverageHoldTimeTitle(key))) {
                chartColumns.push(getAverageHoldTimeTitle(key));
              }
              tempArr.push(data[value][key]);
            }
          });

          chartRows.push(tempArr);
        });

        const chartOptions = {
          titleTextStyle: {
            color: '#68605a',
            fontName: 'Roboto',
            fontSize: '20pt',
          },
          isStacked: false,
          legend: { position: 'top', maxLines: 3 },
          vAxis: {
            title: 'Minutes',
            minValue: 0,
          },
          hAxis: {
            title: 'Daypart',
          },
          series: {
            ...sKeys.map((key) => {
              return { color: getAverageHoldTimeColor(key) };
            }),
          },
        };

        const chart = {
          chartTitle: 'Hold Time Average by Daypart',
          order: order,
          chartType: 'ColumnChart',
          columns: chartColumns,
          rows: chartRows,
          options: chartOptions,
          rootProps: { 'data-testid': `${order}` },
        };
        dispatch(addChart(chart, params));
      }
    } catch {
      if (!signal.aborted) {
        const chart = {
          chartTitle: 'Hold Time Average by Daypart',
          rows: [],
          order: order,
          options: {},
        };
        dispatch(addChart(chart, params));
      }
    }
  };
};

export const getNumberOfItemPackagedPerDay = (params, order, signal) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_POTATO_CHART, order });
      const response = await getAnalytic('/potato-packaged-statistic', params, signal);
      const { success, results } = response;
      if (success) {
        const data = results;

        const chartRows = [];
        const chartColumns = [];
        const sKeys = [];

        chartColumns.push(params.analyticPeriod);
        Object.keys(data).forEach((value) => {
          const tempArr = [];
          tempArr.push(value);
          Object.keys(data[value]).forEach((key) => {
            if (!sKeys.includes(key)) {
              sKeys.push(key);
              chartColumns.push(key);
            }
            tempArr.push(data[value][key]);
          });

          chartRows.push(tempArr);
        });

        const chartOptions = {
          titleTextStyle: {
            color: '#68605a',
            fontName: 'Roboto',
            fontSize: '20pt',
          },
          isStacked: false,
          legend: { position: 'top', maxLines: 3 },
          vAxis: {
            title: 'Number',
            minValue: 0,
          },
          hAxis: {
            title: 'Daypart',
          },
          series: {
            ...sKeys.map((key) => {
              return { color: getPackageTypeColor(key) };
            }),
          },
        };

        const chart = {
          groupTitle: 'Production Reports',
          chartTitle: 'Number of Items Packaged per Day',
          order: order,
          chartType: 'ColumnChart',
          columns: chartColumns,
          rows: chartRows,
          options: chartOptions,
          rootProps: { 'data-testid': `${order}` },
        };
        dispatch(addChart(chart, params));
      }
    } catch {
      if (!signal.aborted) {
        const chart = {
          groupTitle: 'Production Reports',
          chartTitle: 'Number of Items Packaged per Day',
          rows: [],
          order: order,
          options: {},
        };
        dispatch(addChart(chart, params));
      }
    }
  };
};

export const getNumberOfBatchesPerDay = (params, order, signal) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_POTATO_CHART, order });
      const response = await getAnalytic('/potato-batches-statistic', params, signal);
      const { success, results } = response;
      if (success) {
        const data = results;

        const chartRows = [];
        const chartColumns = [];
        const sKeys = [];

        chartColumns.push(params.analyticPeriod);
        Object.keys(data).forEach((value) => {
          const tempArr = [];
          tempArr.push(value);
          Object.keys(data[value]).forEach((key) => {
            if (!sKeys.includes(key)) {
              sKeys.push(key);
              chartColumns.push(key);
            }
            tempArr.push(data[value][key]);
          });

          chartRows.push(tempArr);
        });

        const chartOptions = {
          titleTextStyle: {
            color: '#68605a',
            fontName: 'Roboto',
            fontSize: '20pt',
          },
          isStacked: false,
          legend: { position: 'top', maxLines: 3 },
          vAxis: {
            title: 'Number',
            minValue: 0,
          },
          hAxis: {
            title: 'Daypart',
          },
          series: {
            ...sKeys.map((key) => {
              return { color: getPotatoTypeColor(key) };
            }),
          },
        };

        const chart = {
          chartTitle: 'Number of Batches per Day',
          order: order,
          chartType: 'ColumnChart',
          columns: chartColumns,
          rows: chartRows,
          options: chartOptions,
          rootProps: { 'data-testid': `${order}` },
        };
        dispatch(addChart(chart, params));
      }
    } catch {
      if (!signal.aborted) {
        const chart = {
          chartTitle: 'Number of Batches per Day',
          rows: [],
          order: order,
          options: {},
        };
        dispatch(addChart(chart, params));
      }
    }
  };
};

export const resetCharts = (value) => ({
  type: RESET_POTATO_CHARTS_DATA,
  payload: value,
});

const addChart = (chart, params) => ({
  type: ADD_POTATO_CHART,
  chart,
  params,
});

export const changeValue = (key, value) => ({
  type: CHANGE_POTATO_DASHBOARD_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_POTATO_DASHBOARD_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_POTATO_DASHBOARD_STORE,
});
