import { getModes, getZones, getZoneStreams, startStream, stopStream } from '../../api/plugplay';

export const CHANGE_PLUG_PLAY_VALUE = 'CHANGE_PLUG_PLAY_VALUE';
export const CHANGE_PLUG_PLAY_STREAM_VALUE = 'CHANGE_PLUG_PLAY_STREAM_VALUE';
export const RESET_PLUG_PLAY_STORE = 'RESET_PLUG_PLAY_STORE';
export const SET_PLUG_ACTIVE_STREAM_ID = 'SET_PLUG_ACTIVE_STREAM_ID';
export const SET_PLUG_STREAMS_LIST = 'SET_PLUG_STREAMS_LIST';
export const SET_PLUG_RESTART_STREAM = 'SET_PLUG_RESTART_STREAM';
export const SWAP_PLUG_PLAY_STREAMS = 'SWAP_PLUG_PLAY_STREAMS';

export const GET_PLUG_MODES = 'GET_PLUG_MODES';
export const GET_PLUG_MODES_SUCCESS = 'GET_PLUG_MODES_SUCCESS';
export const GET_PLUG_MODES_ERROR = 'GET_PLUG_MODES_ERROR';

export const GET_PLUG_ZONES = 'GET_PLUG_ZONES';
export const GET_PLUG_ZONES_SUCCESS = 'GET_PLUG_ZONES_SUCCESS';
export const GET_PLUG_ZONES_ERROR = 'GET_PLUG_ZONES_ERROR';

export const START_PLUG_STREAM = 'START_PLUG_STREAM';
export const START_PLUG_STREAM_SUCCESS = 'START_PLUG_STREAM_SUCCESS';
export const START_PLUG_STREAM_ERROR = 'START_PLUG_STREAM_ERROR';

export const STOP_PLUG_STREAM = 'STOP_PLUG_STREAM';
export const STOP_PLUG_STREAM_SUCCESS = 'STOP_PLUG_STREAM_SUCCESS';
export const STOP_PLUG_STREAM_ERROR = 'STOP_PLUG_STREAM_ERROR';

export const GET_PLUG_ZONE_STREAMS = 'GET_PLUG_ZONE_STREAMS';
export const GET_PLUG_ZONE_STREAMS_SUCCESS = 'GET_PLUG_ZONE_STREAMS_SUCCESS';
export const GET_PLUG_ZONE_STREAMS_ERROR = 'GET_PLUG_ZONE_STREAMS_ERROR';

export const setStreamsList = (list) => ({ type: SET_PLUG_STREAMS_LIST, payload: list });

export const setActiveStreamId = (id) => ({ type: SET_PLUG_ACTIVE_STREAM_ID, payload: id });

export const setRestartStream = (needRestart) => ({
  type: SET_PLUG_RESTART_STREAM,
  payload: needRestart,
});

export const getZonesAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: GET_PLUG_ZONES });
    try {
      const response = await getZones(uuid);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_PLUG_ZONES_SUCCESS });
        actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_PLUG_ZONES_ERROR });
    }
  };
};

export const getModesAction = (actions) => {
  return async (dispatch) => {
    dispatch({ type: GET_PLUG_MODES });
    try {
      const response = await getModes();
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_PLUG_MODES_SUCCESS });
        actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_PLUG_MODES_ERROR });
    }
  };
};

export const changeZoneValue = (zone) => {
  return async (dispatch) => {
    dispatch({ type: GET_PLUG_ZONE_STREAMS });
    try {
      const response = await getZoneStreams(zone);
      const { success, results } = response;
      if (success) {
        dispatch({
          type: GET_PLUG_ZONE_STREAMS_SUCCESS,
          payload: {
            results: results,
            zone,
          },
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_PLUG_ZONE_STREAMS_ERROR });
    }
  };
};

export const startStreamAction = (zone, params, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: START_PLUG_STREAM });
    try {
      const response = await startStream(zone, params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: START_PLUG_STREAM_SUCCESS });
        actions?.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: START_PLUG_STREAM_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const stopStreamAction = (zone, params, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: STOP_PLUG_STREAM });
    try {
      const response = await stopStream(zone, params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: STOP_PLUG_STREAM_SUCCESS });
        actions?.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: STOP_PLUG_STREAM_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_PLUG_PLAY_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeStreamValue = (key, name, value) => ({
  type: CHANGE_PLUG_PLAY_STREAM_VALUE,
  payload: {
    key,
    name,
    value,
  },
});

export const swapLayoutStreams = (index1, index2) => ({
  type: SWAP_PLUG_PLAY_STREAMS,
  payload: {
    index1,
    index2,
  },
});

export const resetStore = () => ({
  type: RESET_PLUG_PLAY_STORE,
});
