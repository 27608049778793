import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';

import { ReactComponent as ArrowDropDown } from '../../../assets/img/icons/common/ArrowDropDown.svg';

import './FormQuantitySelect.scss';

export const FormQuantitySelect = ({ className, name, options, value, onChange, disabled }) => {
  const [selectOpened, setSelectOpened] = useState(false);

  const toggleRef = useRef(null);

  useEffect(() => {
    if (selectOpened) {
      document.addEventListener('click', checkSelectClick);
    }
  }, [selectOpened]);

  function checkSelectClick(event) {
    if (!(event.path || (event.composedPath && event.composedPath())).includes(toggleRef.current)) {
      setSelectOpened(false);
      return document.removeEventListener('click', checkSelectClick);
    }
  }

  const handleSelectOption = (newValue) => {
    name ? onChange(name, newValue) : onChange(newValue);
    setSelectOpened(false);
  };

  return (
    <div
      ref={toggleRef}
      className={cx('form-quantity-select', className, { opened: selectOpened, disabled })}
      onClick={(event) => event.stopPropagation()}
    >
      <div
        className="filter__toggle"
        onClick={() => {
          !disabled && setSelectOpened((prev) => !prev);
        }}
      >
        <span className="filter__label">
          {(value && value !== 'select' && options.find((option) => option.value === value)?.label) || ''}
        </span>
        <ArrowDropDown />
      </div>

      {selectOpened && (
        <div className="filter__dropdown-menu">
          <ul className="filter__dropdown-menu__options">
            {options.map((option) => {
              const optionValue = option.value;
              return (
                <li
                  key={optionValue}
                  className={cx('option', { active: value === optionValue })}
                  onClick={() => handleSelectOption(optionValue)}
                >
                  <span className="option__label">{option.label}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
