import Janus from '../janus.es';
import Cookies from 'universal-cookie';
import axios from 'axios';

import { getAccessToken } from '../utils/authorization';

class WebRTCServiceClient {
  constructor() {
    this.janus = null;
    this.streaming = null;
    this.streamsList = [];
    this.streamId = null;
    this.tries = 0;
  }

  async getStreamsList() {
    return new Promise((resolve, reject) => {
      this.streaming?.send({
        message: { request: 'list' },
        success: (result) => {
          console.log('getStreamsList success');
          console.log(result);
          if (!result || result.error) {
            reject(result.error);
          }

          if (result && result.streaming === 'list' && Array.isArray(result.list)) {
            resolve(result.list.filter((res) => JSON.parse(res.metadata || '{}').type === 'STREAM'));
          }

          reject('Unknown error');
        },
      });
    }).catch((error) => {
      console.log(error);
      return [];
    });
  }

  janusAttach = (streamId, streamPin, videoContainer, audioContainer, callbacks = {}) => {
    let streamIdentifier = null;
    this.janus.attach({
      plugin: 'janus.plugin.streaming',
      success: async (pluginHandle) => {
        this.streaming = pluginHandle;
        const plugin = this.streaming.getPlugin();
        const pluginId = this.streaming.getId();
        console.log(`Plugin attached! (${plugin}, id=${pluginId})`);
        if (callbacks.onGetStreamsList) {
          this.streamsList = await this.getStreamsList();
          callbacks.onGetStreamsList(this.streamsList);
        }
        streamIdentifier = streamId ? streamId : this.streamsList[0]?.id;
        if (!streamIdentifier) {
          console.log('Invalid streamId');
        } else {
          this.streaming?.send({
            message: {
              request: 'watch',
              id: parseInt(streamIdentifier),
              ...(streamPin ? { pin: streamPin } : {}),
            },
          });
        }
      },
      error: (error) => {
        console.error(error);
      },
      webrtcState: function (on) {
        Janus.log('Janus says our WebRTC PeerConnection is ' + (on ? 'up' : 'down') + ' now');
      },
      onmessage: (msg, jsep) => {
        console.log('WebRTC message', msg, this.streaming);
        if (msg.error) {
          this.stopStream();
          return;
        }
        if (jsep) {
          console.debug('Handling SDP...');
          console.debug(jsep);
          this.streaming?.createAnswer({
            jsep,
            media: { audioSend: false, videoSend: false, data: true },
            success: (jsep) => {
              console.debug('Got SDP!');
              console.debug(jsep);
              this.streaming?.send({ message: { request: 'start' }, jsep });
            },
            error: (error) => {
              console.error('WebRTC error:', error);
            },
          });
        }
      },
      onremotetrack: (track, mid, on) => {
        Janus.debug('Remote track (mid=' + mid + ') ' + (on ? 'added' : 'removed') + ':', track);
        if (track.kind === 'audio') {
          let stream = new MediaStream();
          stream.addTrack(track.clone());
          Janus.log('Created remote audio stream:', stream);
          Janus.attachMediaStream(audioContainer, stream);
        } else if (track.kind === 'video') {
          let stream = new MediaStream();
          stream.addTrack(track.clone());
          Janus.log('Created remote video stream:', stream);
          Janus.attachMediaStream(videoContainer, stream);
          callbacks.onSetInfoInterval && callbacks.onSetInfoInterval(this.streaming, mid);
        }
      },
      oncleanup: function () {
        Janus.log(' ::: Got a cleanup notification :::');
      },
      detached: () => {
        console.log(`Janus detached from stream with streamId ${streamId}`);
      },
    });
  };

  async startStream(janusHost, streamId, streamPin, videoContainer, audioContainer, callbacks = {}) {
    const turnCredentials =
      process.env.REACT_APP_ENVIRONMENT === 'development'
        ? null
        : await axios.get(`${process.env.REACT_APP_TURN_AUTH_URL}/credentials`);
    Janus.init({
      debug: process.env.REACT_APP_ENVIRONMENT === 'development' ? 'all' : false,
      callback: () => {
        if (!Janus.isWebrtcSupported()) {
          console.log('No WebRTC support...');
          return;
        }

        const cookies = new Cookies();

        if (!cookies.get('token')) {
          cookies.set('token', getAccessToken());
        }
        if (this.janus === null) {
          this.janus = new Janus({
            server: [`${janusHost}`],
            iceServers:
              process.env.REACT_APP_ENVIRONMENT === 'development'
                ? []
                : [
                    {
                      urls: turnCredentials.data.uris,
                      username: turnCredentials.data.username,
                      credential: turnCredentials.data.password,
                    },
                    { urls: `stun:${process.env.REACT_APP_STUN_URL}` },
                  ],
            success: () => {
              console.log('Janus initialized');
              if (typeof callbacks.onInit === 'function') {
                callbacks.onInit();
                console.log('------------callbacks.onInit()');
              }
              if (this.janus && this.janus.isConnected()) {
                console.log('------------if (this.janus)');
                this.tries = 0;
                this.janusAttach(streamId, streamPin, videoContainer, audioContainer, callbacks);
              }
            },
            error: (error) => {
              console.error(error);
              if (typeof callbacks.onError === 'function') {
                callbacks.onError(error, this.tries + 1);
              }
              this.tries += 1;
            },
          });
        }
      },
    });
  }

  stopStream() {
    if (this.janus) {
      const janus = this.janus;
      setTimeout(() => {
        janus?.destroy();
      }, 1000);
      this.janus = null;
      this.streaming = null;
      this.streamsList = [];
      this.streamId = null;
      console.log('Janus session destroyed');
    }
  }
}

export default WebRTCServiceClient;
