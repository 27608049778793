import {
  deleteNotification,
  getNotifications,
  getUnreadNotificationsCount,
  readMultipleNotifications,
} from '../../api/notifications';

export const CHANGE_NOTIFICATIONS_VALUE = 'CHANGE_NOTIFICATIONS_VALUE';
export const RESET_NOTIFICATIONS_STORE = 'RESET_NOTIFICATIONS_STORE';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';

export const GET_NEXT_NOTIFICATIONS = 'GET_NEXT_NOTIFICATIONS';
export const GET_NEXT_NOTIFICATIONS_SUCCESS = 'GET_NEXT_NOTIFICATIONS_SUCCESS';
export const GET_NEXT_NOTIFICATIONS_ERROR = 'GET_NEXT_NOTIFICATIONS_ERROR';

export const GET_NOTIFICATIONS_COUNT = 'GET_NOTIFICATIONS_COUNT';
export const GET_NOTIFICATIONS_COUNT_SUCCESS = 'GET_NOTIFICATIONS_COUNT_SUCCESS';
export const GET_NOTIFICATIONS_COUNT_ERROR = 'GET_NOTIFICATIONS_COUNT_ERROR';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_ERROR = 'DELETE_NOTIFICATION_ERROR';

export const SET_MULTIPLE_NOTIFICATIONS_READ = 'SET_MULTIPLE_NOTIFICATIONS_READ';
export const SET_MULTIPLE_NOTIFICATIONS_READ_SUCCESS = 'SET_MULTIPLE_NOTIFICATIONS_READ_SUCCESS';
export const SET_MULTIPLE_NOTIFICATIONS_READ_ERROR = 'SET_MULTIPLE_NOTIFICATIONS_READ_ERROR';

export const getNotificationsAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: GET_NOTIFICATIONS });
    try {
      const response = await getNotifications(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_NOTIFICATIONS_SUCCESS, payload: params.size ? null : results });
        actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_NOTIFICATIONS_ERROR });
    }
  };
};

export const getNextNotificationsAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_NEXT_NOTIFICATIONS });
    try {
      const response = await getNotifications(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_NEXT_NOTIFICATIONS_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_NEXT_NOTIFICATIONS_ERROR });
    }
  };
};

export const getUnreadNotificationsCountAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_NOTIFICATIONS_COUNT });
    try {
      const response = await getUnreadNotificationsCount(params);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('unreadTotalResults', results));
        dispatch({ type: GET_NOTIFICATIONS_COUNT_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_NOTIFICATIONS_COUNT_ERROR });
    }
  };
};

export const setNotificationsReadAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: SET_MULTIPLE_NOTIFICATIONS_READ });
    try {
      const response = await readMultipleNotifications(params);
      const { success } = response;
      if (success) {
        dispatch({ type: SET_MULTIPLE_NOTIFICATIONS_READ_SUCCESS, payload: params });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: SET_MULTIPLE_NOTIFICATIONS_READ_ERROR });
    }
  };
};

export const deleteNotificationAction = (uuid) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_NOTIFICATION });
    try {
      const response = await deleteNotification(uuid);
      const { success } = response;
      if (success) {
        dispatch({ type: DELETE_NOTIFICATION_SUCCESS, payload: uuid });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_NOTIFICATION_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_NOTIFICATIONS_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_NOTIFICATIONS_STORE,
});
