import React, { useMemo, useState } from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartComponent, ChartNoInfo, SimpleSelect } from '../../../../components';

import { timeseriesChartTypes } from './constants';
import { getChartData, getChartOptions } from './utils';

export const TimeseriesChart = ({ content, dates, mobile, intervalOptions, isLoading, changeValue }) => {
  const [chartType, setChartType] = useState(timeseriesChartTypes[0].value);

  const typedChartType = useMemo(
    () => (chartType === 'LineChart' ? 'LineChart' : chartType === 'AreaChart' ? 'AreaChart' : 'ColumnChart'),
    [chartType]
  );

  return (
    <div className="timeseries__container">
      <div className="timeseries__toolbar">
        <SimpleSelect
          className="timeseries__type-select"
          options={timeseriesChartTypes}
          value={chartType}
          onChange={setChartType}
          disabled={content === null || isLoading}
        />
        <SimpleSelect
          className="timeseries__interval-select"
          name="interval"
          options={intervalOptions}
          value={dates.interval}
          onChange={changeValue}
          disabled={content === null || isLoading}
        />
      </div>

      {content !== null && !isLoading ? (
        <div className="timeseries__chart">
          <ChartComponent
            chartType={typedChartType}
            content={getChartData(content, dates)}
            chartOptions={getChartOptions(content, dates, chartType, mobile)}
          />
        </div>
      ) : (
        <div className="analytics__info-block">
          {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : <ChartNoInfo />}
        </div>
      )}
    </div>
  );
};
