import React from 'react';

import './StatusCell.scss';

export const StatusCell = ({ status, options }) => {
  return (
    <div className="custom-cell__status" style={{ color: options[status.toLowerCase()] || '#1A1A1A' }}>
      {status}
    </div>
  );
};
