import {
  cancelMFA,
  checkCode,
  checkRecoveryCode,
  disableVerification,
  enableVerification,
  getQRCode,
  resetRecoveryCodes,
  verifyCode,
} from '../../api/security';
import { CHANGE_LOGIN_VALUE } from './loginAction';

import * as authUtils from '../../utils/authorization';

export const CHANGE_USER_SECURITY_VALUE = 'CHANGE_USER_SECURITY_VALUE';
export const RESET_USER_SECURITY_STORE = 'RESET_USER_SECURITY_STORE';

export const GET_USER_SECURITY_QR_CODE = 'GET_USER_SECURITY_QR_CODE';
export const GET_USER_SECURITY_QR_CODE_SUCCESS = 'GET_USER_SECURITY_QR_CODE_SUCCESS';
export const GET_USER_SECURITY_QR_CODE_ERROR = 'GET_USER_SECURITY_QR_CODE_ERROR';

export const CHECK_USER_SECURITY_CODE = 'CHECK_USER_SECURITY_CODE';
export const CHECK_USER_SECURITY_CODE_SUCCESS = 'CHECK_USER_SECURITY_CODE_SUCCESS';
export const CHECK_USER_SECURITY_CODE_ERROR = 'CHECK_USER_SECURITY_CODE_ERROR';

export const CHECK_USER_RECOVERY_CODE = 'CHECK_USER_RECOVERY_CODE';
export const CHECK_USER_RECOVERY_CODE_SUCCESS = 'CHECK_USER_RECOVERY_CODE_SUCCESS';
export const CHECK_USER_RECOVERY_CODE_ERROR = 'CHECK_USER_RECOVERY_CODE_ERROR';

export const RESET_USER_RECOVERY_CODES = 'RESET_USER_RECOVERY_CODES';
export const RESET_USER_RECOVERY_CODES_SUCCESS = 'RESET_USER_RECOVERY_CODES_SUCCESS';
export const RESET_USER_RECOVERY_CODES_ERROR = 'RESET_USER_RECOVERY_CODES_ERROR';

export const VERIFY_USER_SECURITY_CODE = 'VERIFY_USER_SECURITY_CODE';
export const VERIFY_USER_SECURITY_CODE_SUCCESS = 'VERIFY_USER_SECURITY_CODE_SUCCESS';
export const VERIFY_USER_SECURITY_CODE_ERROR = 'VERIFY_USER_SECURITY_CODE_ERROR';

export const ENABLE_VERIFICATION = 'ENABLE_VERIFICATION';
export const ENABLE_VERIFICATION_SUCCESS = 'ENABLE_VERIFICATION_SUCCESS';
export const ENABLE_VERIFICATION_ERROR = 'ENABLE_VERIFICATION_ERROR';

export const CANCEL_MFA = 'CANCEL_MFA';
export const CANCEL_MFA_SUCCESS = 'CANCEL_MFA_SUCCESS';
export const CANCEL_MFA_ERROR = 'CANCEL_MFA_ERROR';

export const DISABLE_VERIFICATION = 'DISABLE_VERIFICATION';
export const DISABLE_VERIFICATION_SUCCESS = 'DISABLE_VERIFICATION_SUCCESS';
export const DISABLE_VERIFICATION_ERROR = 'DISABLE_VERIFICATION_ERROR';

export const getQRCodeAction = (actions = null) => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_SECURITY_QR_CODE });
    try {
      const response = await getQRCode();
      const { success, results } = response;
      if (success) {
        let image = URL.createObjectURL(results);
        actions?.onSuccess(image);
        dispatch({ type: GET_USER_SECURITY_QR_CODE_SUCCESS, payload: image });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_USER_SECURITY_QR_CODE_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const checkCodeAction = (params, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: CHECK_USER_SECURITY_CODE });
    try {
      const response = await checkCode(params);
      const { success, results } = response;
      if (success) {
        localStorage.removeItem('multiFactorAuth');
        authUtils.setAuthDataToLocalStorage(results);
        actions?.onSuccess(results);
        dispatch({ type: CHANGE_LOGIN_VALUE, payload: { key: 'verified', value: true } });
        dispatch({ type: CHECK_USER_SECURITY_CODE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: CHECK_USER_SECURITY_CODE_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const checkRecoveryCodeAction = (params, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: CHECK_USER_RECOVERY_CODE });
    try {
      const response = await checkRecoveryCode(params);
      const { success, results } = response;
      if (success) {
        localStorage.removeItem('multiFactorAuth');
        authUtils.setAuthDataToLocalStorage(results);
        actions?.onSuccess(results);
        dispatch({ type: CHANGE_LOGIN_VALUE, payload: { key: 'verified', value: true } });
        dispatch({ type: CHECK_USER_RECOVERY_CODE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: CHECK_USER_RECOVERY_CODE_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const resetRecoveryCodesAction = (actions = null) => {
  return async (dispatch) => {
    dispatch({ type: RESET_USER_RECOVERY_CODES });
    try {
      const response = await resetRecoveryCodes();
      const { success, results } = response;
      if (success) {
        actions?.onSuccess(results);
        dispatch({ type: RESET_USER_RECOVERY_CODES_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: RESET_USER_RECOVERY_CODES_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const cancelMFAAction = (uuid, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: CANCEL_MFA });
    try {
      const response = await cancelMFA(uuid);
      const { success, results } = response;
      if (success) {
        actions?.onSuccess(results);
        dispatch({ type: CANCEL_MFA_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: CANCEL_MFA_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const verifyCodeAction = (params, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: VERIFY_USER_SECURITY_CODE });
    try {
      const response = await verifyCode(params);
      const { success, results } = response;
      if (success) {
        actions?.onSuccess(results);
        dispatch({ type: CHANGE_LOGIN_VALUE, payload: { key: 'verified', value: true } });
        dispatch({ type: VERIFY_USER_SECURITY_CODE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: VERIFY_USER_SECURITY_CODE_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const enableVerificationAction = (actions = null) => {
  return async (dispatch) => {
    dispatch({ type: ENABLE_VERIFICATION });
    try {
      const response = await enableVerification();
      const { success, results } = response;
      if (success) {
        actions?.onSuccess(results);
        dispatch({ type: ENABLE_VERIFICATION_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: ENABLE_VERIFICATION_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const disableVerificationAction = (actions = null) => {
  return async (dispatch) => {
    dispatch({ type: DISABLE_VERIFICATION });
    try {
      const response = await disableVerification();
      const { success, results } = response;
      if (success) {
        actions?.onSuccess(results);
        dispatch({ type: DISABLE_VERIFICATION_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: DISABLE_VERIFICATION_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_USER_SECURITY_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_USER_SECURITY_STORE,
});
