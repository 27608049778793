import { faSpinner, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import WebRTCServiceClient from '../../services/WebRTCServiceClient';

import { ManagerPlayerControls } from './Controls/ManagerPlayerControls';

import { useEscapeKey } from '../../hooks';

import { getAccessToken } from '../../utils/authorization';

const ManagerPlayerLive = ({
  camerasView,
  camera,
  mobile,
  isLoading,
  optionsError,
  onWebRTCError,
  changeCameraValue,
  onRestartStream,
  onResetScale,
  onDragStart,
  onDeleteCameraStream,
  containerRef,
}) => {
  const [modalVideo, setModalVideo] = useState(false);
  const [resolution, setResolution] = useState(1.78);
  const [info, setInfo] = useState({});
  const [isMetaLoaded, setMetaLoaded] = useState(false);
  const [beforeFullscreen, setBeforeFullscreen] = useState(null);

  const restartRef = useRef(false);
  const video = useRef(null);
  const infoTimerRef = useRef(null);
  const webrtcRef = useRef(null);

  useEffect(() => {
    webrtcRef.current = new WebRTCServiceClient();

    return () => {
      clearInterval(infoTimerRef.current);
      webrtcRef.current?.stopStream();
      webrtcRef.current = null;
      onDeleteCameraStream(camera);
    };
  }, []);

  useEffect(() => {
    if (video.current) {
      video.current.addEventListener('loadedmetadata', () => {
        setMetaLoaded(true);
        video.current && setResolution(video.current.videoWidth / (video.current.videoHeight || 1));
      });
    }
  }, [video]);

  useEffect(() => {
    if (!camera.restart) {
      changeCameraValue(camera.key, 'tries', 0);
      restartStream(camera.activeStreamId);
    }
  }, [camerasView.restaurantUuid, camera.activeStreamId]);

  useEffect(() => {
    if (camera.restart) {
      restartRef.current = true;
      changeCameraValue(camera.key, 'restart', false);

      camera.tries > 1
        ? setTimeout(() => {
            restartStream(camera.activeStreamId);
          }, 5000)
        : restartStream(camera.activeStreamId);
    }
  }, [camera.restart]);

  useEffect(() => {
    if (!modalVideo) {
      setBeforeFullscreen(null);
    }
  }, [containerRef.current?.clientHeight, containerRef.current?.clientWidth]);

  const reconnectJanus = (triesQty) => {
    if (triesQty <= 5) {
      changeCameraValue(camera.key, 'tries', triesQty);
      changeCameraValue(camera.key, 'restart', true);
    } else {
      changeCameraValue(camera.key, 'tries', 0);
      onWebRTCError();
    }
  };

  const handleSetInfoInterval = (streaming, mid) => {
    clearInterval(infoTimerRef.current);
    infoTimerRef.current = setInterval(() => {
      setInfo({
        resolution: `${video.current?.videoWidth}x${video.current?.videoHeight}`,
        bitrate:
          typeof streaming?.getBitrate === 'function'
            ? (streaming.getBitrate(mid).split(' ')[0] / 1000).toFixed(3)
            : 0,
      });
    }, 500);
  };

  const restartStream = (streamId) => {
    restartRef.current = false;
    webrtcRef.current?.stopStream();

    if (video.current?.srcObject) {
      video.current.srcObject = null;
    }

    setMetaLoaded(false);
    if (camerasView.restaurantUuid !== 'select' && streamId) {
      webrtcRef.current?.startStream(
        `${process.env.REACT_APP_CFA_PROXY.replace(/^[a-z]*:/, 'wss:')}/?restaurantUuid=${
          camerasView.restaurantUuid
        }&Authorization=${getAccessToken()}`,
        streamId ? streamId : null,
        camera.streamPin,
        video.current,
        null,
        {
          onSwitched: () => {},
          onError: (_error, tries) => reconnectJanus(tries),
          onSetInfoInterval: handleSetInfoInterval,
        }
      );
    }
  };

  const toggleFullScreen = (event) => {
    event.stopPropagation();
    if (modalVideo) {
      setModalVideo(false);
      changeCameraValue(camera.key, 'modalOpened', false);
    } else {
      setBeforeFullscreen({
        width: video.current?.clientWidth,
        height: video.current?.clientHeight,
      });
      setModalVideo(true);
      changeCameraValue(camera.key, 'modalOpened', true);
    }
  };

  const handleCloseFullScreen = () => {
    if (modalVideo) {
      setModalVideo(false);
      changeCameraValue(camera.key, 'modalOpened', false);
      onResetScale();
    }
  };

  const handleVideoClick = () => {
    if (camera.enableScaling || !isMetaLoaded || camera.hasStartStreamError || modalVideo) return;
    setBeforeFullscreen({
      width: video.current?.clientWidth,
      height: video.current?.clientHeight,
    });
    setModalVideo(true);
    changeCameraValue(camera.key, 'modalOpened', true);
  };

  const handleCloseCamera = (event) => {
    event.stopPropagation();
    webrtcRef.current?.stopStream();
    onDeleteCameraStream(camera);
    setModalVideo(false);
    changeCameraValue(camera.key, 'modalOpened', false);
  };

  const prepareVideoStyle = () => {
    if (modalVideo) {
      if ((window.innerWidth - 20) / (window.innerHeight - 20) < resolution) {
        return {
          width: 100 + '%',
          height: (window.innerWidth - 20) / resolution + 'px',
        };
      } else {
        return {
          height: 100 + '%',
          width: (window.innerHeight - 20) * resolution + 'px',
        };
      }
    } else {
      if (isMetaLoaded) {
        if (beforeFullscreen) {
          return { width: beforeFullscreen.width, height: beforeFullscreen.height };
        } else {
          return { maxHeight: containerRef.current?.clientHeight + 'px' };
        }
      } else {
        return { width: 100 + '%', height: 100 + '%' };
      }
    }
  };

  useEscapeKey(handleCloseFullScreen);

  return (
    <>
      <div
        className={cx('manager-modal', {
          'modal fade show': modalVideo,
          'no-data': !isMetaLoaded,
        })}
        onClick={(e) => {
          var path = e.nativeEvent.path || (e.nativeEvent.composedPath && e.nativeEvent.composedPath());
          if (
            !path.some((item) => Array.from(document.getElementsByTagName('video')).includes(item)) &&
            !mobile
          ) {
            setModalVideo(false);
            changeCameraValue(camera.key, 'modalOpened', false);
          }
        }}
      >
        <div
          className={cx('manager-modal-container', {
            'modal--image--support modal-dialog--manager': modalVideo,
          })}
        >
          <div
            className={cx('manager-video--container')}
            style={
              isMetaLoaded
                ? {}
                : camerasView.layout.length === 2 && camerasView.layoutType === 'vertical'
                ? { width: containerRef.current?.clientHeight * 1.77 + 'px', height: 100 + '%' }
                : { width: 100 + '%' }
            }
            onDragStart={onDragStart}
            draggable
          >
            {isLoading && !camera.hasWebRTCError && !camera.modalOpened && !isMetaLoaded ? (
              <div className="manager_camera-view__player-loader">
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            ) : null}
            {camera.hasStartStreamError || camera.hasWebRTCError || optionsError ? (
              <div className="player">
                <div className="manager_camera-view__player-error">
                  <FontAwesomeIcon icon={faUnlink} />
                  <span>
                    {camera.hasWebRTCError && !optionsError
                      ? "Can't connect to the restaurant"
                      : optionsError}
                  </span>
                </div>
              </div>
            ) : null}

            {!restartRef.current && (
              <video
                ref={video}
                playsInline={true}
                autoPlay
                muted={true}
                onClick={handleVideoClick}
                style={prepareVideoStyle()}
              >
                <p>Your browser doesn&#39;t support HTML5 video.</p>
              </video>
            )}

            <ManagerPlayerControls
              camera={camera}
              mobile={mobile}
              videoRef={video.current}
              isMetaLoaded={isMetaLoaded}
              isFullscreen={modalVideo}
              changeCameraValue={changeCameraValue}
              toggleFullScreen={toggleFullScreen}
              onCloseCamera={handleCloseCamera}
              onResetScale={onResetScale}
              onRestartStream={onRestartStream}
            />

            {camera.activeStreamId && camera.showInfo && (
              <ul className="information">
                <li>{info.resolution}</li>
                <li>{info.bitrate || 0} Mbps</li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagerPlayerLive;
