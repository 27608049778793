export const dynamicSort = (array, sortBy, orderDirection = 'DESC') => {
  const arrayCopy = [...array];

  return arrayCopy.sort((a, b) => {
    let valueA = a[sortBy];
    let valueB = b[sortBy];

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      valueA = valueA.toLowerCase();
      valueB = valueB.toLowerCase();
    }

    if (valueA < valueB) {
      return orderDirection === 'ASC' ? -1 : 1;
    }
    if (valueA > valueB) {
      return orderDirection === 'ASC' ? 1 : -1;
    }
    return 0;
  });
};
