export const getChartData = (metrics, activeTab) => {
  return [
    ['Time', 'Percent', { type: 'string', role: 'tooltip', p: { html: true } }],
    ...metrics[activeTab].chart.map((item) => [
      item[0],
      item[2],
      `<div class="chart-tooltip"><span>${item[0]}</span>${`<div>Count: <b>${item[2]} (${Math.round(
        item[1]
      )} %)</b></div>`}</div>`,
    ]),
  ];
};

export const createRows = (rows) => {
  return rows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: item[0],
      },
      {
        label: `${item[1]}`,
      },
      {
        label: `${item[2]}`,
      },
    ],
    minHeight: '42px',
  }));
};
