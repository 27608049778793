import React, { useEffect, useRef, useState } from 'react';
import './Clock.scss';

export const DigitalClock = ({ timezone }) => {
  const [time, setTime] = useState('00:00:00');

  const interval = useRef(null);

  useEffect(() => {
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    clearInterval(interval.current);
    if (timezone !== null && !isNaN(timezone)) {
      interval.current = setInterval(() => {
        const targetDate = new Date(Date.now() + timezone * 60000);

        const hours = targetDate.getHours().toString().padStart(2, '0');
        const minutes = targetDate.getMinutes().toString().padStart(2, '0');
        const seconds = targetDate.getSeconds().toString().padStart(2, '0');

        const formattedTime = `${hours}:${minutes}:${seconds}`;
        setTime(formattedTime);
      }, 1000);
    } else {
      setTime('00:00:00');
    }
  }, [timezone]);

  return (
    <div className="digital-clock">
      <div className="current-time">{time}</div>
    </div>
  );
};
