import request from '../utils/request';

export function getEvents(params, signal) {
  return request({
    url: '/event/events',
    method: 'get',
    data: params,
    signal,
  });
}

export function getEventNames() {
  return request({
    url: '/dictionaries/eventName',
    method: 'get',
  });
}

export function getExelExport(params) {
  return request({
    url: '/event/events/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}
