import _ from 'lodash';

import * as recordingsApi from '../../api/camerasRecordings';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_UNUSUAL_RECORDINGS_VALUE = 'CHANGE_UNUSUAL_RECORDINGS_VALUE';
export const CHANGE_UNUSUAL_RECORDINGS_SEVERAL_VALUES = 'CHANGE_UNUSUAL_RECORDINGS_SEVERAL_VALUES';
export const RESET_UNUSUAL_RECORDINGS_STORE = 'RESET_UNUSUAL_RECORDINGS_STORE';

export const GET_UNUSUAL_RECORDINGS = 'GET_UNUSUAL_RECORDINGS';
export const GET_UNUSUAL_RECORDINGS_SUCCESS = 'GET_UNUSUAL_RECORDINGS_SUCCESS';
export const GET_UNUSUAL_RECORDINGS_ERROR = 'GET_UNUSUAL_RECORDINGS_ERROR';

export const SET_UNUSUAL_RECORDING_PREVIEW = 'SET_UNUSUAL_RECORDING_PREVIEW';

export const DOWNLOAD_UNUSUAL_RECORDING = 'DOWNLOAD_UNUSUAL_RECORDING';
export const DOWNLOAD_UNUSUAL_RECORDING_SUCCESS = 'DOWNLOAD_UNUSUAL_RECORDING_SUCCESS';
export const DOWNLOAD_UNUSUAL_RECORDING_ERROR = 'DOWNLOAD_UNUSUAL_RECORDING_ERROR';
export const SET_UNUSUAL_RECORDING_PROGRESS = 'SET_UNUSUAL_RECORDING_PROGRESS';

export const getRecordingsAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_UNUSUAL_RECORDINGS, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await recordingsApi.getRecordings(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_UNUSUAL_RECORDINGS_SUCCESS, payload: results, params });
          actions.onSuccess && actions.onSuccess(results);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          actions.onError && actions.onError(error.message);
          dispatch({ type: GET_UNUSUAL_RECORDINGS_ERROR });
          console.log(error);
        }
      }
    };
  };
})();

export const deleteRecordingAction = (uuid, actions = {}) => {
  return async () => {
    try {
      const response = await recordingsApi.deleteRecording(uuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const getRecordingLinkAction = (uuid, actions = {}) => {
  return async () => {
    try {
      const response = await recordingsApi.getRecordingLink(uuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const getDownloadLinkAction = (uuid, actions = {}) => {
  return async () => {
    try {
      const response = await recordingsApi.getDownloadLink(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(response);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const downloadRecordingAction = (url, uuid, name, actions = {}) => {
  return async (dispatch) => {
    const controller = new AbortController();
    dispatch({ type: DOWNLOAD_UNUSUAL_RECORDING, payload: { uuid, controller } });
    try {
      const response = await recordingsApi.downloadRecording(
        url,
        controller.signal,
        _.throttle((progressEvent) => {
          if (progressEvent.loaded && progressEvent.total) {
            dispatch({
              type: SET_UNUSUAL_RECORDING_PROGRESS,
              payload: { uuid, progress: Math.round((progressEvent.loaded / progressEvent.total) * 100) },
            });
          }
        }, 500)
      );
      const { success, results } = response;
      if (success) {
        dispatch({ type: DOWNLOAD_UNUSUAL_RECORDING_SUCCESS, payload: { uuid } });
        let video = URL.createObjectURL(results);
        const tag = document.createElement('a');
        tag.href = video;
        tag.download = name;
        tag.click();
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
      dispatch({ type: DOWNLOAD_UNUSUAL_RECORDING_ERROR, payload: { uuid } });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_UNUSUAL_RECORDINGS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_UNUSUAL_RECORDINGS_SEVERAL_VALUES,
  payload: value,
});

export const setRecordingPreview = (uuid, preview) => ({
  type: SET_UNUSUAL_RECORDING_PREVIEW,
  payload: { uuid, preview },
});

export const resetStore = () => ({
  type: RESET_UNUSUAL_RECORDINGS_STORE,
});
