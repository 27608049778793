import { refresh, signIn, signOut } from '../../api/app';
import { getRestaurant, getRestaurants } from '../../api/restaurants';
import { changePassword, checkSecretKey, forgotPassword, getMe, setPassword } from '../../api/users';

import * as authUtils from '../../utils/authorization';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_RESULT = 'LOGIN_USER_RESULT';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';

export const BLOCK_USER = 'BLOCK_USER';

export const GET_LOGIN_RESTAURANTS = 'GET_LOGIN_RESTAURANTS';
export const GET_LOGIN_RESTAURANTS_RESULT = 'GET_LOGIN_RESTAURANTS_RESULT';
export const GET_LOGIN_RESTAURANTS_ERROR = 'GET_LOGIN_RESTAURANTS_ERROR';

export const GET_LOGIN_RESTAURANT = 'GET_LOGIN_RESTAURANT';
export const GET_LOGIN_RESTAURANT_RESULT = 'GET_LOGIN_RESTAURANT_RESULT';
export const GET_LOGIN_RESTAURANT_ERROR = 'GET_LOGIN_RESTAURANT_ERROR';

export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_RESULT = 'SET_PASSWORD_RESULT';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_RESULT = 'CHANGE_PASSWORD_RESULT';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_RESULT = 'FORGOT_PASSWORD_RESULT';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

export const GET_ME = 'GET_ME';
export const GET_ME_RESULT = 'GET_ME_RESULT';
export const GET_ME_ERROR = 'GET_ME_ERROR';

export const CHECK_SECRET_KEY = 'CHECK_SECRET_KEY';
export const CHECK_SECRET_KEY_RESULT = 'CHECK_SECRET_KEY_RESULT';
export const CHECK_SECRET_KEY_ERROR = 'CHECK_SECRET_KEY_ERROR';

export const CHANGE_LOGIN_VALUE = 'CHANGE_LOGIN_VALUE';
export const CHANGE_EXPIRE_LOGIN_VALUE = 'CHANGE_EXPIRE_LOGIN_VALUE';
export const RESET_LOGIN_STORE = 'RESET_LOGIN_STORE';

export const loginUser = (payload, actions = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_USER });
      const response = await signIn(payload);
      const { success, results } = response;
      if (success) {
        if (results.multiFactorAuthentication || results.user.multiFactorAuthentication) {
          localStorage.setItem('multiFactorAuth', true);
        } else if (!!localStorage.getItem('multiFactorAuth')) {
          localStorage.removeItem('multiFactorAuth');
        }
        authUtils.setAuthDataToLocalStorage(results);

        dispatch({ type: LOGIN_USER_RESULT, payload: results });
        actions?.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOGIN_USER_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const refreshToken = () => {
  return async (dispatch) => {
    try {
      const response = await refresh();
      const { success, results } = response;
      if (success) {
        authUtils.setAuthDataToLocalStorage(results);
        localStorage.removeItem('isRefreshing');
      }
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      localStorage.removeItem('isRefreshing');
      if (error.code === 401) {
        authUtils.removeAuthDataFromLocalStorage();
        dispatch(resetStore());
        return Promise.reject(error);
      }
      return Promise.resolve();
    }
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGOUT_USER });
      const response = await signOut();
      const { success } = response;
      if (success) {
        authUtils.removeAuthDataFromLocalStorage();
        dispatch(resetStore());
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOGOUT_USER_ERROR });
    }
  };
};

export const resetUser = () => {
  return async (dispatch) => {
    try {
      authUtils.removeAuthDataFromLocalStorage();
      dispatch(resetStore());
    } catch (error) {
      console.log(error);
    }
  };
};

export const getMeAction = (actions = {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ME });
      const response = await getMe();
      const { success, results } = response;
      if (success) {
        localStorage.removeItem('multiFactorAuth');
        dispatch({ type: GET_ME_RESULT, payload: results });
        actions.onSuccess && actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_ME_ERROR });
      actions.onError && actions.onError();
    }
  };
};

export const checkSecretKeyAction = (params, setData) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CHECK_SECRET_KEY });
      const response = await checkSecretKey(params);
      const { success, results } = response;
      if (success) {
        setData(results);
        dispatch({ type: CHECK_SECRET_KEY_RESULT });
      }
    } catch (error) {
      dispatch({ type: CHECK_SECRET_KEY_ERROR });
      console.log(error);
    }
  };
};

export const setPass = (payload, actions = {}) => {
  return async (dispatch) => {
    try {
      const { password, token } = payload;
      if (password && token) {
        dispatch({ type: SET_PASSWORD });
        const response = await setPassword(payload);
        const { success, results } = response;
        if (success) {
          actions.onSuccess(results);
          dispatch({ type: SET_PASSWORD_RESULT, userData: results.user });
        }
      }
    } catch (error) {
      actions.onError(error.message);
      dispatch({ type: SET_PASSWORD_ERROR, message: error.message });
    }
  };
};

export const changePasswordAction = (payload, actions = {}) => {
  return async (dispatch) => {
    try {
      const { oldPassword, newPassword } = payload;
      if (oldPassword && newPassword) {
        dispatch({ type: CHANGE_PASSWORD });
        const response = await changePassword(payload);
        const { success, results } = response;
        if (success) {
          authUtils.setAuthDataToLocalStorage(results);
          dispatch({ type: CHANGE_PASSWORD_RESULT, payload: results });
          actions.onSuccess(results);
        }
      }
    } catch (error) {
      dispatch({ type: CHANGE_PASSWORD_ERROR, message: error.message });
      actions.onError(error.message);
    }
  };
};

export const forgotPass = (payload, actions = {}) => {
  return async (dispatch) => {
    try {
      if (payload) {
        dispatch({ type: FORGOT_PASSWORD });
        const response = await forgotPassword(payload);
        const { success, results } = response;
        if (success) {
          actions.onSuccess();
          dispatch({ type: FORGOT_PASSWORD_RESULT, userData: results.user });
        }
      }
    } catch (error) {
      actions.onError(error.message);
      dispatch({ type: FORGOT_PASSWORD_ERROR, message: error.message });
    }
  };
};

export const getRestaurantsAction = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_LOGIN_RESTAURANTS });
      const response = await getRestaurants({
        limit: 100,
        offset: 0,
        sortBy: 'number',
        orderDirection: 'DESC',
      });
      const { success, results } = response;
      if (success) {
        dispatch({
          type: GET_LOGIN_RESTAURANTS_RESULT,
          payload: {
            restaurants: [
              { label: 'Global', number: '', value: 'select' },
              ...results.content.map((restaurant) => ({
                label: restaurant.name,
                number: restaurant.number,
                value: restaurant.uuid,
              })),
            ],
            restaurantOptions: [
              {
                label: 'Select Restaurant',
                value: 'select',
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              },
              ...results.content.map((restaurant) => ({
                label: `${restaurant.number} - ${restaurant.name}`,
                value: restaurant.uuid,
                timezone: restaurant.timezone,
              })),
            ],
          },
        });
      }
    } catch (error) {
      dispatch({ type: GET_LOGIN_RESTAURANTS_ERROR });
      console.log(error);
    }
  };
};

export const getRestaurantAction = (uuid) => {
  return async (dispatch) => {
    dispatch({ type: GET_LOGIN_RESTAURANT });
    try {
      const response = await getRestaurant(uuid);
      const { success, results } = response;
      if (success) {
        const restaurantOptions = [
          {
            label: 'Select Restaurant',
            value: 'select',
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          {
            label: `${results.number} - ${results.name}`,
            value: results.uuid,
            timezone: results.timezone,
          },
        ];
        dispatch({ type: GET_LOGIN_RESTAURANT_RESULT, payload: { ...results, restaurantOptions } });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_LOGIN_RESTAURANT_ERROR });
    }
  };
};

export const blockUser = () => ({
  type: BLOCK_USER,
});

export const changeValue = (key, value) => ({
  type: CHANGE_LOGIN_VALUE,
  payload: { key, value },
});

export const changeExpireValue = (value) => ({
  type: CHANGE_EXPIRE_LOGIN_VALUE,
  payload: { value },
});

export const resetStore = () => ({
  type: RESET_LOGIN_STORE,
});
