import { deleteAllSessions, deleteSession, getSessions } from '../../api/sessions';

export const CHANGE_SESSION_VALUE = 'CHANGE_SESSION_VALUE';
export const RESET_SESSION_STORE = 'RESET_SESSION_STORE';

export const GET_SESSIONS = 'GET_SESSIONS';
export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS';
export const GET_SESSIONS_ERROR = 'GET_SESSIONS_ERROR';

export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_ERROR = 'DELETE_SESSION_ERROR';

export const DELETE_ALL_SESSIONS = 'DELETE_ALL_SESSIONS';
export const DELETE_ALL_SESSIONS_SUCCESS = 'DELETE_ALL_SESSIONS_SUCCESS';
export const DELETE_ALL_SESSIONS_ERROR = 'DELETE_ALL_SESSIONS_ERROR';

export const getSessionsAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_SESSIONS });
    try {
      const response = await getSessions(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_SESSIONS_SUCCESS, payload: results });
        typeof setData === 'function' && setData(results);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_SESSIONS_ERROR });
    }
  };
};

export const deleteSessionAction = (uuid, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_SESSION });
    try {
      const response = await deleteSession(uuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess(results);
        dispatch({ type: DELETE_SESSION_SUCCESS, payload: uuid });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_SESSION_ERROR });
    }
  };
};

export const deleteAllSessionsAction = (uuid, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_ALL_SESSIONS });
    try {
      const response = await deleteAllSessions(uuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess(results);
        dispatch({ type: DELETE_ALL_SESSIONS_SUCCESS, payload: uuid });
      }
    } catch (error) {
      console.log(error);
      actions.onError(error.message);
      dispatch({ type: DELETE_ALL_SESSIONS_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_SESSION_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_SESSION_STORE,
});
