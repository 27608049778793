import React from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import './MediumIconButton.scss';

export const MediumIconButton = ({
  Icon,
  content,
  disabled,
  isLoading,
  type = 'black', // 'black' | 'red' | 'gray'
  tooltip,
  onClick,
}) => {
  return (
    <button
      className={cx('medium-icon-button', type, { loading: isLoading })}
      disabled={disabled || isLoading}
      data-tooltip-id={tooltip?.id ? tooltip.id : null}
      data-tooltip-content={tooltip?.title ? tooltip.title : null}
      data-tooltip-place={tooltip?.place ? tooltip.place : 'top'}
      onClick={onClick}
    >
      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <>
          {Icon && <Icon />}
          {content}
        </>
      )}
    </button>
  );
};
