import React from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartComponent, ChartNoInfo } from '../../../../components';
import { getChartData, getChartOptions } from './utils';

export const BarChart = ({ content, mobile, isLoading }) => {
  return (
    <div className="bar__container">
      {content !== null && !isLoading ? (
        <div className="bar__chart">
          <ChartComponent
            className="bar-chart__analytics"
            chartType="BarChart"
            width="100%"
            height="100%"
            content={getChartData(content)}
            chartOptions={getChartOptions(mobile)}
          />
        </div>
      ) : (
        <div className="analytics__info-block">
          {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : <ChartNoInfo />}
        </div>
      )}
    </div>
  );
};
