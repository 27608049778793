export const intervals = [
  { label: '1m', value: 60000 },
  { label: '5m', value: 300000 },
  { label: '15m', value: 900000 },
  { label: '30m', value: 1800000 },
];

export const intervalsTime = [
  { label: 'Past 5 minutes', value: 300, interval: '15s' },
  { label: 'Past 15 minutes', value: 900, interval: '20s' },
  { label: 'Past 30 minutes', value: 1800, interval: '30s' },
  { label: 'Past 1 hour', value: 3600, interval: '1m' },
  { label: 'Past 3 hours', value: 10800, interval: '90s' },
  { label: 'Past 6 hours', value: 21600, interval: '2m' },
  { label: 'Past 12 hours', value: 43200, interval: '3m' },
  { label: 'Past 24 hours', value: 86400, interval: '10m' },
  { label: 'Past 2 days', value: 172800, interval: '20m' },
  { label: 'Past 7 days', value: 604800, interval: '50m' },
  { label: 'Past 30 days', value: 2592000, interval: '80m' },
  { label: 'Past 90 days', value: 7776000, interval: '3h' },
  {
    label: 'Past 6 months',
    value: Math.floor((Date.now() - new Date().setMonth(new Date().getMonth() - 6)) / 1000),
    interval: '6h',
  },
  {
    label: 'Past 1 year',
    value: Math.floor((Date.now() - new Date().setFullYear(new Date().getFullYear() - 1)) / 1000),
    interval: '12h',
  },
];

export const units = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

export const colors = [
  '#dd0030',
  '#0099ff',
  '#ff9900',
  'purple',
  '#00cca3',
  '#73e600',
  '#0392cf',
  '#ff0080',
  '#0066ff',
  '#996600',
  '#33cc00',
  '#cc00cc',
];

export const miniGraphicColors = [
  '#dd0031',
  '#17b61f',
  '#d872dd',
  '#f4d548',
  '#3399ff',
  '#FF7D00',
  '#9747FF',
  '#1999F4',
  '#009308',
  '#3366cc',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411',
];

export const miniGraphicTable = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'Min',
    value: 'min',
  },
  {
    label: 'Max',
    value: 'max',
  },
  {
    label: 'Avg',
    value: 'avg',
  },
  {
    label: 'Current',
    value: 'current',
  },
];

export const metricReadableNames = {
  DCGM_FI_DEV_GPU_UTIL: 'GPU',
  DCGM_FI_DEV_MEM_COPY_UTIL: 'Memory',
  DCGM_FI_DEV_ENC_UTIL: 'Encoder',
  DCGM_FI_DEV_DEC_UTIL: 'Decoder',
};
