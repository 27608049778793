import request from '../utils/request';

export function getStreamRecording(restaurantUuid, streamName) {
  return request({
    url: `${process.env.REACT_APP_CFA_PROXY}/restaurants/${restaurantUuid}/hls/${streamName}/index.m3u8`,
    method: 'get',
  });
}

export function getSupport(params) {
  return request({
    url: `/support`,
    method: 'get',
    data: params,
  });
}

export function sendSupport(params) {
  return request({
    url: `/support/support`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: params,
  });
}
