import cx from 'classnames';
import { units } from '../../../constants/graphics.constants';
import { convertBytes } from '../../../utils';

import './MonitoringStatic.scss';

export const MonitoringStatic = ({ data, type = false }) => {
  return (
    <div className="monitoring-static">
      {data.map((item, index) => {
        const { value, title } = item;
        return (
          <div className="monitoring-static__item" key={!!value && value.length !== 0 ? value : index}>
            <div className="server__item-title">{title}</div>
            <div className={cx('monitoring-static__item-value', { 'no-info': value === null })}>
              {value && value !== null ? (type ? convertBytes(value, units, type) : value) : 'No information'}
            </div>
          </div>
        );
      })}
    </div>
  );
};
