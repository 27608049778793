import * as types from '../actions/breadingAction';

const initialState = {
  restaurantUuid: null,
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  timezone: null,
  chickenTypes: [],
  limit: '20',
  sortBy: 'eventTime',
  orderDirection: 'DESC',
  loading: true,
  scrollLoading: false,
  isFileLoading: false,
  cursorDirection: 'FIRST',
  cursor: null,
  existNext: false,
  existPrevious: false,
  changePage: false,
  chartLoading: false,
  graphContent: [],
  chartHeader: [],
  data: [],
  total: null,
};

const breadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_BREADING_EVENTS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_BREADING_EVENTS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_BREADING_EVENTS:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_BREADING_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
        data:
          action.params.cursorDirection === 'NEXT'
            ? [...state.data, ...action.payload.content]
            : action.payload.content,
      };

    case types.GET_BREADING_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        existNext: false,
        data: [],
      };

    case types.GET_BREADING_EVENTS_ANALYTIC:
      return {
        ...state,
        chartLoading: true,
      };

    case types.GET_BREADING_EVENTS_ANALYTIC_SUCCESS:
      const { results, total } = action.payload;
      return {
        ...state,
        chartLoading: false,
        chartHeader: results.length !== 0 ? Object.keys(results[0].values[0][1]) : [],
        total,
        graphContent:
          results.length !== 0
            ? results[0].values.map((item, index) => {
                return [
                  new Date(item[0] * 1000),
                  ...Object.keys(item[1]).map((material) => {
                    return results.reduce(function (sum, elem) {
                      return (
                        sum +
                        Number(elem.values[index][1][material] > 0 ? elem.values[index][1][material] : 0)
                      );
                    }, 0);
                  }),
                ];
              })
            : [[0, 0]],
      };

    case types.GET_BREADING_EVENTS_ANALYTIC_ERROR:
      return {
        ...state,
        chartLoading: false,
      };

    case types.LOAD_BREADING_EVENTS_FILE:
      return {
        ...state,
        isFileLoading: true,
      };

    case types.LOAD_BREADING_EVENTS_FILE_SUCCESS:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.LOAD_BREADING_EVENTS_FILE_ERROR:
      return {
        ...state,
        isFileLoading: false,
      };

    case types.RESET_BREADING_EVENTS_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default breadingReducer;
