import request from '../utils/request';

export function getRecordings(params, signal) {
  return request({
    url: `/records/list`,
    method: 'get',
    data: params,
    signal,
  });
}

export function getRecordingLink(uuid) {
  return request({
    url: `/records/link/${uuid}`,
    method: 'get',
    withCredentials: true,
  });
}

export function getDownloadLink(uuid) {
  return request({
    url: `/records/download/${uuid}`,
    method: 'get',
  });
}

export function downloadRecording(url, signal, onProgress) {
  return request({
    url: url,
    method: 'get',
    responseType: 'blob',
    headers: {
      Auth: 'delete',
    },
    signal,
    onProgress,
  });
}

export function deleteRecording(uuid) {
  return request({
    url: `/records/${uuid}`,
    method: 'delete',
  });
}
