import request from '../utils/request';

export function getDumpPanInfo(params, signal) {
  return request({
    url: '/dumpPanInfo',
    method: 'get',
    data: params,
    signal,
  });
}

export function getDumpPanTotal(params, signal) {
  return request({
    url: '/dumpPanInfo/total',
    method: 'get',
    data: params,
    signal,
  });
}

export function getExelExportDumpPan(params) {
  return request({
    url: '/dumpPanInfo/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}

export function deletePanInfo(uuid) {
  return request({
    url: `/dumpPanInfo/${uuid}`,
    method: 'delete',
  });
}
