import React from 'react';
import { DesktopTHead } from './DesktopTHead';
import DesktopTBody from './DesktopTBody';
import { DesktopTFoot } from './DesktopTFoot';

export const DesktopTable = ({
  headerOptions = null,
  footerOptions = null,
  rows,
  isLoading,
  emptyText,
  sortModel,
}) => {
  return (
    <table className="custom-table__table">
      <DesktopTHead headerOptions={headerOptions} sortModel={sortModel} />
      <DesktopTBody rows={rows} isLoading={isLoading} emptyText={emptyText} />
      <DesktopTFoot footerOptions={footerOptions} />
    </table>
  );
};
