import React, { memo } from 'react';

import cx from 'classnames';
import { ChartComponent, ChartNoInfo, Preloader, TableWithPagination } from '../..';

import { miniGraphicTable } from '../../../constants/graphics.constants';

import { createMonitoringRows } from '../../../utils/chartHelper';
import { getChartData, getChartOptions, getNetworkOptions } from './utils';

import '../MonitoringGraphic.scss';

export const ServerGraphic = memo(
  ({
    data,
    chartMetric = '%',
    chartArea,
    title = false,
    allMetric = false,
    dates,
    colorIndex,
    table = false,
    network = false,
    large = false,
    percentMaxValue = false,
    showTooltipUnits = false,
  }) => {
    return (
      <div className={cx('monitoring-graphic', { 'mini-graphic': !network && !large })}>
        {title ? <div className="monitoring-graphic__title">{title}</div> : <></>}
        {data !== null && data.length !== 0 ? (
          data[0].values !== null ? (
            <div className="monitoring-graphic__container">
              <ChartComponent
                chartType={data.length !== 1 && !network ? 'LineChart' : 'AreaChart'}
                content={getChartData(data, dates, chartMetric, network, showTooltipUnits)}
                chartOptions={
                  !network
                    ? getChartOptions(
                        data,
                        dates,
                        colorIndex,
                        allMetric,
                        chartMetric,
                        chartArea,
                        percentMaxValue
                      )
                    : getNetworkOptions(data, dates, colorIndex, chartMetric)
                }
              />
              {table ? (
                <TableWithPagination
                  headerOptions={miniGraphicTable}
                  rows={createMonitoringRows(data !== null ? data : [])}
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className="graphic-no-data--info graphic-no-data--info--without-border">
              <Preloader big={true} />
            </div>
          )
        ) : (
          <ChartNoInfo />
        )}
      </div>
    );
  }
);
