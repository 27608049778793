import React from 'react';

import { ChartComponent, ChartNoInfo, Preloader } from '../../../components';

import { getOptions } from './utils';

import './DevicesAvailability.scss';

export const DevicesAvailability = ({ data, isLoading }) => {
  return isLoading ? (
    <div className="devices-availability__info-block">
      <Preloader big={true} />
    </div>
  ) : data.length > 0 ? (
    <div className="devices-availability__container">
      {data.map((item) => (
        <div key={item.deviceUuid} className="devices-availability__item">
          <div className="devices-availability__item__information">
            <span className="device-name">{item.deviceName}</span>
            <span className="device-total-availability">{item.totalAvailable}</span>
          </div>

          <div className="devices-availability__chart">
            <ChartComponent
              chartType="Timeline"
              width="100%"
              height="100%"
              content={item.chartData}
              chartOptions={getOptions(item.chartColors)}
            />
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="devices-availability__info-block">
      <ChartNoInfo />
    </div>
  );
};
