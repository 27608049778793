import moment from 'moment';
import { googleColors, hAxisOptions } from '../../../../constants/analytics.constants';
import { getTimezoneOffsetInSeconds } from '../../../../utils';
import { getChartAxis } from '../../../../utils/chartHelper';

export const getChartData = (content, dates) => {
  return [
    ['Timestamp', { type: 'string', role: 'tooltip', p: { html: true } }, ...content.restaurants],
    ...content.graphicValues.map((value) => [
      new Date(value[0].getTime() + getTimezoneOffsetInSeconds(dates.timezone)),
      `<div class="chart-tooltip">${moment(value[0])
        .tz(dates.timezone)
        .format('MMM D, YYYY, HH:mm:ss')}${`${value
        .slice(1)
        .map((item, index) => [content.restaurants[index], item])
        .sort((x, y) => {
          return y[1] - x[1];
        })
        .map((item) => `<div>${item[0]}: <b>${item[1]}</b></div>`)}`.replaceAll(',', '')}</div>`,
      ...value.slice(1),
    ]),
  ];
};

export const chartAxis = (content, chartType, ticks = false) => {
  return content !== null && content.graphicValues.length !== 0
    ? chartType === 'LineChart'
      ? getChartAxis(Math.max(...(content.graphicValues.map((item) => item.slice(1)).flat() || 100)))
      : getChartAxis(
          Math.max(
            ...content.graphicValues
              .map((item) =>
                item.slice(1).reduce(function (sum, elem) {
                  return sum + Number(elem);
                }, 0)
              )
              .flat()
          )
        )
    : ticks
    ? [0, 1]
    : 1;
};

export const getChartOptions = (content, dates, chartType, mobile) => {
  return {
    animation: { startup: true, duration: 1000, easing: 'out' },
    focusTarget: 'category',
    tooltip: { isHtml: true },
    colors: googleColors,
    legend: { position: 'bottom' },
    isStacked: true,
    bar: { groupWidth: '75%' },
    width: '100%',
    height: '100%',
    hAxis: {
      minValue: new Date(
        Math.floor(
          dates.timeInterval !== null
            ? Date.now() + getTimezoneOffsetInSeconds(dates.timezone) - dates.timeInterval * 1000
            : dates.startDate
        )
      ),
      ...hAxisOptions,
    },
    vAxis: {
      allowContainerBoundaryTextCutoff: true,
      maxTextLines: 1,
      ticks: chartAxis(content, chartType, true),
      minorGridlines: {
        count: 0,
      },
      viewWindow: {
        min: 0,
        max: chartAxis(content, chartType),
      },
      minValue: 0,
      maxValue: chartAxis(content, chartType),
    },
    chartArea: {
      width: mobile ? '85%' : '93%',
      height: '80%',
      top: 10,
      right: mobile ? 10 : 'auto',
    },
  };
};
