import {
  getAllGPU,
  getAllRAM,
  getAllStorage,
  getAvailableRAM,
  getAvailableStorage,
  getCPUCores,
  getCPUInfo,
  getCPUScalar,
  getCPUTemperature,
  getCPUVector,
  getGPUScalar,
  getGPUTemperature,
  getGPUVector,
  getMachines,
  getOSInfo,
  getRAMScalar,
  getRAMVector,
  getReceiveNetworkVector,
  getRestaurants,
  getStorageReadsVector,
  getStorageScalar,
  getStorageVector,
  getStorageWritesVector,
  getSWAPFree,
  getSWAPScalar,
  getSWAPTotal,
  getSWAPVector,
  getTimezone,
  getTransmitNetworkVector,
  getUpTime,
  getAvailableGPU,
  getGPUUtilVector,
} from '../../api/graphics';
import { getMonitoringAlertsCount } from '../../api/monitoringAlerts';
import { metricReadableNames, units } from '../../constants/graphics.constants';
import { convertBytes } from '../../utils';
import { getGraphicsData, getStaticValue, mergeSameMetrics } from '../../utils/chartHelper';

export const CHANGE_SERVER_MANAGEMENT_VALUE = 'CHANGE_SERVER_MANAGEMENT_VALUE';
export const CHANGE_SERVER_MANAGEMENT_SEVERAL_VALUES = 'CHANGE_SERVER_MANAGEMENT_SEVERAL_VALUES';

export const RESET_SERVER_MANAGEMENT_STORE = 'RESET_SERVER_MANAGEMENT_STORE';
export const RESET_GRAPHICS_METRICS = 'RESET_GRAPHICS_METRICS';

export const GET_SERVER_MACHINES = 'GET_SERVER_MACHINES';
export const GET_SERVER_MACHINES_SUCCESS = 'GET_SERVER_MACHINES_SUCCESS';
export const GET_SERVER_MACHINES_ERROR = 'GET_SERVER_MACHINES_ERROR';

export const GET_SERVER_RESTAURANTS = 'GET_SERVER_RESTAURANTS';
export const GET_SERVER_RESTAURANTS_SUCCESS = 'GET_SERVER_RESTAURANTS_SUCCESS';
export const GET_SERVER_RESTAURANTS_ERROR = 'GET_SERVER_RESTAURANTS_ERROR';

export const getRestaurantsAction = (params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_SERVER_RESTAURANTS });
    try {
      const response = await getRestaurants(params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess();
        dispatch({ type: GET_SERVER_RESTAURANTS_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_SERVER_RESTAURANTS_ERROR });
    }
  };
};

export const getMachinesAction = () => {
  return async (dispatch) => {
    dispatch({ type: GET_SERVER_MACHINES });
    try {
      const response = await getMachines();
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_SERVER_MACHINES_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_SERVER_MACHINES_ERROR });
    }
  };
};

export const getCPUCoresAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getCPUCores(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue(
            'cpuCores',
            results.data.result.length !== 0 ? Number(results.data.result[0].value[1]) : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getTimezoneAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getTimezone(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue(
            'timeZone',
            results.data.result.length !== 0
              ? {
                  label:
                    Math.round(results.data.result[0].value[1] / 3600) === 0
                      ? ''
                      : Math.round(results.data.result[0].value[1] / 3600) > 0
                      ? `+${Math.round(results.data.result[0].value[1] / 3600)}`
                      : Math.round(results.data.result[0].value[1] / 3600),
                  value: Number(results.data.result[0].value[1]) / 60 + new Date().getTimezoneOffset(),
                }
              : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCPUInfoAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getCPUInfo(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue(
            'processor',
            results.data.result.length !== 0 ? results.data.result[0].metric.model_name : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getSWAPFreeAction = (machine, type) => {
  return async (dispatch) => {
    try {
      const response = await getSWAPFree(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue(
            'swapFree',
            results.data.result.length !== 0
              ? results.data.result.map((swap) => ({
                  machine: swap.metric
                    ? machine === 'select'
                      ? swap.metric.host
                      : swap.metric.device || swap.metric.host
                    : '',
                  value: convertBytes(Number(swap.value[1]), units, type, true),
                }))
              : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getSWAPTotalAction = (machine, type) => {
  return async (dispatch) => {
    try {
      const response = await getSWAPTotal(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('swapAll', getStaticValue(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getSWAPScalarAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getSWAPScalar(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('swapScalar', results.data.result.length !== 0 ? results.data.result : null));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getSWAPVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const response = await getSWAPVector(machine, params);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('swapVector', getGraphicsData(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getOSInfoAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getOSInfo(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue('osName', results.data.result.length !== 0 ? results.data.result[0].metric.name : null)
        );
        dispatch(
          changeValue(
            'osVersion',
            results.data.result.length !== 0 ? results.data.result[0].metric.version : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCPUScalarAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getCPUScalar(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('cpuScalar', results.data.result.length !== 0 ? results.data.result : null));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getGPUScalarAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getGPUScalar(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue(
            'gpuScalar',
            results.data.result.length !== 0
              ? results.data.result.sort((a, b) =>
                  a.metric.device && b.metric.device ? a.metric.device.localeCompare(b.metric.device) : 0
                )
              : null
          )
        );
        dispatch(
          changeValue(
            'gpuDevices',
            results.data.result.length !== 0
              ? results.data.result
                  .filter((item) => !!item.metric && !!item.metric.host)
                  .sort((a, b) =>
                    a.metric.device && b.metric.device ? a.metric.device.localeCompare(b.metric.device) : 0
                  )
                  .map((item) => ({
                    host: item.metric.host,
                    name: item.metric.device,
                    modelName: item.metric.modelName,
                    driverVersion: item.metric.DCGM_FI_DRIVER_VERSION,
                  }))
              : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getRAMScalarAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getRAMScalar(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('ramScalar', results.data.result.length !== 0 ? results.data.result : null));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getStorageScalarAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getStorageScalar(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('storageScalar', results.data.result.length !== 0 ? results.data.result : null));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCPUTemperatureAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getCPUTemperature(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue(
            'cpuTemp',
            results.data.result.length !== 0
              ? results.data.result.map((cpu) => ({
                  machine: machine === 'select' ? cpu.metric.host : cpu.metric.device || cpu.metric.host,
                  value: Number(cpu.value[1]),
                }))
              : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getCPUVectorAction = (machine, params) => {
  return async (dispatch) => {
    try {
      const response = await getCPUVector(machine, params);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('cpuVector', getGraphicsData(results, machine)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllRAMAction = (machine, type) => {
  return async (dispatch) => {
    try {
      const response = await getAllRAM(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('ramAll', getStaticValue(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAvailableStorageAction = (machine, type) => {
  return async (dispatch) => {
    try {
      const response = await getAvailableStorage(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('storageFree', getStaticValue(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getRAMVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const response = await getRAMVector(machine, params);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('ramVector', getGraphicsData(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllGPUAction = (machine, type) => {
  return async (dispatch) => {
    try {
      const response = await getAllGPU(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('gpuAll', getStaticValue(results, machine, type, 1048576)));
        dispatch(
          changeValue(
            'gpuMax',
            results.data.result.length !== 0
              ? convertBytes(
                  Math.max(...results.data.result.map((gpu) => Number(gpu.value[1]))) * 1048576,
                  units,
                  type,
                  true
                )
              : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getGPUTemperatureAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getGPUTemperature(machine);
      const { success, results } = response;
      if (success) {
        dispatch(
          changeValue(
            'gpuTemp',
            results.data.result.length !== 0
              ? results.data.result
                  .sort((a, b) =>
                    a.metric.device && b.metric.device ? a.metric.device.localeCompare(b.metric.device) : 0
                  )
                  .map((gpu) => ({
                    machine: machine === 'select' ? gpu.metric.host : gpu.metric.device || gpu.metric.host,
                    value: Number(gpu.value[1]),
                  }))
              : null
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAvailableGPUAction = (machine, actions = null) => {
  return async () => {
    try {
      const response = await getAvailableGPU(machine);
      const { success, results } = response;
      if (success) {
        actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      if (actions.onError) actions?.onError(error.message);
    }
  };
};

export const getGPUVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const response = await getGPUVector(machine, params);
      const { success, results } = response;
      if (success) {
        if (
          results.data.result.length !== 0 &&
          results.data.result.filter((item) => !!item.metric && !!item.metric.host).length > 1
        ) {
          dispatch(
            changeValue('device', results.data.result[0].metric.device || results.data.result[0].metric.host)
          );
        }

        dispatch(changeValue('gpuVector', getGraphicsData(results, machine, type, 1048576)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getGPUUtilVectorAction = (machine, params) => {
  return async (dispatch) => {
    try {
      const response = await getGPUUtilVector(machine, params);
      const { success, results } = response;
      if (success) {
        const mergedMetricsResults = mergeSameMetrics(results, '__name__');
        dispatch(
          changeValue(
            'gpuUtilsVector',
            getGraphicsData(mergedMetricsResults, machine, false, 1, {
              key: '__name__',
              readableDictionary: metricReadableNames,
            })
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllStorageAction = (machine, type) => {
  return async (dispatch) => {
    try {
      const response = await getAllStorage(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('storageAll', getStaticValue(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAvailableRAMAction = (machine, type) => {
  return async (dispatch) => {
    try {
      const response = await getAvailableRAM(machine);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('ramFree', getStaticValue(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getStorageVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const response = await getStorageVector(machine, params);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('storageVector', getGraphicsData(results, machine, type)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getStorageIOVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const writes = await getStorageWritesVector(machine, params);
      const reads = await getStorageReadsVector(machine, params);
      if (writes.success && reads.success) {
        let dates = [
          ...writes.results.data.result
            .filter((item) => !!item.metric && !!item.metric.host)
            .map((it) => it.values.map((value) => value[0])),
          ...reads.results.data.result
            .filter((item) => !!item.metric && !!item.metric.host)
            .map((it) => it.values.map((value) => value[0])),
        ]
          .flat()
          .filter((item, index, arr) => arr.indexOf(item) === index)
          .sort();

        dispatch(
          changeValue('storageIOVector', [
            {
              machine: 'Write',
              values:
                writes.results.data.result.length !== 0
                  ? dates.map((value) => [
                      new Date(Number(value) * 1000),
                      writes.results.data.result
                        .filter((item) => !!item.metric && !!item.metric.host)
                        .sort(function (a, b) {
                          return b.values.length - a.values.length;
                        })[0] &&
                      typeof writes.results.data.result
                        .filter((item) => !!item.metric && !!item.metric.host)
                        .sort(function (a, b) {
                          return b.values.length - a.values.length;
                        })[0]
                        .values.find((val) => val[0] === value) !== 'undefined'
                        ? machine === 'select' || machine.includes('|')
                          ? Number(writes.results.data.result[0].values.find((val) => val[0] === value)[1])
                          : convertBytes(
                              Number(
                                writes.results.data.result
                                  .filter((item) => !!item.metric && !!item.metric.host)
                                  .sort(function (a, b) {
                                    return b.values.length - a.values.length;
                                  })[0]
                                  .values.find((val) => val[0] === value)[1]
                              ),
                              units,
                              type,
                              true
                            )
                        : null,
                    ])
                  : [],
            },
            {
              machine: 'Read',
              values:
                reads.results.data.result.length !== 0
                  ? dates.map((value) => [
                      new Date(Number(value) * 1000),
                      reads.results.data.result
                        .filter((item) => !!item.metric && !!item.metric.host)
                        .sort(function (a, b) {
                          return b.values.length - a.values.length;
                        })[0] &&
                      typeof reads.results.data.result
                        .filter((item) => !!item.metric && !!item.metric.host)
                        .sort(function (a, b) {
                          return b.values.length - a.values.length;
                        })[0]
                        .values.find((val) => val[0] === value) !== 'undefined'
                        ? machine === 'select' || machine.includes('|')
                          ? Number(reads.results.data.result[0].find((val) => val[0] === value)[1])
                          : convertBytes(
                              Number(
                                reads.results.data.result
                                  .filter((item) => !!item.metric && !!item.metric.host)
                                  .sort(function (a, b) {
                                    return b.values.length - a.values.length;
                                  })[0]
                                  .values.find((val) => val[0] === value)[1]
                              ),
                              units,
                              type,
                              true
                            )
                        : null,
                    ])
                  : [],
            },
          ])
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getNetworkVectorAction = (machine, params, type) => {
  return async (dispatch) => {
    try {
      const receive = await getReceiveNetworkVector(machine, params);
      const transmit = await getTransmitNetworkVector(machine, params);
      if (receive.success && transmit.success) {
        let dates = [
          ...receive.results.data.result
            .filter((item) => !!item.metric && !!item.metric.host)
            .map((it) => it.values.map((value) => value[0])),
          ...transmit.results.data.result
            .filter((item) => !!item.metric && !!item.metric.host)
            .map((it) => it.values.map((value) => value[0])),
        ]
          .flat()
          .filter((item, index, arr) => arr.indexOf(item) === index)
          .sort();

        dispatch(
          changeValue('networkVector', [
            {
              machine: 'RX',
              values: dates.map((value) => [
                new Date(Number(value) * 1000),
                receive.results.data.result
                  .filter((item) => !!item.metric && !!item.metric.host)
                  .sort(function (a, b) {
                    return b.values.length - a.values.length;
                  })[0] &&
                typeof receive.results.data.result
                  .filter((item) => !!item.metric && !!item.metric.host)
                  .sort(function (a, b) {
                    return b.values.length - a.values.length;
                  })[0]
                  .values.find((val) => val[0] === value) !== 'undefined'
                  ? machine === 'select' || machine.includes('|')
                    ? Number(receive.results.data.result[0].values.find((val) => val[0] === value)[1])
                    : convertBytes(
                        Number(
                          receive.results.data.result
                            .filter((item) => !!item.metric && !!item.metric.host)
                            .sort(function (a, b) {
                              return b.values.length - a.values.length;
                            })[0]
                            .values.find((val) => val[0] === value)[1]
                        ),
                        units,
                        type,
                        true
                      )
                  : null,
              ]),
            },
            {
              machine: 'TX',
              values: dates.map((value) => [
                new Date(Number(value) * 1000),
                transmit.results.data.result
                  .filter((item) => !!item.metric && !!item.metric.host)
                  .sort(function (a, b) {
                    return b.values.length - a.values.length;
                  })[0] &&
                typeof transmit.results.data.result
                  .filter((item) => !!item.metric && !!item.metric.host)
                  .sort(function (a, b) {
                    return b.values.length - a.values.length;
                  })[0]
                  .values.find((val) => val[0] === value) !== 'undefined'
                  ? machine === 'select' || machine.includes('|')
                    ? Number(transmit.results.data.result[0].values.find((val) => val[0] === value)[1])
                    : convertBytes(
                        Number(
                          transmit.results.data.result
                            .filter((item) => !!item.metric && !!item.metric.host)
                            .sort(function (a, b) {
                              return b.values.length - a.values.length;
                            })[0]
                            .values.find((val) => val[0] === value)[1]
                        ),
                        units,
                        type,
                        true
                      )
                  : null,
              ]),
            },
          ])
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getReceiveNetworkVectorAction = (machine, params, actions = null) => {
  return async () => {
    try {
      const response = await getReceiveNetworkVector(machine, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      if (actions.onError) actions?.onError(error.message);
    }
  };
};

export const getTransmitNetworkVectorAction = (machine, params, actions = null) => {
  return async () => {
    try {
      const response = await getTransmitNetworkVector(machine, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess(results);
      }
    } catch (error) {
      console.log(error);
      if (actions.onError) actions?.onError(error.message);
    }
  };
};

export const getUpTimeAction = (machine) => {
  return async (dispatch) => {
    try {
      const response = await getUpTime(machine);
      const { success, results } = response;
      if (success) {
        if (results.data.result.length !== 0) {
          let w = (results.data.result[0].value[1] / 604800).toFixed(1);
          let d = (results.data.result[0].value[1] / 86400).toFixed(1);
          let h = (results.data.result[0].value[1] / 3600).toFixed(1);
          dispatch(
            changeValue(
              'upTime',
              w >= 1
                ? w + (w < 2 ? ' week' : ' weeks')
                : d >= 1
                ? d + (d < 2 ? ' day' : ' days')
                : h + (h < 2 ? ' hour' : ' hours')
            )
          );
        } else dispatch(changeValue('upTime', null));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getMonitoringAlertsCountAction = (params) => {
  return async (dispatch) => {
    try {
      const response = await getMonitoringAlertsCount(params);
      const { success, results } = response;
      if (success) {
        dispatch(changeValue('activeAlerts', results));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_SERVER_MANAGEMENT_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_SERVER_MANAGEMENT_SEVERAL_VALUES,
  payload: value,
});

export const resetAllMetrics = () => ({
  type: RESET_GRAPHICS_METRICS,
});

export const resetStore = () => ({
  type: RESET_SERVER_MANAGEMENT_STORE,
});
