import { HeaderCell } from '../Cells';

export const DesktopTHead = ({ headerOptions, sortModel }) => {
  if (!headerOptions) {
    return null;
  }

  return (
    <thead className="custom-table__thead">
      <tr>
        {headerOptions.map((cell, index) => (
          <HeaderCell key={index} cell={cell} sortModel={sortModel} />
        ))}
      </tr>
    </thead>
  );
};
