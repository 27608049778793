import { useState } from 'react';
import { SideModal, SortFilter } from '../../../components';

export const FiltersSortModal = ({ info, sortOptions, onConfirm, onCancel }) => {
  const [sortBy, setSort] = useState(info.sortBy);
  const [orderDirection, setDirection] = useState(info.orderDirection);

  return (
    <SideModal
      withoutPadding={true}
      size={'sm'}
      title="Filters"
      onCancel={() => {
        onCancel();
      }}
      onConfirm={() => {
        onConfirm({ sortBy, orderDirection });
      }}
      modalBody={
        <div className="mobile-filters__modal">
          <SortFilter
            sortBy={sortBy}
            direction={orderDirection}
            options={sortOptions}
            changeValue={(name, value) => {
              if (name === 'orderDirection') {
                setDirection(value);
              } else setSort(value);
            }}
          />
        </div>
      }
      confirmText="Save"
    />
  );
};
