import * as types from '../actions/tabletsAction';

import { prepareAvailabilityData } from '../../utils/devices';
import { getStartDate, getEndDate } from '../../utils/urlFunctions';

const initialState = {
  limit: '20',
  page: 0,
  totalResults: 0,
  loading: false,
  model: null,
  build: null,
  restaurantUuid: null,
  activeTab: 'devices',
  startDate: null,
  endDate: null,
  timeInterval: null,
  intervalEnd: null,
  tablets: [],
  search: null,
  streamNames: [{ label: 'Select Name', value: 'select' }],
  command: {
    action: 'START',
    streamName: 'select',
    alternateView: false,
    alternateTime: 10,
  },
  sockTablets: [],
  data: [],
  tabletsColumns: [],
  availabilityLoading: false,
  availabilityData: [],
};

const tabletsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_TABLETS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_TABLETS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.CHANGE_TABLETS_COMMAND_VALUE:
      return {
        ...state,
        command: {
          ...state.command,
          [action.payload.key]: action.payload.value,
        },
      };

    case types.GET_TABLETS:
      return {
        ...state,
        loading: true,
        sockTablets: [],
        availabilityData: [],
      };

    case types.GET_TABLETS_STREAM_NAMES_SUCCESS:
      return {
        ...state,
        streamNames: [
          { label: 'Select Name', value: 'select' },
          ...action.payload.map((item) => ({ label: item, value: item })),
        ],
      };

    case types.GET_TABLETS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.total,
        data: action.payload.content,
      };

    case types.CHANGE_TABLET_SUCCESS:
      return {
        ...state,
      };

    case types.CHANGE_TABLET_REMOTE_ACCESS_SUCCESS:
      return {
        ...state,
        data: state.data.map((tablet) =>
          tablet.tabletUuid === action.payload.uuid
            ? { ...tablet, remoteAccess: action.payload.remoteAccess }
            : tablet
        ),
      };

    case types.GET_TABLETS_ERROR:
      return {
        ...state,
        ...(state.search === action.params.search ? { loading: false, data: [] } : {}),
      };

    case types.GET_TABLETS_AVAILABILITY:
      return {
        ...state,
        availabilityLoading: true,
      };

    case types.GET_TABLETS_AVAILABILITY_SUCCESS: {
      const startTime = getStartDate(state.startDate, state.timeInterval, state.timezone);
      const endTime = getEndDate(state.endDate, state.timeInterval, state.timezone);
      return {
        ...state,
        availabilityLoading: false,
        availabilityData: prepareAvailabilityData(
          startTime,
          endTime,
          state.data.map((tablet) => ({
            ...tablet,
            uuid: tablet.tabletUuid,
            identifier: tablet.name || tablet.tabletUuid,
          })),
          action.payload
        ),
      };
    }

    case types.GET_TABLETS_AVAILABILITY_ERROR:
      return {
        ...state,
        availabilityLoading: false,
      };

    case types.RESET_TABLETS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default tabletsReducer;
