import { useState } from 'react';

export const useOpenedRows = () => {
  const [openedRows, setOpenedRows] = useState([]);

  const checkOpenedRows = (e, id) => {
    e.stopPropagation();
    if (openedRows.includes(id)) {
      setOpenedRows(openedRows.filter((it) => it !== id));
    } else {
      setOpenedRows([...openedRows, id]);
    }
  };

  return [openedRows, checkOpenedRows];
};
