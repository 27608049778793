import React from 'react';

import { NumberInput } from './NumberInput/NumberInput';

import './TimeControl.scss';
import cx from 'classnames';

const defaultViews = [
  {
    label: 'Hour',
    maxValue: 23,
  },
  {
    label: 'Minute',
  },
  {
    label: 'Second',
  },
];

export const TimeControl = ({ className, time, onChange, views = defaultViews }) => {
  const handleChangeTimePart = (index, value) => {
    const newTime = time
      .split(':')
      .map((part, i) => (i === index ? value : part))
      .join(':');
    onChange(newTime);
  };

  return (
    <div className={cx('time-control__wrapper', className)}>
      {views.map((part, index) => (
        <NumberInput
          key={index}
          label={part.label}
          value={time ? time.split(':')[index] : '00'}
          onChange={(value) => handleChangeTimePart(index, value)}
          minValue={part.minValue ? part.minValue : 0}
          maxValue={part.maxValue ? part.maxValue : 59}
        />
      ))}
    </div>
  );
};
