import { hAxisOptions } from '../../../constants/analytics.constants';

export const getOptions = (chartColors) => {
  return {
    animation: { startup: true, duration: 1000, easing: 'out' },
    colors: chartColors,
    timeline: { showRowLabels: false, showBarLabels: false },
    avoidOverlappingGridLines: false,
    tooltip: { isHtml: true },
    chartArea: { width: '93%', height: '100%', bottom: 10 },
    hAxis: {
      ...hAxisOptions,
    },
  };
};
