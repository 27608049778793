import request from '../utils/request';

export function getContainers(params, signal) {
  return request({
    url: '/dictionaries/containers',
    method: 'get',
    data: params,
    signal,
  });
}

export function getContainer(id) {
  return request({
    url: `/dictionaries/containers/${id}`,
    method: 'get',
  });
}

export function createContainer(params) {
  return request({
    url: '/dictionaries/containers',
    method: 'post',
    data: params,
  });
}

export function updateContainer(uuid, params) {
  return request({
    url: `/dictionaries/containers/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteContainer(uuid) {
  return request({
    url: `/dictionaries/containers/${uuid}`,
    method: 'delete',
    data: uuid,
  });
}
