import request from '../utils/request';

export function checkCode(params) {
  return request({
    url: '/auth/multiFactor/verify',
    method: 'post',
    data: params,
  });
}

export function enableVerification(params) {
  return request({
    url: '/auth/multiFactor/enable',
    method: 'put',
    data: params,
  });
}

export function disableVerification(params) {
  return request({
    url: '/auth/multiFactor/disable',
    method: 'put',
    data: params,
  });
}

export function getQRCode(params) {
  return request({
    url: '/auth/multiFactor/qr',
    method: 'get',
    data: params,
    responseType: 'blob',
  });
}

export function verifyCode(params) {
  return request({
    url: '/auth/multiFactor/enable/verify',
    method: 'post',
    data: params,
  });
}

export function cancelMFA(userUuid, params) {
  return request({
    url: `/auth/multiFactor/reset/${userUuid}`,
    method: 'put',
    data: params,
  });
}

export function checkRecoveryCode(params) {
  return request({
    url: `/auth/multiFactor/verify/recoveryCode`,
    method: 'post',
    data: params,
  });
}

export function resetRecoveryCodes(params) {
  return request({
    url: '/auth/multiFactor/recoveryCodes/reset',
    method: 'put',
    data: params,
  });
}
