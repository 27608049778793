import { useState } from 'react';

import cx from 'classnames';

import { ReactComponent as ClosedEye } from '../../../assets/img/icons/form/ClosedEye.svg';
import { ReactComponent as OpenedEye } from '../../../assets/img/icons/form/OpenedEye.svg';

import './FormInput.scss';

export const FormInput = ({
  className,
  name,
  value,
  autoComplete = null,
  type = 'text',
  placeholder,
  max = null,
  onChange,
  maxValue,
  minValue,
  onBlur,
}) => {
  const [showed, toggleShowed] = useState(false);

  const handleChange = (e) => {
    name ? onChange(name, e.target.value) : onChange(e.target.value);
  };

  const onNumberKeyDown = (event) => {
    if (
      ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Delete', 'Tab'].includes(event.key)
    ) {
      return;
    }

    if (
      !/\d|\.|-/.test(event.key) ||
      (event.key === '.' && value.toString().includes('.')) ||
      (event.key === '-' && value.length !== 0 && value !== '')
    ) {
      event.preventDefault();
    }
  };

  return (
    <div className="form-input__container">
      <input
        autoComplete={autoComplete}
        className={cx('form-input', className, { password: type === 'password' })}
        name={name}
        maxLength={max}
        onBlur={onBlur}
        max={maxValue}
        min={minValue}
        placeholder={placeholder}
        type={!showed ? type : 'text'}
        value={value}
        onChange={(e) => handleChange(e)}
        onKeyDown={type === 'number' ? onNumberKeyDown : null}
      />
      {type === 'password' && (
        <div className="form-input__eye-icon" onClick={() => toggleShowed((prev) => !prev)}>
          {showed ? <ClosedEye /> : <OpenedEye />}
        </div>
      )}
    </div>
  );
};
