import _ from 'lodash';

import * as recordingsApi from '../../api/camerasRecordings';
import {
  getCameraRecordings,
  getRecording,
  getRecordings,
  rescheduleRecording,
  scheduleRecordings,
  stopRecording,
  deleteAllCameraRecordings,
  deleteRecording,
} from '../../api/wisenetCameras';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_CAMERAS_RECORDINGS_VALUE = 'CHANGE_CAMERAS_RECORDINGS_VALUE';
export const CHANGE_CAMERAS_RECORDINGS_SEVERAL_VALUES = 'CHANGE_CAMERAS_RECORDINGS_SEVERAL_VALUES';
export const RESET_CAMERAS_RECORDINGS_STORE = 'RESET_CAMERAS_RECORDINGS_STORE';

export const GET_CAMERAS_RECORDINGS = 'GET_CAMERAS_RECORDINGS';
export const GET_CAMERAS_RECORDINGS_SUCCESS = 'GET_CAMERAS_RECORDINGS_SUCCESS';
export const GET_CAMERAS_RECORDINGS_ERROR = 'GET_CAMERAS_RECORDINGS_ERROR';

export const GET_SCHEDULED_RECORDINGS = 'GET_SCHEDULED_RECORDINGS';
export const GET_SCHEDULED_RECORDINGS_SUCCESS = 'GET_SCHEDULED_RECORDINGS_SUCCESS';
export const GET_SCHEDULED_RECORDINGS_ERROR = 'GET_SCHEDULED_RECORDINGS_ERROR';
export const UPDATE_SCHEDULED_RECORDING = 'UPDATE_SCHEDULED_RECORDING';

export const SET_CAMERA_RECORDING_PREVIEW = 'SET_CAMERA_RECORDING_PREVIEW';

export const DOWNLOAD_CAMERA_RECORDING = 'DOWNLOAD_CAMERA_RECORDING';
export const DOWNLOAD_CAMERA_RECORDING_SUCCESS = 'DOWNLOAD_CAMERA_RECORDING_SUCCESS';
export const DOWNLOAD_CAMERA_RECORDING_ERROR = 'DOWNLOAD_CAMERA_RECORDING_ERROR';
export const SET_CAMERA_RECORDING_PROGRESS = 'SET_CAMERA_RECORDING_PROGRESS';

export const scheduleRecordingsAction = (restaurantUuid, params, actions = {}) => {
  return async () => {
    try {
      const response = await scheduleRecordings(restaurantUuid, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const rescheduleRecordingAction = (restaurantUuid, recordingId, params, actions = {}) => {
  return async () => {
    try {
      const response = await rescheduleRecording(restaurantUuid, recordingId, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const stopRecordingAction = (restaurantUuid, recordingId, actions = {}) => {
  return async () => {
    try {
      const response = await stopRecording(restaurantUuid, recordingId);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const deleteRecordingAction = (restaurantUuid, recordingId, actions = {}) => {
  return async () => {
    try {
      const response = await deleteRecording(restaurantUuid, recordingId);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const deleteAllCameraRecordingsAction = (restaurantUuid, cameraId, actions = {}) => {
  return async () => {
    try {
      const response = await deleteAllCameraRecordings(restaurantUuid, cameraId);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const getRecordingAction = (restaurantUuid, recordingId, actions = {}) => {
  return async (dispatch) => {
    try {
      const response = await getRecording(restaurantUuid, recordingId);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
        dispatch({ type: UPDATE_SCHEDULED_RECORDING, payload: { recording: results } });
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const getCameraRecordingsAction = (restaurantUuid, cameraId, params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_SCHEDULED_RECORDINGS });
    try {
      const response = await getCameraRecordings(restaurantUuid, cameraId, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results.content);
        dispatch({ type: GET_SCHEDULED_RECORDINGS_SUCCESS, payload: results, params });
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      dispatch({ type: GET_SCHEDULED_RECORDINGS_ERROR });
      console.log(error);
    }
  };
};

export const getRecordingsAction = (function () {
  let prevController;

  return (restaurantUuid, params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_SCHEDULED_RECORDINGS });
      try {
        const response = await getRecordings(restaurantUuid, params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_SCHEDULED_RECORDINGS_SUCCESS, payload: results, params });
          actions.onSuccess && actions.onSuccess(results.content);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          actions.onError && actions.onError(error.message);
          dispatch({ type: GET_SCHEDULED_RECORDINGS_ERROR });
          console.log(error);
        }
      }
    };
  };
})();

export const getReadyRecordingsAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_CAMERAS_RECORDINGS, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await recordingsApi.getRecordings(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_CAMERAS_RECORDINGS_SUCCESS, payload: results, params });
          actions.onSuccess && actions.onSuccess(results);
        } else {
          dispatch({ type: GET_CAMERAS_RECORDINGS_ERROR });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          actions.onError && actions.onError(error.message);
          dispatch({ type: GET_CAMERAS_RECORDINGS_ERROR });
          console.log(error);
        }
      }
    };
  };
})();

export const deleteReadyRecordingAction = (uuid, actions = {}) => {
  return async () => {
    try {
      const response = await recordingsApi.deleteRecording(uuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const getRecordingLinkAction = (uuid, actions = {}) => {
  return async () => {
    try {
      const response = await recordingsApi.getRecordingLink(uuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(results);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const getDownloadLinkAction = (uuid, actions = {}) => {
  return async () => {
    try {
      const response = await recordingsApi.getDownloadLink(uuid);
      const { success } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess(response);
      }
    } catch (error) {
      actions.onError && actions.onError(error.message);
      console.log(error);
    }
  };
};

export const downloadRecordingAction = (url, uuid, name, actions = {}) => {
  return async (dispatch) => {
    const controller = new AbortController();
    dispatch({ type: DOWNLOAD_CAMERA_RECORDING, payload: { uuid, controller } });
    try {
      const response = await recordingsApi.downloadRecording(
        url,
        controller.signal,
        _.throttle((progressEvent) => {
          if (progressEvent.loaded && progressEvent.total) {
            dispatch({
              type: SET_CAMERA_RECORDING_PROGRESS,
              payload: { uuid, progress: Math.round((progressEvent.loaded / progressEvent.total) * 100) },
            });
          }
        }, 500)
      );
      const { success, results } = response;
      if (success) {
        dispatch({ type: DOWNLOAD_CAMERA_RECORDING_SUCCESS, payload: { uuid } });
        let video = URL.createObjectURL(results);
        const tag = document.createElement('a');
        tag.href = video;
        tag.download = name;
        tag.click();
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
      dispatch({ type: DOWNLOAD_CAMERA_RECORDING_ERROR, payload: { uuid } });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CAMERAS_RECORDINGS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_CAMERAS_RECORDINGS_SEVERAL_VALUES,
  payload: value,
});

export const setCameraRecordingPreview = (uuid, preview) => ({
  type: SET_CAMERA_RECORDING_PREVIEW,
  payload: { uuid, preview },
});

export const resetStore = () => ({
  type: RESET_CAMERAS_RECORDINGS_STORE,
});
