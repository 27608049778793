import { getUnusualEvents } from '../../api/unusual';
import { getAnalytics } from '../../api/analytics';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_UNUSUAL_EVENTS_VALUE = 'CHANGE_UNUSUAL_EVENTS_VALUE';
export const CHANGE_UNUSUAL_EVENTS_SEVERAL_VALUES = 'CHANGE_UNUSUAL_EVENTS_SEVERAL_VALUES';
export const CHANGE_UNUSUAL_EVENTS_CURRENT_IMAGE = 'CHANGE_UNUSUAL_EVENTS_CURRENT_IMAGE';
export const SET_UNUSUAL_EVENT_IMAGE = 'SET_UNUSUAL_EVENT_IMAGE';
export const RESET_UNUSUAL_EVENTS_STORE = 'RESET_UNUSUAL_EVENTS_STORE';

export const GET_UNUSUAL_EVENTS = 'GET_UNUSUAL_EVENTS';
export const GET_UNUSUAL_EVENTS_SUCCESS = 'GET_UNUSUAL_EVENTS_SUCCESS';
export const GET_UNUSUAL_EVENTS_ERROR = 'GET_UNUSUAL_EVENTS_ERROR';

export const GET_UNUSUAL_EVENTS_ANALYTIC = 'GET_UNUSUAL_EVENTS_ANALYTIC';
export const GET_UNUSUAL_EVENTS_ANALYTIC_SUCCESS = 'GET_UNUSUAL_EVENTS_ANALYTIC_SUCCESS';
export const GET_UNUSUAL_EVENTS_ANALYTIC_ERROR = 'GET_UNUSUAL_EVENTS_ANALYTIC_ERROR';

export const getUnusualAction = (function () {
  let prevController;

  return (params, actions = {}) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_UNUSUAL_EVENTS });
      try {
        const response = await getUnusualEvents(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_UNUSUAL_EVENTS_SUCCESS, payload: results, params: params });
          actions.onSuccess && actions.onSuccess(results.content);
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_UNUSUAL_EVENTS_ERROR });
          actions.onError && actions.onError(error.message);
        }
      }
    };
  };
})();

export const getUnusualEventsAnalyticAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_UNUSUAL_EVENTS_ANALYTIC });
      try {
        const response = await getAnalytics(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_UNUSUAL_EVENTS_ANALYTIC_SUCCESS, payload: results, params: params });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_UNUSUAL_EVENTS_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const changeValue = (key, value) => ({
  type: CHANGE_UNUSUAL_EVENTS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_UNUSUAL_EVENTS_SEVERAL_VALUES,
  payload: value,
});

export const changeCurrentImage = (index, value) => ({
  type: CHANGE_UNUSUAL_EVENTS_CURRENT_IMAGE,
  payload: { index, value },
});

export const setEventImage = (eventId, index, image) => ({
  type: SET_UNUSUAL_EVENT_IMAGE,
  payload: { eventId, index, image },
});

export const resetStore = () => ({
  type: RESET_UNUSUAL_EVENTS_STORE,
});
