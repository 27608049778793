import { useEffect, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { ReactComponent as ArrowDropDown } from '../../../assets/img/icons/common/ArrowDropDown.svg';
import { ReactComponent as Close } from '../../../assets/img/icons/common/Close.svg';
import { TimeControlWithOptions } from '../../TimeControls/TimeControlWithOptions';
import { deleteSearchParameters, getSearchParameter, setSearchParameters } from '../../../utils/urlFunctions';
import { LabeledValue } from '../../LabeledValue';
import { getOffsetLabel } from '../../../utils/time';
import './TimeRangeFilter.scss';
import { usePlacement } from '../../../hooks/usePlacement';
import { useHandleOutsideClick } from '../../../hooks/useHandleOutsideClick';
import { createPortal } from 'react-dom';

const defaultStart = '13:00';
const defaultEnd = '15:00';
const startTimeIntervalParamName = 'startTimeInterval';
const endTimeIntervalParamName = 'endTimeInterval';

const getInitialValue = (start, end) => {
  return {
    start: start || defaultStart,
    end: end || defaultEnd,
  };
};

const getMomentDate = (date) => moment(`2024-01-01 ${date}:00`);

export const TimeRangeFilter = ({ range, clearable, handleChange, history }) => {
  const { start, end } = range;
  const [timeRange, setTimeRange] = useState(() => getInitialValue(start, end));
  const [selectOpened, setSelectOpened] = useState(false);
  const { refs, floatingStyles } = usePlacement('bottom-start', selectOpened);

  const hasValue = start && end;

  useHandleOutsideClick(
    selectOpened,
    () => {
      setSelectOpened(false);
      handleCancel();
    },
    refs.floating,
    refs.reference
  );

  useEffect(() => {
    const startTimeInterval = getSearchParameter(startTimeIntervalParamName);
    const endTimeInterval = getSearchParameter(endTimeIntervalParamName);

    if (startTimeInterval && endTimeInterval) {
      const newRange = { start: startTimeInterval, end: endTimeInterval };
      setTimeRange({ start: startTimeInterval, end: endTimeInterval });
      handleChange(newRange);
    }
  }, []);

  const handleCancel = () => {
    setSelectOpened(false);
    setTimeRange(getInitialValue(start, end));
  };

  const getFormattedDateRange = () => {
    return `${getMomentDate(start).format('hh:mm a')} - ${getMomentDate(end).format('hh:mm a')}`;
  };

  const getDurationValueLabel = () => {
    const [startHour, startMinute] = timeRange.start.split(':');
    const [endHour, endMinute] = timeRange.end.split(':');
    const diff1 = Number(startHour) * 60 + Number(startMinute);
    const diff2 = Number(endHour) * 60 + Number(endMinute);

    return getOffsetLabel(diff2 - diff1);
  };

  const handleSave = () => {
    const momentStart = getMomentDate(timeRange.start);
    const momentEnd = getMomentDate(timeRange.end);
    const isValidRange = momentEnd.isSameOrAfter(momentStart, 'minute');

    if (isValidRange) {
      if (history) {
        history.push(
          setSearchParameters(
            [startTimeIntervalParamName, endTimeIntervalParamName],
            [timeRange.start, timeRange.end]
          )
        );
      }

      handleChange(timeRange);
      setSelectOpened(false);
    }
  };

  const handleOpenFilter = () => {
    setSelectOpened((prev) => !prev);
  };

  const handleInputChange = (name, val) => {
    setTimeRange((prev) => ({ ...prev, [name]: val }));
  };

  const handleClear = (e) => {
    e.stopPropagation();
    if (history) {
      history.push(deleteSearchParameters([startTimeIntervalParamName, endTimeIntervalParamName]));
    }
    handleChange({ start: null, end: null });
  };

  const dropdownMenu = (
    <div
      ref={refs.setFloating}
      style={floatingStyles}
      className={cx('time-range__dropdown-menu filter__dropdown-menu')}
    >
      <div className="time-range__container">
        <div className="content-container">
          <TimeControlWithOptions
            className="time-control-with-options__datepicker"
            interval={15}
            time={timeRange.start}
            onChange={(e) => handleInputChange('start', e)}
          />
          <span>to</span>
          <TimeControlWithOptions
            className="time-control-with-options__datepicker"
            interval={15}
            time={timeRange.end}
            onChange={(e) => handleInputChange('end', e)}
            minTime={timeRange.start}
            startTime={timeRange.start}
          />
        </div>
        <div className="duration-container">
          <LabeledValue label="Duration" value={getDurationValueLabel()} />
        </div>
        <div className="button-wrapper">
          <button type="button" className="modal-button modal-button__cancel medium" onClick={handleCancel}>
            Cancel
          </button>

          <button onClick={handleSave} className="modal-button modal-button__confirm medium" type="submit">
            Apply
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div ref={refs.setReference} className="time-range-filter">
      <div className="filter__toggle" onClick={handleOpenFilter}>
        <span className="filter__label">Specific Time {hasValue && `: ${getFormattedDateRange()}`}</span>
        <ArrowDropDown />
        {hasValue && clearable && (
          <button className="filter__clear" onClick={handleClear}>
            <Close />
          </button>
        )}
      </div>

      {selectOpened && createPortal(dropdownMenu, document.getElementById('root'))}
    </div>
  );
};
