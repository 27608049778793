import * as types from '../actions/restaurantDevicesAction';
import { getEndDate, getStartDate } from '../../utils/urlFunctions';
import { prepareAvailabilityData } from '../../utils/devices';

const initialState = {
  activeTab: 'devices',
  startDate: null,
  endDate: null,
  timeInterval: null,
  intervalEnd: null,
  limit: '20',
  page: 0,
  totalResults: 0,
  devices: [],
  emptyDevices: [],
  devicesPage: 0,
  loading: false,
  availabilityLoading: false,
  data: [],
  availabilityData: [],
};

const restaurantDevicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_RESTAURANT_DEVICES_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_RESTAURANT_DEVICES_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };

    case types.GET_RESTAURANT_DEVICES:
      return {
        ...state,
        loading: true,
        availabilityData: [],
      };

    case types.GET_RESTAURANT_DEVICES_SUCCESS: {
      const devices = action.payload.devices;
      return {
        ...state,
        loading: false,
        totalResults: devices ? devices.length : 0,
        data: devices || [],
      };
    }

    case types.CHANGE_RESTAURANT_DEVICE_SUCCESS:
      return {
        ...state,
      };

    case types.GET_RESTAURANT_DEVICES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.GET_EMPTY_DEVICES:
      return {
        ...state,
        loadingDevices: true,
        devices: [],
      };

    case types.GET_EMPTY_DEVICES_RESULT:
      return {
        ...state,
        loadingDevices: false,
        emptyDevices: action.payload.content,
      };

    case types.GET_EMPTY_DEVICES_ERROR:
      return {
        ...state,
        loadingDevices: false,
        devices: [],
      };

    case types.ADD_RESTAURANT_DEVICES_SUCCESS:
      return {
        ...state,
      };

    case types.GET_RESTAURANT_DEVICES_AVAILABILITY:
      return {
        ...state,
        availabilityLoading: true,
      };

    case types.GET_RESTAURANT_DEVICES_AVAILABILITY_SUCCESS: {
      const startTime = getStartDate(state.startDate, state.timeInterval, state.timezone);
      const endTime = getEndDate(state.endDate, state.timeInterval, state.timezone);
      return {
        ...state,
        availabilityLoading: false,
        availabilityData: prepareAvailabilityData(startTime, endTime, state.data, action.payload),
      };
    }

    case types.GET_RESTAURANT_DEVICES_AVAILABILITY_ERROR:
      return {
        ...state,
        availabilityLoading: false,
      };

    case types.RESET_RESTAURANT_DEVICES_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default restaurantDevicesReducer;
