import InfiniteScroll from 'react-infinite-scroll-component';
import { DesktopTHead } from './DesktopTHead';
import { TableContentContainer } from '../TableContentContainer';
import { InfoRow } from '../InfoRow';
import { DesktopTableContent } from './DesktopTBody/DesktopTableContent';

export const DesktopScrollableTable = ({
  rows,
  nextData,
  existsNextData,
  isLoading,
  isScrollLoading,
  scrollableTarget,
  headerOptions,
}) => {
  return (
    <InfiniteScroll
      dataLength={rows.length}
      next={nextData}
      hasMore={existsNextData && !isLoading && !isScrollLoading}
      scrollableTarget={scrollableTarget || 'custom-table-container'}
      scrollThreshold={0.99}
      style={{ overflowY: 'hidden' }}
    >
      <div className="custom-table__wrapper">
        <table className="custom-table__table">
          <DesktopTHead headerOptions={headerOptions} />
          <TableContentContainer rows={rows} isLoading={isLoading}>
            <DesktopTableContent rows={rows} />
            {isScrollLoading && (
              <InfoRow>
                <i className="fa fa-spinner fa-spin fa-2x" />
              </InfoRow>
            )}
          </TableContentContainer>
        </table>
      </div>
    </InfiniteScroll>
  );
};
