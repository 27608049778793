import request from '../utils/request';

export function getFryers(params, signal) {
  return request({
    url: '/fryers',
    method: 'get',
    data: params,
    signal,
  });
}

export function createFryer(params) {
  return request({
    url: '/fryers',
    method: 'post',
    data: params,
  });
}

export function updateFryer(uuid, params) {
  return request({
    url: `/fryers/${uuid}`,
    method: 'put',
    data: params,
  });
}

export function deleteFryer(uuid) {
  return request({
    url: `/fryers/${uuid}`,
    method: 'delete',
    data: uuid,
  });
}
