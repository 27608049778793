import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { ReactComponent as ArrowUp } from '../../../assets/img/icons/common/North.svg';
import { ReactComponent as ArrowDown } from '../../../assets/img/icons/common/South.svg';

import './SortFilter.scss';

export const SortFilter = ({ options, sortBy, direction, changeValue }) => {
  const [listOpened, setListOpened] = useState(false);

  const toggleRef = useRef(null);

  useEffect(() => {
    if (listOpened) {
      document.addEventListener('click', checkSelectClick);
    }
  }, [listOpened]);

  function checkSelectClick(event) {
    if (!(event.path || (event.composedPath && event.composedPath())).includes(toggleRef.current)) {
      setListOpened(false);
      return document.removeEventListener('click', checkSelectClick);
    }
  }

  const handleChangeDirection = (event) => {
    event.stopPropagation();
    changeValue('orderDirection', direction === 'ASC' ? 'DESC' : 'ASC');
  };

  const handleSelectOption = (newValue) => {
    sortBy !== newValue && changeValue('sortBy', newValue);
    setListOpened(false);
  };

  return (
    <div ref={toggleRef} className={cx('sort-filter', { opened: listOpened })}>
      <div className="filter__toggle" onClick={() => options.length > 0 && setListOpened((prev) => !prev)}>
        <span className="filter__label">
          {`Sort by ${options.find((option) => option.value === sortBy)?.label}`}
        </span>
        <button className="filter__direction" onClick={handleChangeDirection}>
          {direction === 'ASC' ? <ArrowUp /> : <ArrowDown />}
        </button>
      </div>

      {listOpened && (
        <div className="filter__dropdown-menu">
          <ul className="filter__dropdown-menu__options">
            {options.map((option) => {
              const optionValue = option.value;
              return (
                <li
                  key={optionValue}
                  className={cx('option', { active: sortBy === optionValue })}
                  onClick={() => handleSelectOption(optionValue)}
                >
                  <span className="option__label">{option.label}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
