import moment from 'moment';

export const downloadFile = (data, type) => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const pwa = window.open(url);
  if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    alert('Please disable your Pop-up blocker and try again.');
  }
};

export const downloadFileWithName = (data, type, fileName) => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  if (fileName) a.download = fileName;
  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const makeVideScreenshot = async (videoElement) => {
  if (!videoElement) return;
  const canvas = document.createElement('canvas');

  canvas.width = 1920;
  canvas.height = 1080;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  const image = canvas.toDataURL('image/jpeg');

  const tag = document.createElement('a');
  tag.href = image;
  tag.download = `Screenshot-${new Date().toLocaleString()}.jpeg`;
  tag.click();
};

export const getCanvasFromVideo = (videoElement, width = 300, height = 300) => {
  if (!videoElement) return;
  const canvas = document.createElement('canvas');

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  return canvas;
};

const getISOIgnoreTimezone = (date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
};

export const onStartDateSelect = (date) => {
  const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
  return getISOIgnoreTimezone(startDate);
};

export const onEndDateSelect = (date) => {
  const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
  return getISOIgnoreTimezone(endDate);
};

export const onDateTimeSelect = (date) => {
  const startDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    0,
    0
  );
  return getISOIgnoreTimezone(startDate);
};

export const getFormattedDate = (date) => {
  return moment(date).format('MM/DD/YYYY h:mm:ss A');
};

export const getFormattedDateOrTime = (date, type = 'date') => {
  if (type === 'date') {
    return moment(date).format(`D MMM YYYY`);
  } else {
    return moment(date).format(`LTS`);
  }
};

export const getTimeFromMS = (s) => {
  var pad = (n, z = 2) => ('00' + n).slice(-z);
  return pad((s / 3.6e6) | 0) + ':' + pad(((s % 3.6e6) / 6e4) | 0) + ':' + pad(((s % 6e4) / 1000) | 0);
};

export const getTimeFromMSExcludeHours = (s) => {
  const timeWithHours = getTimeFromMS(s);
  const [hours, minutes, seconds] = timeWithHours.split(':');

  if (hours === '00') {
    return `${minutes}:${seconds}`;
  }

  return timeWithHours;
};

export const getRelativeDate = (date) => {
  const dateDifference = moment().diff(moment.utc(date));
  if (moment.duration(dateDifference)._data.years !== 0)
    return `${moment.duration(dateDifference)._data.years} year${
      moment.duration(dateDifference)._data.years !== 1 ? 's' : ''
    } ago`;
  else if (moment.duration(dateDifference)._data.months !== 0)
    return `${moment.duration(dateDifference)._data.months} month${
      moment.duration(dateDifference)._data.months !== 1 ? 's' : ''
    } ago`;
  else if (moment.duration(dateDifference)._data.days !== 0)
    return `${moment.duration(dateDifference)._data.days} day${
      moment.duration(dateDifference)._data.days !== 1 ? 's' : ''
    } ago`;
  else if (moment.duration(dateDifference)._data.hours !== 0)
    return `${moment.duration(dateDifference)._data.hours} hour${
      moment.duration(dateDifference)._data.hours !== 1 ? 's' : ''
    } ago`;
  else if (moment.duration(dateDifference)._data.minutes !== 0)
    return `${moment.duration(dateDifference)._data.minutes} minute${
      moment.duration(dateDifference)._data.minutes !== 1 ? 's' : ''
    } ago`;
  else if (moment.duration(dateDifference)._data.seconds !== 0) return 'just now';
  else return getFormattedDate(date);
};

export const convertBytes = (x, units, type = units.length, showBytes = false) => {
  let l = 0,
    n = parseInt(x, 10) || 0;
  if (type === units.length) {
    while (n >= 1024 && type > l && ++l) {
      n = n / 1024;
    }
  } else {
    let d = type > 0 ? Math.pow(1024, type) : 1;
    n = n / d;
    l = type;
  }

  return !showBytes ? parseInt(n * 100) / 100 + ' ' + units[l] : parseInt(n * 100) / 100;
};

export const convertThousands = (x, units) => {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1000 && ++l) {
    n = n / 1000;
  }
  return (
    (parseInt(n * 100) / 100).toString().slice(0, (parseInt(n * 100) / 100).toString()[4] !== '.' ? 5 : 4) +
    units[l]
  );
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getTimezoneOffsetInSeconds = (timeZone = 'UTC') => {
  const utcDate = new Date(new Date().toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(new Date().toLocaleString('en-US', { timeZone: timeZone || 'UTC' }));
  return (
    Math.floor((tzDate.getTime() - utcDate.getTime()) / 1000) * 1000 + new Date().getTimezoneOffset() * 60000
  );
};

export const getUTCTimezoneOffsetInSeconds = (timeZone = 'UTC') => {
  const utcDate = new Date(new Date().toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(new Date().toLocaleString('en-US', { timeZone: timeZone || 'UTC' }));
  return Math.floor((tzDate.getTime() - utcDate.getTime()) / 1000) * 1000;
};

export const getTimeWithOffset = (timeString, offset) => {
  const [hours, minutes] = timeString.split(':');

  const allMinutes = Number(hours) * 60 + Number(minutes) + offset;
  const newMinutes = allMinutes % 60;
  const newHours = Math.floor(allMinutes / 60) % 24;

  return `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
};

export const computeDurationBetweenTimes = (startTime, endTime) => {
  const MINUTES_PER_DAY = 24 * 60;
  const [startHours, startMinutes] = startTime.split(':');
  const [endHours, endMinutes] = endTime.split(':');

  const diffInMinutes =
    Number(endHours) * 60 + Number(endMinutes) - Number(startHours) * 60 - Number(startMinutes);

  return diffInMinutes >= 0 ? diffInMinutes : diffInMinutes + MINUTES_PER_DAY;
};

export const getTimeMinutes = (time) => {
  if (!time) return 0;
  const [hours, minutes] = time.split(':');

  return Number(hours) * 60 + Number(minutes);
};

export const convertTo12HourFormat = (time) => {
  if (!time) return '12:00 am';
  const [hour, minute] = time.split(':').map(Number);

  const period = hour >= 12 ? 'pm' : 'am';
  const twelveHour = hour % 12 || 12;

  const formattedTime = `${twelveHour?.toString()?.padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')} ${period}`;

  return formattedTime;
};

export const convertTo24HourFormat = (time) => {
  if (!time) return '00:00';
  const [timePart, period] = time.split(' ');
  const [hour, minute] = timePart.split(':').map(Number);

  let twentyFourHour;
  if (period.toLowerCase() === 'am') {
    twentyFourHour = hour === 12 ? 0 : hour;
  } else {
    twentyFourHour = hour === 12 ? 12 : hour + 12;
  }

  const formattedTime = `${twentyFourHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;

  return formattedTime;
};

export const transformScreamingSnakeCaseIntoHumanCase = (str) => {
  if (!str) return '';
  return str
    .split('_')
    .map((typePart) => typePart[0] + typePart.slice(1).toLowerCase())
    .join(' ');
};

export const isBrowser = (keyword) => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return userAgent.includes(keyword.toLowerCase());
};

export const formatDuration = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const parts = [];
  if (hours > 0) {
    parts.push(`${hours}h`);
  }
  if (minutes > 0) {
    parts.push(`${minutes}m`);
  }
  if (remainingSeconds > 0 || parts.length === 0) {
    parts.push(`${remainingSeconds}s`);
  }

  return parts.join(' ');
};

export const formatDurationFromMs = (ms) => {
  const seconds = Math.floor(ms / 1000);

  return formatDuration(seconds);
};

export const scrollToElement = (containerId, elementDataId, isNotSmooth = false) => {
  const scrollableContainer = document.getElementById(containerId);
  const elementScrollTo = scrollableContainer.querySelector(`[data-id="${elementDataId}"]`);
  if (elementScrollTo) {
    elementScrollTo.scrollIntoView({
      behavior: isNotSmooth ? 'auto' : 'smooth',
      block: 'center',
      inline: 'center',
    });
  }
};
