import React from 'react';
import cx from 'classnames';

import { ReactComponent as ArrowUp } from '../../../../assets/img/icons/common/North.svg';
import { ReactComponent as ArrowDown } from '../../../../assets/img/icons/common/South.svg';

export const HeaderCell = ({
  cell: {
    label,
    value,
    Component,
    align,
    width,
    minWidth,
    maxWidth,
    mobileWidth,
    paddingLeft,
    paddingRight,
    sortable,
  },
  sortModel,
  mobile = false,
}) => {
  const onCellClick = () => {
    if (sortModel.sortBy === value) {
      sortModel.orderDirection === 'DESC'
        ? sortModel.changeSortValue('orderDirection', 'ASC')
        : sortModel.changeSortValue('orderDirection', 'DESC');
    } else {
      sortModel.changeSortValue('sortBy', value);
    }
  };

  return (
    <th
      className={cx('custom-table__header-cell', { sortable })}
      align={align || 'left'}
      style={{
        width: (mobile && mobileWidth) || width || 'auto',
        minWidth: minWidth || 'auto',
        maxWidth: maxWidth || 'auto',
        paddingLeft,
        paddingRight,
      }}
      onClick={sortable && sortModel ? onCellClick : null}
    >
      {Component ? Component : label}
      {sortable && value && sortModel ? (
        <div className={cx('custom-table__header-cell__sort-icon', { active: sortModel.sortBy === value })}>
          {sortModel.orderDirection === 'DESC' ? <ArrowDown /> : <ArrowUp />}
        </div>
      ) : null}
    </th>
  );
};
