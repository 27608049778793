import React, { useEffect, useRef } from 'react';

import { checkAndUpdateDimensions } from '../../utils/resizing';

import './ResizableWrapper.scss';

export const ResizableWrapper = ({
  children,
  wrapperSize,
  sizeRef,
  positionRef,
  setSize,
  setPosition,
  disabled,
  minHeight = 200,
  minWidth = 300,
  updateSavedPositionAndSize,
}) => {
  const cornerPoint = useRef(null);
  const horizontalLine = useRef(null);
  const verticalLine = useRef(null);
  const changePositionRelative = useRef(null);
  const cornerLeftBottomPoint = useRef(null);
  const cornerRightTopPoint = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      let deltaX = e.movementX;
      let deltaY = e.movementY;
      if (cornerPoint.current) {
        changePositionRelativeElement(changeXY, deltaY, deltaX) ||
          changeXY({ xWidth: -deltaX, yHeight: -deltaY });
      } else if (cornerLeftBottomPoint.current) {
        changePositionRelativeElement(changeXY, -deltaY, deltaX, 0, deltaX);
      } else if (cornerRightTopPoint.current) {
        changePositionRelativeElement(changeXY, deltaY, -deltaX, deltaY, 0);
      } else if (horizontalLine.current) {
        changePositionRelativeElement(changeY, deltaY) || changeY({ yHeight: -deltaY });
      } else if (verticalLine.current) {
        changePositionRelativeElement(changeX, undefined, deltaX) || changeX({ xWidth: -deltaX });
      }
    };

    const handleMouseUp = () => {
      if (
        cornerPoint.current ||
        horizontalLine.current ||
        verticalLine.current ||
        changePositionRelative.current ||
        cornerLeftBottomPoint.current ||
        cornerRightTopPoint.current
      ) {
        const { left, top, width, height } = checkAndUpdateDimensions(
          positionRef.current,
          sizeRef.current,
          15,
          0,
          minWidth,
          minHeight
        );
        setPosition({ left, top });
        setSize({ width, height });
        updateSavedPositionAndSize({ left, top }, { width, height });
      }
      cornerPoint.current = false;
      horizontalLine.current = false;
      verticalLine.current = false;
      changePositionRelative.current = false;
      cornerLeftBottomPoint.current = false;
      cornerRightTopPoint.current = false;
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  const changeX = ({ xWidth }) => {
    setSize((prev) => ({
      ...prev,
      width: prev.width - xWidth < minWidth ? prev.width : prev.width - xWidth,
    }));
  };

  const changeY = ({ yHeight }) => {
    setSize((prev) => ({
      ...prev,
      height: prev.height - yHeight < minHeight ? prev.height : prev.height - yHeight,
    }));
  };

  const changeXY = ({ yHeight, xWidth }) => {
    setSize((prev) => ({
      height: prev.height - yHeight < minHeight ? prev.height : prev.height - yHeight,
      width: prev.width - xWidth < minWidth ? prev.width : prev.width - xWidth,
    }));
  };

  const changePositionRelativeElement = (changeSize, yHeight, xWidth, yTop, xLeft) => {
    if (changePositionRelative.current && changePosition) {
      const changeTop = yTop !== undefined ? yTop : yHeight;
      const changeLeft = xLeft !== undefined ? xLeft : xWidth;
      if (
        sizeRef.current?.height - (yHeight || 0) >= minHeight &&
        sizeRef.current?.width - (xWidth || 0) >= minWidth
      ) {
        changePosition(changeTop, changeLeft);
      } else if (
        sizeRef.current?.height - (yHeight || 0) >= minHeight &&
        sizeRef.current?.width - (xWidth || 0) <= minWidth
      ) {
        changePosition(changeTop);
      } else if (
        sizeRef.current?.height - (yHeight || 0) <= minHeight &&
        sizeRef.current?.width - (xWidth || 0) >= minWidth
      ) {
        changePosition(undefined, changeLeft);
      }
      changeSize({ yHeight, xWidth });
      return true;
    }
    return false;
  };

  const selectResizablePoint = (e, ref, refValue) => {
    e.preventDefault();
    ref.current = true;
    changePositionRelative.current = checkChangeValuePosition(refValue);
  };

  const checkChangeValuePosition = ({ horizontal, vertical }) => {
    return !!(horizontal === 'top' || vertical === 'left');
  };

  const changePosition = (top, left) => {
    setPosition((prev) => ({
      top: top ? prev.top + top : prev.top,
      left: left ? prev.left + left : prev.left,
    }));
  };

  return (
    <div className="resizable-window-content">
      {!disabled && (
        <>
          <div
            onMouseDown={(e) => {
              selectResizablePoint(e, cornerPoint, {
                value: true,
                horizontal: 'top',
                vertical: 'left',
              });
            }}
            className="corner-dot corner-dot-left-top left-top-right-bottom-corner-dot"
          ></div>
          <div
            onMouseDown={(e) => {
              selectResizablePoint(e, horizontalLine, {
                value: true,
                horizontal: 'top',
              });
            }}
            className="horizontal-line top-line"
            style={{
              width: `${wrapperSize.width ? wrapperSize.width - 10 : 0}px`,
            }}
          ></div>
          <div
            onMouseDown={(e) => {
              selectResizablePoint(e, cornerRightTopPoint, {
                value: true,
                horizontal: 'top',
                vertical: 'right',
              });
            }}
            className="corner-dot corner-dot-right-top left-bottom-right-top-corner-dot"
          ></div>
          <div
            onMouseDown={(e) => {
              selectResizablePoint(e, verticalLine, {
                value: true,
                vertical: 'right',
              });
            }}
            className="vertical-line right-line"
            style={{
              height: `${wrapperSize.height ? wrapperSize.height - 10 : 0}px`,
            }}
          ></div>
          <div
            onMouseDown={(e) => {
              selectResizablePoint(e, cornerPoint, {
                value: true,
                horizontal: 'bottom',
                vertical: 'right',
              });
            }}
            className="corner-dot corner-dot-right-bottom left-top-right-bottom-corner-dot"
          ></div>
          <div
            onMouseDown={(e) => {
              selectResizablePoint(e, horizontalLine, {
                value: true,
                horizontal: 'bottom',
              });
            }}
            className="horizontal-line bottom-line"
            style={{
              width: `${wrapperSize.width ? wrapperSize.width - 10 : 0}px`,
            }}
          ></div>
          <div
            onMouseDown={(e) => {
              selectResizablePoint(e, cornerLeftBottomPoint, {
                value: true,
                horizontal: 'bottom',
                vertical: 'left',
              });
            }}
            className="corner-dot corner-dot-left-bottom left-bottom-right-top-corner-dot"
          ></div>
          <div
            onMouseDown={(e) => {
              selectResizablePoint(e, verticalLine, {
                value: true,
                vertical: 'left',
              });
            }}
            className="vertical-line left-line"
            style={{
              height: `${wrapperSize.height ? wrapperSize.height - 10 : 0}px`,
            }}
          ></div>
        </>
      )}
      {children}
    </div>
  );
};
