import React from 'react';

import { ChartComponent, TableWithPagination } from '../..';

import { performancesColumns } from '../../../constants/performances.constants';
import { performanceOptions } from './constants';

import './PerformanceAnalytic.scss';
import { createRows, getChartData } from './utils';

export const PerformanceAnalytics = ({ metrics, activeTab }) => {
  return (
    <div className="performances__info-container">
      {metrics[activeTab].chart && (
        <div className="performances__chart-part">
          <div className="pie-chart__wrapper">
            <ChartComponent
              chartType="PieChart"
              width="270px"
              height="270px"
              chartOptions={performanceOptions}
              content={getChartData(metrics, activeTab)}
            />
            <div className="pie-chart__description">
              <div className="pie-chart__item">
                <div className="color__indicator green-zone"></div>
                {'≤ 20 Minutes'}
              </div>
              <div className="pie-chart__item">
                <div className="color__indicator light-green-zone"></div>
                {'20-25 Minutes'}
              </div>
              <div className="pie-chart__item">
                <div className="color__indicator lighter-green-zone"></div>
                {'25-30 Minutes'}
              </div>
              <div className="pie-chart__item">
                <div className="color__indicator yellow-zone"></div>
                {'30-35 Minutes'}
              </div>
              <div className="pie-chart__item">
                <div className="color__indicator orange-zone"></div>
                {'35-40 Minutes'}
              </div>
              <div className="pie-chart__item">
                <div className="color__indicator red-zone"></div>
                {'>40 Minutes'}
              </div>
            </div>
          </div>
        </div>
      )}
      {metrics[activeTab].table && (
        <div className="performances__table-part" id="scrollable-performances-content">
          <TableWithPagination
            className="analytics__pie-chart-table mb-16"
            rows={createRows(metrics[activeTab].table.data)}
            headerOptions={performancesColumns}
            footerOptions={metrics[activeTab].table.total}
          />
        </div>
      )}
    </div>
  );
};
