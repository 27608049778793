import { getArucoRestaurants, getArucoStreams, startStream, stopStream } from '../../api/plugplay';

export const CHANGE_ARUCO_VALIDATION_VALUE = 'CHANGE_ARUCO_VALIDATION_VALUE';
export const CHANGE_ARUCO_STREAM_VALUE = 'CHANGE_ARUCO_STREAM_VALUE';
export const RESET_ARUCO_VALIDATION_STORE = 'RESET_ARUCO_VALIDATION_STORE';
export const SET_ARUCO_ACTIVE_STREAM_ID = 'SET_ARUCO_ACTIVE_STREAM_ID';
export const SET_ARUCO_STREAMS_LIST = 'SET_ARUCO_STREAMS_LIST';
export const SET_ARUCO_RESTART_STREAM = 'SET_ARUCO_RESTART_STREAM';
export const SWAP_ARUCO_STREAMS = 'SWAP_ARUCO_STREAMS';

export const GET_ARUCO_RESTAURANTS = 'GET_ARUCO_RESTAURANTS';
export const GET_ARUCO_RESTAURANTS_SUCCESS = 'GET_ARUCO_RESTAURANTS_SUCCESS';
export const GET_ARUCO_RESTAURANTS_ERROR = 'GET_ARUCO_RESTAURANTS_ERROR';

export const START_ARUCO_STREAM = 'START_ARUCO_STREAM';
export const START_ARUCO_STREAM_SUCCESS = 'START_ARUCO_STREAM_SUCCESS';
export const START_ARUCO_STREAM_ERROR = 'START_ARUCO_STREAM_ERROR';

export const STOP_ARUCO_STREAM = 'STOP_ARUCO_STREAM';
export const STOP_ARUCO_STREAM_SUCCESS = 'STOP_ARUCO_STREAM_SUCCESS';
export const STOP_ARUCO_STREAM_ERROR = 'STOP_ARUCO_STREAM_ERROR';

export const GET_ARUCO_ZONE_STREAMS = 'GET_ARUCO_ZONE_STREAMS';
export const GET_ARUCO_ZONE_STREAMS_SUCCESS = 'GET_ARUCO_ZONE_STREAMS_SUCCESS';
export const GET_ARUCO_ZONE_STREAMS_ERROR = 'GET_ARUCO_ZONE_STREAMS_ERROR';

export const setStreamsList = (list) => ({ type: SET_ARUCO_STREAMS_LIST, payload: list });

export const setActiveStreamId = (id) => ({ type: SET_ARUCO_ACTIVE_STREAM_ID, payload: id });

export const setRestartStream = (needRestart) => ({
  type: SET_ARUCO_RESTART_STREAM,
  payload: needRestart,
});

export const getArucoStreamsAction = (uuid, actions) => {
  return async (dispatch) => {
    dispatch({ type: GET_ARUCO_ZONE_STREAMS });
    try {
      const response = await getArucoStreams(uuid);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_ARUCO_ZONE_STREAMS_SUCCESS, payload: results.sort() });
        actions.onSuccess(results.sort());
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_ARUCO_ZONE_STREAMS_ERROR });
    }
  };
};

export const getArucoRestaurantsAction = (params, actions) => {
  return async (dispatch) => {
    dispatch({ type: GET_ARUCO_RESTAURANTS });
    try {
      const response = await getArucoRestaurants(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_ARUCO_RESTAURANTS_SUCCESS });
        actions.onSuccess([
          { label: 'Select Restaurant', value: 'select' },
          ...(results ? results : []).map((restaurant) => ({
            label: `${restaurant.number} - ${restaurant.name}`,
            value: restaurant.uuid,
          })),
        ]);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_ARUCO_RESTAURANTS_ERROR });
    }
  };
};

export const startStreamAction = (zone, params) => {
  return async (dispatch) => {
    dispatch({ type: START_ARUCO_STREAM });
    try {
      const response = await startStream(zone, params);
      const { success } = response;
      if (success) {
        dispatch({ type: START_ARUCO_STREAM_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: START_ARUCO_STREAM_ERROR });
    }
  };
};

export const stopStreamAction = (zone, params) => {
  return async (dispatch) => {
    dispatch({ type: STOP_ARUCO_STREAM });
    try {
      const response = await stopStream(zone, params);
      const { success } = response;
      if (success) {
        dispatch({ type: STOP_ARUCO_STREAM_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: STOP_ARUCO_STREAM_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_ARUCO_VALIDATION_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeStreamValue = (key, name, value) => ({
  type: CHANGE_ARUCO_STREAM_VALUE,
  payload: {
    key,
    name,
    value,
  },
});

export const swapLayoutStreams = (index1, index2) => ({
  type: SWAP_ARUCO_STREAMS,
  payload: {
    index1,
    index2,
  },
});

export const resetStore = () => ({
  type: RESET_ARUCO_VALIDATION_STORE,
});
