import request from '../utils/request';

export function getRecommendations(params, signal) {
  return request({
    url: `/recommendationTimerCycleHistory`,
    method: 'get',
    data: params,
    signal,
  });
}

export function getRecommendationsTotal(params, signal) {
  return request({
    url: `/recommendationTimerCycleHistory/total`,
    method: 'get',
    data: params,
    signal,
  });
}

export function getRecommendationsAnalytic(params, signal) {
  return request({
    url: `/recommendationTimerCycleHistory/analytic`,
    method: 'get',
    data: params,
    signal,
  });
}

export function deleteRecommendation(uuid) {
  return request({
    url: `/recommendationTimerCycleHistory/${uuid}`,
    method: 'delete',
  });
}
