import cx from 'classnames';

const NavItem = ({ label, active, onClick }) => {
  return (
    <li className={cx('nav-item', { active: active })} aria-selected={active} role="tab" onClick={onClick}>
      <span>{label}</span>
    </li>
  );
};

export default NavItem;
