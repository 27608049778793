import request from '../utils/request';

export function getAuditLog(params, signal) {
  return request({
    url: '/auditTrail',
    method: 'get',
    data: params,
    signal,
  });
}
