import React from 'react';
import cx from 'classnames';

import './Switch.scss';

export const Switch = ({ active, disabled, onChange }) => {
  return (
    <button className={cx('custom-switch', { active })} onClick={() => !disabled && onChange(!active)}>
      <div className="custom-switch__bar">
        <div className="custom-switch__thumb">
          <div className="custom-switch__thumb-main"></div>
        </div>
      </div>
    </button>
  );
};
