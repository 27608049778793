import moment from 'moment';
import { convertBytes, getTimezoneOffsetInSeconds } from '.';
import { pieChartThousands } from '../constants/analytics.constants';
import { miniGraphicColors, units } from '../constants/graphics.constants';
import { roundNumberTo } from './formatting';

export const MsToTimeTransform = (duration) => {
  if (!duration && duration !== 0) return '';

  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
  const minutesStr = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const secondsStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
};

export const dayPeriod = [
  {
    name: 'Breakfast - Before 10:30am',
    startTime: MsToTimeTransform(0),
    endTime: MsToTimeTransform(37799999),
  },
  {
    name: 'Lunch - 10:30am - 2pm',
    startTime: MsToTimeTransform(37800000),
    endTime: MsToTimeTransform(50399999),
  },
  {
    name: 'Dinner - After 2pm',
    startTime: MsToTimeTransform(50400000),
    endTime: MsToTimeTransform(863999999),
  },
];

export const getDayPart = (date) => {
  const time = moment(moment.utc(date).format('HH:mm:ss'), 'HH:mm:ss');
  let dayPart = '';
  dayPeriod.forEach((part) => {
    if (
      moment(time, 'HH:mm:ss').isBetween(
        moment(part.startTime, 'HH:mm:ss'),
        moment(part.endTime, 'HH:mm:ss'),
        null,
        '[]'
      )
    ) {
      dayPart = part.name;
    }
  });
  return dayPart;
};

export const getColor = (material, materials) => {
  if (materials.map((it) => it.label).includes(material)) {
    return materials.find((it) => it.label === material).color;
  }
  switch (material) {
    case 'Breakfast Filets':
      return '#17A2B8';
    case 'Spicy Brkfst Filets':
      return '#FA9370';
    case 'CFA Filets':
      return '#004F71';
    case 'Spicy Filets':
      return '#DD0031';
    case 'Strips':
      return '#249E6B';
    case 'Spicy Strips':
      return '#994878';
    case 'Nuggets':
      return '#F8C1B8';
    default:
      return '#000000';
  }
};

export const getColorRange = (range) => {
  switch (range) {
    case '<= 20 MINUTES':
      return '#eac01a';
    case '21-25 MINUTES':
      return '#04b3e5';
    case '25-30 MINUTES':
      return '#f458d2';
    case '> 30 MINUTES':
      return '#e1080d';
    case 'TIMER ERRORS':
      return '#7b43b0';
    default:
      return '#000000';
  }
};

export const getBoxPlotValues = (array) => {
  let maxLength = 0;
  array.forEach((item) => {
    item.length > maxLength && (maxLength = item.length);
  });

  for (let i = 0; i < array.length; i++) {
    const sortedData = array[i].slice(1, -1).sort((a, b) => a - b);

    const max = sortedData[sortedData.length - 1];
    const min = sortedData[0];
    const median = getMedian(sortedData);

    // First Quartile is the median from lowest to overall median.
    const firstQuartile = getMedian(sortedData.slice(0, Math.round(sortedData.length / 2)));

    // Third Quartile is the median from the overall median to the highest.
    const thirdQuartile = getMedian(sortedData.slice(Math.round(sortedData.length / 2)));

    if (sortedData.length !== maxLength - 2) {
      const dif = maxLength - sortedData.length;
      const medIdx = Math.floor(sortedData.length / 2);
      const medMin = sortedData[medIdx];
      const medMax = sortedData[medIdx + 1];
      const medVal = Math.floor((medMin + medMax) / 2);
      const arrMed = [];
      for (let j = 2; j < dif; j++) {
        arrMed.push(medVal);
      }
      sortedData.splice(medIdx, 0, ...arrMed);
      array[i].splice(medIdx, 0, ...arrMed);
    }

    array[i].push(max);
    array[i].push(min);
    array[i].push(firstQuartile);
    array[i].push(median);
    array[i].push(thirdQuartile);
    array[i].push(
      '<br><p>MAX = ' +
        max.toFixed(2) +
        '</p>' +
        '\n' +
        '<p>75% <= ' +
        thirdQuartile.toFixed(2) +
        '</p>' +
        '\n' +
        '<p>50% <= ' +
        median.toFixed(2) +
        '</p>' +
        '\n' +
        '<p>25% <= ' +
        firstQuartile.toFixed(2) +
        '</p> ' +
        '\n' +
        '<p>MIN = ' +
        min.toFixed(2) +
        '</p> '
    );
  }

  return array;
};

const getMedian = (array) => {
  const length = array.length;

  if (length % 2 === 0) {
    const midUpper = length / 2;
    const midLower = midUpper - 1;

    return (array[midUpper] + array[midLower]) / 2;
  } else {
    return array[Math.floor(length / 2)];
  }
};

export const getHoursInDayPart = (daypart) => {
  if (daypart.toLowerCase().indexOf('breakfast') !== -1) {
    return 4.5;
  }
  if (daypart.toLowerCase().indexOf('lunch') !== -1) {
    return 3.5;
  }
  if (daypart.toLowerCase().indexOf('dinner') !== -1) {
    return 8;
  }
  return 4.5;
};

export const getDateFormatForPeriod = (period) => {
  switch (period) {
    case 'DAY':
      return 'YYYY-MM-DD';
    case 'HOUR':
      return 'YYYY-MM-DD HH';
    case 'MONTH':
      return 'YYYY-MM';
    case 'DAY_PART':
      return 'YYYY-MM-DD';
    case 'WEEK':
      return 'YYYY';
    default:
      return 'YYYY-MM-DD';
  }
};

export const getPackageTypeColor = (packageType) => {
  switch (packageType) {
    case 'WAFFLE_FRIES_SMALL':
      return '#64a0d6';
    case 'WAFFLE_FRIES_MEDIUM':
      return '#fec324';
    case 'WAFFLE_FRIES_LARGE':
      return '#a9a9a9';
    case 'HASHBROWNS':
      return '#4f79c7';
    case 'HASHBROWNS_BIG':
      return '#ed833e';
    default:
      return '#000000';
  }
};

export const getPotatoTypeColor = (potatoType) => {
  switch (potatoType) {
    case 'WAFFLE_FRIES':
      return '#4f79c7';
    case 'HASHBROWNS':
      return '#ed833e';
    default:
      return '';
  }
};

export const getAverageHoldTimeColor = (htType) => {
  switch (htType) {
    case 'averageCookedTime':
      return '#4f79c7';
    case 'averagePackagedTime':
      return '#ed833e';
    default:
      return '#000000';
  }
};

export const getAverageHoldTimeTitle = (htType) => {
  switch (htType) {
    case 'averageCookedTime':
      return 'Average of Cooked Hold Time';
    case 'averagePackagedTime':
      return 'Average of Packaged Hold Time';
    default:
      return 'unknown';
  }
};

export const getColorCompliant = (compliant) => {
  switch (compliant) {
    case 'Cooked Hold Time Violation':
      return '#ed833e';
    case 'Packaged Hold Time Violation':
      return '#fec324';
    case 'Violated Both Hold Times':
      return '#ff231d';
    case 'Hold Time Compliant':
      return '#0baf57';
    default:
      return '#000000';
  }
};

export const getChartMax = (x, returnThousand = false) => {
  let l = 0,
    n = parseInt(x * 1.3, 10) || 0;
  while (n >= 1000 && x >= 1000 && ++l) {
    n = n / 1000;
  }
  if (l > 1 || x > 50) {
    let pow = Math.pow(10, `${Math.floor(n)}`.length - 1);
    let finalPow = Math.pow(10, `${Math.floor(n)}`.length - 1 + l * 3);
    let temp = n / pow;
    if (temp % 1.5 !== 0 && temp % 2 !== 0) {
      let coef = 1;
      if ((temp - (temp % 0.3)) * finalPow > x || (temp - (temp % 2)) * finalPow > x) {
        coef = -temp % ((temp - (temp % 2)) * finalPow > x ? 2 : 0.3);
      } else {
        coef = temp % 0.3 <= temp % 2 ? 0.3 - (temp % 0.3) : 2 - (temp % 2);
      }
      n = Math.floor((temp + coef).toFixed(1) * finalPow);
    }
  } else {
    if (x > 10) n += 20 - (n % 20);
    else n += 2 - (n % 2);
  }
  return !returnThousand ? n.toFixed(1) : { n, l };
};

export const getChartAxis = (x) => {
  let { n, l } = getChartMax(x, true) || 0;
  let pow = l > 0 ? Math.pow(10, -l * 3) : 1;
  return n % 3 === 0 || n % 1.5 === 0
    ? [
        {
          v: 0,
          f: 0,
        },
        {
          v: n / 3,
          f: parseFloat(((n / 3) * pow).toFixed(1)) + pieChartThousands[l],
        },
        {
          v: (n * 2) / 3,
          f: parseFloat((((n * 2) / 3) * pow).toFixed(1)) + pieChartThousands[l],
        },
        {
          v: n,
          f: parseFloat((n * pow).toFixed(1)) + pieChartThousands[l],
        },
      ]
    : [
        {
          v: 0,
          f: 0,
        },
        {
          v: n * 0.25,
          f: parseFloat((n * 0.25 * pow).toFixed(1)) + pieChartThousands[l],
        },
        {
          v: n * 0.5,
          f: parseFloat((n * 0.5 * pow).toFixed(1)) + pieChartThousands[l],
        },
        {
          v: n * 0.75,
          f: parseFloat((n * 0.75 * pow).toFixed(1)) + pieChartThousands[l],
        },
        {
          v: n,
          f: parseFloat((n * pow).toFixed(1)) + pieChartThousands[l],
        },
      ];
};

export const initialValues = (dates) => [
  [
    dates[2] !== null
      ? Date.now() + getTimezoneOffsetInSeconds(dates[3]) - dates[2] * 1000
      : new Date(dates[0]),
    '',
    0,
  ],
  [dates[2] !== null ? new Date(moment().tz(dates[3])) : new Date(dates[1]), '', 0],
];

export const createMonitoringRows = (rows) => {
  return rows.map((item, index) => ({
    id: index,
    cells: [
      {
        label: item !== null && item.values !== null ? item.metricLabel || item.machine : '',
      },
      {
        label:
          item !== null && item.values !== null
            ? roundNumberTo(Math.min(...item.values.map((val) => val[1])), 2)
            : 'N/A',
      },
      {
        label:
          item !== null && item.values !== null
            ? roundNumberTo(Math.max(...item.values.map((val) => val[1])), 2)
            : 'N/A',
      },
      {
        label:
          item !== null && item.values !== null
            ? roundNumberTo(
                item.values.map((val) => val[1]).reduce((partialSum, a) => partialSum + a, 0) /
                  item.values.length,
                2
              )
            : 'N/A',
      },
      {
        label:
          item !== null && item.values !== null && item.values[item.values.length - 1][1] !== null
            ? roundNumberTo(item.values[item.values.length - 1][1], 2)
            : 'N/A',
      },
    ],
  }));
};

export const getChartTooltip = (
  data,
  value,
  valueIndex,
  dates,
  chartMetric,
  colorIndex = null,
  showUnits = false
) => {
  let tooltip = `<div class="chart-tooltip">${moment(value[0])
    .tz(dates[3])
    .format('MMM D, YYYY, HH:mm:ss')}${`${
    data.length > 1
      ? data
          .map((content, ind) => [
            content.metricLabel || content.machine,
            content.values[valueIndex] ? roundNumberTo(Number(content.values[valueIndex][1]), 2) : null,
            colorIndex !== null ? ind + (colorIndex || 0) : null,
          ])
          .filter((item) => item !== null)
          .sort((x, y) => {
            return y[1] - x[1];
          })
          .map(
            (content) =>
              `<div class="chart-tooltip__content">${
                colorIndex !== null
                  ? `<div class="chart-tooltip__color" style="background-color: ${
                      miniGraphicColors[content[2]]
                    };"></div>`
                  : ''
              }${content[0]}:&nbsp<b>${content[1]}${
                showUnits ? (chartMetric === '%' ? '%' : units[chartMetric]) : ''
              }</b></div>`
          )
      : value
          .slice(1)
          .map(
            (content) =>
              `<div>${chartMetric === '%' ? 'Percent' : units[chartMetric]}: <b>${
                content !== null ? roundNumberTo(content, 2) : 'N/A'
              }</b></div>`
          )
  }`.replaceAll(',', '')}</div>`;

  return tooltip;
};

export const getGraphicsData = (results, machine, type = false, multiplier = 1, metricLabelModel = null) => {
  let dates = results.data.result
    .filter((item) => !!item.metric && !!item.metric.host)
    .map((it) => it.values.map((value) => value[0]))
    .flat()
    .filter((item, index, arr) => arr.indexOf(item) === index)
    .sort();
  return results.data.result.length !== 0
    ? results.data.result
        .filter((item) => !!item.metric && !!item.metric.host)
        .sort(function (a, b) {
          return b.values.length - a.values.length;
        })
        .map((item) => ({
          machine: item.metric
            ? machine === 'select' || machine.includes('|')
              ? item.metric.host +
                (results.data.result.map((item) => item.metric.host).filter((v) => v === item.metric.host)
                  .length > 1
                  ? '(' + item.metric.device + ')'
                  : '')
              : item.metric.device || item.metric.host
            : '',
          values: dates.map((value) => [
            new Date(Number(value) * 1000),
            typeof item.values.find((val) => val[0] === value) !== 'undefined'
              ? machine === 'select' || machine.includes('|') || !type
                ? Number(item.values.find((val) => val[0] === value)[1])
                : convertBytes(
                    Number(item.values.find((val) => val[0] === value)[1] * multiplier),
                    units,
                    type,
                    true
                  )
              : null,
          ]),
          metricLabel:
            item.metric && metricLabelModel && item.metric[metricLabelModel.key]
              ? metricLabelModel.readableDictionary
                ? metricLabelModel.readableDictionary[item.metric[metricLabelModel.key]] ||
                  item.metric[metricLabelModel.key]
                : item.metric[metricLabelModel.key]
              : null,
        }))
        .sort((a, b) => (a.machine && b.machine ? a.machine.localeCompare(b.machine) : 0))
    : null;
};

export const getStaticValue = (results, machine, convert = false, multiplier = 1) => {
  return results.data.result.length !== 0
    ? results.data.result.map((item) => ({
        machine: item.metric
          ? machine === 'select'
            ? item.metric.host
            : item.metric.device || item.metric.host
          : '',
        value: convert
          ? convertBytes(Number(item.value[1]), units, convert, true)
          : Number(item.value[1]) * multiplier,
      }))
    : null;
};

export const mergeSameMetrics = (results, key) => {
  const resultMergedMetrics = results.data.result.reduce(
    (acc, { metric: currentMetric, values: currentValues }) => {
      const existMetric = acc.find(({ metric }) => metric[key] === currentMetric[key]);
      if (existMetric) {
        return [
          ...acc.filter(({ metric }) => metric[key] !== currentMetric[key]),
          {
            metric: { ...existMetric.metric, ...currentMetric },
            values: [...existMetric.values, ...currentValues],
          },
        ];
      }

      return [...acc, { metric: currentMetric, values: currentValues }];
    },
    []
  );

  return { ...results, data: { ...results.data, result: resultMergedMetrics } };
};
