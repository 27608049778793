import * as types from '../actions/camerasAction';

const initialState = {
  limit: '10',
  page: 0,
  totalResults: 0,
  loading: false,
};

const camerasReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CAMERAS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.GET_CAMERAS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_CAMERAS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case types.GET_CAMERAS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.NEW_CAMERA:
      return {
        ...state,
        loading: false,
      };
    case types.UPDATE_CAMERA:
      return {
        ...state,
        loading: false,
      };
    case types.DELETE_CAMERA:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_CAMERAS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default camerasReducer;
