import moment from 'moment';
import { getTimezoneOffsetInSeconds } from '.';
import { intervalsDate } from '../constants/analytics.constants';

export const setSearchParameter = (name, value) => {
  let url = new URL(window.location.href);
  url.searchParams.set(name, value);
  return url.pathname + url.search;
};

export const deleteSearchParameters = (names) => {
  let url = new URL(window.location.href);
  names.forEach((item) => url.searchParams.delete(item));
  return url.pathname + url.search;
};

export const getSearchParameter = (name) => {
  let url = new URL(window.location.href);
  return url.searchParams.get(name);
};

export const getSearchParametersSize = () => {
  let url = new URL(window.location.href);
  return url.searchParams.size;
};

export const checkSearchParameter = (name) => {
  let url = new URL(window.location.href);
  return url.searchParams.has(name);
};

export const checkSearchString = () => {
  let url = new URL(window.location.href);
  return url.search.length !== 0;
};

export const setSearchParameters = (names, values, removeNames = []) => {
  let url = new URL(window.location.href);
  removeNames.forEach((item) => url.searchParams.delete(item));
  values.forEach((item, index) => {
    if (item !== null) url.searchParams.set(names[index], item);
    else url.searchParams.delete(names[index]);
  });
  return url.pathname + url.search;
};

export const getStartDate = (startDate, timeInterval, timezone) => {
  if (!!timeInterval) {
    const date = new Date(
      moment().unix() * 1000 -
        timeInterval * 1000 -
        getTimezoneOffsetInSeconds(Intl.DateTimeFormat().resolvedOptions().timeZone)
    );
    date.setSeconds(0, 0);

    return date.toISOString();
  } else if (startDate !== 'select') {
    const date = new Date(startDate - getTimezoneOffsetInSeconds(timezone));
    date.setSeconds(0, 0);
    return date.toISOString();
  }
  return null;
};

export const getEndDate = (endDate, timeInterval, timezone, extraTime = 0) => {
  if (!!timeInterval) {
    const date = new Date(
      moment().unix() * 1000 -
        getTimezoneOffsetInSeconds(Intl.DateTimeFormat().resolvedOptions().timeZone) +
        extraTime
    );
    date.setSeconds(0, 0);

    return date.toISOString();
  } else if (endDate !== 'select') {
    const date = new Date(endDate - getTimezoneOffsetInSeconds(timezone) + extraTime);
    date.setSeconds(0, 0);
    return date.toISOString();
  }
};

export const changeSearchDateString = (
  startDate,
  endDate,
  arrowInterval,
  defaultValue,
  withoutTime = false,
  allowFuture = false
) => {
  return startDate &&
    startDate !== 'select' &&
    (defaultValue === null ||
      (startDate !== defaultValue &&
        (endDate === null ||
          moment(endDate).isBefore(moment(), withoutTime ? 'day' : 'minute') ||
          allowFuture)) ||
      (startDate === defaultValue && endDate !== null))
    ? intervalsDate.find((interval) => interval.icon === startDate) && endDate === null
      ? setSearchParameters(['timeInterval'], [startDate], ['startDate', 'endDate', 'interval'])
      : arrowInterval !== null
      ? setSearchParameters(
          ['startDate', 'endDate', 'interval'],
          [
            moment(startDate).format(withoutTime ? 'YYYY-MM-DD' : 'x'),
            moment(endDate).format(withoutTime ? 'YYYY-MM-DD' : 'x'),
            arrowInterval,
          ],
          ['timeInterval']
        )
      : setSearchParameters(
          ['startDate', 'endDate'],
          [
            moment(startDate).format(withoutTime ? 'YYYY-MM-DD' : 'x'),
            moment(endDate).format(withoutTime ? 'YYYY-MM-DD' : 'x'),
          ],
          ['timeInterval', 'interval']
        )
    : deleteSearchParameters(['startDate', 'endDate', 'timeInterval', 'interval']);
};
