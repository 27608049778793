import React from 'react';

import cx from 'classnames';

import { ReactComponent as ArrowPrev } from '../../assets/img/icons/common/ChevroneLeft.svg';
import { ReactComponent as ArrowNext } from '../../assets/img/icons/common/ChevroneRight.svg';
import { TransparentSelect } from '../Filters';

import { usePagination } from '../../hooks/usePagination';

import './TablePagination.scss';

const perPageOptions = [
  { label: '10', value: '10' },
  { label: '20', value: '20' },
  { label: '50', value: '50' },
];
const Pagination = ({
  perPage,
  page,
  mobile = false,
  totalRows,
  siblingCount = 1,
  className,
  onChangePage,
  onChangePerPage,
}) => {
  const paginationRange = usePagination({
    currentPage: page,
    totalCount: totalRows,
    perPage,
    siblingCount,
    pages: mobile ? 1 : 3,
  });

  return (
    <div className="table-pagination" id="tablePagination">
      <div className={cx('table-pagination__container', className)}>
        <span className="table-pagination__indicator">
          {`${page * perPage + 1} - ${
            (page + 1) * perPage <= totalRows ? (page + 1) * perPage : totalRows
          } of ${totalRows}`}
        </span>

        <div className="pagination__wrapper">
          <button
            className="pagination__control-btn"
            disabled={page === 0}
            onClick={() => onChangePage(page - 1)}
          >
            <ArrowPrev />
          </button>

          <ul className="pagination__pages">
            {paginationRange &&
              paginationRange.map((pageIndex, index) => {
                if (pageIndex === 'DOTS') {
                  return (
                    <li key={index} className="pagination__item dots">
                      ...
                    </li>
                  );
                }
                return (
                  <li
                    key={pageIndex}
                    className={cx('pagination__item', { active: pageIndex === page })}
                    onClick={() => onChangePage(pageIndex)}
                  >
                    {pageIndex + 1}
                  </li>
                );
              })}
          </ul>

          <button
            className="pagination__control-btn"
            disabled={!paginationRange || page === paginationRange[paginationRange.length - 1]}
            onClick={() => onChangePage(page + 1)}
          >
            <ArrowNext />
          </button>
        </div>

        <div className="table-pagination__per-page">
          <TransparentSelect
            mobile={mobile}
            value={perPage}
            options={perPageOptions}
            isOpenUp={true}
            onChange={onChangePerPage}
          />
        </div>
      </div>
    </div>
  );
};

export const TablePagination = ({
  perPage,
  mobile = false,
  totalRows,
  isLoading,
  page,
  ...paginationProps
}) => {
  const isShowPagination = totalRows > 10 && !isLoading && (page || page === 0) && perPage;

  return isShowPagination ? (
    <Pagination perPage={perPage} mobile={mobile} page={page} totalRows={totalRows} {...paginationProps} />
  ) : null;
};
