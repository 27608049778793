import request from '../utils/request';

export function getPackages(params) {
  return request({
    url: '/dictionaries/packages',
    method: 'get',
    data: params,
  });
}
export function getPackage(id) {
  return request({
    url: `/dictionaries/packages/${id}`,
    method: 'get',
  });
}
export function updatePackage(uuid, params) {
  return request({
    url: `/dictionaries/packages/${uuid}`,
    method: 'put',
    data: params,
  });
}
