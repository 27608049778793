import React, { useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';

import { isEmpty } from 'lodash';
import { createPortal } from 'react-dom';
import { useHandleOutsideClick } from '../../hooks/useHandleOutsideClick';
import { usePlacement } from '../../hooks/usePlacement';
import { convertTo12HourFormat, convertTo24HourFormat, getTimeMinutes, getTimeWithOffset } from '../../utils';
import { getNearestInterval, getOffsetLabel } from '../../utils/time';

export const TimeControlWithOptions = ({
  className,
  time,
  interval,
  startTime,
  hideInterval = false,
  minTime = false,
  maxTime = false,
  onChange,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [inputString, setInputString] = useState(time ? convertTo12HourFormat(time) : '12:00 am');
  const inputRef = useRef(null);
  const { refs, floatingStyles } = usePlacement('bottom-start', isOpen);

  const listRef = refs.floating;

  useHandleOutsideClick(
    isOpen,
    () => {
      setOpen(false);
    },
    listRef,
    refs.reference
  );

  useEffect(() => {
    if (time) {
      setInputString(convertTo12HourFormat(time));
    }
  }, [time]);

  const handleInputTime = (event) => {
    const inputTime = event.target.value;
    setInputString(inputTime);
    if (isInputValid(inputTime, maxTime)) {
      const value24HFormat = convertTo24HourFormat(inputTime);
      onChange(value24HFormat);

      const nearestElementIndex = getNearestElementIndex(inputTime);
      listRef.current && listRef.current.scrollTo(0, nearestElementIndex * 40);
    }
  };

  const handleBlurInput = () => {
    if (!isInputValid(inputString)) {
      setInputString(convertTo12HourFormat(time));
    }
  };

  const handleSelectTime = (value) => {
    const value24HFormat = convertTo24HourFormat(value);
    onChange(value24HFormat);
    setOpen(false);
  };

  const generateOptions = (interval, startTime = null, minTime, maxTime) => {
    const optionsAmount = Math.floor((24 * 60) / interval);
    let firstTime = getNearestInterval(minTime, interval);
    if (startTime) {
      firstTime = getNearestInterval(startTime, interval);
    }

    const options = [];
    for (let i = 0; i < optionsAmount; i++) {
      const timeWithOffset = getTimeWithOffset(firstTime, interval * i);
      const timeWithOffset12HFormat = convertTo12HourFormat(timeWithOffset);
      const option = {
        label: startTime
          ? `${timeWithOffset12HFormat} ${
              hideInterval
                ? ''
                : `(${getOffsetLabel(
                    (moment(timeWithOffset, 'HH:mm').unix() - moment(startTime, 'HH:mm').unix()) / 60
                  )})`
            }`
          : timeWithOffset12HFormat,
        value: timeWithOffset12HFormat,
      };

      const option24Format = moment(convertTo24HourFormat(option.value), 'HH:mm');
      if (
        (!startTime || convertTo24HourFormat(option.value) !== startTime) &&
        (!minTime || moment(minTime, 'HH:mm').isSameOrBefore(option24Format)) &&
        (!maxTime || option24Format.isSameOrBefore(moment(`${maxTime}`, 'HH:mm'))) &&
        (!startTime ||
          getOffsetLabel((moment(timeWithOffset, 'HH:mm').unix() - moment(startTime, 'HH:mm').unix()) / 60)
            .length !== 0)
      ) {
        options.push(option);
      }
    }
    return options;
  };

  const generatedOptions = useMemo(() => {
    return generateOptions(interval, startTime, minTime, maxTime);
  }, [time, startTime, minTime, maxTime]);

  const getNearestElementIndex = (time) => {
    const time24HFormat = convertTo24HourFormat(time);
    let minValue = convertTo24HourFormat(generatedOptions[0]?.value);
    let minIndex = 0;
    generatedOptions?.forEach((option, index) => {
      const currentValue24HFormat = convertTo24HourFormat(option.value);
      if (
        Math.abs(getTimeMinutes(time24HFormat) - getTimeMinutes(currentValue24HFormat)) <
        Math.abs(getTimeMinutes(time24HFormat) - getTimeMinutes(minValue))
      ) {
        minValue = convertTo24HourFormat(option.value);
        minIndex = index;
      }
    });

    return minIndex;
  };

  const isInputValid = (inputString) => {
    const regex = /^(0[1-9]|1[0-2]):[0-5][0-9] (am|pm)$/;
    return (
      regex.test(inputString) &&
      (!startTime ||
        moment(startTime, 'HH:mm').isBefore(moment(convertTo24HourFormat(inputString), 'HH:mm'))) &&
      (!minTime ||
        moment(minTime, 'HH:mm').isSameOrBefore(moment(convertTo24HourFormat(inputString), 'HH:mm'))) &&
      (!maxTime ||
        moment(convertTo24HourFormat(inputString), 'HH:mm').isSameOrBefore(moment(maxTime, 'HH:mm')))
    );
  };

  const checkClick = () => {
    if (isOpen) {
      inputRef?.current.focus();
      setOpen(false);
    } else {
      inputRef?.current.blur();
      setOpen(true);
    }
  };

  const dropdownMenu = (
    <ul ref={refs.setFloating} style={floatingStyles} className="time-control__options">
      {generatedOptions.map((option) => (
        <li
          key={option.value}
          className="time-control__options-item"
          onClick={(e) => {
            e.stopPropagation();
            handleSelectTime(option.value);
          }}
        >
          {option.label}
        </li>
      ))}
    </ul>
  );

  return (
    <div
      ref={refs.setReference}
      className={cx('time-control-with-options', className, { invalid: !isInputValid(inputString) })}
      onClick={() => {
        checkClick();
      }}
    >
      <input
        ref={inputRef}
        type="text"
        value={inputString}
        onChange={handleInputTime}
        onBlur={handleBlurInput}
      />
      {isOpen && !isEmpty(generatedOptions) && createPortal(dropdownMenu, document.getElementById('root'))}
    </div>
  );
};
