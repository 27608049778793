import React from 'react';
import { ReactComponent as Tune } from '../../../assets/img/icons/common/Tune.svg';

import './FiltersButton.scss';

export const FiltersButton = ({ activeFilters, disabled, onClick }) => {
  return (
    <button className="filters-icon-button" disabled={disabled} onClick={onClick}>
      <Tune />
      {activeFilters !== 0 ? <div className="filters-icon-button__quantity">{activeFilters}</div> : <></>}
    </button>
  );
};
