import { useEffect, useState } from 'react';

import cx from 'classnames';
import moment from 'moment';
import 'moment-timezone';

import { ReactComponent as Calendar } from '../../assets/img/icons/datepicker/Calendar.svg';
import { intervalsDate } from '../../constants/analytics.constants';
import { getUTCNow, getUTCTimestamp } from '../../utils/commonMethods';
import {
  changeSearchDateString,
  checkSearchParameter,
  checkSearchString,
  deleteSearchParameters,
  getSearchParameter,
} from '../../utils/urlFunctions';
import { CustomCalendar } from './Common/CustomCalendar';
import { CustomDateArrow } from './Common/CustomDateArrow';
import { CustomIntervalSelect } from './Common/CustomIntervalSelect';
import './DateTimepicker.scss';

export const DateTimepicker = ({
  history = null,
  selectMax = intervalsDate.length,
  selectMin = 1,
  calendarMax = null,
  addNotification = null,
  value,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  onChange,
  end,
  timeInterval,
  defaultValue = null,
  changedSearch,
  setChangedSearch,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selectOpened, setSelectOpened] = useState(false);
  const [dateIcon, setDateIcon] = useState('');

  useEffect(() => {
    let startDate = getSearchParameter('startDate');
    let endDate = getSearchParameter('endDate');
    let interval = getSearchParameter('timeInterval');
    if (changedSearch) {
      if (
        [startDate, endDate, interval].every((it) => it === null) &&
        value !== 'select' &&
        Date.now() - 100 < (end !== null && end !== 'select' ? end : 0) &&
        (defaultValue === null ||
          intervalsDate.find((interv) => interv.icon === defaultValue).value !== timeInterval)
      ) {
        onChange(
          defaultValue !== null
            ? intervalsDate.find((interv) => interv.icon === defaultValue).value
            : 'select',
          defaultValue !== null ? null : 'select'
        );
      } else if (
        interval !== null
          ? timeInterval === null ||
            intervalsDate.find((interv) => interv.value === timeInterval).icon !== interval
          : startDate !== null
          ? Number(startDate) !== value
          : Number(endDate) !== end
      ) {
        checkAddressLine();
      }
      setChangedSearch(false);
    } else if (
      defaultValue !== null &&
      [startDate, interval, value, end, timeInterval].every((it) => it === null)
    ) {
      onChange(intervalsDate.find((interv) => interv.icon === defaultValue).value, null);
    }
  }, [changedSearch]);

  useEffect(() => {
    if (checkSearchParameter('interval')) {
      setDateIcon(getSearchParameter('interval'));
    } else if (timeInterval !== null) {
      setDateIcon(intervalsDate.find((item) => item.value === Number(timeInterval)).icon);
    } else if (value === 'select') {
      setDateIcon('');
    } else {
      setDateIcon(<Calendar />);
    }
  }, [value, end, timeInterval]);

  const checkAddressLine = () => {
    if (checkSearchString() && checkSearchParameter('startDate')) {
      let startDate = Number(getSearchParameter('startDate'));
      let endDate = Number(getSearchParameter('endDate'));
      if (
        new Date(startDate) &&
        new Date(endDate) &&
        moment(startDate).isBefore(moment()) &&
        moment(endDate).isSameOrBefore(moment())
      ) {
        if (startDate !== value || endDate !== end) {
          onChange(moment(endDate).diff(moment(startDate), 'seconds'), endDate);
        }
      } else {
        history.push(deleteSearchParameters(['startDate', 'endDate', 'interval']));
      }
    } else if (checkSearchString() && checkSearchParameter('timeInterval')) {
      let interval = getSearchParameter('timeInterval');
      if (intervalsDate.find((interv) => interv.icon === interval)) {
        if (timeInterval !== intervalsDate.find((interv) => interv.icon === interval).value) {
          onChange(intervalsDate.find((interv) => interv.icon === interval).value, null);
        }
      } else {
        history.push(deleteSearchParameters(['timeInterval']));
      }
    }
  };

  const onChangeAction = (startDate, endDate = null, arrowInterval = null) => {
    if (startDate !== null && endDate === null) {
      onChange(intervalsDate.find((item) => item.icon === startDate).value, endDate);
    } else if (endDate !== null && startDate !== 'select') {
      onChange(moment(endDate).diff(moment(startDate), 'seconds'), moment(endDate).unix() * 1000);
    } else if (endDate === 'select') {
      onChange('select', 'select');
    }
    if (history !== null) {
      history.push(changeSearchDateString(startDate, endDate, arrowInterval, defaultValue));
    }
  };

  const checkYear = (dates, withoutTime = false) => {
    let withoutYear = withoutTime ? 'MMM D' : 'MMM D, h:mm a';
    let withYear = withoutTime ? 'YYYY MMM D' : 'YYYY MMM D, h:mm a';
    return dates.every((date) => new Date(date).getFullYear() === new Date().getFullYear())
      ? withoutYear
      : withYear;
  };

  const getSelectDates = () => {
    let startTime = Math.floor(value / 1000) * 1000;
    let endTime = Math.floor(end / 1000) * 1000;
    let interval = Date.now() - timeInterval * 1000;
    return (value !== null && value !== 'select') || timeInterval !== null
      ? timeInterval !== null
        ? `${moment(interval)
            .tz(timezone)
            .format(checkYear([interval]))} - ${moment()
            .tz(timezone)
            .format(checkYear([interval]))}`
        : `${moment(startTime).format(checkYear([startTime]))} - ${moment(endTime).format(
            checkYear([endTime, startTime])
          )}`
      : intervalsDate[0].label;
  };

  return (
    <div className="datepicker__container">
      <CustomDateArrow
        start={value}
        end={end}
        timeInterval={intervalsDate.find((item) => item.value === Number(timeInterval || 0))}
        timezone={timezone}
        reverse={true}
        disabled={
          value === 'select' || (moment(value).isSameOrBefore(moment('2019-01-01')) && timeInterval === null)
        }
        onChange={onChangeAction}
      />
      <div
        className={cx('datepicker__range', { active: selectOpened || isOpen })}
        id="datepicker_range"
        onClick={() => {
          if (typeof dateIcon === 'string') {
            setSelectOpened((val) => !val);
          } else {
            setOpen(true);
          }
        }}
      >
        {(!isNaN(end) && !isNaN(value)) || timeInterval !== null ? (
          <div className="datepicker__interval-short">{dateIcon}</div>
        ) : (
          ''
        )}
        <div
          className={`datepicker__interval-text${
            value === 'select' ? ' datepicker__interval-text__placeholder' : ''
          }`}
        >
          {getSelectDates()}
        </div>
      </div>
      <CustomDateArrow
        start={value}
        end={end}
        timeInterval={intervalsDate.find((item) => item.value === Number(timeInterval || 0))}
        timezone={timezone}
        disabled={
          value === 'select' || timeInterval !== null || getUTCTimestamp(end, timezone) >= getUTCNow() - 60000
        }
        onChange={onChangeAction}
      />
      {selectOpened && (
        <CustomIntervalSelect
          value={value}
          timeInterval={intervalsDate.find((item) => item.value === Number(timeInterval || 0))}
          selectMax={selectMax}
          selectMin={selectMin}
          onChange={(start, end) => {
            onChangeAction(start, end);
            setSelectOpened(false);
          }}
          onCancel={() => setSelectOpened(false)}
          openCalendar={() => {
            setSelectOpened(false);
            setOpen(true);
          }}
        />
      )}
      {isOpen && (
        <CustomCalendar
          start={value}
          end={end}
          timeInterval={intervalsDate.find((item) => item.value === Number(timeInterval || 0))}
          withTime={true}
          timezone={timezone}
          selectMax={selectMax}
          selectMin={selectMin}
          calendarMax={calendarMax}
          addNotification={addNotification}
          onChange={(start, end) => {
            onChangeAction(start, end);
            setOpen(false);
          }}
          onCancel={() => setOpen(false)}
        />
      )}
    </div>
  );
};
