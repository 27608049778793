import { animationNames } from '../constants';
import { getStageProgressWidth } from '../utils';

const getAnimationNames = (isFirstStage) => {
  return animationNames.slice(0, isFirstStage ? 1 : 2).join();
};

const getLoadingWidth = (isFirstStage, stageNumber, stages) => {
  if (isFirstStage) return '65px';

  return getStageProgressWidth(stages, stageNumber - 1, false);
};

const getStyle = (row, stages) => {
  const stageNumber = row.status.stage || 1;
  const isFirstStage = stageNumber === 1;

  return {
    width: getLoadingWidth(isFirstStage, stageNumber, stages),
    backgroundColor: row.statusName.color,
    '--statusScale': getStageProgressWidth(stages, stageNumber),
    '--animationNames': getAnimationNames(isFirstStage),
  };
};

export const GoProgress = ({ row, stages }) => {
  return (
    <div className="queue-table__loading" style={getStyle(row, stages)}>
      {row.statusName.label}
    </div>
  );
};
