import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { ReactComponent as Xmark } from '../../assets/img/icons/common/Close.svg';
import { ReactComponent as CaretDown } from '../../assets/img/icons/header/CaretDown.svg';
import { ReactComponent as Location } from '../../assets/img/icons/header/Location.svg';
import { ReactComponent as Menu } from '../../assets/img/icons/header/Menu.svg';
import { ReactComponent as Question } from '../../assets/img/icons/header/Question.svg';
import { ReactComponent as User } from '../../assets/img/icons/header/User.svg';

import { Notifications, PageDropdown } from '../index';

import {
  changeValue,
  getRestaurantAction,
  getRestaurantsAction,
  logoutUser,
} from '../../redux/actions/loginAction';

import {
  AUTHENTIFICATION_LOG,
  BLOCKED_USER,
  CHANGE_EXPIRED_PASSWORD,
  CHICKEN_DASHBOARD,
  LOGIN,
  MULTI_FACTOR_AUTH,
  PASSWORD_RESET,
  SET_PASSWORD,
  SUPPORT_HISTORY,
} from '../../constants/routes.constants';

import logo from '../../assets/img/icons/header/cfa_logo_without_tm.svg';
import { checkSearchParameter, deleteSearchParameters } from '../../utils/urlFunctions';
import './Header.scss';

const Header = ({
  login,
  app,
  showMenu,
  logoutUser,
  getRestaurantsAction,
  getRestaurantAction,
  sidebarToggle,
  changeValue,
}) => {
  const history = useHistory();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (login.loginInfo && login.loginInfo.daysToPasswordExpiration === 0)
      history.push(CHANGE_EXPIRED_PASSWORD);
    else if (!login.verified) history.push(MULTI_FACTOR_AUTH);
    if (login.role && login.role !== 'USER') {
      getRestaurantsAction();
    }
    if (login.role && login.role === 'USER' && login.user && login.user.restaurantUuid) {
      changeValue('restaurantUuid', login.user?.restaurantUuid);
      getRestaurantAction(login.user?.restaurantUuid);
    }
  }, []);

  useEffect(() => {
    if (
      !login.isLoggedIn &&
      ![PASSWORD_RESET, CHANGE_EXPIRED_PASSWORD, MULTI_FACTOR_AUTH, SET_PASSWORD].find((item) =>
        history.location.pathname.includes(item)
      )
    )
      history.push(LOGIN);
    else if (login.loginInfo && login.loginInfo.daysToPasswordExpiration === 0)
      history.push(CHANGE_EXPIRED_PASSWORD);
    else if (!login.verified) history.push(MULTI_FACTOR_AUTH);
    else if (login.isBlocked) history.push(BLOCKED_USER);
    else if (
      login.isLoggedIn &&
      [PASSWORD_RESET, CHANGE_EXPIRED_PASSWORD, MULTI_FACTOR_AUTH, SET_PASSWORD].find((item) =>
        history.location.pathname.includes(item)
      )
    ) {
      history.push(CHICKEN_DASHBOARD);
    }
  }, [login.isLoggedIn, app.currentPage]);

  useEffect(() => {
    login.loginInfo &&
      login.loginInfo.daysToPasswordExpiration === 0 &&
      history.push(CHANGE_EXPIRED_PASSWORD);
  }, [login.loginInfo?.daysToPasswordExpiration]);

  useEffect(() => {
    !login.verified && history.push(MULTI_FACTOR_AUTH);
  }, [login.verified]);

  useEffect(() => {
    if (login.isBlocked) {
      history.push(BLOCKED_USER);
    }
  }, [login.isBlocked]);

  useEffect(() => {
    if (login.role && login.role !== 'USER' && localStorage.getItem('restaurantUuid')) {
      changeValue('restaurantUuid', localStorage.getItem('restaurantUuid'));
    }
  }, [login.role]);

  useEffect(() => {
    if (
      login.role &&
      login.role !== 'USER' &&
      (!localStorage.getItem('restaurantUuid') ||
        localStorage.getItem('restaurantUuid') === 'select' ||
        login.restaurantUuid !== localStorage.getItem('restaurantUuid'))
    ) {
      if (checkSearchParameter('restaurantUuid') || checkSearchParameter('restaurants')) {
        history.push(deleteSearchParameters(['restaurantUuid', 'restaurants']));
      }
      localStorage.setItem('restaurantUuid', login.restaurantUuid);
    }
  }, [login.restaurantUuid]);

  useEffect(() => {
    if (login.role && login.role !== 'USER') getRestaurantsAction();
    if (login.role && login.role === 'USER' && login.user && login.user.restaurantUuid) {
      changeValue('restaurantUuid', login.user?.restaurantUuid);
      getRestaurantAction(login.user?.restaurantUuid);
    }
  }, [login.role, login.user?.restaurantUuid]);

  useEffect(() => {
    if (opened) {
      document.addEventListener('click', checkClick);
    }
  }, [opened]);

  function checkClick(e) {
    if (
      !(e.path || (e.composedPath && e.composedPath())).includes(
        document.getElementById(`navbar_select_restaurant`)
      )
    ) {
      setOpened(false);
      return document.removeEventListener('click', checkClick);
    }
  }
  const handleLogout = () => {
    logoutUser();
    history.push(LOGIN);
  };

  return (
    showMenu && (
      <header id="app-header" className="header">
        <div className="header-main-group">
          <div className="header-main-group__bars header-icons-item" onClick={sidebarToggle}>
            <Menu />
          </div>
          <Link className="header-main-group__logo" to={CHICKEN_DASHBOARD}>
            <img alt="logo" src={logo} />
          </Link>
          {login.role && login.role !== 'USER' && login.restaurants.length !== 0 && (
            <div id="navbar_select_restaurant" className="header-global__container">
              <div
                className="global-option__container"
                onClick={() => {
                  setOpened((prev) => !prev);
                }}
              >
                <Location />
                <div className="global-option__container-text">
                  <div
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={
                      typeof login.restaurants.find((rest) => rest.value === login.restaurantUuid) !==
                      'undefined'
                        ? login.restaurants.find((rest) => rest.value === login.restaurantUuid).label
                        : ''
                    }
                  >
                    {login.restaurantUuid === 'select'
                      ? 'Global'
                      : typeof login.restaurants.find((rest) => rest.value === login.restaurantUuid) !==
                        'undefined'
                      ? login.restaurants.find((rest) => rest.value === login.restaurantUuid).number
                      : ''}
                  </div>
                </div>
                <div
                  onClick={(e) => {
                    if (login.restaurantUuid !== 'select') {
                      e.stopPropagation();
                      changeValue('restaurantUuid', 'select');
                      setOpened(false);
                    }
                  }}
                  className="global-option__container-icon"
                >
                  {login.restaurantUuid === 'select' ? <CaretDown /> : <Xmark />}
                </div>
              </div>
              {opened && (
                <ul className="global-option__container__ul">
                  {(login.restaurantUuid === 'select' ? login.restaurants.slice(1) : login.restaurants).map(
                    (option) => (
                      <li
                        key={option.value}
                        value={option.value}
                        className="navbar--option-li"
                        onClick={(e) => {
                          e.stopPropagation();
                          changeValue('restaurantUuid', option.value);
                          setOpened(false);
                        }}
                      >
                        <div
                          className="global-option__item"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title={`${option.number} - ${option.label}`}
                        >
                          <div className="option--item-label">{option.label}</div>
                          <div className="option--item-number">{option.number}</div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          )}
        </div>
        <div className="header-icons-group">
          {login.role && login.role === 'ADMIN' && <Notifications />}
          <div className="header-icons-item" onClick={() => history.push(SUPPORT_HISTORY)}>
            <Question />
          </div>
          <PageDropdown
            icon={User}
            options={[
              { label: 'My Account', function: () => history.push(AUTHENTIFICATION_LOG) },
              { label: 'Sign Out', function: () => handleLogout() },
            ]}
          />
        </div>
      </header>
    )
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
  app: state.app,
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
    changeValue: (key, value) => dispatch(changeValue(key, value)),
    getRestaurantsAction: () => dispatch(getRestaurantsAction()),
    getRestaurantAction: (uuid) => dispatch(getRestaurantAction(uuid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
