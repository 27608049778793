import { getAnalytics } from '../../api/analytics';
import { handleActionAbortController } from '../../utils/handleActionAbortController';

export const CHANGE_EVENTS_ANALYTIC_VALUE = 'CHANGE_EVENTS_ANALYTIC_VALUE';
export const CHANGE_EVENTS_ANALYTIC_SEVERAL_VALUES = 'CHANGE_EVENTS_ANALYTIC_SEVERAL_VALUES';

export const RESET_EVENTS_ANALYTIC_STORE = 'RESET_EVENTS_ANALYTIC_STORE';

export const GET_EVENTS_ANALYTIC = 'GET_EVENTS_ANALYTIC';
export const GET_EVENTS_ANALYTIC_SUCCESS = 'GET_EVENTS_ANALYTIC_SUCCESS';
export const GET_EVENTS_ANALYTIC_ERROR = 'GET_EVENTS_ANALYTIC_ERROR';

export const getEventsAnalyticAction = (function () {
  let prevController;

  return (params) => {
    return async (dispatch) => {
      const abortController = handleActionAbortController(prevController);
      prevController = abortController;
      dispatch({ type: GET_EVENTS_ANALYTIC, payload: params.cursorDirection === 'FIRST' });
      try {
        const response = await getAnalytics(params, abortController.signal);
        const { success, results } = response;
        if (success && !abortController.signal.aborted) {
          dispatch({ type: GET_EVENTS_ANALYTIC_SUCCESS, payload: results });
        }
      } catch (error) {
        if (!abortController.signal.aborted) {
          console.log(error);
          dispatch({ type: GET_EVENTS_ANALYTIC_ERROR });
        }
      }
    };
  };
})();

export const changeValue = (key, value) => ({
  type: CHANGE_EVENTS_ANALYTIC_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeSeveralValues = (value) => ({
  type: CHANGE_EVENTS_ANALYTIC_SEVERAL_VALUES,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_EVENTS_ANALYTIC_STORE,
});
