import { faCalendarDays, faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as Warning } from '../../assets/img/icons/common/WarningAmber.svg';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/datepicker/Calendar.svg';

import { TimeControlWithOptions } from '../TimeControls/TimeControlWithOptions';

import { computeDurationBetweenTimes, getTimeWithOffset } from '../../utils';

import './inputDateTime.scss';

const DROPDOWN_PANEL_WIDTH = 350;

export const InputDateTime = ({
  title,
  className,
  minDate,
  maxDate,
  datetime,
  onChange,
  onChangeDuration,
  error,
  errorMessage,
  interval,
  duration,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  enableDuration,
  disableTime,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [openMode, setOpenMode] = useState('left');
  const [display, setDisplay] = useState('date');
  const [timezoneOffset, setTimezoneOffset] = useState('+00:00');

  const fieldRef = useRef(null);
  const dropdownPanelRef = useRef(null);

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('resize', checkOpenMode);
    checkOpenMode();

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('resize', checkOpenMode);
    };
  }, []);

  useEffect(() => {
    setTimezoneOffset(momentTZ.tz(timezone).format('Z'));
  }, [timezone]);

  useEffect(() => {
    if (!isOpen) {
      setDisplay('date');
    } else {
      checkOpenMode();
    }
  }, [isOpen]);

  const handleMouseDown = (event) => {
    if (
      fieldRef.current &&
      !fieldRef.current.contains(event.target) &&
      typeof event.target.className.includes === 'function' &&
      !event.target.className.includes('time-control__options')
    ) {
      setOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (dropdownPanelRef.current && (event.which === 13 || event.keyCode === 13 || event.charCode === 13)) {
      setOpen(false);
    }
  };

  const checkOpenMode = () => {
    if (!fieldRef.current) setOpenMode('left');
    const fieldWidth = fieldRef.current.clientWidth;

    fieldWidth < DROPDOWN_PANEL_WIDTH ? setOpenMode('right') : setOpenMode('left');
  };

  const handleChangeDate = (value) => {
    const newDate = moment(value).format('YYYY-MM-DD');
    if (disableTime) {
      onChange(newDate);
      setOpen(false);
    } else if (datetime) {
      const datetimeParts = datetime.split('T');
      onChange(datetimeParts.map((part, index) => (index === 0 ? newDate : part)).join('T'));
    } else {
      onChange(`${newDate}T00:00${timezoneOffset}`);
    }
    !disableTime && setDisplay('time');
  };

  const handleChangeTime = (value) => {
    if (datetime) {
      const datePart = datetime.split('T')[0];
      const newDateTime = `${datePart}T${value}${timezoneOffset}`;
      onChange(newDateTime);
    } else {
      const datePart = momentTZ.tz(timezone).format().split('T')[0];
      const newDateTime = `${datePart}T${value}${timezoneOffset}`;
      onChange(newDateTime);
    }
  };

  const handleChangeDuration = (value) => {
    if (datetime) {
      const timePart = datetime.split('T')[1];
      const [hours, minutes] = timePart.slice(0, 5).split(':');
      const duration = computeDurationBetweenTimes(`${hours}:${minutes}`, value);
      onChangeDuration(duration * 60);
    } else {
      onChangeDuration(0);
    }
  };

  const timeValue = useMemo(() => {
    if (!datetime || disableTime) return '00:00';
    const timePart = datetime.split('T')[1];
    const [hours, minutes] = timePart.slice(0, 5).split(':');

    return `${hours}:${minutes}`;
  }, [datetime]);

  const secondTimeValue = useMemo(() => {
    if (disableTime) return '00:00';
    const timePart = datetime ? datetime.split('T')[1] : '00:00';
    const [hours, minutes] = timePart.slice(0, 5).split(':');

    return getTimeWithOffset(`${hours}:${minutes}`, Math.floor(duration / 60));
  }, [datetime, duration]);

  const labelValue = useMemo(() => {
    if (!datetime) return null;

    const datePart = datetime.split('T')[0];

    const dateLabel = momentTZ.tz(datetime, timezone).format(disableTime ? 'YYYY MMM D' : 'YYYY MMM D,');
    const startTime = momentTZ.tz(datetime, timezone).format('h:mm a');
    const endTime = moment(`${datePart}T${secondTimeValue}`).format('h:mm a');

    return {
      date: dateLabel,
      startTime: startTime,
      endTime: endTime,
    };
  }, [datetime, duration]);

  return (
    <div
      ref={fieldRef}
      className={cx('datetime-input__container', className, {
        active: isOpen,
        error: error || errorMessage,
        'not-empty': datetime,
      })}
      onClick={() => setOpen(true)}
    >
      <div className="datetime-input__input">
        {!datetime ? (
          <input type="text" placeholder={title} readOnly={true} value={''} />
        ) : (
          <>
            <span className="datetime-input__field-label">{title}</span>
            <span className="datetime-input__label-date">{labelValue.date}</span>{' '}
            {!disableTime && (
              <>
                <span
                  className="datetime-input__label-time"
                  onClick={() => {
                    setOpen(true);
                    setDisplay('time');
                  }}
                >
                  {labelValue.startTime}
                </span>{' '}
                {enableDuration && (
                  <>
                    {' – '}
                    <span
                      className="datetime-input__label-time"
                      onClick={() => {
                        setOpen(true);
                        setDisplay('time');
                      }}
                    >
                      {labelValue.endTime}
                    </span>
                  </>
                )}{' '}
              </>
            )}
          </>
        )}

        {(error || errorMessage) && (
          <div
            className="datetime-input__error"
            data-tooltip-id="datetime-input-tooltip"
            data-tooltip-content={errorMessage}
          >
            <Warning />
          </div>
        )}

        <div
          className="datetime-input__icon"
          onClick={(e) => {
            e.stopPropagation();
            setOpen((prev) => !prev);
          }}
        >
          <CalendarIcon />
        </div>
      </div>

      {isOpen && (
        <div ref={dropdownPanelRef} className={`datetime-input__dropdown-panel ${openMode}`}>
          {!disableTime && (
            <div className="navigation-tabs">
              <div
                className={cx('navigation-tabs__item', { active: display === 'date' })}
                onClick={() => setDisplay('date')}
              >
                <FontAwesomeIcon icon={faCalendarDays} />
              </div>
              <div
                className={cx('navigation-tabs__item', { active: display === 'time' })}
                onClick={() => setDisplay('time')}
              >
                <FontAwesomeIcon icon={faClock} />
              </div>
            </div>
          )}
          {display === 'date' && (
            <Calendar
              className=""
              showDateDisplay={false}
              date={datetime ? new Date(datetime.split('T')[0]) : null}
              onChange={handleChangeDate}
              minDate={minDate ? minDate : new Date()}
              maxDate={maxDate}
            />
          )}
          {display === 'time' && (
            <div className="time-control">
              <TimeControlWithOptions interval={interval} time={timeValue} onChange={handleChangeTime} />
              {enableDuration && (
                <>
                  {' – '}
                  <TimeControlWithOptions
                    interval={interval}
                    time={secondTimeValue}
                    startTime={timeValue}
                    onChange={handleChangeDuration}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
      {errorMessage && <Tooltip id="datetime-input-tooltip" variant={'error'} />}
    </div>
  );
};
