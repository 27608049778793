import { isNil } from 'lodash';
import { HeaderCell } from '../Cells';

export const MobileTHead = ({ headerOptions, hideMobileCell }) => {
  const getHeaderContent = () => {
    const beforeChevron = [];
    const afterChevron = [];

    headerOptions.forEach((it) => {
      if (it.mobile) {
        if (it.beforeChevron) {
          beforeChevron.push(it);
        } else {
          afterChevron.push(it);
        }
      }
    });

    return (
      <tr>
        {beforeChevron.map((cell, index) => (
          <HeaderCell mobile key={index} cell={cell} />
        ))}
        {!hideMobileCell && <th className="mobile-table__header-cell" />}
        {afterChevron.map((cell, index) => (
          <HeaderCell mobile key={index} cell={cell} />
        ))}
      </tr>
    );
  };

  return !isNil(headerOptions) ? <thead className="mobile-table__thead">{getHeaderContent()}</thead> : null;
};
