import { useEffect } from 'react';

import { ReactComponent as Close } from '../../assets/img/icons/common/Close.svg';

import './Modal.scss';

export const NotificationModal = ({ title, modalBody, onCancel }) => {
  useEffect(() => {
    document.addEventListener('click', checkClick);
  }, []);

  function checkClick(e) {
    if (!(e.path || (e.composedPath && e.composedPath())).includes(document.getElementById(`side_modal`))) {
      onCancel();
      return document.removeEventListener('click', checkClick);
    }
  }

  return (
    <div className="modal modal__side-container" tabIndex="-1" role="dialog" aria-hidden="true">
      <div role="document" id="side_modal" className="modal-dialog modal__side modal__pattern-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
            <div className="modal-close__button" onClick={() => onCancel()}>
              <Close />
            </div>
          </div>
          <div className="modal-body modal-body__scrolled">{modalBody}</div>
        </div>
      </div>
    </div>
  );
};
