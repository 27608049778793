import { MobileTHead } from './MobileTHead';
import { MobileTBody } from './MobileTBody';
import { useOpenedRows } from './useOpenedRows';

export const MobileTable = ({ headerOptions = null, rows, isLoading, emptyText, hideMobileCell = false }) => {
  const [openedRows, checkOpenedRows] = useOpenedRows();

  return (
    <table className="mobile-table__table">
      <MobileTHead headerOptions={headerOptions} hideMobileCell={hideMobileCell} />
      <MobileTBody
        rows={rows}
        isLoading={isLoading}
        emptyText={emptyText}
        openedRows={openedRows}
        checkOpenedRows={checkOpenedRows}
        headerOptions={headerOptions}
        hideMobileCell={hideMobileCell}
      />
    </table>
  );
};
