import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import WebRTCServiceClient from '../../services/WebRTCServiceClient';

import { ReactComponent as Times } from '../../assets/img/icons/common/Times.svg';

export const MiniPlayerLive = ({ miniPlayer, onWebRTCError, changeValue, closePlayer }) => {
  const history = useHistory();
  const video = useRef(null);

  const webrtcRef = useRef(null);

  useEffect(() => {
    webrtcRef.current = new WebRTCServiceClient();

    return () => {
      webrtcRef.current?.stopStream();
      webrtcRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (!miniPlayer.restart) {
      changeValue('tries', 0);
      restartStream(miniPlayer.streamId);
    }
  }, [miniPlayer.restaurantUuid, miniPlayer.streamId]);

  useEffect(() => {
    if (miniPlayer.restart) {
      if (video.current.srcObject) {
        video.current.srcObject = null;
      }
      changeValue('restart', false);

      miniPlayer.tries > 1
        ? setTimeout(() => {
            restartStream(miniPlayer.streamId);
          }, 5000)
        : restartStream(miniPlayer.streamId);
    }
  }, [miniPlayer.restart]);

  const reconnectJanus = (triesQty) => {
    if (triesQty <= 5) {
      changeValue('tries', triesQty);
      changeValue('restart', true);
    } else {
      changeValue('tries', 0);
      onWebRTCError();
    }
  };

  const restartStream = (streamId) => {
    if (video.current.srcObject) {
      video.current.srcObject = null;
    }

    webrtcRef.current?.stopStream();

    if (miniPlayer.restaurantUuid !== 'select' && streamId && miniPlayer.janusHost) {
      webrtcRef.current?.startStream(
        miniPlayer.janusHost,
        streamId ? streamId : null,
        null,
        video.current,
        null,
        {
          onError: (error, tries) => reconnectJanus(tries),
          onGetStreamsList: () => {},
        }
      );
    }
  };

  return (
    <>
      <div className={cx('miniplayer-video--container')}>
        <div className="video-header">
          <div className="video-header--tools">
            <button
              className="tool-btn link"
              onClick={() => {
                webrtcRef.current?.stopStream();
                closePlayer();
                history.push(miniPlayer.returnLink);
              }}
            >
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </button>
            <button
              className="tool-btn times"
              onClick={(e) => {
                e.stopPropagation();
                webrtcRef.current?.stopStream();
                closePlayer();
              }}
            >
              <Times />
            </button>
          </div>
        </div>
        <video ref={video} playsInline={true} autoPlay muted={true}>
          <p>Your browser doesn&#39;t support HTML5 video.</p>
        </video>
      </div>
    </>
  );
};
