export const performancesColumns = [
  {
    label: 'Time in Holding (min)',
  },
  {
    label: 'Percent',
  },
  {
    label: 'Weight (kg)',
  },
];
