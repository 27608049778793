import * as types from '../actions/potatoMaterialsAction';

const initialState = {
  limit: '20',
  page: 0,
  totalResults: 0,
  loading: false,
  sortBy: 'materialCode',
  orderDirection: 'ASC',
};

const potatoMaterialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_POTATO_MATERIALS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case types.CHANGE_POTATO_MATERIALS_SEVERAL_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case types.GET_POTATO_MATERIALS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_POTATO_MATERIALS_SUCCESS:
      return {
        ...state,
        totalResults: action.payload.total,
        loading: false,
      };

    case types.GET_POTATO_MATERIALS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.NEW_POTATO_MATERIAL:
      return {
        ...state,
        loading: false,
      };

    case types.NEW_POTATO_MATERIAL_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };

    case types.UPDATE_POTATO_MATERIAL:
      return {
        ...state,
        loading: false,
      };

    case types.UPDATE_POTATO_MATERIAL_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };
    case types.DELETE_POTATO_MATERIAL:
      return {
        ...state,
        loading: false,
      };

    case types.DELETE_POTATO_MATERIAL_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults - 1,
      };

    case types.RESET_POTATO_MATERIALS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default potatoMaterialsReducer;
