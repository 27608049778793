import request from '../utils/request';

export function getConveyorEvents(params, signal) {
  return request({
    url: '/conveyorTimerCycleHistory',
    method: 'get',
    data: params,
    signal,
  });
}

export function getConveyorTotal(params, signal) {
  return request({
    url: '/conveyorTimerCycleHistory/total',
    method: 'get',
    data: params,
    signal,
  });
}

export function getExelExport(params) {
  return request({
    url: '/conveyorTimerCycleHistory/xls',
    method: 'get',
    data: params,
    responseType: 'arraybuffer',
  });
}

export function deleteConveyor(uuid) {
  return request({
    url: `/conveyorTimerCycleHistory/${uuid}`,
    method: 'delete',
  });
}
