import { TableContentContainer } from '../../TableContentContainer';
import { DesktopTableContent } from './DesktopTableContent';

const DesktopTBody = ({ rows, isLoading, emptyText }) => {
  return (
    <TableContentContainer rows={rows} isLoading={isLoading} emptyText={emptyText}>
      <DesktopTableContent rows={rows} />
    </TableContentContainer>
  );
};

export default DesktopTBody;
