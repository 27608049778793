import { getCameras, createCamera, deleteCamera, updateCamera } from '../../api/cameras';

export const CHANGE_CAMERAS_VALUE = 'CHANGE_CAMERAS_VALUE';
export const RESET_CAMERAS_STORE = 'RESET_CAMERAS_STORE';

export const GET_CAMERAS = 'GET_CAMERAS';
export const GET_CAMERAS_SUCCESS = 'GET_CAMERAS_SUCCESS';
export const GET_CAMERAS_ERROR = 'GET_CAMERAS_ERROR';

export const NEW_CAMERA = 'NEW_CAMERA';
export const UPDATE_CAMERA = 'UPDATE_CAMERA';
export const DELETE_CAMERA = 'DELETE_CAMERA';

export const getCamerasAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_CAMERAS });
    try {
      const response = await getCameras(params);
      const { success, results } = response;
      if (success) {
        setData([...results.content]);
        dispatch(changeValue('totalResults', results.total));
        dispatch({ type: GET_CAMERAS_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_CAMERAS_ERROR });
    }
  };
};

export const createCameraAction = (params) => {
  return async (dispatch) => {
    await createCamera(params);
    dispatch({ type: NEW_CAMERA });
  };
};

export const updateCameraAction = (uuid, params) => {
  return async (dispatch) => {
    await updateCamera(uuid, params);
    dispatch({ type: UPDATE_CAMERA });
  };
};

export const deleteCameraAction = (uuid) => {
  return async (dispatch) => {
    await deleteCamera(uuid);
    dispatch({ type: DELETE_CAMERA });
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CAMERAS_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_CAMERAS_STORE,
});
